var render = function render(){var _vm=this,_c=_vm._self._c;return _c('z-list',{staticClass:"z-filelist",class:[
        `z-filelist--size-${_vm.size}`,
        `z-filelist--theme-${_vm.theme}`,
        {
            'z-filelist--horizontal': _vm.horizontal,
            'z-filelist--vertical': !_vm.horizontal
        }
    ],attrs:{"bullets":false,"tag":"ul"}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fb': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M46.5 24c0 12.426-10.074 22.5-22.5 22.5S1.5 36.426 1.5 24 11.574 1.5 24 1.5 46.5 11.574 46.5 24zm1.5 0c0 13.255-10.745 24-24 24S0 37.255 0 24 10.745 0 24 0s24 10.745 24 24zm-27.833 2.766V37.5h7.476V26.766h3.288l.974-7.266h-4.262v-1.453c0-.244.017-.404.037-.503.115-.03.311-.06.606-.06h3.785v-6.641l-1.336-.147a32.058 32.058 0 00-3.497-.196c-2.034 0-3.763.622-5.087 1.919-1.427 1.348-1.984 3.153-1.984 5.206V19.5H16.5v7.266h3.667zm1.5-9.141c0-1.781.476-3.14 1.523-4.125 1-.984 2.334-1.5 4.048-1.5 1.333 0 2.476.094 3.333.188v3.796h-2.285c-.857 0-1.477.188-1.762.563-.286.328-.381.844-.381 1.5V21h4.047l-.571 4.266h-3.476V36h-4.476V25.266H18V21h3.667v-3.375z" _fill="#C4C4C4"/>'
  }
})

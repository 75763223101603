<template>
    <div class="forwarding-and-logistics">
        <z-button
            @click="openForm"
            kind="primary"
            rounded
            tag="button"
        >{{text.button}}</z-button>
        <span class="forwarding-and-logistics__form-indicator" v-if="form.isSent && form.isSuccessfullySent">{{ text.formSuccessfullySent }}</span>
        <span class="forwarding-and-logistics__form-indicator" v-if="form.isSent && !form.isSuccessfullySent">{{ text.formSentWithError }}</span>
        <z-modal id="modalForwardingLogisticsForm" class="careers-vacancies__modal" @close="modalClosed">
            <div class="careers-vacancies__modal-body subscribe">
                <form
                    class="subscribe__container"
                    @submit.prevent="submit"
                >
                    <div class="subscribe__content">
                        <h3>{{text.formLead}}</h3>
                    </div>
                    <div class="subscribe__content">
                        <div class="row between-default">
                            <div class="col-default-6 col-mobile-12">
                                <z-input
                                    ref="mail"
                                    name="mail"
                                    type="email"
                                    v-model="form.userMail"
                                    :placeholder="text.field.email"
                                    required
                                ></z-input>
                                <z-input
                                    ref="company"
                                    name="company"
                                    v-model="form.company"
                                    :placeholder="text.field.company"
                                ></z-input>
                            </div>
                            <div class="col-default-6 col-mobile-12">
                                <z-input
                                    ref="name"
                                    name="name"
                                    v-model="form.userName"
                                    :placeholder="text.field.fullName"
                                ></z-input>
                                <div class="subscribe__agreement">
                                    <z-checkbox
                                        name="subscribtionAggrement"
                                        @change="form.checkbox = !form.checkbox"
                                        :class="'subscribe__required-field'"
                                    >
                                        {{ text.field.agreement.text }}
                                        <z-link
                                            :href="`/${$root.lang}/terms/privacy-notice/`"
                                        >{{ text.field.agreement.link }}</z-link>
                                    </z-checkbox>
                                    <span v-if="form.isSubmitting && !form.checkbox" class="subscribe__error">{{ text.field.agreement.error }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="subscribe__content">
                        <div class="row">
                            <div class="col-default-12">
                                <textarea
                                    class="z-input z-input__textarea"
                                    v-model="form.message"
                                    :placeholder="text.field.textarea"
                                ></textarea>
                            </div>
                            <div class="col-default-6 col-mobile-12">
                                <span class="subscribe__tip">
                                    <span>*</span> {{ text.field.required }}
                                </span>
                                <z-button
                                    type="submit"
                                    rounded
                                >{{ text.field.subscribe }}</z-button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </z-modal>
    </div>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { localize } from '@/utils/i18n'
import { debounce } from 'throttle-debounce'
import { sendFormData } from '@/api/services-form'

export default {
    name: 'forwarding-and-logistics-form',
    props: {
    },
    data () {
        return {
            about: this.$root.app.components[this.component],
            text: {
                button: localize({
                    ru: 'Запросить условия',
                    en: 'Offer request'
                }),
                formSuccessfullySent: localize({
                    ru: 'Запрос успешно отправлен',
                    en: 'Form is successfully sent'
                }),
                formSentWithErrors: localize({
                    ru: 'Произошла ошибка. Попробуйте чуть позже',
                    en: 'There are some errors. Please, try later'
                }),
                formLead: localize({
                    ru: 'Пожалуйста, заполните форму',
                    en: 'Please fill in the form'
                }),
                field: {
                    fullName: localize({
                        'ru': 'Введите ФИО',
                        'en': 'Your name'
                    }),
                    email: localize({
                        'ru': 'Введите свой E-mail',
                        'en': 'Email'
                    }),
                    company: localize({
                        'ru': 'Название компании',
                        'en': 'Company name'
                    }),
                    textarea: localize({
                        'ru': 'Введите запрос',
                        'en': 'Enter your request'
                    }),
                    required: localize({
                        'ru': 'Поля, обязательные для заполнения',
                        'en': 'Required fields'
                    }),
                    subscribe: localize({
                        'ru': 'Запросить условия',
                        'en': 'Offer request'
                    }),
                    agreement: {
                        text: localize({
                            'ru': 'Согласен с ',
                            'en': 'I agree with '
                        }),
                        link: localize({
                            'ru': 'политикой обработки персональных данных',
                            'en': 'the personal data processing policy'
                        }),
                        error: localize({
                            'ru': 'Поле обязательно для заполнения',
                            'en': 'Required field'
                        })
                    },
                    rubrics: {
                        error: localize({
                            'ru': 'Выберите новости интересующих вас направлений',
                            'en': 'Please select news directions you are interested in'
                        })
                    }
                }
            },
            form: {
                userMail: '',
                userName: '',
                company: '',
                message: '',
                checkbox: false,
                isSubmitting: false,
                isSent: false,
                isSuccessfullySent: false
            },
            gKey: '6LcWjt4UAAAAAEHUXE1KYNBAgxm5gDuWtCfi3ra5'
        }
    },
    methods: {
        valid () {
            this.$refs.mail.valid()

            let valid = !this.$refs.mail.error &&
                        this.form.checkbox

            return valid
        },
        resetAll () {
            this.form = {
                ...this.form,
                userMail: '',
                userName: '',
                company: '',
                message: '',
                checkbox: false
            }
        },
        openForm () {
            this.$root.$bus.$emit('open-modal', {
                id: `modalForwardingLogisticsForm`,
                index: 0
            })
        },
        modalClosed () {
            console.log('closed')
        },
        submit () {
            this.form.isSubmitting = true
            let isValid = this.valid()
            if (isValid) this.send(this.form, this.gKey, this)
        },
        send: debounce(500, (params, key, that) => {
            // const submitBtn = that.$refs.submitbtn.$el

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(key, {
                    action: 'reCaptcha'
                }).then((token) => {
                    let data = new FormData()

                    params.reCaptcha = token

                    /* eslint-disable no-unused-vars */
                    for (let key in params) {
                        data.append(key, params[key])
                    }
                    /* eslint-enable no-unused-vars */

                    sendFormData(data).then(res => {
                        showNotyfications(res.status === 'success' ? 'Отправка успешна' : 'Ошибка отправки', { type: res.status })
                        params.isSent = res.status
                        params.isSubmitting = false

                        if (params.isSent === 'success') {
                            params.isSuccessfullySent = true
                            let closeBtn = document.querySelector('.z-modal__close')
                            closeBtn.click()
                            that.resetAll()
                        }
                    })
                }, (reason) => {
                    showNotyfications(reason, { type: 'error' })
                })
            })
        })

    },
    created () {
        const script = document.createElement('script')

        script.async = true
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.gKey}`

        document.head.appendChild(script)
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <div class="sustainability">
        <div class="row" equal-height=".card">
            <div class="col-default-8 col-tablet-6 col-mobile-12">
                <card
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>{{ text.overviewTitle }}</template>
                    <template v-slot:content>
                        <div v-html="text.overviewText"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" :href="`/${lang}/sustainability/overview/`" kind="text">
                            {{ text.more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="grey"
                            size="l"
                        >
                            <z-icon name="care" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            <!-- <div class="col-default-4 col-tablet-6 col-mobile-12">
                <card
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>{{ text.esgTitle }}
                        <z-tooltip
                            content="The use by&nbsp;Global Ports Investments PLC of&nbsp;any MSCI&nbsp;ESG research llc or&nbsp;its affiliates (&laquo;MSCI&raquo;) data, and the use of&nbsp;MSCI&nbsp;logos, trademarks, service marks or&nbsp;index names herein, do&nbsp;not constitute a&nbsp;sponsorship, endorsement, recommendation, or&nbsp;promotion of&nbsp;Global Ports Investments PLC by&nbsp;MSCI. MSCI services and data are the property of&nbsp;MSCI&nbsp;or its information providers, and are provided &lsquo;as-is&rsquo; and without warranty. MSCI names and logos are trademarks or&nbsp;service marks of&nbsp;MSCI.">
                        </z-tooltip>
                    </template>
                    <template v-slot:content>
                        <z-image
                            src="/images/components/sustainability/esg.png"
                        ></z-image>
                    </template>
                </card>
            </div> -->
            <div class="col-default-4 col-tablet-6 col-mobile-12">
                <card
                    style="min-height: 350px"
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>{{ text.safetyTitle }}</template>
                    <template v-slot:content>
                        <div class="z-key-indicator z-key-indicator--size-m">
                            <div class="z-key-indicator__body">
                                <div class="z-key-indicator__group">
                                    <span class="z-key-indicator__value">{{ text.indicatorValue }}</span>
                                    <div class="z-key-indicator__unit">
                                        <span><z-tooltip :content="text.safetyTooltip"><span>LTIFR</span></z-tooltip> {{ text.indicatorUnit }}</span>
                                    </div>
                                </div>
                                <span class="z-key-indicator__name" v-html="text.indicatorName"></span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" :href="`/${lang}/sustainability/safety/`" kind="text">
                            {{ text.more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="grey"
                            size="l"
                        >
                            <z-icon name="safety" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
        </div>
        <div class="row" equal-height=".card-group">
            <div class="col-default-8 col-tablet-12">
                <card
                    theme="background"
                    overlay="rgba(0,0,0,0.45)"
                    image="/images/hero/company/about.jpg"
                    class="card-group"
                    equal-height-max-breakpoint="tablet"
                >
                    <template v-slot:title>{{ text.engagementTitle }}</template>
                    <template v-slot:content>
                        <div v-html="text.engagementText" style="max-width: 640px;"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" theme="white" :href="`/${lang}/sustainability/engagement/`" kind="text">
                            <span>{{ text.more }}</span>
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                </card>
            </div>
            <div class="col-default-4 col-tablet-12">
                <div class="card-group" equal-height-max-breakpoint="tablet">
                    <div class="row" equal-height=".card">
                        <div class="col-default-12 col-tablet-6 col-v-mobile-12">
                            <card
                                theme="background"
                                overlay="rgba(0,0,0,0.45)"
                                image="/images/components/company-about/4.jpg"
                                :link="`/${lang}/sustainability/people/`"
                                :mark="false"
                                padding="175px"
                                equal-height-max-breakpoint="v-mobile"
                            >
                                <template v-slot:title>{{ text.peopleTitle }}</template>
                            </card>
                        </div>
                        <div class="col-default-12 col-tablet-6 col-v-mobile-12">
                            <card
                                theme="background"
                                overlay="rgba(0,0,0,0.45)"
                                image="/images/hero/company/strategy.jpg"
                                :link="`/${lang}/sustainability/environmental/`"
                                :mark="false"
                                padding="175px"
                                equal-height-max-breakpoint="v-mobile"
                            >
                                <template v-slot:title>{{ text.environmentalTitle }}</template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-default-12">
                <card
                    theme="background"
                    overlay="rgba(0,0,0,0.45)"
                    image="/images/hero/investors/dividends.jpg"
                    style="min-height: 285px"
                    :mark="false"
                >
                    <template v-slot:title>{{ text.communitiesTitle }}</template>
                    <template v-slot:content>
                        <div v-html="text.communitiesText" style="max-width: 640px;"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" theme="white" :href="`/${lang}/sustainability/communities/`" kind="primary" rounded>
                            <span>{{ text.more }}</span>
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                </card>
            </div>
        </div>
        <div class="row" equal-height=".card">
            <div class="col-default-4 col-tablet-6 col-mobile-12">
                <card
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>
                        <span v-html="text.reportsTitle"></span>
                    </template>
                    <template v-slot:content>
                        <div v-html="text.reportsText"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" :href="`/${lang}/sustainability/reports/`" kind="text">
                            {{ text.more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="grey"
                            size="l"
                        >
                            <z-icon name="file" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            <div class="col-default-4 col-tablet-6 col-mobile-12">
                <card
                    fullWidth
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>
                        <span v-html="text.governanceTitle"></span>
                    </template>
                    <template v-slot:content>
                        <div v-html="text.governanceText"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" :href="`/${lang}/sustainability/governance/`" kind="text">
                            {{ text.more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="grey"
                            size="l"
                        >
                            <z-icon name="file" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            <div class="col-default-4 col-tablet-12">
                <card
                    equal-height-max-breakpoint="tablet"
                >
                    <template v-slot:title>{{ text.anticorruptionTitle }}</template>
                    <template v-slot:content>
                        <div v-html="text.anticorruptionText"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" :href="`/${lang}/sustainability/anti-corruption/`" kind="text">
                            {{ text.more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="grey"
                            size="l"
                        >
                            <z-icon name="eye" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'mainpage',
    data () {
        return {
            lang: this.$root.lang,
            text: {
                overviewTitle: localize({
                    'ru': 'Обзор',
                    'en': 'Overview'
                }),
                overviewText: localize({
                    'ru': 'Глобал Портс считает следование целям устойчивого развития одним из&nbsp;ключевых приоритетов деятельности и&nbsp;неотъемлемой частью стратегии развития Компании.',
                    'en': 'Global Ports considers adherence to&nbsp;the goals of&nbsp;sustainable development as&nbsp;one of&nbsp;its key priorities and an&nbsp;integral part of&nbsp;the Company&rsquo;s development strategy.'
                }),
                esgTitle: localize({
                    'ru': 'ESG Рейтинг',
                    'en': 'ESG Rating'
                }),
                safetyTitle: localize({
                    'ru': 'Промышленная безопасность',
                    'en': 'Safety'
                }),
                safetyTooltip: localize({
                    'ru': 'Частота травматизма с&nbsp;временной потерей трудоспособности',
                    'en': 'Lost Time Injury Frequency Rate'
                }),
                indicatorValue: localize({
                    'ru': '1,48',
                    'en': '1.48'
                }),
                indicatorUnit: localize({
                    'ru': 'в 2023 году',
                    'en': 'in 2023'
                }),
                communitiesTitle: localize({
                    'ru': 'Общество',
                    'en': 'Our Communities'
                }),
                communitiesText: localize({
                    'ru': 'Глобал Портс стремится поддерживать местные сообщества в&nbsp;регионах присутствия. Являясь крупным работодателем, инвестором и&nbsp;потребителем товаров и&nbsp;услуг, мы&nbsp;вносим значительный вклад в&nbsp;экономику. И&nbsp;мы&nbsp;гордимся тем, что являемся неотъемлемой частью сообществ, в&nbsp;которых работаем.',
                    'en': 'Global Ports is&nbsp;committed to&nbsp;supporting local communities in&nbsp;the regions where it&nbsp;operates. As&nbsp;a&nbsp;major employer, investor, and consumer of&nbsp;goods and services, we&nbsp;make a&nbsp;significant contribution to&nbsp;the economy. We&nbsp;are proud to&nbsp;be&nbsp;an&nbsp;integral part of&nbsp;the communities where we&nbsp;operate.'
                }),
                engagementTitle: localize({
                    'ru': 'Мотивация персонала',
                    'en': 'Employee Engagement'
                }),
                engagementText: localize({
                    'ru': 'Вовлеченность и&nbsp;лояльность сотрудников имеют для нас стратегическое значение. Наш долгосрочный успех зависит от&nbsp;поддержания культуры, при которой сотрудники чувствуют причастность к&nbsp;общему делу и&nbsp;собственную ценность.<br>То, как относятся работники к&nbsp;Компании, имеет для нее первостепенное значение. Поэтому обратная связь от&nbsp;сотрудников в&nbsp;рамках проектной работы крайне важна для успешного долгосрочного развития.',
                    'en': 'Employee engagement and loyalty is&nbsp;a&nbsp;strategic area because our long-term success depends on&nbsp;a&nbsp;culture where our employees feel their involvement and value. The attitude of&nbsp;our employees to&nbsp;the Company is&nbsp;of&nbsp;paramount importance, so&nbsp;getting feedback from employees as&nbsp;part of&nbsp;project work is&nbsp;essential to&nbsp;building the foundations for the future.'
                }),
                peopleTitle: localize({
                    'ru': 'Наши люди',
                    'en': 'Our People'
                }),
                environmentalTitle: localize({
                    'ru': 'Окружающая среда',
                    'en': 'Environment'
                }),
                reportsTitle: localize({
                    'ru': 'Отчеты и&nbsp;результаты',
                    'en': 'Reports'
                }),
                reportsText: localize({
                    'ru': 'Открытое и&nbsp;прозрачное информирование о&nbsp;нашей приверженности корпоративной ответственности&nbsp;— одна из&nbsp;задач Глобал Портс в&nbsp;рамках КСО.',
                    'en': 'Communicate our commitment to&nbsp;corporate responsibility openly and transparently&nbsp;— one of&nbsp;our CSR objectives.'
                }),
                governanceTitle: localize({
                    'ru': 'Управление и&nbsp;этика',
                    'en': 'Governance &amp;&nbsp;Ethics'
                }),
                governanceText: localize({
                    'ru': 'Надлежащее управление и&nbsp;этичное поведение являются краеугольными камнями нашей деятельности и&nbsp;основой наших операций. Как предприятие мы&nbsp;обязуемся соблюдать все соответствующие законы и&nbsp;нормативные акты, придерживаясь при этом самых высоких этических стандартов. Мы&nbsp;ожидаем, что все, кто работает с&nbsp;нами, от&nbsp;наших сотрудников до&nbsp;подрядчиков и&nbsp;поставщиков, разделяют наши ценности и&nbsp;ведут себя этично и&nbsp;ответственно.',
                    'en': 'Proper governance and ethical behaviour are the cornerstones of&nbsp;our business and the foundation of&nbsp;our operations. As&nbsp;a&nbsp;business, we&nbsp;are committed to&nbsp;complying with all relevant laws and regulations while upholding the highest standards of&nbsp;ethics. We&nbsp;expect everyone who works with&nbsp;us, from our employees to&nbsp;contractors and suppliers, to&nbsp;share our values and behave in&nbsp;an&nbsp;ethical and responsible way.'
                }),
                anticorruptionTitle: localize({
                    'ru': 'Противодействие коррупции',
                    'en': 'Anti-corruption'
                }),
                anticorruptionText: localize({
                    'ru': 'Глобал Портс придерживается позиции нулевой терпимости к&nbsp;коррупции в&nbsp;любом ее&nbsp;виде.',
                    'en': 'Global Ports maintains a&nbsp;strict stance against bribery and corruption and operate a&nbsp;zero tolerance regime to&nbsp;all forms of&nbsp;corruption.'
                }),
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more'
                })
            }
        }
    }
}
</script>

<style lang="scss">
    .sustainability {
        margin-top: 2rem;
        margin-bottom: 4rem;

        .z-blockquote {
            display: block;

            &__body {
                margin-bottom: 1rem;
                padding-right: 0;
                max-width: 90%;
            }

            &__aside {
                max-width: 80%;
            }

            &__detail {
                font-size: 16px
            }
        }
    }
</style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search-filters': {
    width: 16,
    height: 13,
    viewBox: '0 0 16 13',
    data: '<path pid="0" d="M12.377 2.9c0 1.325-1.08 2.4-2.415 2.4a2.408 2.408 0 01-2.415-2.4m4.83 0c0-1.325-1.08-2.4-2.415-2.4a2.408 2.408 0 00-2.415 2.4m4.83 0H16m-8.453 0H0M3.623 10.1c0-1.325 1.08-2.4 2.415-2.4a2.408 2.408 0 012.415 2.4m-4.83 0c0 1.325 1.08 2.4 2.415 2.4a2.408 2.408 0 002.415-2.4m-4.83 0H0m8.453 0H16" _stroke="#fff"/>'
  }
})

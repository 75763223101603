<template>
    <div class="documents-group">
        <z-accordion v-if="data.type === 'accordion'">
            <z-accordion-item
                v-for="item in data.items"
                :key="item.code"
                :id="item.code"
            >
                <template v-slot:icon>
                    <z-icon name="arrow" dir="down" height="16"></z-icon>
                </template>
                <template v-slot:header>
                    <span v-html="item.name"></span>
                </template>
                <template v-slot:body>
                    <p v-if="item.detail" v-html="item.detail"></p>
                    <z-filelist
                        v-if="item.items.length"
                    >
                        <z-filelist-item
                            v-for="(file, i) in item.items"
                            :key="i"
                            icon="file_filled"
                            :date="showDate ? file.date : ''"
                            :info="`${file.size}, ${file.extension}`"
                        >
                            <z-link
                                :href="file.link"
                                :downloadTitle="file.name"
                                downloadFile
                            >{{ file.name }}</z-link>
                        </z-filelist-item>
                    </z-filelist>
                </template>
            </z-accordion-item>
        </z-accordion>
        <template v-else-if="data.type === 'header'">
            <div
                v-for="obj in data.items"
                :key="obj.code"
            >
                <z-lead v-html="obj.name"></z-lead>
                <documents v-if="obj.items" :data-arr="obj.items" :show-date="showDate"></documents>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'documents-group',
    props: {
        data: {
            type: Object
        },
        showDate: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss">
    .documents-group {
        + .documents-group {
            margin-top: 2.5rem;
        }
    }
</style>

<template>
    <div class="sustainability-people">
        <h3 class="u-top-margin--xl-desktop u-bottom-margin--xl-desktop" v-html="text.firstChartTitle"></h3>
        <z-chart
            @mounted="getStructureChart1"
            :options='{
                clipPath: false,
                size: {
                    height: 400
                },
                data: {
                    columns: [
                        [text.female, 29],
                        [text.male, 71]
                    ],
                    type: "donut"
                },
                padding: {
                    top: 24,
                    right: 24,
                    bottom: 24,
                    left: 24
                },
                donut: {
                    padAngle: 0.025,
                    label: {
                        ratio: 1.35
                    },
                    width: 20
                }
            }'
            :legend='{
                position: "right-middle",
            }'
        ></z-chart>
        <h3 class="u-top-margin--xxl-desktop u-bottom-margin--xl-desktop" v-html="text.secondChartTitle"></h3>
        <div class="row">
            <div class="col-default-6 col-mobile-12">
                <z-figure class="u-top-margin--l-desktop">
                    <template v-slot:caption>
                        <z-caption v-html="text.production"></z-caption>
                    </template>
                    <template v-slot:content>
                        <z-chart
                            :options='{
                                clipPath: false,
                                size: {
                                    height: 250
                                },
                                data: {
                                    columns: [
                                        [text.female, 25],
                                        [text.male, 75]
                                    ],
                                    type: "donut"
                                },
                                padding: {
                                    top: 24,
                                    right: 24,
                                    bottom: 24,
                                    left: 24
                                },
                                donut: {
                                    padAngle: 0.025,
                                    label: {
                                        ratio: 1.65
                                    },
                                    width: 20
                                }
                            }'
                        ></z-chart>
                    </template>
                </z-figure>
            </div>
            <div class="col-default-6 col-mobile-12">
                <z-figure class="u-top-margin--l-desktop">
                    <template v-slot:caption>
                        <z-caption v-html="text.administration"></z-caption>
                    </template>
                    <template v-slot:content>
                        <z-chart
                            :options='{
                                clipPath: false,
                                size: {
                                    height: 250
                                },
                                data: {
                                    columns: [
                                        [text.female, 62],
                                        [text.male, 38]
                                    ],
                                    type: "donut"
                                },
                                padding: {
                                    top: 24,
                                    right: 24,
                                    bottom: 24,
                                    left: 24
                                },
                                donut: {
                                    padAngle: 0.025,
                                    label: {
                                        ratio: 1.65
                                    },
                                    width: 20
                                }
                            }'
                        ></z-chart>
                    </template>
                </z-figure>
            </div>
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'

export default {
    name: 'sustainability-people',
    mixins: [mixinDevice],
    data () {
        return {
            structureChart1: null,
            structureChart2: null,
            text: {
                firstChartTitle: localize({
                    'ru': 'Женщины, %&nbsp;от&nbsp;общего числа сотрудников',
                    'en': 'Diversity: females as&nbsp;a&nbsp;percentage of&nbsp;total staff'
                }),
                secondChartTitle: localize({
                    'ru': 'Женщины, %&nbsp;от&nbsp;сотрудников подразделения',
                    'en': 'Diversity: females as&nbsp;a&nbsp;percentage of&nbsp;total staff'
                }),
                production: localize({
                    'ru': 'Производственный персонал',
                    'en': 'Production'
                }),
                administration: localize({
                    'ru': 'Административный персонал',
                    'en': 'Administration'
                }),
                male: localize({
                    'ru': 'Мужчины',
                    'en': 'Male'
                }),
                female: localize({
                    'ru': 'Женщины',
                    'en': 'Female'
                })
            }
        }
    },
    methods: {
        getStructureChart1 (chart) {
            this.structureChart1 = chart
        },
        getStructureChart2 (chart) {
            this.structureChart2 = chart
        },
        setRatio (chart, value) {
            return chart.config('donut.label.ratio', value, true)
        }
    },
    watch: {
        device (value) {
            if (this.structureChart1 !== null && this.structureChart2 !== null) {
                if (value === 'v-mobile') {
                    this.setRatio(this.structureChart1, 0.7)
                    this.setRatio(this.structureChart2, 0.7)
                } else {
                    this.setRatio(this.structureChart1, 1.35)
                    this.setRatio(this.structureChart2, 1.45)
                }
            }
        }
    }
}
</script>

<style lang="scss"></style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quotes': {
    width: 17,
    height: 15,
    viewBox: '0 0 17 15',
    data: '<path pid="0" d="M15.345 12.35L11.079 15c-1.231-1.38-1.847-3.147-1.847-5.302 0-4.396 1.804-7.629 5.412-9.698L17 2.328c-1.995 1.379-2.992 3.448-2.992 6.206 0 1.638.445 2.91 1.337 3.815zm-9.233 0L1.846 15C.616 13.62 0 11.853 0 9.698 0 5.302 1.804 2.07 5.412 0l2.356 2.328C5.773 3.707 4.775 5.776 4.775 8.534c0 1.638.446 2.91 1.337 3.815z" _fill="#363636"/>'
  }
})

<template>
  <div v-html="isMobile() || isVMobile() ? mobileSvg : desktopSvg"></div>
</template>

<script>
/* eslint-disable import/no-webpack-loader-syntax */
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'
import desktopEn from '!!raw-loader!./svg/desktop_value_en.svg'
import desktopRu from '!!raw-loader!./svg/desktop_value_ru.svg'
import mobileEn from '!!raw-loader!./svg/mobile_value_en.svg'
import mobileRu from '!!raw-loader!./svg/mobile_value_ru.svg'

export default {
    name: 'business-model-slides',
    mixins: [mixinDevice],
    data () {
        return {
            mobileSvg: localize({
                ru: mobileRu,
                en: mobileEn
            }),
            desktopSvg: localize({
                ru: desktopRu,
                en: desktopEn
            })
        }
    }
}
</script>

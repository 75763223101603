/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32 48c12.15 0 22-16 22-16s-9.85-16-22-16-22 16-22 16 9.85 16 22 16zm0-6.563a9 9 0 100-18 9 9 0 000 18z" _fill="#ED1C24"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M36.701 31.753a4.145 4.145 0 01-5.555-3.99A4.751 4.751 0 0032 37.188a4.75 4.75 0 004.701-5.433z" _fill="#ED1C24"/>'
  }
})

<template>
    <div class="documents">
        <documents-group
            v-for="(item, i) in documentsGrouped"
            :key="`document-${i}`"
            :show-date="showDate"
            :data="item"
        ></documents-group>
    </div>
</template>

<script>
import DocumentsGroup from './components/DocumentsGroup.vue'
export default {
    name: 'documents',
    components: {
        DocumentsGroup
    },
    props: {
        showDate: {
            type: Boolean,
            default: true
        },
        dataArr: {
            type: Array,
            default: () => []
        },
        component: {
            type: String
        }
    },
    data () {
        return {
            documents: this.$root.app.components[this.component] || [],
            documentsGrouped: []
        }
    },
    methods: {
        buildStructure (arr) {
            const data = []
            arr.forEach((obj, i) => {
                let currentType = null
                if (!obj?.link) {
                    currentType = arr[i]?.type || 'accordion'
                }

                if (currentType) {
                    if (i > 0) {
                        const prevType = arr[i - 1]?.type || 'accordion'
                        if (currentType === 'accordion' && prevType === 'accordion') {
                            data[data.length - 1].items.push(obj)
                        } else {
                            data.push({
                                type: currentType,
                                items: [
                                    {
                                        ...obj,
                                        items: obj.items ? this.buildStructure(obj.items) : []
                                    }
                                ]
                            })
                        }
                    } else {
                        data.push({
                            type: currentType,
                            items: [
                                {
                                    ...obj,
                                    items: obj.items ? this.buildStructure(obj.items) : []
                                }
                            ]
                        })
                    }
                } else {
                    data.push(obj)
                }
            })

            return data
        }
    },
    created () {
        this.documentsGrouped = this.dataArr.length ? this.dataArr : this.buildStructure(this.documents)
    }
}
</script>

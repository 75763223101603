/* eslint-disable */
require('./about-1')
require('./about-2')
require('./about-3')
require('./about-4')
require('./appstore')
require('./arrow_up')
require('./arrow')
require('./attach')
require('./avatar')
require('./bullet')
require('./burger-menu')
require('./business-reports')
require('./calendar')
require('./captain')
require('./care')
require('./cargo_2')
require('./cargo-item')
require('./cargo')
require('./cars')
require('./clock')
require('./close_bold')
require('./close')
require('./compas')
require('./databank')
require('./desktop')
require('./dogovor')
require('./dots')
require('./download')
require('./eco')
require('./external')
require('./eye')
require('./facebook')
require('./fb')
require('./file_filled')
require('./file')
require('./general')
require('./globe')
require('./googleplay')
require('./governance')
require('./hands-safe')
require('./hands-shake')
require('./hands')
require('./IDK')
require('./in')
require('./insta')
require('./invest-case-1')
require('./invest-case-2')
require('./invest-case-3')
require('./invest-case-4')
require('./invest-case-5')
require('./invest-case-6')
require('./job-bus')
require('./job-contract')
require('./job-food')
require('./job-money')
require('./letter')
require('./lighthouse')
require('./link')
require('./man')
require('./management')
require('./manager')
require('./mediagallery')
require('./photo')
require('./play')
require('./presentation')
require('./print')
require('./Q&A')
require('./quotes')
require('./rzd')
require('./safety')
require('./search-filters')
require('./search')
require('./sheep')
require('./ship')
require('./ship2')
require('./sport-items')
require('./sustainability')
require('./teambuilding')
require('./teambuilding2')
require('./telegram')
require('./terminals')
require('./tooltip')
require('./truck')
require('./twitter')
require('./video')
require('./vk')
require('./webcast')
require('./worker-band')
require('./worker')
require('./youtube')
require('./zoom-in')

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close_bold': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.47 3.47a.75.75 0 011.06 0L8 6.94l3.47-3.47a.75.75 0 111.06 1.06L9.06 8l3.47 3.47a.75.75 0 11-1.06 1.06L8 9.06l-3.47 3.47a.75.75 0 01-1.06-1.06L6.94 8 3.47 4.53a.75.75 0 010-1.06z" _fill="#7A7A7E"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'googleplay': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M27.258 22.969l2.812-2.813-13.125-7.547 10.313 10.36zM14.227 12l12.046 12-12.046 12c-.704-.328-1.032-.89-1.032-1.64V13.64c0-.75.328-1.265 1.032-1.64zm19.921 10.594c.422.328.657.797.657 1.406 0 .656-.188 1.125-.61 1.406L31.383 27l-3.047-3 3.047-3.047 2.765 1.64zM16.945 35.39l10.313-10.36 2.812 2.813-13.125 7.547z" _fill="#ED1C24"/>'
  }
})

<template>
    <div class="ratings">
        <div class="ratings__highlights">
            <card style="min-height: 295px">
                <template v-slot:title>
                    Credit ratings
                    <z-tooltip
                        content=" IJSC “Global Ports Investments” "
                    ></z-tooltip>
                </template>
                <template v-slot:content>
                    <div class="row">
                        <div class="col-default-4 col-mobile-12">
                            <z-key-indicator noCounter value="AA(RU)" name="Stable">
                                <template v-slot:footer>
                                    <z-link
                                        href="https://www.acra-ratings.ru/ratings/issuers/555/?lang=ru"
                                        target="_blank"
                                        theme="dark-grey"
                                        :underline="false"
                                    >
                                        <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                        <span>ACRA</span>
                                    </z-link>
                                </template>
                            </z-key-indicator>
                        </div>
                        <div class="col-default-4 col-mobile-12">
                            <z-key-indicator noCounter value="ruAA-" name="Stable">
                                <template v-slot:footer>
                                    <z-link
                                        href="https://www.raexpert.ru/database/companies/1000019672/"
                                        target="_blank"
                                        theme="dark-grey"
                                        :underline="false"
                                    >
                                        <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                        <span>Expert RA</span>
                                    </z-link>
                                </template>
                            </z-key-indicator>
                        </div>
                    </div>
                </template>
            </card>
        </div>
        <div class="ratings__history u-top-margin--xl-desktop">
            <h2>Ratings history</h2>
            <div class="row">
                <div class="col-default-12 col-mobile-12">
                    <z-figure>
                        <template v-slot:caption>
                            <z-caption
                                size="l"
                                color="red"
                            >
                                Expert RA
                                <z-tooltip
                                    content="IJSC “Global Ports Investments”, Credit Ratings of Non-financial Companies"
                                ></z-tooltip>
                            </z-caption>
                        </template>
                        <template v-slot:content>
                            <z-chart
                                :options="expertOptions"
                            ></z-chart>
                        </template>
                    </z-figure>
                </div>
                <div class="col-default-6 col-mobile-12">
                    <z-figure>
                        <template v-slot:caption>
                            <z-caption
                                size="l"
                                color="grey"
                            >
                                Moody's Investors Service
                                <z-tooltip
                                    content="Global Ports Investments, LT Corporate Family Ratings"
                                ></z-tooltip>
                            </z-caption>
                            <p>Ratings Withdrawn</p>
                        </template>
                        <template v-slot:content>
                            <z-chart
                                :options="moodyOptions"
                            ></z-chart>
                        </template>
                    </z-figure>
                </div>
                <div class="col-default-6 col-mobile-12">
                    <z-figure>
                        <template v-slot:caption>
                            <z-caption
                                size="l"
                                color="grey"
                            >
                                Fitch Ratings
                                <z-tooltip
                                    content="Global Ports Investments, Long-Term Issuer Default Ratings"
                                ></z-tooltip>
                            </z-caption>
                            <p>Ratings Withdrawn</p>
                        </template>
                        <template v-slot:content>
                            <z-chart
                                :options="fitchOptions"
                            ></z-chart>
                        </template>
                    </z-figure>
                </div>
            </div>
        </div>
        <div class="ratings__table u-top-margin--xl-desktop">
            <h2>All credit ratings</h2>
            <z-table
                :columns="['Issuer', 'Rating assigned to', 'Rating', 'Rating type', 'Outlook', 'Date', 'History']"
                captionText="Moody's investors services"
            >
                <z-table-row isClickable name="moody">
                    <z-table-col index="0" width="25%">
                        Global Ports Investments
                    </z-table-col>
                    <z-table-col index="1" width="15%">
                        Issuer
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text" width="6%">
                        Withdrawn
                    </z-table-col>
                    <z-table-col index="3" width="30%">
                        LT Corporate Family Ratings
                    </z-table-col>
                    <z-table-col index="4" width="7%">
                    </z-table-col>
                    <z-table-col index="5" width="10%">
                        31.03.2022
                    </z-table-col>
                    <z-table-col index="6" width="7%">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="moody">
                    <z-table-col index="0">
                    </z-table-col>
                    <z-table-col index="1">
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        Caa2
                    </z-table-col>
                    <z-table-col index="3">
                      LT Corporate Family Ratings
                    </z-table-col>
                    <z-table-col index="4">
                        Negative
                    </z-table-col>
                    <z-table-col index="5">
                        10.03.2022
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="moody">
                    <z-table-col index="0">
                    </z-table-col>
                    <z-table-col index="1">
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        Ba1
                    </z-table-col>
                    <z-table-col index="3">
                        LT Corporate Family Ratings
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        03.12.2021
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="moody">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        Ba2
                    </z-table-col>
                    <z-table-col index="3" width="30%">
                        LT Corporate Family Ratings
                    </z-table-col>
                    <z-table-col index="4" width="7%">
                        Stable
                    </z-table-col>
                    <z-table-col index="5" width="10%">
                        28.10.2020
                    </z-table-col>
                    <z-table-col index="6" width="7%">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="moody">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        Ba2
                    </z-table-col>
                    <z-table-col index="3" width="30%">
                        LT Corporate Family Ratings
                    </z-table-col>
                    <z-table-col index="4" width="7%">
                        Stable
                    </z-table-col>
                    <z-table-col index="5" width="10%">
                        31.10.2019
                    </z-table-col>
                    <z-table-col index="6" width="7%">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="moody">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        Ba3
                    </z-table-col>
                    <z-table-col index="3">
                        LT Corporate Family Ratings
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        25.10.2018
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="moody">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        Ba3
                    </z-table-col>
                    <z-table-col index="3">
                        LT Corporate Family Ratings
                    </z-table-col>
                    <z-table-col index="4">
                        Negative
                    </z-table-col>
                    <z-table-col index="5">
                        31.08.2016
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row>
                    <z-table-col index="0">
                        Global Ports Investments
                    </z-table-col>
                    <z-table-col index="1">
                        Issuer
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        Withdrawn
                    </z-table-col>
                    <z-table-col index="3">
                        Probability of Default
                    </z-table-col>
                    <z-table-col index="4">
                    </z-table-col>
                    <z-table-col index="5">
                        31.03.2022
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
            </z-table>
            <z-table
                :columns="['Issuer', 'Rating assigned to', 'Rating', 'Rating type', 'Outlook', 'Date', 'History']"
                captionText="Fitch ratings"
            >
                <z-table-row isClickable name="fitch">
                    <z-table-col index="0" width="25%">
                        Global Ports Investments
                    </z-table-col>
                    <z-table-col index="1" width="15%">
                        Issuer
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text" width="6%">
                        Withdrawn
                    </z-table-col>
                    <z-table-col index="3" width="30%">
                        Long-Term Issuer Default Ratings / Local-Currency Long Term Issuer Default Ratings
                    </z-table-col>
                    <z-table-col index="4" width="7%">
                    </z-table-col>
                    <z-table-col index="5" width="10%">
                        30.03.2022
                    </z-table-col>
                    <z-table-col index="6" width="7%">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                   <z-table-col index="0">
                    </z-table-col>
                    <z-table-col index="1">
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        CC
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                    </z-table-col>
                    <z-table-col index="5">
                        11.03.2022
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0">
                    </z-table-col>
                    <z-table-col index="1">
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        B
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Rating Watch Negative
                    </z-table-col>
                    <z-table-col index="5">
                        04.03.2022
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1" width="15%"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        BB+
                    </z-table-col>
                    <z-table-col index="3"></z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        02.07.2021
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        BB+
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        07.07.2020
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        BB+
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        01.04.2020
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        BB+
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        17.07.2019
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        BB
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        08.08.2018
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        BB
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Negative
                    </z-table-col>
                    <z-table-col index="5">
                        24.08.2017
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        BB-
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Negative
                    </z-table-col>
                    <z-table-col index="5">
                        09.09.2016
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        BB
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        27.04.2016
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="fitch">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        BB
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        08.12.2015
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row>
                    <z-table-col index="0">JSC First Container Terminal (FCT)</z-table-col>
                    <z-table-col index="1">
                        Local bonds (Issue 01-03)
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        Withdrawn
                    </z-table-col>
                    <z-table-col index="3">
                        Long Term Rating
                    </z-table-col>
                    <z-table-col index="4">
                    </z-table-col>
                    <z-table-col index="5">
                        30.03.2022
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
            </z-table>
            <z-table
                :columns="['Issuer', 'Rating assigned to', 'Rating', 'Rating type', 'Outlook', 'Date', 'History']"
                captionText="ACRA"
            >

                <z-table-row>
                    <z-table-col index="0" width="25%">
                        Global Ports Investments
                    </z-table-col>
                    <z-table-col index="1" width="15%">
                        Issuer
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text" width="6%">
                        AA(RU)
                    </z-table-col>
                    <z-table-col index="3" width="30%">
                        Credit Ratings of&nbsp;Non-financial Companies
                    </z-table-col>
                    <z-table-col index="4" width="7%">
                        Stable
                    </z-table-col>
                    <z-table-col index="5" width="10%">
                        09.02.2024
                    </z-table-col>
                    <z-table-col index="6" width="7%">
                    </z-table-col>
                </z-table-row>
                <z-table-row>
                    <z-table-col index="0">First Container Terminal (FCT)</z-table-col>
                    <z-table-col index="1">
                        Local bonds (Issue 01-03)
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        AA(RU)
                    </z-table-col>
                    <z-table-col index="3">
                        Credit Ratings of&nbsp;Debt Instruments
                    </z-table-col>
                    <z-table-col index="4">Stable</z-table-col>
                    <z-table-col index="5">
                        09.02.2024
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row>
                    <z-table-col index="0">Vostochnaya Stevedoring Company (VSC)</z-table-col>
                    <z-table-col index="1">
                        Local bonds (Issue 01-04)
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        AA(RU)
                    </z-table-col>
                    <z-table-col index="3">
                        Credit Ratings of&nbsp;Debt Instruments
                    </z-table-col>
                    <z-table-col index="4">Stable</z-table-col>
                    <z-table-col index="5">
                        09.02.2024
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row>
                    <z-table-col index="0" width="25%">
                        First Container Terminal (FCT)
                    </z-table-col>
                    <z-table-col index="1" width="15%">
                        Local bonds (Issue 1P1)
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text" width="6%">
                        AA(RU)
                    </z-table-col>
                    <z-table-col index="3" width="30%">
                        Credit Ratings of&nbsp;Debt Instruments
                    </z-table-col>
                    <z-table-col index="4" width="7%">
                        Stable
                    </z-table-col>
                    <z-table-col index="5" width="10%">
                        17.04.2024
                    </z-table-col>
                    <z-table-col index="6" width="7%">
                    </z-table-col>
                </z-table-row>
            </z-table>
            <z-table
                :columns="['Issuer', 'Rating assigned to', 'Rating', 'Rating type', 'Outlook', 'Date', 'History']"
                captionText="Expert ra"
            >


            <z-table-row isClickable name="expert">
                    <z-table-col index="0" width="25%">
                        Global Ports Investments
                    </z-table-col>
                    <z-table-col index="1" width="15%">
                        Issuer
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text" width="6%">
                        ruAA-
                    </z-table-col>
                    <z-table-col index="3" width="30%">
                        Credit Ratings of&nbsp;Non-financial Companies
                    </z-table-col>
                    <z-table-col index="4" width="7%">
                        Stable
                    </z-table-col>
                    <z-table-col index="5" width="10%">
                        27.11.2024
                    </z-table-col>
                    <z-table-col index="6" width="7%">
                    </z-table-col>
                </z-table-row>

                <z-table-row isExpandable name="expert">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">ruAA-</z-table-col>
                    <z-table-col index="3"></z-table-col>
                    <z-table-col index="4">Stable</z-table-col>
                    <z-table-col index="5">11.04.2024</z-table-col>
                    <z-table-col index="6"></z-table-col>
                </z-table-row>

                <z-table-row isExpandable name="expert">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">ruAA</z-table-col>
                    <z-table-col index="3"></z-table-col>
                    <z-table-col index="4">Stable</z-table-col>
                    <z-table-col index="5">10.05.2023</z-table-col>
                    <z-table-col index="6"></z-table-col>
                </z-table-row>

                <z-table-row isExpandable name="expert">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">ruAA-</z-table-col>
                    <z-table-col index="3"></z-table-col>
                    <z-table-col index="4">Developing</z-table-col>
                    <z-table-col index="5">13.05.2022</z-table-col>
                    <z-table-col index="6"></z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="expert">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">ruAA</z-table-col>
                    <z-table-col index="3"></z-table-col>
                    <z-table-col index="4">Stable</z-table-col>
                    <z-table-col index="5">14.05.2021</z-table-col>
                    <z-table-col index="6"></z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="expert">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        ruA+
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        14.05.2020
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="expert">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        ruA+
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        30.05.2019
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row isExpandable name="expert">
                    <z-table-col index="0"></z-table-col>
                    <z-table-col index="1"></z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        ruA
                    </z-table-col>
                    <z-table-col index="3">
                    </z-table-col>
                    <z-table-col index="4">
                        Stable
                    </z-table-col>
                    <z-table-col index="5">
                        09.06.2018
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>


                <z-table-row>
                    <z-table-col index="0">First Container Terminal (FCT)</z-table-col>
                    <z-table-col index="1">
                        Local bonds (Issue 01-03)
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        ruAA-
                    </z-table-col>
                    <z-table-col index="3">
                        Credit Ratings of&nbsp;Debt Instruments
                    </z-table-col>
                    <z-table-col index="4">Stable</z-table-col>
                    <z-table-col index="5">
                        29.11.2024
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>

                <z-table-row>
                    <z-table-col index="0">Vostochnaya Stevedoring Company (VSC)</z-table-col>
                    <z-table-col index="1">
                        Local bonds (Issue 01-04)
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        ruAA-
                    </z-table-col>
                    <z-table-col index="3">
                        Credit Ratings of&nbsp;Debt Instruments
                    </z-table-col>
                    <z-table-col index="4">Stable</z-table-col>
                    <z-table-col index="5">
                        29.11.2024
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
                <z-table-row>
                    <z-table-col index="0">First Container Terminal (FCT)</z-table-col>
                    <z-table-col index="1">
                        Local bonds (Issue 1P1)
                    </z-table-col>
                    <z-table-col index="2" class="gp-red-text">
                        ruAA-
                    </z-table-col>
                    <z-table-col index="3">
                        Credit Ratings of&nbsp;Debt Instruments
                    </z-table-col>
                    <z-table-col index="4">Stable</z-table-col>
                    <z-table-col index="5">
                        29.11.2024
                    </z-table-col>
                    <z-table-col index="6">
                    </z-table-col>
                </z-table-row>
            </z-table>
        </div>
    </div>
</template>

<script>
const ratingsMoodys = {
    13: 'Baa1',
    12: 'Baa2',
    11: 'Baa2',
    10: 'Baa3',
    9: 'Ba1',
    8: 'Ba2',
    7: 'Ba3',
    6: 'B1',
    5: 'B2',
    4: 'B3',
    3: 'Caa1',
    2: 'Caa2',
    1: 'Caa3'
}

const ratingsFitch = {
    15: 'AAA',
    14: 'AA+',
    13: 'AA',
    12: 'AA-',
    11: 'BBB',
    10: 'BBB-',
    9: 'BB+',
    8: 'BB',
    7: 'BB-',
    6: 'B+',
    5: 'B',
    4: 'B-',
    3: 'CCC',
    2: 'CC',
    1: 'C'

}

const ratingsExperts = {
    9: 'ruAA+',
    8: 'ruAA',
    7: 'ruAA-',
    6: 'ruA+',
    5: 'ruA',
    4: 'ruA-',
    3: 'ruBBB+',
    2: 'ruBBB',
    1: 'ruBBB-'
}

const moodyOptions = {
    data: {
        type: 'step',
        json: [
            {
                date: '2016-08-31',
                Rating: 7
            },
            {
                date: '2018-10-25',
                Rating: 7
            },
            {
                date: '2019-10-31',
                Rating: 8
            },
            {
                date: '2020-10-28',
                Rating: 8
            },
            {
                date: '2021-12-03',
                Rating: 9
            }
        ],
        keys: {
            x: 'date',
            value: ['Rating']
        },
        color: function (x) {
            return '#808083'
        }
    },
    line: {
        step: {
            type: 'step-after'
        }
    },
    point: {
        show: true,
        pattern: [
            '<circle cx="2" cy="2" r="2"></circle>'
        ]
    },
    tooltip: {
        format: {
            title: function (x) {
                return new Date(x).toLocaleDateString()
            }
        }
    },
    axis: {
        x: {
            type: 'timeseries',
            tick: {
                count: 6,
                format: '%Y'
            }
        },
        y: {
            min: 1,
            max: 10,
            tick: {
                format: function (x) {
                    return ratingsMoodys[x]
                }
            }
        }
    },
    padding: {
        left: 80,
        top: 0,
        right: 24,
        bottom: 32
    },
    legend: {
        show: false
    }
}

const fitchOptions = {
    data: {
        type: 'step',
        json: [
            {
                date: '2015-12-08',
                Rating: 8
            },
            {
                date: '2016-04-27',
                Rating: 8
            },
            {
                date: '2016-09-09',
                Rating: 7
            },
            {
                date: '2017-08-24',
                Rating: 7
            },
            {
                date: '2018-08-08',
                Rating: 8
            },
            {
                date: '2019-07-17',
                Rating: 9
            },
            {
                date: '2020-07-07',
                Rating: 9
            },
            {
                date: '2021-07-02',
                Rating: 9
            }
        ],
        keys: {
            x: 'date',
            value: ['Rating']
        },
        color: function (x) {
            return '#808083'
        }
    },
    line: {
        step: {
            type: 'step-after'
        }
    },
    point: {
        show: true,
        pattern: [
            '<circle cx="2" cy="2" r="2"></circle>'
        ]
    },
    tooltip: {
        format: {
            title: function (x) {
                return new Date(x).toLocaleDateString()
            }
        }
    },
    axis: {
        x: {
            type: 'timeseries',
            tick: {
                count: 7,
                format: '%Y'
            }
        },
        y: {
            min: 1,
            max: 10,
            tick: {
                format: function (x) {
                    return ratingsFitch[x]
                }
            }
        }
    },
    padding: {
        left: 80,
        top: 0,
        right: 24,
        bottom: 24
    },
    legend: {
        show: false
    }
}

const expertOptions = {
    data: {
        type: 'step',
        json: [
            {
                date: '2018-06-09',
                Rating: 5
            },
            {
                date: '2019-05-30',
                Rating: 6
            },
            {
                date: '2020-05-14',
                Rating: 6
            },
            {
                date: '2021-05-14',
                Rating: 8
            },
            {
                date: '2022-05-13',
                Rating: 7
            },
            {
                date: '2023-05-10',
                Rating: 8
            },
            {
                date: '2024-04-11',
                Rating: 7
            },
            {
                date: '2024-11-27',
                Rating: 7
            }
        ],
        keys: {
            x: 'date',
            value: ['Rating']
        }
    },
    line: {
        step: {
            type: 'step-after'
        }
    },
    point: {
        show: true,
        pattern: [
            '<circle cx="2" cy="2" r="2"></circle>'
        ]
    },
    tooltip: {
        format: {
            title: function (x) {
                return new Date(x).toLocaleDateString()
            }
        }
    },
    axis: {
        x: {
            type: 'timeseries',
            tick: {
                count: 6,
                format: '%Y'
            }
        },
        y: {
            min: 1,
            max: 9,
            tick: {
                format: function (x) {
                    return ratingsExperts[x]
                }
            }
        }
    },
    padding: {
        left: 80,
        top: 0,
        right: 24,
        bottom: 24
    },
    legend: {
        show: false
    }
}

export default {
    name: 'ratings',
    data () {
        return {
            moodyOptions,
            fitchOptions,
            expertOptions
        }
    }
}
</script>

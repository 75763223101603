<template>
    <div class="special-equipment">
        <div class="row special-equipment__list">
            <div
                class="col-default-4 col-tablet-6 col-mobile-12 special-equipment__col"
                v-for="(item, index) in list"
                :key="index"
            >
                <equipment-card class="special-equipment__item" :data="item"></equipment-card>
            </div>
        </div>
    </div>
</template>

<script>
import EquipmentCard from './components/EquipmentCard.vue'
import { mapActions } from 'vuex'

export default {
    name: 'special-equipment',
    components: {
        EquipmentCard
    },
    data () {
        return {
            list: []
        }
    },
    methods: {
        ...mapActions(['SetHeroSrc'])
    },
    created () {
        if (this.$root.app.components['special-equipment']) {
            this.list = this.$root.app.components['special-equipment'].items
        }
    }
}
</script>

<style lang="scss">
.special-equipment {
    padding-top: $token-spacers-desktop-l;
    padding-bottom: $token-spacers-desktop-xxl;

    &__col {
        display: flex;
    }

    &__item {
        width: 100%;
    }
}
</style>

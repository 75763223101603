<template>
    <div class="sustainability-reports">
        <div class="row" equal-height=".card">
            <div
                v-for="(item, i) in reports"
                :key="i"
                class="col-default-4 col-mobile-12"
            >
                <card
                    :overlay="'rgba(0,0,0,0.15)'"
                    :theme="'background'"
                    :image="item.image"
                    style="min-height: 400px;"
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>
                        {{ item.name }}
                    </template>
                    <template v-slot:bottom-left>
                        <z-link
                            v-if="item.link"
                            :href="item.link"
                            :downloadTitle="item.name"
                            downloadFile
                            :content="text.pdf"
                            class="u-inline"
                            v-tippy-tooltip="{
                                theme: 'transparent',
                                arrow: true,
                                placement: 'top-start'
                            }"
                        >
                            <z-icon name="download" width="48" height="48"></z-icon>
                        </z-link>
                    </template>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
export default {
    name: 'sustainability-reports',
    props: {
    },
    data () {
        return {
            reports: this.$root.app.components['sustainability-reports'],
            text: {
                pdf: localize({
                    'ru': 'PDF версия',
                    'en': 'PDF version',
                    'cn': 'PDF version'
                })
            }
        }
    }
}
</script>

<style lang="scss">
    .sustainability-reports {
        .z-icon path {
            fill: #fff;
        }
    }
</style>

<template>
    <div class="news">
        <news-filters
            :data="filtersData"
            @change="debouncedChange"
            :redesign="redesign"
        ></news-filters>
        <div class="news__list" equal-height=".card">
            <z-not-found
                v-if="(notFound.state && !loading)"
                :title="notFound.title"
                :text="notFound.text"
            ></z-not-found>
            <div class="row" v-else-if="list.length">
                <div
                    v-for="(item, i) in list"
                    :key="i"
                    class="col-default-4 col-mobile-12"
                >
                    <card
                        :link="item.link"
                        :overlay="item.image ? 'rgba(0,0,0,0.35)' : ''"
                        :theme="item.image ? 'background' : 'white'"
                        :image="item.image"
                        style="min-height: 325px"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>
                            {{ getFormatedTitle(item.name) }}
                        </template>
                        <template v-slot:content v-if="item.detail">
                            {{ item.detail }}
                        </template>
                        <template v-slot:bottom-left>
                            <span class="card__tag" v-if="item.categories">{{ item.categories[0] }}</span>
                            <span class="card__date" v-if="item.date">{{ item.date | momentFilter('DD MMMM YYYY') }}</span>
                        </template>
                    </card>
                </div>
            </div>
            <z-preloader v-if="loading"></z-preloader>
        </div>
    </div>
</template>

<script>
import { debounce, throttle } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'
import { localize } from '@/utils/i18n'
import { getNews } from '@/api/news'

export default {
    name: 'news',
    props: {
        initial: {
            type: Object
        },
        component: {
            type: String,
            required: true
        },
        url: {
            type: String,
            default: '/news'
        },
        redesign: Boolean
    },
    data () {
        return {
            loading: false,
            params: {},
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не найдено',
                    'en': 'No results found'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            },
            news: this.$root.app.components[this.component],
            list: this.$root.app.components[this.component].items,
            filters: this.$root.app.components[this.component].filters,
            page: this.$root.app.components[this.component].nav,
            code: this.$root.app.system.params.get.TERMINAL
        }
    },
    computed: {
        filtersData () {
            const filtersObj = {
                name: this.params.name,
                date: this.params.date,
                code: this.code
            }

            if (this.filters) {
                if (this.filters.categories) filtersObj.categories = this.filters.categories
                if (this.filters.enterprises) filtersObj.enterprises = this.filters.enterprises
            }

            return filtersObj
        },
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })
            delete params['code']

            return params
        }
    },
    methods: {
        getFormatedTitle (title) {
            let maxLength = 120

            let trimmedString = title.substr(0, maxLength)

            trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))

            return title.length > maxLength ? `${trimmedString}...` : title
        },
        getNewsList () {
            this.loading = true

            if (this.params.page === 1) this.list = []

            queryString(this.clearEmptyParams)

            getNews(this.params, this.url).then((res) => {
                if (res.hasOwnProperty('filters')) {
                    if (Object.keys(res.filters).length) this.filters = res.filters
                }

                if (res.hasOwnProperty('nav')) {
                    this.page.current = res.nav.current
                    this.page.total = res.nav.total
                    this.page.count = res.nav.count
                }

                if (res.hasOwnProperty('items')) {
                    if (res.items.length === 0) {
                        this.notFound.state = true
                    } else {
                        this.notFound.state = false
                    }

                    if (this.page.current < 2) {
                        this.list = res.items
                    } else {
                        this.list = this.list.concat(res.items)
                    }
                }

                // This garbage code for the sake of IE
                let customEvent = document.createEvent('HTMLEvents')
                customEvent.initEvent('newCardsAdded', true, true)
                document.dispatchEvent(customEvent)

                // Not working on IE :(
                // window.dispatchEvent(new CustomEvent('newCardsAdded'))

                this.loading = false
            })
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(500, e => {
                const offsetTop = this.$el.offsetTop
                const offsetHeight = this.$el.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (scrolled >= (bottom - 100) && !this.loading && this.page.current < this.page.total) {
                    this.params.page++
                    this.getNewsList()
                };
            }))
        }
    },
    created () {
        if (this.filters) {
            if (this.filters.categories) {
                this.filters.categories = this.filters.categories.map((obj) => {
                    if (this.initial.categories.indexOf(`${obj.id}`) >= 0) {
                        obj.selected = true
                    }
                    return obj
                })
            }
            if (this.filters.enterprises) {
                this.filters.enterprises = this.filters.enterprises.map((obj) => {
                    if (this.initial.enterprises.indexOf(`${obj.id}`) >= 0) {
                        obj.selected = true
                    }
                    return obj
                })
            }
        }

        this.params = {
            ...this.initial,
            page: 1,
            code: this.code
        }

        this.debouncedChange = debounce(500, (data) => {
            if (data == null) {
                const paramsObj = {
                    name: '',
                    date: [],
                    code: this.code
                }

                if (this.filters) {
                    if (this.filters.categories) paramsObj.categories = []
                    if (this.filters.enterprises) paramsObj.enterprises = []
                }

                this.params = paramsObj
            } else if (data.hasOwnProperty('name') || data.hasOwnProperty('date')) {
                this.params[data.name] = data.value
            }

            this.params.page = 1

            this.getNewsList()
        })

        if (!this.list.length) {
            this.notFound.state = true
        }
        this.listenScroll()
    },
    watch: {
        list: {
            handler () {
                this.$nextTick(function () {
                    const event = document.createEvent('Event')
                    event.initEvent('resize', true, true)
                    window.dispatchEvent(event)
                })
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'business-reports': {
    width: 57,
    height: 71,
    viewBox: '0 0 57 71',
    data: '<path pid="0" d="M31.458 21.028H9.075v15.726h22.383V21.028zM21.51 31.552l6.703.007M21.512 28.314l7.082.007M12.803 25.993l-.007 7.362" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M11.607 27.88l1.197-1.887 1.231 1.89M17.814 25.998l-.007 7.362M16.618 27.886l1.196-1.888 1.232 1.89" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M27.176 68.599H1V11.954h10.943M29.562 11.954h10.13v41.033" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" d="M29.559 7.117h-17.62v8.292h17.62V7.117zM5.5 11.954V8.128h6.439" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="4" d="M29.557 8.129h14.638V57.64" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="5" d="M46.607 61.202h3.04V4.562H10.955v3.567" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="6" d="M16.406 4.564V1h38.693v56.639h-5.453M14.862 11.263h11.774M5.5 45.593h25.45M5.5 52.685h25.45M27.755 59.06H5.5M43.521 61.37c.018.08.173.81.173 1.554A7.084 7.084 0 0136.618 70c-3.902 0-7.077-3.174-7.077-7.076 0-3.902 3.175-7.076 7.077-7.076" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="7" d="M41.078 58.335l-3.63 7.748a1.07 1.07 0 01-1.728.3L33 63.642" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})

<template>
    <div class="page-404">
        <h1 v-html="text.title"></h1>
        <p class="page-404__text">
            <span v-html="text.text"></span>
            <br>
            <span v-if="lang">Вернитесь&nbsp;на <z-link :href="siteDir" size="xl">главную</z-link> или воспользуйтесь <z-link :href="siteDir + 'search/'" size="xl">поиском сайта.</z-link></span>
            <span v-else>You can use the <z-link :href="siteDir + 'search/'" size="xl">search</z-link> or&nbsp;return to&nbsp;the <z-link :href="siteDir" size="xl">main page.</z-link></span>
        </p>
        <div class="page-404__image">
            <img :src="buildSrc()" class="">
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'page-not-found',
    props: {
        siteDir: {
            type: String,
            default: '/',
            required: true
        }
    },
    data () {
        return {
            lang: this.$root.lang === 'ru',
            text: {
                title: localize({
                    'ru': 'Что-то пошло не&nbsp;так',
                    'en': 'Unfortunately, this page is&nbsp;not found'
                }),
                text: localize({
                    'ru': 'Неправильно набран адрес или такой страницы больше не&nbsp;существует.',
                    'en': 'Perhaps you enter the wrong address or&nbsp;the page has been deleted.'
                })
            }
        }
    },
    methods: {
        buildSrc: function () {
            return require('./images/page_404.svg')
        }
    }
}
</script>

<style lang="scss">
.page-404 {
    margin-top: 82px;

    h1 {
        padding-bottom: 80px;
    }

    &__text {
        color: #808083;
        font-size: 24px;
        line-height: 175%;
        padding-bottom: 56px;

        .z-link--size-xl {
            font-weight: 500;
        }
    }

    &__image {
        position: relative;
        text-align: center;
        margin-bottom: 120px;

        img {
            width: 100%;
            max-width: 1172px;
        }
    }
}
</style>

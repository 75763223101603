<template src="./index.html"></template>

<script>

export default {
    name: 'card',
    props: {
        link: String,
        overlay: String,
        image: String,
        padding: String,
        theme: {
            type: String,
            default: 'white'
        },
        mark: {
            type: Boolean,
            default: true
        },
        shadow: {
            type: Boolean,
            default: true
        },
        fullWidth: {
            type: Boolean
        }
    },
    data () {
        return {
            withCardIcon: false
        }
    },
    methods: {
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : false
        }
    },
    computed: {
        classObject () {
            let arrClass = [
                {
                    'card--shadow': this.shadow,
                    'card--marked': this.mark,
                    'card--padding': this.padding,
                    'card--with-icon': this.withCardIcon,
                    'card--full-width': this.fullWidth
                }
            ]

            if (this.withCardIconSize) {
                arrClass.push(`card--icon-size-${this.withCardIconSize}`)
            }

            if (this.theme) {
                arrClass.push(`card--theme-${this.theme}`)
            }

            return arrClass
        },
        styleHelperPadding () {
            let style = ''

            if (this.padding) {
                style += `padding-top: ${this.padding}`
            }

            return style
        },
        styleHelperImage () {
            let style = ''

            if (this.image) {
                style = `background-image: url('${this.image}');`
            }

            return style
        },
        styleOverlay () {
            let style = ''

            if (this.overlay) {
                style = `background: ${this.overlay};`
            }

            return style
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

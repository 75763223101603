<template>
    <div class="slider">
        <div class="slider__container swiper-container" ref="sliderContainer">
            <div class="slider__wrapper swiper-wrapper">
                <div
                    v-for="(item, index) in slides"
                    :key="index"
                    class="slider__slide swiper-slide"
                    ref="sliderItem"
                >
                    <template v-if="modal">
                        <a href="#" @click="$root.$bus.$emit('open-modal', {id: 'sliderModal', index: index})">
                            <span v-if="item.name" class="slider__title">{{ item.name }}</span>
                            <div v-if="item.preview" class="slider__image">
                                <img :src="item.preview" />
                            </div>
                        </a>
                    </template>
                    <template v-else>
                        <span v-if="item.name" class="slider__title">{{ item.name }}</span>
                        <div v-if="item.preview" class="slider__image">
                            <img :src="item.preview" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="slider-bottom" ref="bottomSlider">
            <div class="slider-bottom__wrapper">
                <div
                    v-for="(item, index) in slides"
                    :key="index"
                    :style="`background-image: url(${item.preview})`"
                    class="slider-bottom__item"
                >
                </div>
            </div>
        </div>
        <div
            class="slider__pagination"
            :class="[ `slider__pagination-${_uid}` ]"
        ></div>
        <template>
            <z-link href="#" class="slider__arrow slider__arrow--prev" :class="[ `slider__arrow--prev-${_uid}` ]">
                <z-icon name="arrow" dir="left" width="32" height="32"></z-icon>
            </z-link>
            <z-link href="#" class="slider__arrow slider__arrow--next" :class="[ `slider__arrow--next-${_uid}` ]">
                <z-icon name="arrow" dir="right" width="32" height="32"></z-icon>
            </z-link>
        </template>

        <z-modal
            v-if="modal"
            id="sliderModal"
            class="slider-modal"
            :data="slidesForModal"
        ></z-modal>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'slider',
    mixins: [mixinDevice],
    props: {
        slides: Array,
        pagination: Boolean,
        modal: Boolean,
        autoplay: Boolean,
        effect: {
            type: String,
            default: 'slide'
        }
    },
    data () {
        return {
            slider: null,
            sliderBottom: null,
            lang: this.$root.lang,
            scrollPos: 0
        }
    },
    created () {
        this.$nextTick(function () {
            this.enableSwiper()
        })
    },
    computed: {
        slidesForModal () {
            return this.slides.map((el) => {
                el.type = 'photo'
                el.image = {
                    preview: el.preview,
                    full: el.preview
                }

                return el
            })
        }
    },
    methods: {
        enableSwiper () {
            this.sliderBottom = new Swiper(this.$refs.bottomSlider, {
                spaceBetween: 20,
                slidesPerView: 5,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                slideClass: 'slider-bottom__item',
                wrapperClass: 'slider-bottom__wrapper',
                containerModifierClass: 'slider-bottom'
            })

            this.slider = new Swiper(this.$refs.sliderContainer, {
                init: false,
                observer: true,
                observeParents: true,
                autoHeight: true,
                slidesPerView: 1,
                effect: this.effect,
                slideClass: 'slider__slide',
                wrapperClass: 'slider__wrapper',
                autoplay: this.autoplay,
                speed: 300,
                simulateTouch: false,
                fadeEffect: {
                    crossFade: true
                },
                pagination: (this.pagination) ? {
                    el: `.slider__pagination-${this._uid}`,
                    type: 'bullets',
                    clickable: true
                } : false,
                navigation: {
                    nextEl: `.slider__arrow--next-${this._uid}`,
                    prevEl: `.slider__arrow--prev-${this._uid}`
                },
                initialSlide: 0,
                direction: 'horizontal',
                on: {
                    init: () => {
                        console.log('swiper loaded')
                        this.$emit('loaded')
                    }
                },
                thumbs: {
                    swiper: this.sliderBottom
                }
            })

            this.slider.init()

            this.slider.on('transitionEnd', () => {
                console.log('transition end')
                this.slider.update()
            })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.25 5.5V3h1.5v2.5h-1.5zm7 0V3h1.5v2.5h-1.5zm-12-.813H6.5v1.5H3.75V20.75h15.5V6.187H16.5v-1.5h4.25V22.25H2.25V4.687zm8.25 0h2v1.5h-2v-1.5zm-2 6.188h-2v-1.5h2v1.5zm4 0h-2v-1.5h2v1.5zm4 0h-2v-1.5h2v1.5zm-8 3h-2v-1.5h2v1.5zm4 0h-2v-1.5h2v1.5zm4 0h-2v-1.5h2v1.5zm-8 3h-2v-1.5h2v1.5zm4 0h-2v-1.5h2v1.5zm4 0h-2v-1.5h2v1.5z" _fill="#ED1C24"/>'
  }
})

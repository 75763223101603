/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'job-money': {
    width: 81,
    height: 45,
    viewBox: '0 0 81 45',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.843 1.008C16.843.45 17.294 0 17.85 0H79.9c.556 0 1.007.451 1.007 1.008v27.609c0 .556-.45 1.007-1.008 1.007H78.25a1.008 1.008 0 110-2.015h.644V2.016H18.858v2.556a1.008 1.008 0 01-2.015 0V1.008zM1.008 7.848C.45 7.848 0 8.3 0 8.856v34.928c0 .555.45 1.006 1.005 1.008L73.289 45a1.008 1.008 0 001.011-1.008V8.856c0-.556-.451-1.008-1.008-1.008H1.008zm1.008 34.93V9.865h70.269V42.98l-70.27-.202zm4.498-28.732c-.557 0-1.008.45-1.008 1.008v23.315c0 .557.451 1.008 1.008 1.008h60.328c.556 0 1.007-.451 1.007-1.008V15.053c0-.556-.45-1.007-1.007-1.007H6.514zM7.522 37.36v-21.3h58.312v21.3H7.522zm10.473-8.863c.222.31.333.73.333 1.261 0 .52-.11.936-.329 1.248-.22.312-.56.468-1.023.468-.5 0-.899-.174-1.196-.525-.297-.35-.446-.935-.446-1.754h-2.57l-.02.047c-.024 1.332.298 2.342.967 3.03.67.688 1.53 1.092 2.58 1.211v1.848h1.37v-1.858c1.026-.118 1.834-.501 2.425-1.148.591-.648.887-1.51.887-2.585 0-1.145-.303-2.043-.91-2.693-.606-.651-1.517-1.201-2.73-1.652-.606-.344-1.016-.663-1.229-.957-.213-.293-.319-.685-.319-1.173 0-.525.096-.945.286-1.261.192-.316.506-.474.944-.474.418 0 .747.186.985.558.237.372.356.905.356 1.6h2.636c0-1.17-.28-2.114-.84-2.834-.56-.719-1.33-1.16-2.312-1.323v-2.016h-1.36v1.98c-1.027.119-1.84.514-2.44 1.186-.6.673-.9 1.531-.9 2.576 0 1.15.297 2.036.89 2.655.595.62 1.518 1.173 2.769 1.66.576.307.974.615 1.196.925zm12.392-5.549c0-.556.451-1.008 1.008-1.008h30.507a1.008 1.008 0 110 2.016H31.395a1.008 1.008 0 01-1.008-1.008zm1.008 7.96a1.008 1.008 0 000 2.015h30.507a1.008 1.008 0 000-2.016H31.395z" _fill="#ED1C24"/>'
  }
})

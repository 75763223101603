<script>
import Vue from 'vue'
import VueSvgIcon from 'vue-svgicon'
import VueTippy from 'vue-tippy'
import 'vue-svgicon/dist/polyfill'

/*
 * Components
*/
import '@/styles/app.scss'

Vue.use(VueSvgIcon, {
    tagName: 'z-icon',
    classPrefix: 'z',
    isOriginalDefault: true
})

Vue.use(VueTippy, {
    directive: 'tippy-tooltip', // --> v-tippy-tooltip
    theme: 'sta',
    placement: 'right-start',
    onShow (settings) {
        const parent = settings.reference
        const wrapper = parent.parentElement
        const classes = ['z-tooltip', 'z-tooltip--block']
        if (parent.tagName === 'SPAN' && wrapper.tagName === 'SPAN' && !wrapper.classList.contains('z-tooltip')) {
            wrapper.classList.add(...classes)
        }
    }
})

Vue.directive('click-outside', {
    bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) { warn += `Found in component '${compName}'` }

            console.warn(warn)
        }

        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
                binding.value(e)
            }
        }

        el.__vueClickOutside__ = handler

        // add Event Listeners
        document.addEventListener('click', handler)
    },
    unbind: function (el, binding) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
    }
})

Vue.component('vue-raw', {
    props: ['raw'],
    render (h) {
        const div = document.createElement('div')
        div.innerHTML = this.raw
        return h(Vue.compile(div.outerHTML.replace(/\s{2,}/g, '')))
    }
})

Vue.component('noindex', {
    name: 'NoIndex',
    functional: true,
    props: {
        tag: {
            type: String,
            default: 'div'
        }
    },
    render (h, {children, props}) {
        const commentStart = h('')
        commentStart.text = 'noindex'
        const commentEnd = h('')
        commentEnd.text = '/noindex'

        return h(props.tag, {
            class: ['robots-noindex']
        }, [commentStart, ...children, commentEnd])
    }
})

export default {
    name: 'App',
    components: {

    }
}
</script>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dogovor': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.375 16a2 2 0 012-2h19.943a2 2 0 011.399.57l6.057 5.93a2 2 0 01.601 1.428V50h-30V16zm5.507 7.975a1 1 0 011-1H38.53a1 1 0 110 2H20.882a1 1 0 01-1-1zm0 5.4a1 1 0 011-1H38.53a1 1 0 110 2H20.882a1 1 0 01-1-1zm8 10.75a1 1 0 011-1h9.647a1 1 0 110 2h-9.647a1 1 0 01-1-1zm-4.436-2.47c-.109-.533-.872-.533-.98 0a.5.5 0 01-.802.293c-.427-.34-1.011.151-.751.63a.5.5 0 01-.427.739c-.544.014-.677.766-.17.965a.5.5 0 01.148.84c-.408.362-.026 1.022.49.85a.5.5 0 01.654.548c-.08.539.636.8.921.335a.5.5 0 01.853 0c.285.465 1.002.204.922-.335a.5.5 0 01.653-.548c.517.172.899-.488.49-.85a.5.5 0 01.149-.84c.507-.2.374-.95-.17-.965a.5.5 0 01-.427-.74c.26-.478-.325-.968-.751-.63a.5.5 0 01-.802-.291z" _fill="#ED1C24"/><path pid="1" d="M45.625 29h4v13.262l-2 2.738-2-2.738V29zM45.625 26.625a1 1 0 011-1h2a1 1 0 011 1v1.387h-4v-1.387z" _fill="#ED1C24"/>'
  }
})

<template>
    <div class="media-identity-detail">
        <z-not-found
            v-if="data.length === 0"
            :title="notFound.title"
            :text="notFound.text"
        ></z-not-found>
        <div class="row" v-if="data.length" equal-height=".card">
            <div
                class="col-default-4 col-tablet-12"
                v-for="(item, i) in data"
                :key="i"
            >
                <card
                    style="min-height: 272px"
                    class="ar-card"
                    :theme="item.preview ? 'background' : 'red'"
                    overlay="rgba(0,0,0,0.45)"
                    :mark="false"
                    equal-height-max-breakpoint="mobile"
                    :image="item.preview"
                >
                    <template v-slot:title v-if="item.name">{{item.name}}</template>
                    <template v-slot:content>
                        <span v-if="item.size && item.extension" class="card__file-info">{{item.size}}, {{item.extension}}</span>
                    </template>
                    <template v-slot:bottom-left v-if="item.image">
                        <z-link
                            class="ar-card__icon u-inline"
                            target="_blank"
                            :content="image.download"
                            :href="item.image.full"
                            download
                            :downloadTitle="item.name"
                            v-tippy-tooltip="{
                                theme : 'transparent',
                                arrow: true,
                                placement: 'top-start'
                            }"
                        >
                            <z-icon name="download" width="48" height="48"></z-icon>
                        </z-link>
                        <z-link
                            class="ar-card__icon u-inline"
                            :content="image.preview"
                            href="#"
                            @click="$root.$bus.$emit('open-modal', {id: 'identikaModal', index: i})"
                            theme="red"
                            v-tippy-tooltip="{
                                theme : 'transparent',
                                arrow: true,
                                placement: 'top-start'
                            }"
                        >
                            <z-icon name="zoom-in" width="48" height="48"></z-icon>
                        </z-link>
                    </template>
                </card>
            </div>
        </div>
        <z-modal
            autoWidth
            id="identikaModal"
            class="gallery-modal"
            download
            theme="grey"
            :data="slidesForModal"
        ></z-modal>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'media-identity-detail',
    data () {
        return {
            loading: false,
            params: {},
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не найдено',
                    'en': 'No results found'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            },
            image: {
                download: localize({
                    'ru': 'Скачать изображение',
                    'en': 'Download image'
                }),
                preview: localize({
                    'ru': 'Посмотреть изображение',
                    'en': 'Preview image'
                })
            },
            data: this.$root.app.components['identity-detail']
        }
    },
    computed: {
        slidesForModal () {
            return this.data.map((el) => {
                el.type = 'photo'
                el.image = {
                    preview: el.preview,
                    full: el.image
                }

                return el
            })
        }
    }
}
</script>

<style lang="scss">
.media-identity-detail {
    .ar-card__icon:hover svg {
        background-color: transparent;
    }
    .ar-card__icon:hover [pid="0"] {
        fill: $token-colors-gp-red !important;
    }
}
</style>

<template>
    <z-list-item
        class="z-filelist-item"
        :class="{
            'z-filelist-item--with-icon': icon
        }"
    >
        <div class="z-filelist-item__body">
            <span class="z-filelist-item__date" v-if="date">{{ date | momentFilter('DD MMMM YYYY') }}</span>
            <div class="z-filelist-item__slot">
                <z-icon v-if="icon" :name="icon"></z-icon>
                <slot></slot>
            </div>
            <span class="z-filelist-item__info" v-if="info" v-html="info"></span>
        </div>
    </z-list-item>
</template>

<script>
export default {
    name: 'z-filelist-item',
    props: {
        icon: {
            type: String,
            default: ''
        },
        info: {
            type: String,
            default: ''
        },
        date: {
            type: [String, Number],
            default: ''
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <div
        class="business-model"
        :class="{
            'business-model--state-loaded' : !sliderLoaded
        }"
    >
        <div class="container">
            <div class="business-model__hero">
                <z-breadcrumbs></z-breadcrumbs>
                <h1 v-html="text.hero" ref="slide1"></h1>
            </div>
        </div>
        <z-preloader v-if="!sliderLoaded"></z-preloader>
        <div class="business-model__content">
            <gallery
                is-html
                pagination
                theme="business-model"
                effect="fade"
                @loaded="onSliderLoaded"
            >
                <gallery-slide>
                    <div class="container">
                        <div class="business-model__slide business-model__slide-1" :class="{'business-model__slide--in-view': slides.slide1}">
                            <div class="business-model__slide-badge">
                                <p v-html="text.slide1.badge"></p>
                            </div>
                            <z-lead v-html="text.slide1.lead"></z-lead>
                            <div class="business-model__slide-indicators content">
                                <div class="row">
                                    <div class="col-default-3 col-tablet-3 col-mobile-12">
                                        <z-key-indicator
                                            value="7"
                                            :name="text.slide1.indicators[0].name"
                                        ></z-key-indicator>
                                    </div>
                                    <div class="col-default-6 col-tablet-9 col-mobile-12">
                                        <div class="business-model__slide-indicator">
                                            <h4 v-html="text.slide1.indicators[1].header"></h4>
                                            <div class="row">
                                                <div class="col-default-5">
                                                    <z-key-indicator
                                                        value="400"
                                                        :prefix="text.slide1.indicators[1].prefix[0]"
                                                        :unit="text.slide1.indicators[1].unit[0]"
                                                        :name="text.slide1.indicators[1].name[0]"
                                                    ></z-key-indicator>
                                                </div>
                                                <div class="col-default-4">
                                                    <z-key-indicator
                                                        value="5,6"
                                                        :unit="text.slide1.indicators[1].unit[1]"
                                                        :name="text.slide1.indicators[1].name[1]"
                                                    ></z-key-indicator>
                                                </div>
                                                <div class="col-default-3">
                                                    <z-key-indicator
                                                        value="28"
                                                        :name="text.slide1.indicators[1].name[2]"
                                                    ></z-key-indicator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-default-3 col-tablet-4 col-mobile-12">
                                        <div class="business-model__slide-indicator">
                                            <p v-html="text.slide1.indicators[6].desc"></p>
                                        </div>
                                    </div>
                                    <div class="col-default-3 col-tablet-3 col-mobile-12">
                                        <z-key-indicator
                                            value="1"
                                            :name="text.slide1.indicators[8].name"
                                        ></z-key-indicator>
                                    </div>
                                    <div class="col-default-4 col-tablet-4 col-mobile-12">
                                        <div class="business-model__slide-indicator">
                                            <h4 v-html="text.slide1.indicators[2].header"></h4>
                                            <z-key-indicator
                                                value="25"
                                                :prefix="text.slide1.indicators[2].prefix"
                                                :unit="text.slide1.indicators[2].unit"
                                                :name="text.slide1.indicators[2].name"
                                            ></z-key-indicator>
                                        </div>
                                    </div>
                                    <div class="col-default-3 col-tablet-5 col-mobile-12">
                                        <div class="business-model__slide-indicator">
                                            <p v-html="text.slide1.indicators[5].desc"></p>
                                        </div>
                                    </div>
                                    <div class="col-default-2 col-tablet-4 col-mobile-12">
                                        <div class="business-model__slide-indicator">
                                            <h4 v-html="text.slide1.indicators[3].header"></h4>
                                        </div>
                                    </div>
                                    <div class="col-default-3 col-tablet-3 col-mobile-12">
                                        <z-key-indicator
                                            value="3200"
                                            :unit="text.slide1.indicators[4].unit"
                                            :name="text.slide1.indicators[4].name"
                                        ></z-key-indicator>
                                    </div>
                                    <div class="col-default-3 col-mobile-12">
                                        <div class="business-model__slide-indicator">
                                            <h4 v-html="text.slide1.indicators[7].header"></h4>
                                            <p v-html="text.slide1.indicators[7].desc"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </gallery-slide>
                <gallery-slide>
                    <div class="business-model__slide" ref="slide2" :class="{'business-model__slide--in-view': slides.slide2}">
                        <div class="container">
                            <div class="business-model__slide-badge">
                                <p v-html="text.slide2.badge"></p>
                            </div>
                        </div>
                        <div class="business-model__slide-infographics">
                            <business-model-slides />
                        </div>
                    </div>
                </gallery-slide>
                <gallery-slide>
                    <div class="container">
                        <div class="business-model__slide business-model__slide-3" ref="slide3" :class="{'business-model__slide--in-view': slides.slide3}">
                            <div class="business-model__slide-badge">
                                <p>{{text.slide3.badge}}</p>
                            </div>
                            <div class="row">
                                <div class="col-default-3 col-mobile-12">
                                    <div class="business-model__slide-header">
                                        <p>{{text.slide3.header[0]}}</p>
                                    </div>
                                    <div
                                        class="business-model__slide-indicator html-content"
                                        v-html="text.slide3.block[0]">
                                    </div>
                                </div>
                                <div class="col-default-3 col-mobile-12">
                                    <div class="business-model__slide-header">
                                        <p>{{text.slide3.header[1]}}</p>
                                    </div>
                                    <div
                                        class="business-model__slide-indicator html-content"
                                        v-html="text.slide3.block[1]">
                                    </div>
                                </div>
                                <div class="col-default-3 col-mobile-12">
                                    <div class="business-model__slide-header">
                                        <p>{{text.slide3.header[2]}}</p>
                                    </div>
                                    <div
                                        class="business-model__slide-indicator html-content"
                                        v-html="text.slide3.block[2]">

                                    </div>
                                </div>
                                <div class="col-default-3 col-mobile-12">
                                    <div class="business-model__slide-header business-model__slide-header--last">
                                        <p>{{text.slide3.header[3]}}</p>
                                    </div>
                                    <div
                                        class="business-model__slide-indicator html-content"
                                        v-html="text.slide3.block[3]"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </gallery-slide>
                <gallery-slide>
                    <div class="container">
                        <div class="business-model__slide" ref="slide4" :class="{'business-model__slide--in-view': slides.slide4}">
                            <div class="business-model__slide-badge">
                                <p v-html="text.slide4.badge"></p>
                            </div>
                            <div class="row">
                                <div class="col-default-3 col-mobile-12">
                                    <div class="business-model__slide-header">
                                        <p v-html="text.slide3.header[0]"></p>
                                    </div>
                                    <div class="business-model__slide-indicator--red business-model__slide-indicator">
                                        <z-key-indicator
                                            no-counter
                                            v-for="(indicator, i) in text.slide4.block[0]"
                                            :key="'s4b1' + i"
                                            :prefix="indicator.prefix"
                                            :value="indicator.value"
                                            :unit="indicator.unit"
                                            :name="indicator.name"
                                        ></z-key-indicator>
                                    </div>
                                </div>
                                <div class="col-default-3 col-mobile-12">
                                    <div class="business-model__slide-header">
                                        <p v-html="text.slide3.header[1]"></p>
                                    </div>
                                    <div class="business-model__slide-indicator--red business-model__slide-indicator">
                                        <!-- <p v-if="$root.lang === 'en'" class="u-bold">Total</p> -->
                                        <z-key-indicator
                                            no-counter
                                            v-for="(indicator, i) in text.slide4.block[1]"
                                            :key="'s4b2' + i"
                                            :prefix="indicator.prefix"
                                            :value="indicator.value"
                                            :unit="indicator.unit"
                                            :name="indicator.name"
                                        ></z-key-indicator>
                                    </div>
                                </div>
                                <div class="col-default-3 col-mobile-12">
                                    <div class="business-model__slide-header">
                                        <p v-html="text.slide3.header[2]"></p>
                                    </div>
                                    <div class="business-model__slide-indicator--red business-model__slide-indicator">
                                        <z-key-indicator
                                            no-counter
                                            v-for="(indicator, i) in text.slide4.block[2]"
                                            :key="'s4b3' + i"
                                            :prefix="indicator.prefix"
                                            :value="indicator.value"
                                            :unit="indicator.unit"
                                            :name="indicator.name"
                                        ></z-key-indicator>
                                    </div>
                                </div>
                                <div class="col-default-3 col-mobile-12">
                                    <div class="business-model__slide-header business-model__slide-header--last">
                                        <p v-html="text.slide3.header[3]"></p>
                                    </div>
                                    <div class="business-model__slide-indicator--red business-model__slide-indicator">
                                        <z-key-indicator
                                            no-counter
                                            v-for="(indicator, i) in text.slide4.block[3]"
                                            :key="'s4b4' + i"
                                            :prefix="indicator.prefix"
                                            :value="indicator.value"
                                            :unit="indicator.unit"
                                            :name="indicator.name"
                                        ></z-key-indicator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </gallery-slide>
                <div class="gallery__pagination gallery__pagination-54 swiper-pagination-clickable swiper-pagination-bullets">
                    <span
                        class="swiper-pagination-bullet"
                        @click="goToSlide(slideBtn)"
                        v-for="slideBtn in 4"
                        :key="slideBtn" :class="{'swiper-pagination-bullet-active': isActiveBtn[slideBtn]}"
                        tabindex="0"
                        role="button"
                        :aria-label="`Go to slide ${slideBtn}`"
                    ></span>
                </div>
            </gallery>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import verge from 'verge'

export default {
    name: 'business-model',
    data () {
        return {
            sliderLoaded: false,
            slides: {
                slide1: false,
                slide2: false,
                slide3: false,
                slide4: false
            },
            isActiveBtn: [false, true, false, false],
            text: {
                hero: localize({
                    ru: 'Бизнес модель',
                    en: 'Business model'
                }),
                slide1: {
                    badge: localize({
                        ru: 'Наши ресурсы',
                        en: 'Input'
                    }),
                    lead: localize({
                        ru: 'Единственный игрок на&nbsp;рынке с&nbsp;сетью терминалов в&nbsp;двух ключевых морских бассейнах России',
                        en: 'Only player with network of&nbsp;terminals in&nbsp;two key Russian container marine gateways'
                    }),
                    indicators: [
                        {
                            name: localize({
                                ru: 'морских контейнерных и&nbsp;многофункциональных терминалов <span>в&nbsp;России и&nbsp;Финляндии</span>',
                                en: 'marine container and multipurpose terminals <span>in&nbsp;Russia and Finland</span>'
                            })
                        },
                        {
                            header: localize({
                                ru: 'Уникальная база активов:',
                                en: 'Unique asset base'
                            }),
                            prefix: [
                                localize({
                                    ru: 'около',
                                    en: 'about'
                                })
                            ],
                            unit: [
                                localize({
                                    ru: 'га',
                                    en: 'ha'
                                })
                            ],
                            name: [
                                localize({
                                    ru: 'общая площадь морских терминалов',
                                    en: "of&nbsp;marine terminals' total area"
                                }),
                                localize({
                                    ru: 'длина причальной стенки',
                                    en: 'of quay wall'
                                }),
                                localize({
                                    ru: 'причалов',
                                    en: 'berths'
                                })
                            ]
                        },
                        {
                            header: localize({
                                ru: 'Развитая инфраструктура:',
                                en: 'Port infrastructure'
                            }),
                            prefix: localize({
                                ru: 'более',
                                en: 'more than'
                            }),
                            unit: localize({
                                ru: 'км',
                                en: 'km'
                            }),
                            name: localize({
                                ru: 'общая протяженность железнодорожных путей',
                                en: 'of railway tracks'
                            })
                        },
                        {
                            header: localize({
                                ru: 'Продвинутая ИТ-система',
                                en: 'Advanced IT system'
                            })
                        },
                        {
                            unit: localize({
                                ru: 'профессионалов',
                                en: 'professionals'
                            }),
                            name: localize({
                                ru: 'команда',
                                en: 'trained staff'
                            })
                        },
                        {
                            desc: localize({
                                ru: '<span>Стабильно высокие</span> операционные и&nbsp;финансовые результаты, денежный поток, <span>рентабельность по&nbsp;EBITDA</span>',
                                en: '<span>Robust</span> operational and financial performance, strong cash flow generation, high EBITDA margin'
                            })
                        },
                        {
                            desc: localize({
                                ru: '<span>Сильный и&nbsp;опытный ключевой акционер:</span> группа компаний &laquo;Дело&raquo;&nbsp;&mdash; крупнейший в&nbsp;России транспортно-логистический холдинг',
                                en: '<span>Strong and experienced key shareholder:</span> Delo Group, the major Russian transportation and logistics holding'
                            })
                        },
                        {
                            header: localize({
                                ru: 'Доступ',
                                en: 'Access'
                            }),
                            desc: localize({
                                ru: 'к&nbsp;российскому и&nbsp;международному <span>рынкам капитала</span>',
                                en: 'to&nbsp;local and international <span>capital markets</span>'
                            })
                        },
                        {
                            name: localize({
                                ru: 'тыловой терминал под Санкт-Петербургом',
                                en: 'inland terminal in&nbsp;the vicinity of&nbsp;St. Petersburg'
                            })
                        }
                    ]
                },
                slide2: {
                    badge: localize({
                        ru: 'Как мы создаем ценность',
                        en: 'How we create value'
                    }),
                    header: [
                        {
                            title: localize({
                                ru: 'Мы создаем ценность',
                                en: 'We create value'
                            }),
                            desc: localize({
                                ru: '<p>для наших клиентов (судоходных линий и&nbsp;экспедиторов), предоставляя им&nbsp;первоклассные портовые и&nbsp;сопутствующие услуги и&nbsp;обеспечивая эффективное взаимодействие с&nbsp;партнерами</p>',
                                en: '<p>by&nbsp;providing our clients (shipping lines and freight forwarders) with first class port and related logistics services and ensuring efficient interaction with our partners, by&nbsp;forming and integral part of&nbsp;import/export and transit logistics chains</p>'
                            })
                        },
                        {
                            title: localize({
                                ru: 'Предоставляя услуги',
                                en: 'When providing'
                            }),
                            desc: localize({
                                ru: '<p>и&nbsp;взаимодействуя с&nbsp;клиентами, мы&nbsp;стремимся быть:</p><ul><li>Предпочтительным партнером на&nbsp;Балтике и&nbsp;Дальнем Востоке</li> <li>Компанией с&nbsp;безопасной и&nbsp;эффективной организацией производственных процессов</li></ul>',
                                en: '<p>Services and interacting with clients we&nbsp;aim to&nbsp;be:</p><ul><li>Preferred port in&nbsp;every location, partner of&nbsp;choice for all parties involved</li> <li>Healthy, safe and effective organisation</li></ul>'
                            })
                        }
                    ]
                },
                slide3: {
                    badge: localize({
                        ru: 'Ценность',
                        en: 'Output'
                    }),
                    header: [
                        localize({
                            ru: 'Для клиентов',
                            en: 'Clients'
                        }),
                        localize({
                            ru: 'Для сотрудников',
                            en: 'Employees'
                        }),
                        localize({
                            ru: 'Для общества',
                            en: 'Community'
                        }),
                        localize({
                            ru: 'Для акционеров',
                            en: 'Shareholders'
                        })
                    ],
                    block: [
                        localize({
                            ru: `<ul>
                                    <li>Технологичный и&nbsp;эффективный логистический центр</li>
                                    <li>Качественный сервис</li>
                                    <li>Инфраструктура, обеспечивающая бесшовную логистику</li>
                                </ul>`,
                            en: `<ul>
                                    <li>Smart, swift, efficient logistics hub</li>
                                    <li>Efficient and effective services</li>
                                    <li>Infrastructure to&nbsp;facilitate import/export and transit flows</li>
                                </ul>`
                        }),
                        localize({
                            ru: `<ul>
                                    <li>Комфортная и&nbsp;безопасная рабочая среда</li>
                                    <li>Достойная оплата труда</li>
                                    <li>Возможности для профессионального роста и&nbsp;развития</li>
                                </ul>`,
                            en: `<ul>
                                    <li>Reliable and safe work environment</li>
                                    <li>Competitive salaries</li>
                                    <li>Opportunities for professional growth and development</li>
                                </ul>`
                        }),
                        localize({
                            ru: `<ul>
                                    <li>Один из&nbsp;крупнейших работодателей в&nbsp;регионах присутствия, вносящий значительный вклад в&nbsp;их&nbsp;социально-экономическое развитие</li>
                                    <li>Устойчивый бизнес, ограничивающий свое влияние на&nbsp;окружающую среду</li>
                                    <li>Удовлетворенные клиенты и&nbsp;местное общество</li>
                                </ul>`,
                            en: `<ul>
                                    <li>One of&nbsp;the biggest employers in&nbsp;the region and sizable contributor to&nbsp;local economy</li>
                                    <li>Satisfied customers and communities</li>
                                    <li>Sustainable business that limits environmental impact &amp;&nbsp;delivers positive change</li>
                                </ul>`
                        }),
                        localize({
                            ru: `<ul>
                                    <li>Акционерная стоимость</li>
                                    <li>Способность генерировать стабильно высокие денежные потоки и&nbsp;выплачивать дивиденды</li>
                                </ul>`,
                            en: `<ul>
                                    <li>Shareholder value</li>
                                    <li>Sustainable high free cash flow generation and dividend capability</li>
                                </ul>`
                        })
                    ]
                },
                slide4: {
                    badge: localize({
                        ru: 'Результаты Глобал Портс в&nbsp;2023 году',
                        en: 'Global Ports results in&nbsp;2023'
                    }),
                    block: [
                        [
                            {
                                value: localize({
                                    ru: '-8,6',
                                    en: '-8.6'
                                }),
                                unit: localize({
                                    ru: '%',
                                    en: '%'
                                }),
                                name: localize({
                                    ru: 'снижение консолидированного контейнерного грузооборота по&nbsp;итогам года',
                                    en: 'decrease in&nbsp;consolidated marine container throughput'
                                })
                            },
                            {
                                value: localize({
                                    ru: '+34,7',
                                    en: '+34.7'
                                }),
                                unit: localize({
                                    ru: '%',
                                    en: '%'
                                }),
                                name: localize({
                                    ru: 'рост консолидированного контейнерного грузооборота в&nbsp;II полугодии',
                                    en: 'increase in&nbsp;consolidated marine container throughput in&nbsp;2H&nbsp;2023'
                                })
                            },
                            {
                                value: localize({
                                    ru: '+68,4',
                                    en: '+68.4'
                                }),
                                unit: localize({
                                    ru: '%',
                                    en: '%'
                                }),
                                name: localize({
                                    ru: 'увеличение консолидированного грузооборота навалочных грузов',
                                    en: 'increase in&nbsp;consolidated bulk cargo throughput'
                                })
                            }
                        ],
                        [
                            {
                                value: localize({
                                    ru: '7,3',
                                    en: '7.3'
                                }),
                                prefix: localize({
                                    ru: '',
                                    en: 'RUB'
                                }),
                                unit: localize({
                                    ru: 'млрд руб.',
                                    en: 'bn'
                                }),
                                name: localize({
                                    ru: 'выплачено сотрудникам в&nbsp;виде заработной платы',
                                    en: 'paid to&nbsp;employees'
                                })
                            },
                            {
                                value: localize({
                                    ru: '17,6',
                                    en: '17.6'
                                }),
                                prefix: localize({
                                    ru: '',
                                    en: 'RUB'
                                }),
                                unit: localize({
                                    ru: 'млн руб.',
                                    en: 'mn'
                                }),
                                name: localize({
                                    ru: 'расходы на обучение персонала',
                                    en: 'in&nbsp;personnel training costs'
                                })
                            },
                            {
                                value: localize({
                                    ru: '3,7',
                                    en: '3.7'
                                }),
                                prefix: localize({
                                    ru: '',
                                    en: 'RUB'
                                }),
                                unit: localize({
                                    ru: 'млн руб.',
                                    en: 'mn'
                                }),
                                name: localize({
                                    ru: 'расходы на&nbsp;обучение по&nbsp;охране труда и&nbsp;производственной безопасности',
                                    en: 'in&nbsp;occupational health and safety training costs'
                                })
                            }
                        ],
                        [
                            {
                                value: localize({
                                    ru: '67,0',
                                    en: '67.0'
                                }),
                                prefix: localize({
                                    ru: '',
                                    en: 'RUB'
                                }),
                                unit: localize({
                                    ru: 'млн руб.',
                                    en: 'mn'
                                }),
                                name: localize({
                                    ru: 'направлено на&nbsp;благотворительность',
                                    en: 'spent on&nbsp;charity'
                                })
                            },
                            {
                                value: localize({
                                    ru: '67,8',
                                    en: '67.8'
                                }),
                                prefix: localize({
                                    ru: '',
                                    en: 'RUB'
                                }),
                                unit: localize({
                                    ru: 'млн руб.',
                                    en: 'mn'
                                }),
                                name: localize({
                                    ru: 'направлено на&nbsp;защиту окружающей среды',
                                    en: 'spent on&nbsp;environmental protection'
                                })
                            },
                            {
                                value: localize({
                                    ru: '5,8',
                                    en: '5.8'
                                }),
                                prefix: localize({
                                    ru: '',
                                    en: 'RUB'
                                }),
                                unit: localize({
                                    ru: 'млрд руб.',
                                    en: 'bn'
                                }),
                                name: localize({
                                    ru: 'уплачено налогов',
                                    en: 'taxes paid'
                                })
                            }
                        ],
                        [
                            {
                                value: localize({
                                    ru: 'АА(RU)',
                                    en: 'АА(RU)'
                                }),
                                name: localize({
                                    ru: 'кредитный рейтинг от&nbsp;агентства АКРА',
                                    en: 'credit rating from ACRA agency'
                                })
                            },
                            {
                                value: localize({
                                    ru: 'ruAA–',
                                    en: 'ruAA–'
                                }),
                                name: localize({
                                    ru: 'кредитный рейтинг от&nbsp;агентства &laquo;Эксперт&nbsp;РА&raquo;',
                                    en: 'credit rating from Expert RA&nbsp;agency'
                                })
                            },
                            {
                                value: localize({
                                    ru: '2,2x',
                                    en: '2.2x'
                                }),
                                name: localize({
                                    ru: 'чистый долг/EBITDA',
                                    en: 'Net Debt-to-Adjusted EBITDA'
                                })
                            }
                        ]
                    ]
                }
            }
        }
    },
    mounted () {
        // nope
        window.addEventListener('scroll', this.slidesInView)
    },
    methods: {
        slidesInView () {
            ['slide1', 'slide2', 'slide3', 'slide4'].forEach((slide, index) => {
                if (verge.inViewport(this.$refs[slide])) {
                    this.slides[slide] = true
                    this.setActiveBtn(index + 1)
                }
            })
        },
        setActiveBtn (index) {
            this.isActiveBtn = [false, false, false, false]
            this.isActiveBtn[index] = true
        },
        goToSlide (slideIndex) {
            this.$refs[`slide${slideIndex}`].scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'})
        },
        onSliderLoaded () {
            setTimeout(() => {
                this.sliderLoaded = true
            }, 1000)

            setTimeout(() => {
                this.goToSlide(2)
            }, 1200)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

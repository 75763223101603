<template>
    <div class="contacts-item" v-if="data">
        <div class="row">
            <div
                v-for="(item, i) in data.items"
                :key="i"
                class="col-default-6 col-mobile-12"
            >
                <div class="contacts-item">
                    <h2 v-if="item.name" class="contacts-item__name">{{ item.name }}</h2>
                    <h3 v-if="item.full_name" class="contacts-item__full-name">{{ item.full_name }}</h3>
                    <span v-if="item.position" class="contacts-item__position">{{ item.position }}</span>
                    <span
                        class="contacts-item__company"
                        v-for="(company, k) in item.companies"
                        :key="k"
                    >
                        {{ company }}
                    </span>
                    <div class="card__contacts" :class="{'card__contacts--long-name': item['postal-address']}">
                        <div class="card__contacts-row" v-if="item.address">
                            <span class="card__contacts-name">{{ titleAddress }}</span>
                            <span class="card__contacts-value">{{ item.address }}</span>
                        </div>
                        <div class="card__contacts-row" v-if="item['postal-address']">
                            <span class="card__contacts-name">{{ titlePostalAddress.start }} <br> {{ titlePostalAddress.end }}</span>
                            <span class="card__contacts-value">{{ item['postal-address'] }}</span>
                        </div>
                        <div class="card__contacts-row" v-if="item.phones.length">
                            <span class="card__contacts-name">{{ titlePhone }}</span>
                            <span class="card__contacts-value">
                                <span
                                    v-for="phone in item.phones"
                                    :key="phone.number"
                                >
                                    {{ phone.number }}
                                </span>
                            </span>
                        </div>
                        <div class="card__contacts-row" v-if="item.fax">
                            <span class="card__contacts-name">{{ titleFax }}</span>
                            <span class="card__contacts-value">{{ item.fax }}</span>
                        </div>
                        <div class="card__contacts-row" v-if="item.mail">
                            <span class="card__contacts-name">{{ titleEmail }}</span>
                            <span class="card__contacts-value"><z-link :href="'mailto:' + item.mail">{{ item.mail }}</z-link></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <z-accordion v-if="data.sections && data.sections.length">
            <z-accordion-item
                v-for="section in data.sections"
                :key="section.code"
                :id="section.code"
            >
                <template v-slot:icon>
                    <z-icon name="arrow" dir="down" height="16"></z-icon>
                </template>
                <template v-slot:header>
                    <span v-html="section.name"></span>
                </template>
                <template v-slot:body>
                    <div class="row">
                        <div
                            v-for="(item, o) in section.items"
                            :key="o"
                            class="col-default-6 col-mobile-12"
                        >
                            <div class="contacts-item">
                                <h2 v-if="item.name" class="contacts-item__name">{{ item.name }}</h2>
                                <h3 v-if="item.full_name" class="contacts-item__full-name">{{ item.full_name }}</h3>
                                <span v-if="item.position" class="contacts-item__position">{{ item.position }}</span>
                                <span
                                    class="contacts-item__company"
                                    v-for="(company, k) in item.companies"
                                    :key="k"
                                >
                                    {{ company }}
                                </span>
                                <div class="card__contacts">
                                    <div class="card__contacts-row" v-if="item.address">
                                        <span class="card__contacts-name">{{ titleAddress }}</span>
                                        <span class="card__contacts-value">{{ item.address }}</span>
                                    </div>
                                    <div class="card__contacts-row" v-if="item['postal-address']">
                                        <span class="card__contacts-name">{{ titlePostalAddress.start }} <br> {{ titlePostalAddress.end }}</span>
                                        <span class="card__contacts-value">{{ item['postal-address'] }}</span>
                                    </div>
                                    <div class="card__contacts-row" v-if="item.phones.length">
                                        <span class="card__contacts-name">{{ titlePhone }}</span>
                                        <span class="card__contacts-value">
                                            <span
                                                v-for="phone in item.phones"
                                                :key="phone.number"
                                            >
                                                {{ phone.number }}
                                            </span>
                                        </span>
                                    </div>
                                    <div class="card__contacts-row" v-if="item.fax">
                                        <span class="card__contacts-name">{{ titleFax }}</span>
                                        <span class="card__contacts-value">{{ item.fax }}</span>
                                    </div>
                                    <div class="card__contacts-row" v-if="item.mail">
                                        <span class="card__contacts-name">{{ titleEmail }}</span>
                                        <span class="card__contacts-value"><z-link :href="'mailto:' + item.mail">{{ item.mail }}</z-link></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </z-accordion-item>
        </z-accordion>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'contacts',
    props: [
        'data'
    ],
    data () {
        return {
            titleAddress: localize({
                'ru': 'Адрес:',
                'en': 'Address:',
                'cn': '地址:'
            }),
            titlePostalAddress: {
                start: localize({
                    'ru': 'Адрес:',
                    'en': 'Postal address:',
                    'cn': '地址:'
                }),
                end: localize({
                    'ru': '(для почтовых отправлений)',
                    'en': ''
                })
            },
            titlePhone: localize({
                'ru': 'Тел.:',
                'en': 'Phone:',
                'cn': '电话:'
            }),
            titleFax: localize({
                'ru': 'Факс:',
                'en': 'Fax:',
                'cn': '传真:'
            }),
            titleEmail: localize({
                'ru': 'Е-mail:',
                'en': 'Е-mail:',
                'cn': '邮箱:'
            })
        }
    }
}
</script>

<style lang="scss">
    .contacts {
        &-item {
            &__full-name {
                margin-bottom: 1rem;
            }

            &__company {
                display: block;
                margin-top: 1.75rem;
                font-size: 18px;

                + .contacts-item__company {
                    margin-top: 0;
                }
            }

            &__position {
                display: block;
                color: #7A7A7E;
                font-weight: bold;
                font-size: 18px;
                line-height: 1.5;
            }

            .card__contacts {
                margin-top: 1.5rem;
                margin-bottom: 2rem;
            }
        }
    }
</style>

const app = {
    state: {
        device: 'desktop',
        heroTitle: '',
        heroSrc: '',
        terminalLogo: ''
    },
    mutations: {
        SetHeroTitle: (state, heroTitle) => {
            state.heroTitle = heroTitle
        },
        SetHeroSrc: (state, heroSrc) => {
            state.heroSrc = heroSrc
        },
        SetTerminalLogo: (state, terminalLogo) => {
            state.terminalLogo = terminalLogo
        },
        ToggleDevice: (state, device) => {
            state.device = device
        }
    },
    actions: {
        SetHeroTitle ({ commit }, title) {
            commit('SetHeroTitle', title)
        },
        SetHeroSrc ({ commit }, heroSrc) {
            commit('SetHeroSrc', heroSrc)
        },
        SetTerminalLogo ({ commit }, terminalLogo) {
            commit('SetTerminalLogo', terminalLogo)
        },
        ToggleDevice ({ commit }, device) {
            commit('ToggleDevice', device)
        }
    }
}

export default app

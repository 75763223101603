/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'avatar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.325 19.1c.327-.815.757-1.316 1.36-1.72.442-.298 1.278-.543 2.046-.769.555-.163 1.076-.315 1.385-.47.738-.367 1.174-.743 1.61-1.12.178.86.356 1.72.465 2.46.11.739.149 1.358.189 1.976.178-.367.356-.735.554-1.01.198-.275.415-.458.632-.641l.002-.002c.277.426.555.85.632 1.107.043.147.022.238-.033.466-.04.172-.1.421-.163.83-.125.798-.267 2.206-.411 3.711A11.99 11.99 0 012.325 19.1zm11.083 4.818c-.145-1.505-.287-2.913-.412-3.712-.064-.408-.123-.657-.164-.828-.054-.23-.076-.32-.032-.467.077-.256.353-.679.629-1.103l.003-.004.002.002c.217.183.434.366.632.64.198.277.376.644.554 1.011v-.004c.04-.617.08-1.235.188-1.973.11-.739.288-1.6.466-2.46.436.378.872.754 1.61 1.122.31.154.83.306 1.385.47.768.225 1.604.47 2.047.767.602.405 1.032.906 1.359 1.721a11.99 11.99 0 01-8.268 4.818zm.846-9.178v-1.297l.197-.204c.307-.316.47-.681.5-1.116l.047-.718c.03-.007.06-.015.088-.024.152-.044.279-.096.374-.172.096-.076.16-.176.232-.345a4.432 4.432 0 00.326-1.349c.021-.235.028-.471-.028-.632a.48.48 0 00-.309-.296.688.688 0 00-.195-.041l.003-.023c.024-.023.049-.049.073-.076.271-.313.54-.913.599-1.61.058-.698-.094-1.495-.343-1.888-.249-.394-.594-.385-.94-.377-.107-.46-.214-.919-.658-1.289-.444-.37-1.224-.65-1.982-.685-.758-.035-1.493.175-2.148.552-.348.2-.673.447-.96.665-.254.193-.478.363-.663.46-.394.205-.61.074-.825-.057-.117.683-.233 1.365-.161 2.14.072.774.332 1.64.677 2.082l.027.033.002.027c0 .008.01.016.02.024.014.012.027.023.002.03-.015.003-.032.003-.051.003-.033 0-.073 0-.13.017a.484.484 0 00-.275.272c-.05.136-.053.31-.037.515a6.54 6.54 0 00.321 1.465c.073.197.137.297.232.373.095.076.222.128.374.173l.01.003.05.744c.03.438.195.805.506 1.122l.17.172v1.327c0 .236.102.44.294.583l2.143 1.605 2.144-1.605a.707.707 0 00.294-.583z" _fill="#ED1C24"/>'
  }
})

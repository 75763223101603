<template>
    <div class="media-booklets">
        <z-not-found
            v-if="(notFound.state && !loading)"
            :title="notFound.title"
            :text="notFound.text"
        ></z-not-found>
        <div class="row" v-if="list.length" equal-height=".card">
            <div
                class="col-default-4 col-tablet-12"
                v-for="(item, i) in list"
                :key="i"
            >
                <card
                    style="min-height: 272px"
                    class="ar-card"
                    :theme="item.image ? 'background' : 'red'"
                    overlay="rgba(0,0,0,0.45)"
                    :mark="false"
                    :image="item.image"
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title v-if="item.name">{{item.name}}</template>
                    <template v-slot:content>
                        <span v-if="item.size && item.extension" class="card__file-info">{{item.size}}, {{item.extension}}</span>
                    </template>
                    <template v-slot:bottom-left v-if="item.link">
                        <z-link
                            class="ar-card__icon u-inline"
                            target="_blank"
                            content="PDF version"
                            :href="item.link"
                            v-tippy-tooltip="{
                                theme : 'transparent',
                                arrow: true,
                                placement: 'top-start'
                            }"
                        >
                            <z-icon name="download" width="48" height="48"></z-icon>
                        </z-link>
                    </template>
                </card>
            </div>
        </div>
        <z-preloader v-show="loading"></z-preloader>
    </div>
</template>

<script>
import {getBooklets} from '@/api/media'
import {throttle} from 'throttle-debounce'
import { localize } from '@/utils/i18n'

export default {
    name: 'media-booklets',
    data () {
        return {
            loading: false,
            params: {},
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не найдено',
                    'en': 'No results found'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            },
            url: '/booklets',
            booklets: this.$root.app.components['booklets-list'],
            list: this.$root.app.components['booklets-list'].items,
            page: this.$root.app.components['booklets-list'].nav,
            code: this.$root.app.system.params.get.CODE
        }
    },
    methods: {
        getMoreBooklets () {
            this.loading = true
            if (this.params.page === 1) this.list = []

            getBooklets(this.params).then((res) => {
                if (res.hasOwnProperty('nav')) {
                    this.page.current = res.nav.current
                    this.page.total = res.nav.total
                    this.page.count = res.nav.count
                }

                if (res.hasOwnProperty('items')) {
                    if (res.items.length === 0) {
                        this.notFound.state = true
                    } else {
                        this.notFound.state = false
                    }

                    if (this.page.current < 2) {
                        this.list = res.items
                    } else {
                        this.list = this.list.concat(res.items)
                    }
                }

                // This garbage code for the sake of IE
                let customEvent = document.createEvent('HTMLEvents')
                customEvent.initEvent('newCardsAdded', true, true)
                document.dispatchEvent(customEvent)

                this.loading = false
            })
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(500, e => {
                const offsetTop = this.$el.offsetTop
                const offsetHeight = this.$el.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (scrolled >= (bottom - 100) && !this.loading && this.page.current < this.page.total) {
                    this.params.page++
                    this.getMoreBooklets()
                };
            }))
        }
    },
    created () {
        this.params = {
            page: 1,
            code: this.code
        }

        if (!this.list.length) {
            this.notFound.state = true
        }
        this.listenScroll()
    }
}
</script>

<style lang="scss"></style>

<template>
    <div class="site-map" v-if="siteMap">
        <!-- begin: temporary-title -->
        <div class="site-map--title">
            <h1>{{ text.title }}</h1>
        </div>
        <!-- end: temporary-title -->

        <div class="site-map__container">
            <div
                v-for="(item, index) in siteMap"
                :key="index"
                class="site-map__content"
            >
                <div class="site-map__title">
                    <a :href="item.link">
                        <h3 v-html="item.name"></h3>
                    </a>
                </div>

                <z-list size="l" :bullets="false">
                    <z-list-item
                        v-for="(subItem, index) in item.items"
                        :key="index"
                    ><a :href="subItem.link" v-html="subItem.name"></a>
                        <z-list v-if="subItem.items" nested :bullets="false">
                            <z-list-item v-for="(element, index) in subItem.items" :key="index">
                                <a :href="element.link" v-html="element.name"></a>
                            </z-list-item>
                        </z-list>
                    </z-list-item>
                </z-list>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'site-map',
    props: {
        component: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            siteMap: this.$root.app.components[this.component],
            text: {
                title: localize({
                    'ru': 'Карта сайта',
                    'en': 'Site map',
                    'cn': '移动客户端'
                })
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

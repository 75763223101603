/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tooltip': {
    width: 12,
    height: 15,
    viewBox: '0 0 12 15',
    data: '<ellipse pid="0" cx="6" cy="7.152" rx="6" ry="7.152" _fill="#C4C4C4"/><path pid="1" d="M4.964 8.741V8.22c0-.404.074-.755.223-1.054.148-.3.419-.617.813-.954.38-.316.63-.573.748-.77.123-.198.185-.42.185-.664 0-.273-.087-.482-.26-.625-.174-.143-.416-.214-.727-.214-.543 0-1.16.206-1.855.618L3.5 3.173c.806-.526 1.661-.789 2.565-.789.745 0 1.336.208 1.773.625.441.417.662.973.662 1.667 0 .464-.09.864-.271 1.2-.18.337-.524.716-1.03 1.137-.348.299-.568.526-.662.682-.09.156-.136.36-.136.613v.433H4.964zm-.173 2.105c0-.353.081-.62.244-.802.162-.18.4-.271.71-.271.3 0 .532.092.694.278.167.185.25.45.25.795 0 .333-.083.596-.25.79-.166.19-.397.284-.694.284-.304 0-.539-.093-.705-.278-.166-.19-.25-.454-.25-.796z" _fill="#fff"/>'
  }
})

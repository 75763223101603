<template>
    <label
        :for="name"
        class="z-checkbox"
        :class="{
            'is-checked': check,
            'is-disabled': disabled
        }"
    >
        <input
            :id="name"
            :name="name"
            :checked="checked"
            :disabled="disabled"
            @change="change($event.target.checked)"
            type="checkbox"
            ref="checkbox"
        />
        <span class="z-checkbox__box"></span>
        <span class="z-checkbox__text">
            <slot />
        </span>
    </label>
</template>

<script>
export default {
    name: 'z-checkbox',
    props: {
        name: {
            type: String,
            required: true
        },
        paramsName: String,
        checked: Boolean,
        disabled: Boolean
    },
    data () {
        return {
            error: false,
            focus: false,
            check: this.checked
        }
    },
    mounted () {
        this.$root.$bus.$on('clear', name => {
            if (this.name === name) this.change(false)
        })
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.change(false)
        })
    },
    methods: {
        change (value) {
            this.check = value
            this.$refs.checkbox.checked = value
            this.$emit('change', { value: value, name: this.name, paramsName: this.paramsName })
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>

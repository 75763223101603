<template>
    <div class="investors">
        <div class="row">
            <div class="col-default-4 col-mobile-12 row mp-0">
                <div class="col-default-12 col-mobile-12">
                    <card class="investors-results" theme="red">
                        <template v-slot:title>
                            <div v-html="investors['banner'].name"></div>
                        </template>
                        <template v-slot:content>
                            <div class="html-content" v-html="investors['banner'].detail"></div>
                        </template>
                        <template v-slot:bottom-right>
                            <z-button tag="a" theme="white" :href="investors['banner'].link" kind="text">
                            <span v-html="investors['banner']['name-link'] ? investors['banner']['name-link'] : 'Learn more'"></span>
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                            </z-button>
                        </template>
                    </card>
                </div>

                <div class="col-default-12 col-mobile-12 u-top-margin--no-important">
                    <card link="gdr-holders/" theme="background" overlay="rgba(0,0,0,0.45)" image="/images/components/investors/gdr-holders.jpg">
                        <template v-slot:title>Important information for GDR holders</template>
                    </card>
                </div>
            </div>
            <div class="col-default-8 col-mobile-12 row mp-0" equal-height=".card">
                <div class="col-default-6 col-mobile-12">
                    <card
                        style="min-height: 365px"
                        class="ar-card u-bottom-margin--l-desktop"
                        theme="background"
                        overlay="rgba(0,0,0,0.45)"
                        :image="investors['annual-report'][lang].image ? investors['annual-report'][lang].image : '/images/annual-report.jpg'"
                        equal-height-max-breakpoint="mobile"
                        >
                        <template v-slot:title>
                            {{investors['annual-report'][reportLang].name}}
                        </template>
                        <template v-slot:content>
                            <template v-if="investors['annual-report']['ru']">
                                <z-dropdown ref="report-dropdown">
                                    <template v-slot:trigger>
                                        <span
                                            class="ar-card__active"
                                            v-html="langVersion[reportLang]"
                                        ></span>
                                        <z-icon dir="down" color="#fff" name="arrow" width="12" height="12"></z-icon>
                                    </template>
                                    <template v-slot:content>
                                        <div
                                            class="ar-card__version"
                                            @click.prevent="changeReportLang('ru')"
                                        >
                                            {{ langVersion['ru'] }}
                                        </div>
                                        <div
                                            class="ar-card__version"
                                            @click.prevent="changeReportLang('en')"
                                        >
                                            {{ langVersion['en'] }}
                                        </div>
                                        <div
                                            class="ar-card__version"
                                            @click.prevent="changeReportLang('cn')"
                                        >
                                            {{ langVersion['cn'] }}
                                        </div>
                                    </template>
                                </z-dropdown>
                            </template>
                        </template>
                        <template v-slot:bottom-left>
                            <z-link
                                class="ar-card__icon u-inline"
                                v-if="investors['annual-report'][reportLang] && investors['annual-report'][reportLang].online"
                                :href="investors['annual-report'][reportLang].online"
                                target="_blank"
                                content="Online version"
                                v-tippy-tooltip="{
                                        theme : 'transparent',
                                        arrow: true,
                                        placement: 'top-start'
                                    }"
                                >
                                <z-icon name="desktop" width="48" height="48"></z-icon>
                            </z-link>
                            <z-link
                                class="ar-card__icon u-inline"
                                v-if="investors['annual-report'][reportLang] && investors['annual-report'][reportLang].file"
                                :href="investors['annual-report'][reportLang].file"
                                target="_blank"
                                :content="text.pdfReport[reportLang]"
                                v-tippy-tooltip="{
                                    theme : 'transparent',
                                    arrow: true,
                                    placement: 'top-start'
                                }"
                                >
                                <z-icon name="download" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                    </card>
                </div>
                <div class="col-default-6 col-mobile-12">
                    <card style="min-height: 365px" equal-height-max-breakpoint="mobile">
                        <template v-slot:title>IR Quick Links</template>
                        <template v-slot:content>
                            <div class="investors__links">
                                <div>
                                    <z-icon name="link"></z-icon>
                                    <z-link
                                        href="reports-and-results/"
                                        class="investors__link-text"
                                        :underline="false"
                                        >
                                        Reports and results
                                    </z-link>
                                </div>
                                <div>
                                    <z-icon name="link"></z-icon>
                                    <z-link
                                        href="contacts/"
                                        class="investors__link-text"
                                        :underline="false"
                                        >
                                        Contact IR Team
                                    </z-link>
                                </div>
                                <div>
                                    <z-icon name="link"></z-icon>
                                    <z-link href="events/" class="investors__link-text" :underline="false">IR calendar</z-link>
                                </div>
                                <div>
                                    <z-icon name="link"></z-icon>
                                    <z-link
                                        href="subscription/"
                                        class="investors__link-text"
                                        :underline="false"
                                        >
                                        Subscribe IR news
                                    </z-link>
                                </div>
                                <div>
                                    <z-icon name="link"></z-icon>
                                    <z-link href="dividends/" class="investors__link-text" :underline="false">Dividends</z-link>
                                </div>
                                <div>
                                    <z-icon name="link"></z-icon>
                                    <z-link href="/files/en/Global_Ports_June_2024_Factsheet.pdf" target="_blank" class="investors__link-text" :underline="false">Investor Fact Sheet</z-link>
                                </div>
                                <div>
                                    <z-icon name="link"></z-icon>
                                    <z-link href="gdr-holders/" class="investors__link-text" :underline="false">For GDR holders</z-link>
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <div class="row investors__invest-row">
            <div class="col-default-12 col-mobile-12">
                <card
                    theme="background"
                    overlay="rgba(0,0,0,0.45)"
                    image="/images/components/investors/invest.jpg"
                    style="min-height: 285px"
                    :mark="false"
                >
                    <template v-slot:title>Invest in Global Ports</template>
                    <template v-slot:content>
                        <div class="row">
                            <div class="col-default-5 col-mobile-12">
                                <p>No.&nbsp;1&nbsp;container terminal operator in&nbsp;the Russian market with unique network of&nbsp;terminals in&nbsp;the key container gateways. Robust financial and operational performance combined with sustainable strong cash flow generation ability.</p>
                            </div>
                            <div class="col-default-1 col-mobile-hide"></div>
                            <div class="col-default-2 col-mobile-6 col-v-mobile-12">
                                <div class="investors__invest-indicator investors__invest-indicator--text">
                                    <z-key-indicator unit="AA(RU)" name="high credit rating by&nbsp;ACRA (last update 09.02.2024)"></z-key-indicator>
                                </div>
                            </div>
                            <div class="col-default-2 col-mobile-6 col-v-mobile-12">
                                <div class="investors__invest-indicator">
                                    <z-key-indicator value="2.2" decimal-places="1" unit="x" name="Net Debt/Adjusted EBITDA (as&nbsp;of&nbsp;31.12.2023)"></z-key-indicator>
                                </div>
                            </div>
                            <div class="col-default-2 col-mobile-12">
                                <div class="investors__links">
                                    <div>
                                        <z-icon name="link" color="white"></z-icon>
                                        <z-link
                                            href="/en/company/strategy/"
                                            class="investors__link-text"
                                            theme="white"
                                            :underline="false"
                                            >
                                            Strategy
                                        </z-link>
                                    </div>
                                    <div>
                                        <z-icon name="link" color="white"></z-icon>
                                        <z-link
                                            href="/en/company/business-model/"
                                            class="investors__link-text"
                                            theme="white"
                                            :underline="false"
                                            >
                                            Business model
                                        </z-link>
                                    </div>
                                    <div>
                                        <z-icon name="link" color="white"></z-icon>
                                        <z-link
                                            href="/en/company/structure/"
                                            theme="white"
                                            class="investors__link-text"
                                            :underline="false"
                                            >
                                            Asset base
                                        </z-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" theme="white" href="proposition/" kind="primary" rounded>
                            <span>Learn more</span>
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                </card>
            </div>
        </div>
        <div class="row" equal-height=".card">
            <div class="col-default-6 col-mobile-12">
                <card
                    link="securities/listing/"
                    style="height: 157px"
                    class="ar-card u-bottom-margin--l-desktop"
                    theme="background"
                    overlay="rgba(0,0,0,0.45)"
                    image="/images/components/investors/equity.jpg"
                    :mark="false"
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>For equity investors</template>
                </card>
            </div>
            <div class="col-default-6 col-mobile-12">
                <card
                    link="securities/bonds/"
                    style="height: 157px"
                    class="ar-card u-bottom-margin--l-desktop"
                    theme="background"
                    overlay="rgba(0,0,0,0.45)"
                    image="/images/components/investors/income.jpg"
                    :mark="false"
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>For fixed income investors</template>
                </card>
            </div>
            <!-- <div class="col-default-4 col-mobile-12">
                <card
                    style="min-height: 150px"
                    class="ar-card u-bottom-margin--l-desktop"
                    theme="white"
                    equal-height-max-breakpoint="mobile"
                    :mark="false"
                >
                    <template v-slot:content>
                        <iframe class="quotes-iframe" :src="'https://charts3.equitystory.com/teaser/globalports-v31/English/'" width="100%"></iframe>
                    </template>
                    <template v-slot:title>
                        <z-link
                            href="/en/investors/securities/tools/"
                            theme="dark"
                            :underline="false"
                            size="xl"
                        >
                        Share price
                        </z-link>
                    </template>
                </card>
            </div> -->
        </div>
        <div class="row investors__links-row">
            <div class="col-default-4 col-mobile-12">
                <card
                    theme="background"
                    overlay="rgba(0,0,0,0.45)"
                    image="/images/components/investors/governance.jpg"
                    style="min-height: 475px"
                >
                    <template v-slot:title>Corporate governance</template>
                    <template v-slot:content>
                        <div class="investors__links">
                            <div>
                                <z-icon name="link" color="white"></z-icon>
                                <z-link
                                    href="/en/company/governance/management/"
                                    class="investors__link-text"
                                    theme="white"
                                    :underline="false"
                                    >
                                    Management
                                </z-link>
                            </div>
                            <div>
                                <z-icon name="link" color="white"></z-icon>
                                <z-link
                                    href="/en/company/governance/board-of-directors/"
                                    class="investors__link-text"
                                    theme="white"
                                    :underline="false"
                                    >
                                    Board of directors
                                </z-link>
                            </div>
                            <div>
                                <z-icon name="link" color="white"></z-icon>
                                <z-link
                                    href="/en/company/governance/documents/"
                                    theme="white"
                                    class="investors__link-text"
                                    :underline="false"
                                    >
                                    Documents
                                </z-link>
                            </div>
                        </div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" theme="white" href="/en/company/governance/" kind="text">
                            <span>Learn more</span>
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon theme="white" size="l">
                            <z-icon name="governance" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            <div class="col-default-8 col-mobile-12">
                <card
                    theme="white"
                    style="min-height: 475px"
                >
                    <template v-slot:title>News</template>
                    <template v-slot:content>
                        <div class="investors__news">
                            <div v-if="investors['news'].length !== 0" class="investors__news-main">
                                <z-link :href="investors['news'][0].link" :underline="false">
                                    <span>
                                        <p class="investors__news-paragraph">{{investors['news'][0].name}}</p>
                                        <span class="card__date">{{ investors['news'][0].date | momentFilter('DD MMMM YYYY') }}</span>
                                    </span>
                                </z-link>
                            </div>
                            <div class="investors__news-other">
                                <div class="row">
                                    <div class="col-default-6 col-mobile-12" v-for="(item,index) in investors['news'].slice(1, 3)" :key="index">
                                        <z-link :href="item.link" :underline="false">
                                            <span>
                                                <p class="investors__news-paragraph investors__news-paragraph--small">{{item.name}}</p>
                                                <span class="card__date investors__news-date--small">{{ item.date | momentFilter('DD MMMM YYYY') }}</span>
                                            </span>
                                        </z-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" theme="dark-grey" href="/en/investors/news/" kind="text">
                            <span>All news</span>
                            <template v-slot:right>
                                <z-icon name="arrow" theme="gp-red" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                </card>
            </div>
        </div>
        <div class="row">
            <div class="col-default-12 col-mobile-12">
                <card
                    theme="background"
                    overlay="rgba(0,0,0,0.45)"
                    image="/images/components/investors/esg.png"
                    style="min-height: 447px"
                    :mark="false"
                >
                    <template v-slot:title>ESG</template>
                    <template v-slot:content>
                        <div class="row investors__blockquote-row">
                            <div class="col-default-6 col-mobile-12 investors__blockquote">
                                <p>Being a responsible business is a critical component of how we operate at Global Ports and fundamental to our delivering long term sustainable growth.</p>
                            </div>
                            <div class="col-default-1 col-mobile-hide"></div>
                            <div class="col-default-2 col-mobile-6 col-v-mobile-12">
                                <div class="investors__blockquote-indicator">
                                    <z-key-indicator value="20" unit="%" name="share of independent<br>directors on&nbsp;the Board<br>(as&nbsp;of&nbsp;31.12.2023)"></z-key-indicator>
                                </div>
                            </div>
                            <div class="col-default-3 col-mobile-6 col-v-mobile-12">
                                <div class="investors__blockquote-indicator">
                                    <z-key-indicator value="28" unit="%" name="share of&nbsp;women<br>in&nbsp;total number of&nbsp;employees<br>(2023)"></z-key-indicator>
                                </div>
                            </div>
                            <div class="col-default-7 col-mobile-hide"></div>
                            <div class="col-default-3 col-mobile-6 col-v-mobile-12">
                                <div class="investors__blockquote-indicator">
                                    <z-key-indicator prefix="RUB" value="67.8" unit="million" name="paid for environment protection (2023)"></z-key-indicator>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" theme="white" href="/en/sustainability/" kind="primary" rounded>
                            <span>Learn more</span>
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'investors',
    data () {
        return {
            investors: this.$root.app.components['investors'],
            langVersion: {
                'ru': 'Russian version',
                'en': 'English version',
                'cn': '中文版_'
            },
            lang: this.$root.lang,
            reportLang: this.$root.lang,
            text: {
                pdfReport: {
                    'ru': 'PDF версия',
                    'en': 'PDF version',
                    'cn': 'PDF version'
                },
                onlineReport: {
                    'ru': 'Онлайн версия',
                    'en': 'Online version',
                    'cn': 'Online version'
                }
            }
        }
    },
    methods: {
        changeReportLang (lang) {
            if (this.$refs['report-dropdown']) this.$refs['report-dropdown'].toggle()
            this.reportLang = lang
        }
    }
}
</script>

<style lang="scss">

.card--theme-red {
    & .html-content {
        a {
            color: white;
        }
    }
}

.investors {
    margin-top: 32px;
    margin-bottom: 2rem;

    & .margin-fix {
        & .z-list {
            margin: 0px;
        }
    }
    &__links {
        & :not(:first-child) {
            margin-top: 16px;
        }
    }

    &__invest {
        &-indicator {
            & .z-key-indicator__group {
                color: white;
            }

            & .z-key-indicator__name {
                color: $token-colors-light-grey;
            }

            &--text {
                .z-key-indicator__unit {
                    font-size: 48px;
                }
            }
        }

        &-row {
            margin-top: -32px !important;
        }
    }

    &__link {
        &-text {
            margin-left: 8px;
        }
    }

    &__news {
        &-main {
            padding-bottom: 42px;
            border-bottom: 2px solid $token-colors-light-grey;
        }

        &-other {
            margin-top: 42px;
        }

        &-paragraph {
            color: $token-colors-dark-grey;
            font-size: 24px;
            margin-bottom: 8px;
            max-width: 700px;

            &--small {
                font-size: 18px;
            }
        }

        &-date {
            &--small {
                font-size: 12px;
            }
        }
    }
    &__blockquote {
        &-row {
            align-items: center;
        }

        &-indicator {
            & .z-key-indicator__group {
                color: white;
            }

            & .z-key-indicator__name {
                color: $token-colors-light-grey;
            }

            &--height {
                margin-top: -24px;
            }
        }
        & .z-blockquote__detail {
            color: white;
        }

        & .z-blockquote__body {
            padding-right: 0;
            flex: 0 0 100%;
            max-width: 100%;

            & > svg :first-child {
                fill: white;
            }
        }

        &-paragraph {
            margin-top: 24px;
            margin-bottom: 0;

            &--postfix {
                color: $token-colors-middle-grey;
            }
        }
    }
}

.quotes-iframe {
    width: 100%;
    height: 70px;
    max-width: 400px;

    @include breakpoint-width(1520px) {
        height: auto;
    }
}

.investors-results {
    .card__content {
        margin-bottom: 0;
    }
}

.mp-0 {
   padding: 0;
   margin: 0;
}
</style>

<template>
    <div
        class="z-select-multi"
        :class="{'is-opened': opened}"
    >
        <div class="z-select-multi__container" @click.self="opened = !opened">
            <span
                class="z-select-multi__placeholder"
                v-if="placeholder && !selected.length"
                v-html="placeholder"
            ></span>
            <div class="z-select-multi__wrap">
                <div class="z-select-multi__items">
                    <div class="z-select-multi__scroll">
                        <span
                            class="z-select-multi__selected"
                            v-for="(item, index) in selected"
                            v-if="selected.length"
                            :key="index"
                        >
                            <span v-html="item.text" @click.self="opened = !opened"></span>
                            <span
                                class="z-select-multi__delete"
                                @click.prevent="changeSelected(item)"
                            ></span>
                        </span>
                    </div>
                </div>
            </div>
            <span class="z-select-dots" v-if="ellipsis">...</span>
            <span
                class="z-select-multi__clear"
                v-if="selected.length"
                @click.prevent="clearSelected"
            >
                <slot @click.self="clearSelected" v-if="!!this.$slots['icon-clear']" name="icon-clear"></slot>
            </span>
            <span
                class="z-select-multi__arrow"
                @click.prevent="opened = !opened"
            >
                <slot @click.self="opened = !opened" v-if="!!this.$slots['icon-arrow']" name="icon-arrow"></slot>
            </span>
        </div>
        <div class="z-select-multi__dropdown" v-if="opened">
            <ul class="z-select-multi__options">
                <li
                    v-for="option in options"
                    :key="option.id"
                    class="z-select-multi__option"
                    :class="buildClass(option)"
                    @click="changeSelected(option)"
                    v-html="option.text"
                ></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-select-multi',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        name: String,
        placeholder: String
    },
    data () {
        return {
            selected: [],
            opened: false,
            duration: 300,
            options: this.data,
            ellipsis: false
        }
    },
    mounted () {
        this.onLoad()

        document.addEventListener('click', e => {
            if (this.opened) {
                this.hideDropdown(e)
            }
        })
    },
    beforeDestroy () {
        document.removeEventListener('click', e => {
            if (this.opened) {
                this.hideDropdown(e)
            }
        })
    },
    watch: {
        selected (array) {
            // debugger
        },
        data (array) {
            this.options = array
        }
    },
    methods: {
        onLoad () {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].selected === true) this.initSelected(this.options[i])
            }
        },
        buildClass (option) {
            return {
                'is-selected': option.selected,
                'is-disabled': option.disabled
            }
        },
        hideDropdown (e) {
            const isOutside = this.$el !== e.target && !this.$el.contains(e.target)
            this.opened = !isOutside
        },
        changeSelected (option) {
            option.selected ? this.removeSelected(option) : this.addSelected(option)
        },
        initSelected (option) {
            this.selected.push(option)
            this.toggleSelected(option, true)
        },
        addSelected (option) {
            this.initSelected(option)
            this.send()
        },
        removeSelected (option) {
            let index = -1

            this.selected.forEach((el, i) => {
                if (el.id === option.id) {
                    index = i
                }
            })

            if (index >= 0) this.selected.splice(index, 1)

            this.toggleSelected(option, false)
            this.send()
        },
        toggleSelected (option, value) {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].id === option.id) this.options[i].selected = value
            }
        },
        clearSelected () {
            this.selected = []
            this.opened = false
            for (let i = 0; i < this.options.length; i++) this.options[i].selected = false
            this.send(null)
        },
        send (value) {
            let data = {
                value: [],
                name: this.name
            }

            if (value !== null) {
                for (let i = 0; i < this.selected.length; i++) data.value.push(this.selected[i].id)
            }

            this.$nextTick(() => this.$emit('change', data))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-identity-detail"},[(_vm.data.length === 0)?_c('z-not-found',{attrs:{"title":_vm.notFound.title,"text":_vm.notFound.text}}):_vm._e(),_vm._v(" "),(_vm.data.length)?_c('div',{staticClass:"row",attrs:{"equal-height":".card"}},_vm._l((_vm.data),function(item,i){return _c('div',{key:i,staticClass:"col-default-4 col-tablet-12"},[_c('card',{staticClass:"ar-card",staticStyle:{"min-height":"272px"},attrs:{"theme":item.preview ? 'background' : 'red',"overlay":"rgba(0,0,0,0.45)","mark":false,"equal-height-max-breakpoint":"mobile","image":item.preview},scopedSlots:_vm._u([(item.name)?{key:"title",fn:function(){return [_vm._v(_vm._s(item.name))]},proxy:true}:null,{key:"content",fn:function(){return [(item.size && item.extension)?_c('span',{staticClass:"card__file-info"},[_vm._v(_vm._s(item.size)+", "+_vm._s(item.extension))]):_vm._e()]},proxy:true},(item.image)?{key:"bottom-left",fn:function(){return [_c('z-link',{directives:[{name:"tippy-tooltip",rawName:"v-tippy-tooltip",value:({
                            theme : 'transparent',
                            arrow: true,
                            placement: 'top-start'
                        }),expression:"{\n                            theme : 'transparent',\n                            arrow: true,\n                            placement: 'top-start'\n                        }"}],staticClass:"ar-card__icon u-inline",attrs:{"target":"_blank","content":_vm.image.download,"href":item.image.full,"download":"","downloadTitle":item.name}},[_c('z-icon',{attrs:{"name":"download","width":"48","height":"48"}})],1),_vm._v(" "),_c('z-link',{directives:[{name:"tippy-tooltip",rawName:"v-tippy-tooltip",value:({
                            theme : 'transparent',
                            arrow: true,
                            placement: 'top-start'
                        }),expression:"{\n                            theme : 'transparent',\n                            arrow: true,\n                            placement: 'top-start'\n                        }"}],staticClass:"ar-card__icon u-inline",attrs:{"content":_vm.image.preview,"href":"#","theme":"red"},on:{"click":function($event){return _vm.$root.$bus.$emit('open-modal', {id: 'identikaModal', index: i})}}},[_c('z-icon',{attrs:{"name":"zoom-in","width":"48","height":"48"}})],1)]},proxy:true}:null],null,true)})],1)}),0):_vm._e(),_vm._v(" "),_c('z-modal',{staticClass:"gallery-modal",attrs:{"autoWidth":"","id":"identikaModal","download":"","theme":"grey","data":_vm.slidesForModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
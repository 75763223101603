/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'truck': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1 21.969h24.105v13.078H1V21.97zm49.014.062h-23.03v13.016h23.03V22.031zm8.13 4.157h-5.081c-.826 0-1.063.401-1.063 1.023v7.89h10.25v-2.379c0-1.276-2.243-6.535-4.105-6.535zm-3.89 6.203v-4.337h3.418c1.008 0 2.38 2.707 2.446 4.337h-5.863zm-18.563 7.245c0-1.32 1.09-2.395 2.43-2.395 1.339 0 2.43 1.075 2.43 2.395s-1.091 2.395-2.43 2.395c-1.34 0-2.43-1.075-2.43-2.395zm1.268 0c0 .631.52 1.145 1.162 1.145.64 0 1.162-.513 1.162-1.145 0-.63-.521-1.145-1.162-1.145-.641 0-1.162.514-1.162 1.145zm18.195 0c0-1.32 1.09-2.395 2.429-2.395 1.34 0 2.43 1.075 2.43 2.395s-1.09 2.395-2.43 2.395-2.43-1.075-2.43-2.395zm1.267 0c0 .631.52 1.145 1.162 1.145.641 0 1.162-.513 1.162-1.145 0-.63-.521-1.145-1.162-1.145-.641 0-1.161.514-1.161 1.145zm5.814-3.632v1.334H63v2.424h-2.565c.01-.093.014-.187.014-.282 0-1.552-1.276-2.81-2.85-2.81s-2.85 1.258-2.85 2.81c0 .096.005.19.014.282H40.979l.002-.034c.003-.05.007-.1.007-.152 0-1.55-1.277-2.809-2.852-2.809-1.54 0-2.792 1.207-2.844 2.713l-.006-.003v.245h-8.349v-1.24h-1.95v1.28h-2.94c.01-.093.014-.187.014-.282 0-1.553-1.276-2.81-2.85-2.81s-2.851 1.257-2.851 2.81c0 .095.006.189.015.282H9.59l.003-.047c.003-.046.006-.092.006-.139 0-1.551-1.276-2.809-2.85-2.809-1.542 0-2.794 1.206-2.846 2.713l-.005-.004v.286h-2.85v-3.754h23.939v.569h1.95v-.573h23.115v1.008h1.98v-1.008h10.203zm-43.04 1.237c-1.34 0-2.43 1.075-2.43 2.395s1.09 2.395 2.43 2.395c1.339 0 2.43-1.075 2.43-2.395s-1.09-2.395-2.43-2.395zm0 3.54a1.155 1.155 0 01-1.162-1.145c0-.63.52-1.145 1.162-1.145.64 0 1.162.514 1.162 1.145s-.522 1.145-1.162 1.145zM4.303 39.636c0-1.32 1.09-2.395 2.43-2.395 1.339 0 2.429 1.075 2.429 2.395s-1.09 2.395-2.43 2.395-2.43-1.075-2.43-2.395zm1.267 0c0 .631.521 1.145 1.163 1.145.64 0 1.16-.514 1.16-1.145 0-.63-.52-1.145-1.16-1.145-.642 0-1.163.514-1.163 1.145z" _fill="#ED1C24"/>'
  }
})

<template>
    <div
        :class="['z-share z-share--' + template, { active: active }]"
        @click="toggleActive()"
    ></div>
</template>

<script>
/* eslint-disable */
const platform = require('platform')

export default {
    name: 'z-share',
    props: {
        services: {
            type: Array,
            required: true
        },
        counter: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: undefined
        },
        contentByService: {
            type: Object,
            default () {
                return {}
            }
        },
        vertical: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        lang: {
            type: String,
            default: 'ru'
        },
        limit: {
            type: Number,
            default: undefined
        },
        bare: {
            type: Boolean,
            default: false
        },
        copy: {
            type: String,
            default: 'last'
        },
        popupTop: {
            type: Boolean,
            default: false
        },
        popupOuter: {
            type: Boolean,
            default: false
        },
        template: {
            type: String,
            default: 'default'
        },
        withCross: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            active: false
        }
    },
    mounted () {
        if (!(platform.name === 'IE' && platform.version < '11')) {
            this.init()
        };

        this.$root.$el.addEventListener('click', e => { this.hidePopup(e) })
    },
    beforeDestroy () {
        this.$root.$el.removeEventListener('click', e => { this.hidePopup(e) })
    },
    methods: {
        init () {
            this
                .attach()
                .then(() => {
                    window.Ya.share2(this.$el, {
                        content: {
                            url: this.url || window.location.href,
                            title: this.title || document.title,
                            description: this.description,
                            image: this.image
                        },
                        contentByService: this.contentByService,
                        theme: {
                            bare: this.bare,
                            copy: this.copy,
                            counter: this.counter,
                            direction: this.vertical ? 'vertical' : 'horizontal',
                            lang: this.lang,
                            limit: this.limit,
                            popupDirection: this.popupTop ? 'top' : 'bottom',
                            popupPosition: this.popupOuter ? 'outer' : 'inner',
                            services: this.services.join(','),
                            size: this.small ? 's' : 'm'
                        },
                        hooks: {
                            onready: () => {
                                this.$emit('ready')
                            },
                            onshare: name => { this.$emit('share', name) }
                        }
                    })
                })
        },
        attach () {
            let attached = false,
                loaded = false,
                error = false,
                stack = []

            return new Promise((resolve, reject) => {
                if (!attached) {
                    const script = document.createElement('script')
                    script.setAttribute('src', 'https://yastatic.net/share2/share.js')
                    script.setAttribute('async', '')
                    document.head.appendChild(script)
                    script.onload = () => {
                        resolve()
                        loaded = true
                        stack.forEach(cb => {
                            cb[0]()
                        })
                        stack = []
                    }
                    script.onerror = () => {
                        reject()
                        loaded = true
                        error = true
                        stack.forEach(cb => {
                            cb[1]()
                        })
                        stack = []
                    }
                    attached = true
                }

                if (error) reject()

                if (loaded) {
                    resolve()
                } else {
                    stack.push([resolve, reject])
                }
            })
        },
        toggleActive () {
            if (!this.withCross) return
            this.active = !this.active
        },
        hidePopup (e) {
            if (!this.withCross) return
            let isOutside = this.$el !== e.target && !this.$el.contains(e.target)

            isOutside ? this.active = false : false
        }
    }
}
</script>
<style lang="scss" src="./index.scss"></style>

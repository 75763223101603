/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'desktop': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 46.5c12.426 0 22.5-10.074 22.5-22.5S36.426 1.5 24 1.5 1.5 11.574 1.5 24 11.574 46.5 24 46.5zm0 1.5c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z" _fill="#C4C4C4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10.5 12.25h27v17.5H24.75v4.5h6a.75.75 0 010 1.5h-12.5a.75.75 0 010-1.5h5v-4.5H10.5v-17.5zm1.5 1.5v9.5h24v-9.5H12zm24 11H12v3.5h24v-3.5z" _fill="#C4C4C4"/>'
  }
})

<template>
    <div class="news__filter">
        <span class="news__filter-title" v-if="!redesign">
            <span v-if="!searchOpened">{{ text.filter }}</span>
            <span v-else>{{ text.search }}</span>
        </span>
        <div :class="`news__filter-container ${redesign ? 'news__filter-container--redesign' : ''}`">
            <div class="news__filter-box" v-show="!searchOpened">
                <z-select-multi
                    v-if="data.categories"
                    name="categories"
                    ref="vmCategories"
                    @change="filtersChange"
                    :data="data.categories"
                    :placeholder="text.placeholders.categories"
                >
                    <template v-slot:icon-arrow>
                        <z-icon name="arrow" dir="down" width="16" height="16"></z-icon>
                    </template>
                    <template v-slot:icon-clear>
                        <z-icon name="close" width="12" height="12"></z-icon>
                    </template>
                </z-select-multi>
                <z-select-multi
                    v-if="data.enterprises"
                    name="enterprises"
                    ref="vmEnterprises"
                    @change="filtersChange"
                    :data="data.enterprises"
                    :placeholder="text.placeholders.enterprises"
                >
                    <template v-slot:icon-arrow>
                        <z-icon name="arrow" dir="down" width="16" height="16"></z-icon>
                    </template>
                    <template v-slot:icon-clear>
                        <z-icon name="close" width="12" height="12"></z-icon>
                    </template>
                </z-select-multi>
                <date-picker
                    v-model="date"
                    format="D MMM YYYY"
                    value-type="X"
                    :lang="lang"
                    range
                    @change="filtersChange({
                        name: 'date',
                        value: date
                    })"
                    @input-error="handleInputError"
                    :placeholder="redesign ? text.placeholders.redesignDate : text.placeholders.date"
                >
                    <template v-slot:icon-calendar>
                        <z-icon name="calendar" width="16" height="16"></z-icon>
                    </template>
                    <template v-slot:icon-clear>
                        <z-icon name="close" width="12" height="12"></z-icon>
                    </template>
                </date-picker>
                <z-button
                    v-if="isFiltersActive && !redesign"
                    kind="text"
                    theme="grey"
                    @click="filtersReset"
                    class="news__reset"
                >{{ text.reset }}</z-button>
            </div>
            <template v-if="redesign">
                <z-button
                    class="news__filter-button"
                    kind="primary"
                    rounded
                    tag="a"
                    :href="`/${lang}/news-and-media/contacts/subscription/`"
                >
                    {{text.signUpButton}}
                </z-button>
                <div class="news__search-box">
                    <z-input
                        name="name"
                        v-model="name"
                        :value="data.name"
                        :placeholder="text.placeholders.nameRedesign"
                        @change="filtersChange({
                            name: 'name',
                            value: name
                        })"
                    />
                    <z-icon
                        class="news__search-submit"
                        @click="filtersChange"
                        name="search"
                        width="24px"
                        height="24px"
                    ></z-icon>
                </div>
                <z-button
                    v-if="isFiltersActive"
                    kind="text"
                    theme="grey"
                    @click="filtersReset"
                    class="news__reset"
                >{{ text.reset }}</z-button>
            </template>
            <template v-else>
                <div class="news__search-box" v-show="searchOpened">
                    <z-icon
                        class="news__search-submit"
                        @click="filtersChange"
                        name="search"
                        width="24px"
                        height="24px"
                    ></z-icon>
                    <z-input
                        name="name"
                        v-model="name"
                        :value="data.name"
                        :placeholder="text.placeholders.name"
                        @change="filtersChange({
                            name: 'name',
                            value: name
                        })"
                    />
                    <z-icon
                        class="news__search-close"
                        @click="searchOpened = false"
                        name="close"
                        width="24px"
                        height="24px"
                    ></z-icon>
                </div>
                <div
                    class="news__search-icon"
                    v-show="!searchOpened"
                    @click="searchOpened = true"
                >
                    <z-icon
                        class="news__search-toggle"
                        name="search"
                        width="24px"
                        height="24px"
                    ></z-icon>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import { localize } from '@/utils/i18n'
import 'vue2-datepicker/locale/zh-cn'

export default {
    name: 'news-filters',
    components: {
        DatePicker
    },
    props: {
        data: Object,
        redesign: Boolean
    },
    data () {
        return {
            name: this.data.name,
            date: this.$root.lang === 'en' ? this.data.date : [],
            lang: this.$root.lang,
            text: {
                filter: localize({
                    'ru': 'Фильтровать',
                    'en': 'Filter'
                }),
                search: localize({
                    'ru': 'Искать',
                    'en': 'Search'
                }),
                reset: localize({
                    'ru': 'Сбросить фильтры',
                    'en': 'Reset',
                    'cn': '重置'
                }),
                signUpButton: localize({
                    'ru': 'Подписаться на новости компании',
                    'en': 'Sign up for news',
                    'cn': '订阅公司新闻'
                }),
                placeholders: {
                    name: localize({
                        'ru': 'Введите название новости',
                        'en': 'Enter the name of the news'
                    }),
                    nameRedesign: localize({
                        'ru': 'Поиск',
                        'en': 'Search',
                        'cn': '查询'
                    }),
                    categories: localize({
                        'ru': 'По категориям',
                        'en': 'By categories',
                        'cn': '按类别'
                    }),
                    enterprises: localize({
                        'ru': 'По предприятиям',
                        'en': 'By enterprises',
                        'cn': '按企业'
                    }),
                    date: localize({
                        'ru': 'Выберите дату',
                        'en': 'Select date'
                    }),
                    redesignDate: localize({
                        'ru': 'По дате',
                        'en': 'By date',
                        'cn': '按日期'
                    })
                }
            },
            searchOpened: false
        }
    },
    computed: {
        isFiltersActive () {
            return !!((this.name.length || this.date.length || this.isCategories() || this.isEnterprises()))
        },
        categories () {
            if (this.data.categories) {
                return this.data.categories.filter(obj => obj.selected).map(obj => obj.id)
            }
        },
        enterprises () {
            if (this.data.enterprises) {
                return this.data.enterprises.filter(obj => obj.selected).map(obj => obj.id)
            }
        }
    },
    methods: {
        handleInputError (text) {
            let timestampArr = []
            if (text.includes('-')) {
                timestampArr = text.split('-')
            } else if (text.includes('~')) {
                timestampArr = text.split('~')
            } else {
                timestampArr = [text, text]
            }

            let ts1 = +moment(timestampArr[0], 'DD.MM.YYYY') / 1000
            let ts2 = +moment(timestampArr[1], 'DD.MM.YYYY') / 1000

            if (ts1 > ts2) {
                [ts1, ts2] = [ts2, ts1]
            }

            this.date = [ts1.toString(), ts2.toString()]

            this.$emit('change', {
                name: 'date',
                value: [ts1, ts2]
            })
        },
        filtersChange (data) {
            if (data.name === 'date') {
                if (!data.value.filter(el => el !== null).length) {
                    this.date = []
                    data = {
                        name: 'date',
                        value: []
                    }
                } else {
                    data.value = data.value.map(timestamp => {
                        const date = moment.unix(timestamp)
                        const offset = date.utcOffset()
                        let utcDate = timestamp

                        if (offset > 0) {
                            utcDate = parseInt(utcDate) + parseInt(offset) * 60
                        } else {
                            utcDate = parseInt(utcDate) - parseInt(offset) * 60
                        }

                        return utcDate
                    })
                }
            }
            this.$emit('change', data)
        },
        filtersReset () {
            this.name = ''
            this.date = []

            if (this.data.categories) this.$refs.vmCategories.clearSelected()
            if (this.data.enterprises) this.$refs.vmEnterprises.clearSelected()

            setTimeout(() => {
                this.$emit('change', null)
            }, 1)
        },
        isCategories () {
            if (this.categories) return this.categories.length
        },
        isEnterprises () {
            if (this.enterprises) return this.enterprises.length
        }
    },
    created () {
        // this.name = this.data.name
        this.lang === 'ru' && import('vue2-datepicker/locale/ru').then(() => { this.date = this.data.date })
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

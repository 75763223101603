<template>
    <div class="terminals">
        <div class="row">
            <div class="col-default-8 col-mobile-12">
                <h2 v-html="text.profile"></h2>
                <z-lead v-html="text.lead"></z-lead>
            </div>
            <div class="col-default-4 col-mobile-12">
                <card
                    theme="red"
                    style="min-height: 350px"
                >
                    <template v-slot:title>{{ text.portalTitle }}</template>
                    <template v-slot:content>
                        <div v-html="text.portalText"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-link theme="white" href="https://globalports.rlisystems.ru/" external target="_blank">
                            <span v-html="text.signin"></span>
                        </z-link>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="white"
                            size="m"
                        >
                            <z-icon name="man" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
        </div>

        <h2 v-show="isDesktop()" v-html="text.geography"></h2>
        <z-wide-section v-show="isDesktop()">
            <terminals-map></terminals-map>
            <!-- <img src="/images/map.jpg" width="100%"/> -->
        </z-wide-section>

        <template v-if="terminals.length">
            <h2 v-html="text.terminals"></h2>
            <div class="row" equal-height=".card">
                <div
                    v-for="(item, i) in terminals"
                    :key="i"
                    class="col-default-4 col-tablet-6 col-mobile-12"
                >
                    <card
                        :link="item.link"
                        :overlay="'rgba(54,54,54,0.6)'"
                        :theme="'background'"
                        :image="item.image"
                        :shadow="false"
                        :mark="false"
                        padding="100%"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>
                            {{ item.name }}
                        </template>
                        <template v-slot:bottom-right>
                            <img :src="item.icon" height="32px">
                        </template>
                    </card>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'

export default {
    name: 'terminals',
    mixins: [mixinDevice],
    data () {
        return {
            text: {
                profile: localize({
                    'ru': 'Профиль компании',
                    'en': 'Company Profile',
                    'cn': '公司简介'
                }),
                lead: localize({
                    'ru': 'Группа компаний Глобал Портс&nbsp;&mdash; один из&nbsp;ведущих операторов специализированных портовых терминалов в&nbsp;России, лидер российского рынка перевалки контейнеров. Глобал Портс управляет сетью из&nbsp;пяти морских контейнерных терминалов в&nbsp;России и&nbsp;двух в&nbsp;Финляндии, а&nbsp;также логистическим комплексом вблизи Санкт-Петербурга. Предоставляет широкий спектр услуг в&nbsp;сфере контейнерного сервиса; организует перевозки контейнеров по&nbsp;железной дороге на&nbsp;базе собственного и&nbsp;привлеченного подвижного состава, в&nbsp;партнерстве с&nbsp;крупнейшими ж/д операторами.',
                    'en': 'Global Ports Group is&nbsp;a&nbsp;leading operator of&nbsp;container and multi-purpose terminals in&nbsp;Russia, a&nbsp;key player on&nbsp;the Russian container handling market. Global Ports operates a&nbsp;network of&nbsp;five marine container terminals in&nbsp;Russia and two in&nbsp;Finland, and a&nbsp;logistics complex near Saint Petersburg. The Group provides a&nbsp;wide range of&nbsp;container services, organises the railway transportation of&nbsp;containers using its own and engaged rolling stock, in&nbsp;partnership with major railway operators.',
                    'cn': 'GlobalPorts集团公司是一家俄罗斯领先的专业港口码头运营商，也是俄罗斯集装箱装卸市场的领导者。Global Ports 经营着一个由俄罗斯5个海运集装箱码头，芬兰2个海运集装箱码头，圣彼得堡附近的一个物流综合体组成的运输网络。提供广泛的集装箱服务；与最大的铁路运营商合作，使用自有和借用的机车车辆来组织集装箱的铁路运输。'
                }),
                geography: localize({
                    'ru': 'География деятельности',
                    'en': 'Geography',
                    'cn': '业务分布'
                }),
                terminals: localize({
                    'ru': 'Терминалы',
                    'en': 'Terminals',
                    'cn': '码头'
                }),
                portalTitle: localize({
                    'ru': 'Единый клиентский портал',
                    'en': 'Client portal',
                    'cn': '统一客户平台'
                }),
                signin: localize({
                    'ru': 'Войти в&nbsp;личный кабинет',
                    'en': 'Sign in',
                    'cn': '登入个人帐户'
                }),
                portalText: localize({
                    'ru': 'Подать электронную заявку, отследить и&nbsp;подписать документы электронной подписью',
                    'en': 'Submit an&nbsp;electronic application, track and sign documents electronically',
                    'cn': '提交电子申请，使用电子签名跟踪和签署文件'
                })
            },
            terminals: this.$root.app.components['terminals-list']
        }
    },
    methods: {

    },
    created () {
    }
}
</script>

<style lang="scss">
    .terminals {
        padding-top: 2rem;
        padding-bottom: 3.75rem;
    }
</style>

<template>
    <figure class="figure">
        <slot
            v-if="isCaptionBottom"
            name="content"
        />
        <figcaption
            class="figure__caption"
            :class="{'figure__caption--over': captionPosition === 'over'}"
        >
            <slot name="caption" />
        </figcaption>
        <slot
            v-if="isCaptionTop"
            name="content"
        />
    </figure>
</template>

<script>
export default {
    name: 'z-figure',
    props: {
        captionPosition: {
            type: String,
            default: 'top',
            validator: prop => ['top', 'bottom', 'over'].includes(prop)
        }
    },
    computed: {
        isCaptionTop () {
            return this.captionPosition === 'top' || this.captionPosition === 'over'
        },
        isCaptionBottom () {
            return this.captionPosition === 'bottom'
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

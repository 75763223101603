<template src="./index.html"></template>

<script>
import { localize } from '@/utils/i18n'
export default {
    name: 'navigation-left-tablet',
    data () {
        return {
            opened: false,
            navIndexOpened: -1,
            list: this.$root.app.components['navigation-left'].items,
            text: {
                placeholder: localize({
                    'ru': 'Выберите меню',
                    'en': 'Select menu',
                    'cn': '选择菜单'
                })
            }
        }
    },
    created () {
        if (this.list) {
            this.list.forEach((el, i) => {
                if (el.active && el.hasOwnProperty('items') && el.items.length) {
                    this.navIndexOpened = i
                }
            })
        }
    },
    methods: {
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : '_self'
        },
        toggleChilds (event, i, item) {
            if (item.hasOwnProperty('items') && item.items.length && this.navIndexOpened !== i) {
                event.preventDefault()

                this.navIndexOpened = i
            } else {
                this.navIndexOpened = -1
            }
        }
    },
    computed: {
        stateActive () {
            let activeName = this.text.placeholder

            this.list.forEach(el => {
                if (el.active) {
                    activeName = el.name
                }
            })

            return activeName
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <div class="z-key-indicator" :class="classObject">
        <span v-if="!!this.$slots['icon']" class="z-key-indicator__icon">
            <slot name="icon"></slot>
        </span>
        <div class="z-key-indicator__body">
            <div class="z-key-indicator__group" v-if="value || unit">
                <span class="z-key-indicator__prefix" v-if="prefix" v-html="prefix"></span>
                <template v-if="value">
                    <span class="z-key-indicator__value" v-if="isNaN(value)">
                        <span class="z-key-indicator__value-text" v-html="value"></span>
                    </span>
                    <span class="z-key-indicator__value" v-else v-html="value"></span>
                </template>
                <div v-if="!!this.$slots['unit'] || unit" class="z-key-indicator__unit">
                    <slot v-if="!!this.$slots['unit']" name="unit"></slot>
                    <span v-else v-html="unit"></span>
                </div>
            </div>
            <span class="z-key-indicator__name" v-if="name" v-html="name"></span>
            <div v-if="!!this.$slots['footer']" class="z-key-indicator__footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import verge from 'verge'
import { CountUp } from 'countup.js'

export default {
    name: 'z-key-indicator',
    data () {
        return {
            options: {
                ru: {
                    duration: 5,
                    useEasing: true,
                    useGrouping: true,
                    decimalPlaces: this.getDecimals(),
                    separator: ' ',
                    decimal: ',',
                    prefix: '',
                    suffix: ''
                },
                en: {
                    duration: 5,
                    useEasing: true,
                    useGrouping: true,
                    decimalPlaces: this.getDecimals(),
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                },
                cn: {
                    duration: 5,
                    useEasing: true,
                    useGrouping: true,
                    decimalPlaces: this.getDecimals(),
                    separator: '',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                }
            },
            viewport: false,
            counter: null
        }
    },
    props: {
        value: [String, Number],
        unit: String,
        name: String,
        prefix: String,
        size: {
            type: String,
            default: 'm',
            validator: prop => ['l', 'm', 's'].includes(prop)
        },
        noCounter: {
            type: Boolean,
            default: false
        },
        decimalPlaces: {
            type: [String, Number],
            default: '0'
        },
        fullHeight: Boolean
    },
    computed: {
        classObject () {
            return {
                [`z-key-indicator--size-${this.size}`]: this.size,
                'z-key-indicator--full-height': this.fullHeight
            }
        },
        valueParsed () {
            return Number.parseFloat(this.value.replace(',', '.'))
        }
    },
    methods: {
        getDecimals () {
            if (this.value) {
                let valueParsed = Number.parseFloat(this.value.replace(',', '.'))
                return valueParsed.toString().includes('.') ? (valueParsed.toString().split('.').pop().length) : this.decimalPlaces
            }
            return 0
        },
        inView () {
            if (verge.inViewport(this.$el, 145)) {
                this.viewport = true
                if (this.counter) {
                    this.counter.start()
                }
            }
            this.viewport && window.removeEventListener('scroll', this.inView)
        }
    },
    mounted () {
        if (!this.noCounter) {
            window.addEventListener('scroll', this.inView)
            if (this.value) {
                this.counter = new CountUp(this.$el.getElementsByClassName('z-key-indicator__value')[0], this.valueParsed, this.options[this.$root.lang])
            }
        }
        this.inView()
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.inView)
    }
}
</script>

<style lang="scss">
    .z-key-indicator {
        display: flex;

        &__value-text {
            font-size: 0.75em;
        }

        &--full-height {
            height: 100%;
        }

        &--size-l {
            & .z-key-indicator {
                &__value {
                    font-size: 64px;

                    @include breakpoint(tablet) {
                        font-size: 50px;
                    }

                    @include breakpoint(mobile) {
                        font-size: 46px;
                    }
                }

                &__unit,
                &__prefix {
                    font-size: 28px;

                    @include breakpoint(tablet){
                        font-size: 24px;
                    }

                    @include breakpoint(mobile){
                        font-size: 20px;
                    }
                }

                &__name {
                    margin-top: 1.5rem;
                    font-size: 28px;

                    @include breakpoint(tablet){
                        font-size: 24px;
                    }

                    @include breakpoint(mobile){
                        font-size: 20px;
                    }
                }
            }
        }

        &--size-s {
            & .z-key-indicator {
                &__value {
                    font-size: 32px;

                    @include breakpoint(tablet) {
                        font-size: 28px;
                    }

                    @include breakpoint(mobile) {
                        font-size: 24px;
                    }
                }

                &__unit,
                &__prefix {
                    font-size: 18px;

                    @include breakpoint(tablet) {
                        font-size: 16px;
                    }

                    @include breakpoint(mobile) {
                        font-size: 15px;
                    }
                }

                &__name {
                    font-size: 16px;
                }
            }
        }

        &__icon {
            max-width: 96px;
            max-height: 96px;
            margin-right: 2rem;
            margin-bottom: auto;

            @include breakpoint(tablet) {
                max-width: 72px;
                max-height: 72px;
                margin-right: 1.5rem;
            }

            @include breakpoint(mobile) {
                max-width: 64px;
                max-height: 64px;
                margin-right: 1rem;
            }

            & > svg {
                width: 100%;
                height: 100%;
            }
        }

        &__group {
            display: flex;
            flex-wrap: wrap;
            color: #ED1C24;
            font-weight: bold;
            line-height: 1;
            align-items: baseline;
        }

        &__value {
            margin-right: 0.25em;
            font-size: 48px;

            @include breakpoint(tablet) {
                font-size: 44px;
            }

            @include breakpoint(mobile) {
                font-size: 40px;
            }
        }

        &__unit,
        &__prefix {
            font-size: 24px;

            @include breakpoint(tablet){
                font-size: 20px;
            }

            @include breakpoint(mobile){
                font-size: 16px;
            }
        }

        &__name {
            margin-top: 1rem;
            display: block;
            font-size: 18px;
            line-height: 1.4;
            color: #7A7A7E;

            @include breakpoint(mobile) {
                font-size: 16px;
            }
        }

        &__prefix {
            margin-right: 0.5em;
        }

        &__unit {
            display: inline-flex;
            align-items: baseline;

            > svg {
                margin-left: 0.5em;
            }
        }

        &__footer {
            margin-top: 1rem;
        }

        &__body {
            width: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
        }

    }
</style>

<template src="./index.html"></template>

<script>
export default {
    name: 'navigation-left',
    data () {
        return {
            navIndexOpened: -1,
            list: this.$root.app.components['navigation-left'].items
        }
    },
    created () {
        if (this.list) {
            this.list.forEach((el, i) => {
                if (el.active && el.hasOwnProperty('items') && el.items.length) {
                    this.navIndexOpened = i
                }
            })
        }
    },
    methods: {
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : '_self'
        },
        toggleChilds (event, i, item) {
            if (item.hasOwnProperty('items') && item.items.length && this.navIndexOpened !== i) {
                event.preventDefault()

                this.navIndexOpened = i
            } else {
                this.navIndexOpened = -1
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template src="./index.html"></template>

<script>
export default {
    name: 'card-icon',
    props: {
        theme: {
            type: String,
            default: 'white'
        },
        size: {
            type: String,
            default: 'l'
        }
    },
    data () {
        return {}
    },
    mounted () {
        this.$parent.withCardIcon = true
        this.$parent.withCardIconSize = this.size
    },
    computed: {
        classObject () {
            let arrClass = []

            if (this.theme) {
                arrClass.push(`card-icon--theme-${this.theme}`)
            }

            if (this.size) {
                arrClass.push(`card-icon--size-${this.size}`)
            }

            return arrClass
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

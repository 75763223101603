<template>
    <div class="reports-and-results">
        <div class="container">
            <div class="row">
                <div class="page__aside col-default-3 col-tablet-hide">
                    <navigation-left></navigation-left>

                    <card
                        v-if="results.banner"
                        style="min-height: 250px"
                        theme="red"
                        class="u-bottom-margin--l-desktop"
                    >
                        <template v-slot:title>{{ results.banner.name }}</template>
                        <template v-slot:content>
                            <div v-html="results.banner.detail"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" theme="white" :href="results.banner.link" kind="text">
                                Call details
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="14" height="14"></z-icon>
                                </template>
                            </z-button>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="white"
                                size="m"
                            >
                                <z-icon name="clock" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>

                    <card
                        v-if="results.databook"
                        style="min-height: 250px"
                        class="u-bottom-margin--l-desktop"
                    >
                        <template v-slot:title>{{ results.databook.name }}</template>
                        <template v-slot:content>
                            <span class="card__file-info">{{ results.databook.size }}, {{ results.databook.extension }}</span>
                        </template>
                        <template v-slot:bottom-left>
                            <z-link
                                :href="results.databook.link"
                                :downloadTitle="results.databook.name"
                                downloadFile
                            >
                                <z-icon name="download" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="grey"
                                size="m"
                            >
                                <z-icon name="databank" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <main class="content page__main col-default-9 col-tablet-12">
                    <div v-if="latterResults">
                        <h2>Latest results</h2>

                        <!-- <z-blockquote
                            v-if="latterResults.quote"
                            class="u-bottom-margin--xl-desktop"
                            :date="latterResults.quote.date"
                            :post="latterResults.quote.post"
                            :author="latterResults.quote.name"
                            :image="latterResults.quote.image"
                            :icon="true"
                        >
                            <template v-slot:detail>
                                <div v-html="latterResults.quote.detail"></div>
                            </template>
                        </z-blockquote> -->

                        <card
                            v-if="latterResults['key-results']"
                            class="u-bottom-margin--xl-desktop"
                        >
                            <template v-slot:title>{{ latterResults['key-results'].name }}</template>
                            <template v-slot:content>
                                <div class="row">
                                    <div
                                        v-for="(item, i) in latterResults['key-results'].items"
                                        :key="i"
                                        class="col-default-3 col-tablet-6 col-mobile-12"
                                    >
                                        <z-key-indicator
                                            :value="item.number"
                                            :unit="item.unit"
                                            :name="item.name"
                                            :decimal-places="getDecimalPlaces(item.number)"
                                        ></z-key-indicator>
                                    </div>
                                </div>
                            </template>
                        </card>

                        <card
                            v-if="results.databook && isDatabookMobile"
                            style="min-height: 250px"
                            class="u-bottom-margin--l-desktop"
                        >
                            <template v-slot:title>{{ results.databook.name }}</template>
                            <template v-slot:content>
                                <span class="card__file-info">{{ results.databook.size }}, {{ results.databook.extension }}</span>
                            </template>
                            <template v-slot:bottom-left>
                                <z-link
                                    :href="results.databook.link"
                                    :downloadTitle="results.databook.name"
                                    downloadFile
                                >
                                    <z-icon name="download" width="48" height="48"></z-icon>
                                </z-link>
                            </template>
                            <template v-slot:bottom-right>
                                <card-icon
                                    theme="grey"
                                    size="m"
                                >
                                    <z-icon name="databank" width="64" height="64"></z-icon>
                                </card-icon>
                            </template>
                        </card>

                        <div
                            v-if="latterResults['operating-results']"
                            class="u-bottom-margin--xl-desktop"
                        >
                            <h3>{{ latterResults['operating-results'].name }}</h3>
                            <z-filelist
                                v-if="latterResults['operating-results'].items.length"
                                horizontal
                            >
                                <z-filelist-item
                                    v-for="(item, i) in latterResults['operating-results'].items"
                                    :key="i"
                                    icon="file_filled"
                                    :date="item.date"
                                    :info="`${item.size}, ${item.extension}`"
                                >
                                    <z-link
                                        :href="item.link"
                                        :downloadTitle="item.name"
                                        downloadFile
                                    >{{ item.name }}</z-link>
                                </z-filelist-item>
                            </z-filelist>
                        </div>

                        <div class="row u-bottom-margin--xl-desktop" equal-height=".card">
                            <div class="col-default-4 col-tablet-12">
                                <card
                                    v-if="latterResults['financial-results']"
                                    style="min-height: 320px"
                                    theme="red"
                                    class="u-bottom-margin--l-desktop"
                                    equal-height-max-breakpoint="mobile"
                                >
                                    <template v-slot:title>{{ latterResults['financial-results'].name }}</template>
                                    <template v-slot:content>
                                        <z-list
                                            theme="links"
                                            :bullets="false"
                                        >
                                            <z-list-item
                                                v-for="(item, i) in latterResults['financial-results'].items"
                                                :key="i"
                                            >
                                                <z-link
                                                    v-if="item.link"
                                                    :href="item.link"
                                                    :downloadTitle="item.name"
                                                    downloadFile
                                                    :underline="false"
                                                    theme="white"
                                                >
                                                    <z-icon name="link" color="#fff" width="14" height="14"></z-icon>
                                                    <span v-html="item.name"></span>
                                                </z-link>
                                            </z-list-item>
                                        </z-list>
                                    </template>
                                    <template v-slot:bottom-right>
                                        <card-icon
                                            theme="white"
                                            size="m"
                                        >
                                            <z-icon name="clock" width="64" height="64"></z-icon>
                                        </card-icon>
                                    </template>
                                </card>
                            </div>
                            <div class="col-default-4 col-tablet-12">
                                <card
                                    v-if="latterResults.presentations"
                                    style="min-height: 320px"
                                    class="u-bottom-margin--l-desktop"
                                    equal-height-max-breakpoint="mobile"
                                >
                                    <template v-slot:title>{{ latterResults.presentations.name }}</template>
                                    <template v-slot:content>
                                        <span v-if="latterResults.presentations.size && latterResults.presentations.extension" class="card__file-info">{{ latterResults.presentations.size }}, {{ latterResults.presentations.extension }}</span>
                                    </template>
                                    <template v-slot:bottom-left>
                                        <z-link
                                            v-if="latterResults.presentations.link"
                                            :href="latterResults.presentations.link"
                                            :downloadTitle="latterResults.presentations.name"
                                            downloadFile
                                        >
                                            <z-icon name="download" width="48" height="48"></z-icon>
                                        </z-link>
                                    </template>
                                    <template v-slot:bottom-right>
                                        <card-icon
                                            theme="grey"
                                            size="m"
                                        >
                                            <z-icon name="file" width="64" height="64"></z-icon>
                                        </card-icon>
                                    </template>
                                </card>
                            </div>
                            <div class="col-default-4 col-tablet-12">
                                <card
                                    v-if="latterResults['annual-report']"
                                    style="min-height: 320px"
                                    class="ar-card u-bottom-margin--l-desktop"
                                    theme="background"
                                    overlay="rgba(0,0,0,0.45)"
                                    equal-height-max-breakpoint="mobile"
                                    :image="latterResults['annual-report'][lang].image ? latterResults['annual-report'][lang].image : '/images/annual-report.jpg'"
                                >
                                    <template v-slot:title>{{latterResults['annual-report'][lang].name ? latterResults['annual-report'][lang].name : 'Annual report'}}</template>
                                    <template v-slot:content>
                                        <template v-if="latterResults['annual-report']['ru']">
                                            <z-dropdown>
                                                <template v-slot:trigger>
                                                    <span
                                                        class="ar-card__active"
                                                        v-html="langVersion[lang]"
                                                    ></span>
                                                    <z-icon dir="down" color="#fff" name="arrow" width="12" height="12"></z-icon>
                                                </template>
                                                <template v-slot:content>
                                                <a
                                                        v-if="lang == 'en'"
                                                        class="ar-card__version"
                                                        @click.prevent="lang = 'ru'"
                                                    >{{ langVersion['ru'] }}</a>
                                                <a
                                                        v-else-if="lang == 'ru'"
                                                        class="ar-card__version"
                                                        @click.prevent="lang = 'en'"
                                                    >{{ langVersion['en'] }}</a>
                                                </template>
                                            </z-dropdown>
                                        </template>
                                    </template>
                                    <template v-slot:bottom-left>
                                        <z-link
                                            v-if="latterResults['annual-report'][lang].online"
                                            class="ar-card__icon u-inline"
                                            :href="latterResults['annual-report'][lang].online"
                                            target="_blank"
                                            content="Online version"

                                            v-tippy-tooltip="{
                                                theme : 'transparent',
                                                arrow: true,
                                                placement: 'top-start'
                                            }"
                                        >
                                            <z-icon name="desktop" width="48" height="48"></z-icon>
                                        </z-link>
                                        <z-link
                                            v-if="latterResults['annual-report'][lang].file"
                                            class="ar-card__icon u-inline"
                                            :href="latterResults['annual-report'][lang].file"
                                            :downloadTitle="latterResults['annual-report'].name"
                                            downloadFile
                                            content="PDF version"

                                            v-tippy-tooltip="{
                                                theme : 'transparent',
                                                arrow: true,
                                                placement: 'top-start'
                                            }"
                                        >
                                            <z-icon name="download" width="48" height="48"></z-icon>
                                        </z-link>
                                    </template>
                                </card>
                            </div>
                        </div>
                    </div>

                    <div v-if="allResults">
                        <h2>All results</h2>
                        <z-tabs>
                            <template v-slot:label>
                                <z-tabs-label
                                    v-for="(year, i) in years"
                                    :key="i"
                                    :id="year"
                                >{{ year }}</z-tabs-label>
                            </template>
                            <template v-slot:content>
                                <z-tabs-content
                                    v-for="(year, i) in years"
                                    :key="i"
                                    :id="year"
                                >
                                    <z-table
                                        :columns="['Title', 'Published', 'Report', 'Release', 'Presentation', 'Webcast']"
                                        class="reports-and-results__table"
                                    >
                                        <z-table-row
                                            v-for="(row, j) in allResults[year]"
                                            :key="j"
                                        >
                                            <z-table-col index="0">{{ row.name }}</z-table-col>
                                            <z-table-col index="1" class="gp-red-text">
                                                {{ row.date | momentFilter('DD MMM YYYY') }}
                                            </z-table-col>
                                            <z-table-col index="2">
                                                <z-link
                                                    v-if="row.report"
                                                    :href="row.report"
                                                    :downloadTitle="row.name"
                                                    downloadFile
                                                >
                                                    <z-icon name="file_filled" width="24" height="24"></z-icon>
                                                </z-link>
                                            </z-table-col>
                                            <z-table-col index="3">
                                                <z-link
                                                    v-if="row.release"
                                                    :href="row.release"
                                                    :downloadTitle="row.name"
                                                    downloadFile
                                                >
                                                    <z-icon name="file_filled" width="24" height="24"></z-icon>
                                                </z-link>
                                            </z-table-col>
                                            <z-table-col index="4">
                                                <z-link
                                                    v-if="row.presentation"
                                                    :href="row.presentation"
                                                    :downloadTitle="row.name"
                                                    downloadFile
                                                >
                                                    <z-icon name="presentation" width="24" height="24"></z-icon>
                                                </z-link>
                                            </z-table-col>
                                            <z-table-col index="5">
                                                <z-link
                                                    v-if="row.webcast"
                                                    :href="row.webcast"
                                                    :downloadTitle="row.name"
                                                    downloadFile
                                                >
                                                    <z-icon name="webcast" width="24" height="24"></z-icon>
                                                </z-link>
                                            </z-table-col>
                                        </z-table-row>
                                    </z-table>
                                </z-tabs-content>
                            </template>
                        </z-tabs>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'reports-and-results',
    mixins: [mixinDevice],
    data () {
        return {
            isDatabookMobile: false,
            langVersion: {
                'ru': 'Russian version',
                'en': 'English version'
            },
            lang: 'en',
            results: this.$root.app.components['reports-and-results'],
            allResults: this.$root.app.components['reports-and-results']['all-results'],
            latterResults: this.$root.app.components['reports-and-results']['latter-results']
        }
    },
    computed: {
        years () {
            return Object.keys(this.allResults).sort((a, b) => b - a)
        }
    },
    watch: {
        device (value) {
            if (value === 'tablet' || value === 'mobile' || value === 'v-mobile') {
                this.isDatabookMobile = true
            } else {
                this.isDatabookMobile = false
            }
        }
    },
    methods: {
        getDecimalPlaces (data) {
            if (data.indexOf(',') !== -1 || data.indexOf('.') !== -1) return 1
            return 0
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <div class="media-identity">
        <z-not-found
            v-if="(notFound.state && !loading)"
            :title="notFound.title"
            :text="notFound.text"
        ></z-not-found>
        <div class="row" v-if="list.length" equal-height=".card">
            <div
                class="col-default-4 col-tablet-12"
                v-for="(item, i) in list"
                :key="i"
            >
                <z-link :href="item.link" :underline="false" theme="white">
                    <card
                        style="min-height: 272px"
                        class="ar-card"
                        :theme="item.image ? 'background' : 'red'"
                        overlay="rgba(0,0,0,0.45)"
                        :mark="false"
                        :image="item.image"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title v-if="item.name">{{item.name}}</template>
                        <template v-slot:content>
                            <span v-if="item.count" class="card__file-info">{{item.count}}</span>
                        </template>
                        <template v-slot:bottom-left v-if="item.zip">
                            <z-link
                                class="ar-card__icon u-inline"
                                target="_blank"
                                :content="zip"
                                :href="item.zip"
                                download
                                :downloadTitle="item.name"
                                v-tippy-tooltip="{
                                    theme : 'transparent',
                                    arrow: true,
                                    placement: 'top-start'
                                }"
                            >
                                <z-icon name="download" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                    </card>
                </z-link>
            </div>
        </div>
        <z-preloader v-show="loading"></z-preloader>
    </div>
</template>

<script>
import {getIdentity} from '@/api/media'
import {throttle} from 'throttle-debounce'
import { localize } from '@/utils/i18n'

export default {
    name: 'media-identity',
    data () {
        return {
            loading: false,
            params: {},
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не найдено',
                    'en': 'No results found'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            },
            zip: localize({
                'ru': 'ZIP архив',
                'en': 'ZIP archive'
            }),
            identity: this.$root.app.components['identity-list'],
            list: this.$root.app.components['identity-list'].items,
            page: this.$root.app.components['identity-list'].nav,
            code: this.$root.app.system.params.get.CODE
        }
    },
    methods: {
        getMoreIdentity () {
            this.loading = true
            if (this.params.page === 1) this.list = []

            getIdentity(this.params).then((res) => {
                if (res.hasOwnProperty('nav')) {
                    this.page.current = res.nav.current
                    this.page.total = res.nav.total
                    this.page.count = res.nav.count
                }

                if (res.hasOwnProperty('items')) {
                    if (res.items.length === 0) {
                        this.notFound.state = true
                    } else {
                        this.notFound.state = false
                    }

                    if (this.page.current < 2) {
                        this.list = res.items
                    } else {
                        this.list = this.list.concat(res.items)
                    }
                }

                // This garbage code for the sake of IE
                let customEvent = document.createEvent('HTMLEvents')
                customEvent.initEvent('newCardsAdded', true, true)
                document.dispatchEvent(customEvent)

                this.loading = false
            })
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(1000, e => {
                const offsetTop = this.$el.offsetTop
                const offsetHeight = this.$el.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (scrolled >= (bottom - 100) && !this.loading && this.page.current < this.page.total) {
                    this.params.page++
                    this.getMoreIdentity()
                };
            }))
        }
    },
    created () {
        this.params = {
            page: 1,
            code: this.code
        }

        if (!this.list.length) {
            this.notFound.state = true
        }
        this.listenScroll()
    }
}
</script>

<style lang="scss"></style>

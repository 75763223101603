/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 16,
    height: 18,
    viewBox: '0 0 16 18',
    data: '<path pid="0" d="M15 7.2c1.333.769 1.333 2.693 0 3.463L3 17.591c-1.333.77-3-.192-3-1.732V2.003C0 .463 1.667-.499 3 .271l12 6.928z" _fill="#ED1C24"/>'
  }
})

<template>
    <div
        class="z-tabs"
        :class="`z-tabs--theme-${theme}`"
    >
        <div class="z-tabs__container">
            <div class="z-tabs__header">
                <slot name="label"/>
            </div>
        </div>
        <slot name="content"/>
    </div>
</template>

<script>
export default {
    name: 'z-tabs',
    props: {
        nested: Boolean,
        theme: {
            type: String,
            default: 'light'
        }
    },
    data () {
        return {
            name: 'z-tabs',
            hash: window.location.hash,
            openedItem: {
                id: {
                    type: String,
                    default: ''
                }
            }
        }
    },
    mounted () {
        this.defaultState()
        this.$nextTick(() => this.checkHash())
        this.$on('toggle-item', data => this.openHandler(data))
    },
    beforeDestroy () {
        this.$off('toggle-item', data => this.openHandler(data))
    },
    computed: {
        currentHash () {
            return this.hash ? this.hash.split('#')[1] : ''
        }
    },
    methods: {
        openHandler (data) {
            this.openedItem.id = data

            for (let i = 0; i < this.$children.length; i++) {
                if (this.$children[i].itemId === this.openedItem.id) {
                    this.$children[i].state = true
                } else {
                    this.$children[i].state = false
                };
            };
        },
        checkHash () {
            if (this.currentHash.length) {
                let matched = this.$children.filter(item => item.itemId === this.currentHash)

                if (matched.length) this.openItem()
            };
        },
        defaultState () {
            let label = this.$children.filter(item => item.name === 'z-tabs-label')[0]
            let content = this.$children.filter(item => item.name === 'z-tabs-content')[0]

            label.state = true
            content.state = true
        },
        closeAll () {
            for (let i = 0; i < this.$children.length; i++) {
                this.$children[i].state = false
            };
        },
        openItem () {
            for (let i = 0; i < this.$children.length; i++) {
                if (this.$children[i].itemId === this.currentHash) {
                    this.$children[i].state = true

                    this.openedItem.id = this.$children[i].itemId

                    if (this.nested) this.openParent(this.$parent)
                    if (this.$parent.name === 'z-accordion-item') this.openAccordion(this.$parent)
                } else {
                    this.$children[i].state = false
                };
            };
        },
        openParent (parent) {
            let grandParent = parent.$parent
            let grandParentChildrens = grandParent.$children
            let grandParentTabs = grandParentChildrens.filter(item => item.name === 'z-tabs-label')
            let grandParentContent = grandParentChildrens.filter(item => item.name === 'z-tabs-content')

            for (let i = 0; i < grandParentTabs.length; i++) {
                if (grandParentTabs[i].itemId === parent.itemId) {
                    grandParentTabs[i].state = true
                    grandParentContent[i].state = true
                } else {
                    grandParentTabs[i].state = false
                    grandParentContent[i].state = false
                };
            };

            if (grandParent.$parent.name === 'z-accordion-item') this.openAccordion(grandParent.$parent)

            if (grandParent.name === 'z-tabs' && grandParent.nested) {
                grandParent.$parent.state = true
                this.openParent(grandParent.$parent)
            };
        },
        openAccordion (parent) {
            let accordion = parent.$parent

            accordion.openParent(parent)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

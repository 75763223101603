export default [
    // {
    //     id: 'balt',
    //     name: 'Балтийский бассейн',
    //     lead: '',
    //     text: `asd`,
    //     image: '/images/map/amursk.jpg',
    //     link: `/ru/assets/where-we-operate/amursk-pox-hub/`,
    //     linkText: 'Посмотреть актив',
    //     type: 'pool',
    //     title: {
    //         position: 'left-bottom'
    //     },
    //     isCluster: true,
    //     scaleClusterSize: 3
    // },
    // {
    //     id: 'east',
    //     name: 'Восточный бассейн',
    //     lead: '',
    //     text: `asd`,
    //     image: '/images/map/amursk.jpg',
    //     link: `/ru/assets/where-we-operate/amursk-pox-hub/`,
    //     linkText: 'Посмотреть актив',
    //     type: 'pool',
    //     title: {
    //         position: 'left-bottom'
    //     },
    //     isCluster: true,
    //     scaleClusterSize: 3
    // }
]

<template>
    <div
        v-if="!hideNotification"
        class="notification">
        <slot />
    </div>
</template>

<script>
import BrowserMixin from '@/utils/browser'

export default {
    name: 'notification',
    mixins: [BrowserMixin],

    computed: {
        hideNotification () {
            return ['YandexBrowser', 'ChromiumGost'].includes(this.browser)
        }
    }
}
</script>

<style scoped lang="scss">
.notification {
    color: $token-colors-ulct-red
}
</style>

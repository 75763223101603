var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"z-input",class:{
        'is-error': _vm.error,
        'is-filled': _vm.val,
        'is-focused': _vm.focus,
        'is-disabled': _vm.disabled,
        'is-required': _vm.required
    },attrs:{"for":_vm.name}},[_c('input',{attrs:{"id":_vm.name,"name":_vm.name,"type":_vm.type,"disabled":_vm.disabled,"autocomplete":"off"},domProps:{"value":_vm.value},on:{"blur":function($event){_vm.focus = false},"focus":function($event){_vm.focus = true},"change":function($event){return _vm.change($event.target.value)},"input":function($event){return _vm.input($event.target.value)}}}),_vm._v(" "),(_vm.placeholder)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.value && !_vm.focus),expression:"!value && !focus"}],staticClass:"z-input__placeholder",domProps:{"innerHTML":_vm._s(_vm.placeholder)}}):_vm._e(),_vm._v(" "),(_vm.required && _vm.error)?_c('span',{staticClass:"z-input__error"},[(!_vm.value)?_c('span',[_vm._v(_vm._s(_vm.text.errors.required))]):(_vm.type === 'email')?_c('span',[_vm._v(_vm._s(_vm.text.errors.invalid.email))]):(_vm.type === 'phone')?_c('span',[_vm._v(_vm._s(_vm.text.errors.invalid.phone))]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.theme === 'search')?_c('z-icon',{attrs:{"name":_vm.value.length > 0 ? 'close' : 'search',"width":"24","height":"24"},on:{"click":function($event){return _vm.$emit('input', '')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
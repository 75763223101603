<template src="./index.html"></template>

<script>
import { localize } from '@/utils/i18n'
import { queryString } from '@/utils/queryString'
import Swiper from 'swiper/dist/js/swiper.js'

export default {
    name: 'bods',
    props: {
        data: Array
    },
    data () {
        return {
            modal: this.$root.app.system.params.get['modal'],
            slider: null,
            dataModal: {
                data: {},
                slider: []
            },
            firstNavElem: false,
            lastNavElem: false,
            text: {
                director: localize({
                    'ru': 'Председатель совета директоров',
                    'en': 'Chairman of&nbsp;the Board of&nbsp;Directors',
                    'cn': '主席'
                }),
                committeeMan: localize({
                    'ru': 'Председатель комитета',
                    'en': 'Chairman of the Committee',
                    'cn': '主席'
                }),
                committeeWoman: localize({
                    'ru': 'Председательница комитета',
                    'en': 'Chairwoman of the Committee',
                    'cn': '主席'
                })
            }
        }
    },
    mounted () {
        window.addEventListener('resize', this.sliderUpdate)
        this.onMounted()

        if (this.slider !== null) {
            this.disableArrowOnEdge()
        }
    },
    destroyed () {
        window.removeEventListener('resize', this.sliderUpdate)
    },
    updated () {
        this.sliderUpdate()
        this.setImgObjectFit()
    },
    computed: {
        hasItems () {
            return (this.data.length && this.data[0].hasOwnProperty('items'))
        }
    },
    methods: {
        onMounted () {
            if (this.modal !== undefined) {
                if (this.hasItems) {
                    this.data.forEach((group) => group.items.forEach((bod, i) => {
                        return bod.code === this.modal && this.openBod(bod, i, group)
                    }))
                } else {
                    this.data.forEach((bod, i) => bod.code === this.modal && this.openBod(bod, i))
                }
            }
        },
        sliderInit () {
            this.slider = new Swiper(this.$refs.sliderContainer, {
                freeMode: true,
                observer: true,
                observeParents: true,
                slidesPerView: 'auto',
                slideClass: 'bods-modal-slider__slide',
                wrapperClass: 'bods-modal-slider__wrapper',
                // containerModifierClass: 'bods-modal-slider',
                simulateTouch: false,
                navigation: {
                    nextEl: '.bods-modal-slider__arrow--next',
                    prevEl: '.bods-modal-slider__arrow--prev',
                    disabledClass: 'hack'
                }
            })
        },
        sliderUpdate () {
            if (this.slider !== null) {
                this.slider.update()
            }
        },
        closeBod () {
            this.slider = null
            this.dataModal = {
                data: {},
                slider: []
            }
        },
        openBod (bod, index, group) {
            this.dataModal.data = bod

            queryString({
                modal: bod.code
            })

            if (this.slider == null) {
                // if bod have no group
                if (group === undefined) {
                    this.dataModal.slider = this.data.map((item, i) => {
                        item.selected = (index === i)
                        return item
                    })
                } else {
                    this.dataModal.slider = group.items.map((item, i) => {
                        item.selected = (index === i)
                        return item
                    })
                }
            } else {
                this.dataModal.slider.forEach((item, i) => {
                    item.selected = (index === i)
                })
            }

            this.$root.$bus.$emit('open-modal', {
                id: `modalBods-${this._uid}`,
                index: 0
            })

            if (this.slider == null) {
                this.$nextTick(function () {
                    this.sliderInit()
                })
            }

            this.$nextTick(function () {
                let initialIndex = 0

                const showed = Math.floor(this.$refs.sliderContainer.clientWidth / 106)
                const critical = this.$refs.sliderItem.length - showed

                if (index > showed) {
                    if (index > critical) {
                        initialIndex = critical
                    } else {
                        initialIndex = index - Math.floor(showed / 2)
                    }
                }

                this.slider.slideTo(initialIndex, 500)
            })

            this.$nextTick(() => {
                if (this.slider !== null) {
                    this.disableArrowOnEdge()
                }
            })
        },
        setImgObjectFit () {
            this.$refs['img-tab'].forEach((item, i) => {
                objectFitPolyfill(item)
            })
        },
        navNextSlide () {
            this.getSlide('next')
        },
        navPrevSlide () {
            this.getSlide('prev')
        },
        getSlide (direction) {
            /* return -1 if found nothing,
            else return index */
            let index = this.dataModal.slider.findIndex((slide, i) => {
                if (slide.selected) {
                    return true
                }
            })

            if (index > -1) {
                if (direction === 'next') {
                    this.openBod(this.dataModal.slider[index + 1], index + 1)
                } else if (direction === 'prev') {
                    this.openBod(this.dataModal.slider[index - 1], index - 1)
                }
            }
        },
        disableArrowOnEdge () {
            let lastIndex = this.dataModal.slider.length - 1
            if (this.dataModal.slider[0].selected) {
                this.firstNavElem = true
                this.lastNavElem = false
            } else if (this.dataModal.slider[lastIndex].selected) {
                this.firstNavElem = false
                this.lastNavElem = true
            } else {
                this.firstNavElem = false
                this.lastNavElem = false
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <div class="governance">
        <div class="governance__scheme" v-html="svg[lang]"></div>
    </div>
</template>

<script>
/* eslint-disable import/no-webpack-loader-syntax */
import schemeEng from '!!raw-loader!./schemeEng.svg'
import schemeRus from '!!raw-loader!./schemeRus.svg'
import schemeCn from '!!raw-loader!./schemeCn.svg'

export default {
    name: 'governance',
    data () {
        return {
            svg: {
                ru: schemeRus,
                en: schemeEng,
                cn: schemeCn
            },
            lang: this.$root.lang
        }
    }
}
</script>

<style lang="scss">
.governance__scheme {
    padding-top: 2rem;
    position: relative;
    width: 100%;

    > svg {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    &:before {
        content: '';
        padding-top: 75%;
        display: block;

        @include breakpoint(mobile) {
            padding-top: 77%;
        }

        @include breakpoint(v-mobile) {
            padding-top: 80%;
        }
    }
}
</style>

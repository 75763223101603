<template>
    <div
        v-show="state"
        class="z-tabs-content"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'z-tabs-content',
    props: {
        id: {
            type: String,
            default: '',
            required: true
        }
    },
    data () {
        return {
            state: false,
            name: 'z-tabs-content',
            itemId: `tabs-${this.id}`
        }
    }
}
</script>

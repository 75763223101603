/* eslint-disable indent */
import { deviceEnquire } from '@/utils/device'
import { throttle } from 'throttle-debounce'

class EqualHeights {
    constructor ($container) {
        this.$container = $container
        this.elSelector = $container.getAttribute('equal-height')
    }

    init () {
        this.go()

        window.addEventListener('resize', throttle(250, e => {
            this.go()
        }))

        window.addEventListener('newCardsAdded', () => {
            this.go()
        })
    }

    go () {
        if (!window.document.documentMode && typeof InstallTrigger === 'undefined') {
            this.setMaxHeight(0)
        }
        const height = this.getMaxHeight()
        this.setMaxHeight(height)
    }

    getMaxHeight () {
        const $els = this.$container.querySelectorAll(this.elSelector)
        let maxHeight = 0

        Array.from($els).forEach(el => {
            const height = el.offsetHeight
            maxHeight = (height > maxHeight ? height : maxHeight)
        })

        return maxHeight
    }

    setMaxHeight (height) {
        const $els = this.$container.querySelectorAll(this.elSelector)

        Array.from($els).forEach(el => {
            let elContainer
            if (el.classList.contains('card')) elContainer = el.querySelector('.card__body')

            if (el.getAttribute('equal-height-max-breakpoint') !== null) {
                const breakpoint = el.getAttribute('equal-height-max-breakpoint')
                const isMinHeightStable = el.getAttribute('data-stable-height')

                let condition = true

                deviceEnquire((device) => {
                    switch (breakpoint) {
                        case 'laptop':
                            if (device === 'laptop' || device === 'tablet' || device === 'mobile' || device === 'v-mobile') {
                                condition = false
                            }
                            break
                        case 'tablet':
                            if (device === 'tablet' || device === 'mobile' || device === 'v-mobile') {
                                condition = false
                            }
                            break
                        case 'mobile':
                            if (device === 'mobile' || device === 'v-mobile') {
                                condition = false
                            }
                            break
                        case 'v-mobile':
                            if (device === 'v-mobile') {
                                condition = false
                            }
                            break
                    }
                })

                if (condition) {
                    if (!isMinHeightStable) {
                        el.style.minHeight = height + 'px'
                    }
                    if (elContainer) elContainer.style.minHeight = height + 'px'
                } else {
                    el.style.minHeight = ''
                    if (elContainer) elContainer.style.minHeight = ''
                }
            } else {
                el.style.minHeight = height + 'px'
                if (elContainer) elContainer.style.minHeight = height + 'px'
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    let $arEqualHeights = document.querySelectorAll('[equal-height]')

    Array.from($arEqualHeights).forEach(el => {
        new EqualHeights(el).init()
    })
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attach': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.5 17.328V7.5h-1v9.828h1zm8-9.828v11h1v-11h-1zm-5 11V9.041h-1V18.5h1zm2-9.459V17h1V9.041h-1zm-1-1a1 1 0 011 1h1a2 2 0 00-2-2v1zm-1 1a1 1 0 011-1v-1a2 2 0 00-2 2h1zM14 21a2.5 2.5 0 01-2.5-2.5h-1A3.5 3.5 0 0014 22v-1zm2.5-2.5A2.5 2.5 0 0114 21v1a3.5 3.5 0 003.5-3.5h-1zm-4-15a4 4 0 014 4h1a5 5 0 00-5-5v1zm-4 4a4 4 0 014-4v-1a5 5 0 00-5 5h1z" _fill="#4F4F4F"/>'
  }
})

<template>
    <div class="simple-document" v-if="documents">
        <vue-raw v-if="documents.detail" class="html-content" :raw="documents.detail" />
        <z-filelist v-if="documents.items.length">
            <z-filelist-item
                v-for="(item, index) in documents.items"
                :key="index"
                icon="file_filled"
                :info="`${item.size}, ${item.extension}`"
            >
                <z-link :href="item.link" target="_blank">{{ item.name }}</z-link>
            </z-filelist-item>
        </z-filelist>
    </div>
</template>

<script>
export default {
    name: 'simple-document',
    props: {
        component: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            documents: this.$root.app.components[this.component]
        }
    }
}
</script>

<style lang="scss">
    .simple-document {
        .html-content {
            margin-bottom: 1.5rem;
        }
    }
</style>

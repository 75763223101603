/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.336 7.8c0 3.42-2.832 6.24-6.387 6.24-3.556 0-6.388-2.82-6.388-6.24 0-3.418 2.832-6.239 6.388-6.239 3.555 0 6.387 2.821 6.387 6.24zm-2.626 6.875c-1.12.591-2.4.927-3.761.927C3.559 15.602 0 12.109 0 7.8S3.559 0 7.949 0s7.948 3.493 7.948 7.8a7.658 7.658 0 01-1.205 4.132l3.71 3.641a1.978 1.978 0 010 2.835 2.07 2.07 0 01-2.889 0l-3.803-3.733zm1.329-.883l3.589 3.522a.473.473 0 00.66 0 .452.452 0 000-.648l-3.568-3.501a7.918 7.918 0 01-.681.627z" _fill="#7A7A7E"/>'
  }
})

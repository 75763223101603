<template>
    <div class="company-strategy">
        <h2 class="company-strategy__subtitle" v-html="text.subtitle"></h2>
        <div class="company-strategy__scheme" v-html="isVMobile() ? scheme.mobile : scheme.desktop"></div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'
/* eslint-disable import/no-webpack-loader-syntax */
import desktopEn from '!!raw-loader!./svg/desktopEn.svg'
import desktopRu from '!!raw-loader!./svg/desktopRu.svg'
import mobileEn from '!!raw-loader!./svg/mobileEn.svg'
import mobileRu from '!!raw-loader!./svg/mobileRu.svg'

export default {
    name: 'company-strategy',
    mixins: [mixinDevice],
    data () {
        return {
            scheme: {
                desktop: localize({
                    'ru': desktopRu,
                    'en': desktopEn
                }),
                mobile: localize({
                    'ru': mobileRu,
                    'en': mobileEn
                })
            },
            text: {
                subtitle: localize({
                    'ru': 'Наши ключевые приоритеты:',
                    'en': 'To&nbsp;succeed, we remain focused&nbsp;on:'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.company-strategy {
    &__scheme {
        padding-top: 2rem;
        position: relative;
        width: 100%;

        > svg {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }

        &:before {
            content: '';
            padding-top: 96%;
            display: block;

            @include breakpoint(v-mobile) {
                padding-top: 532%;
            }
        }
    }

    @for $i from 1 through 4 {
        &__card-wrapper-#{$i} {
            opacity: 0;
            animation: 0.5s ease-out #{$i/4}s 1 alternate forwards showUp;
        }

        &__card-bottom-#{$i} {
            opacity: 0;
            animation: 0.5s ease-out #{$i/4 + 1}s 1 alternate forwards showUp;
        }

        &__card-top-#{$i} {
            opacity: 0;
            animation: 0.5s ease-out #{$i/4 + 2}s 1 alternate forwards showUpAndDown;
        }
    }

    margin-top: 2.5rem;

    @keyframes showUp { from { opacity: 0; } to { opacity: 1; }  }

    @keyframes showUpAndDown { from { opacity: 0; transform: translateY(-20px) } to { opacity: 1; transform: translateY(0)}  }
}
</style>

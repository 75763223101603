/* eslint-disable import/no-webpack-loader-syntax */
'use strict'

import hub from '!!raw-loader!./markers/pick.svg'
import terminal from '!!raw-loader!./markers/mining_processing.svg'

export default [
    {
        id: 'hub',
        image: hub,
        name: 'Хабы/Бассейны'
    },
    {
        id: 'terminal',
        image: terminal,
        name: 'Терминалы'
    }
]

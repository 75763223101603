<template>
    <div class="z-not-found">
        <span class="z-not-found__title" v-html="title"></span>
        <img :src="buildSrc()" class="z-not-found__image">
        <p class="z-not-found__text" v-html="text"></p>
    </div>
</template>

<script>
export default {
    name: 'z-not-found',
    props: [
        'title',
        'text'
    ],
    methods: {
        buildSrc: function () {
            return require('./images/bg.svg')
        }
    }
}
</script>

<style lang="scss">
.z-not-found {
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    text-align: center;

    &__title {
        margin-bottom: 1.875rem;
        display: block;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.4;
        margin-bottom: 0;
        color: #363636;
    }

    &__text {
        font-size: 14px;
        line-height: 1.5;
        color: #808083;
        display: block;
        margin-bottom: 0;
    }

    &__image {
        margin-top: 3.5rem;
        margin-bottom: 1rem;
    }
}
</style>

<template>
    <label
        :for="name"
        class="file-input"
        :class="{
            'is-error': error,
            'is-disabled': disabled,
            'is-required': required
        }"
    >
        <div class="file-input__container">
            <input
                :id="name"
                :name="name"
                type="file"
                :disabled="disabled"
                :accept="accept"
                @change="change"
                ref="fileInput"
            />
            <span class="file-input__placeholder">
                <template v-if="fileName">
                    {{ fileName }}
                </template>
                <template v-else>
                    {{ placeholder }}
                </template>
            </span>
            <z-icon
                :name="fileName ? 'close' : 'attach'"
                @click.prevent="resetFile"
                width="24"
                height="24"
            />
        </div>
        <span
            class="file-input__error"
            v-if="required && error"
        >
            {{ text.errors.required }}
        </span>
        <span v-if="subtitle" class="file-input__subtitle">{{ subtitle }}</span>
    </label>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'file-input',
    props: {
        name: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        accept: String,
        subtitle: String,
        required: Boolean,
        placeholder: {
            type: String,
            default: localize({
                'ru': 'Прикрепить файл',
                'en': 'Attach file'
            })
        }
    },
    data () {
        return {
            error: false,
            text: {
                errors: {
                    required: localize({
                        'ru': 'Поле обязательно для заполнения',
                        'en': 'Required field'
                    })
                }
            },
            value: undefined
        }
    },
    computed: {
        fileName () {
            if (!this.value) return ''
            return this.value.name
        }
    },
    methods: {
        change (e) {
            let file = e.target.files[0]
            this.value = file
            this.$emit('change', file)
            this.valid()
        },
        valid () {
            if (this.required && !this.value) {
                this.error = true
            } else {
                this.error = false
            }
        },
        resetFile () {
            this.$refs.fileInput.value = null
            this.value = null
            this.$emit('change', '')
            this.valid()
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'in': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 46.5c12.426 0 22.5-10.074 22.5-22.5S36.426 1.5 24 1.5 1.5 11.574 1.5 24 11.574 46.5 24 46.5zm0 1.5c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z" _fill="#C4C4C4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M16.349 16.198a1.849 1.849 0 100-3.698 1.849 1.849 0 000 3.698zm0 1.5a3.349 3.349 0 100-6.698 3.349 3.349 0 000 6.698zM17.64 20.314H14.5V33.5h3.14V20.314zm-4.64-1.5V35h6.14V18.814H13zM28.149 19.422c1.37-.796 3.228-1.453 4.974-1.162.922.154 1.703.485 2.345 1 .64.513 1.106 1.18 1.442 1.954.658 1.519.84 3.517.84 5.786v7.993h-7.36V28.07c0-.753-.1-1.283-.29-1.606a.788.788 0 00-.307-.31c-.126-.068-.315-.125-.607-.125-.558 0-.883.203-1.095.496-.237.329-.388.85-.388 1.545v6.923h-7.356l-.102-16.243h6.807v1.412a9.712 9.712 0 011.097-.74zm-1.097 2.862h-1.5V20.25h-3.797l.083 13.243h4.365V28.07c0-.876.184-1.75.673-2.424.513-.71 1.305-1.117 2.31-1.117.488 0 .934.096 1.322.306.39.212.68.518.888.872.397.68.493 1.546.493 2.363v5.423h4.36V27c0-2.242-.188-3.973-.716-5.19-.257-.595-.588-1.048-1.003-1.38-.413-.332-.947-.572-1.653-.69-1.254-.21-2.745.264-3.974.98-.604.35-1.105.737-1.445 1.078-.17.171-.286.317-.354.426-.046.074-.055.108-.056.108 0 0 .004-.017.004-.048z" _fill="#C4C4C4"/>'
  }
})

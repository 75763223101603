<template src="./index.html"></template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
import Velocity from 'velocity-animate'
import { localize } from '@/utils/i18n'

export default {
    name: 'card-slider',
    props: {
        theme: {
            type: String,
            validator: prop => ['mainpage', 'blockquote'].includes(prop),
            default: 'mainpage'
        },
        autoplay: [Number, String],
        navigation: {
            type: Boolean,
            default: true
        },
        mainpage: Boolean,
        slides: Array
    },
    data () {
        return {
            slider: null,
            lang: this.$root.lang,
            mapIndex: -1,
            text: {
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more',
                    'cn': '了解更多'
                }),
                title: localize({
                    'ru': 'Глобал Портс&nbsp;&mdash; ведущий оператор сети высокотехнологичных терминалов',
                    'en': 'Global Ports is&nbsp;a&nbsp;leading high-tech terminal network operator',
                    'cn': 'Global Ports 是领先的高科技码头网络营运商'
                })
            }
        }
    },
    created () {
        const vm = this

        let options = {
            observer: true,
            observeParents: true,
            slidesPerView: '1',
            effect: 'fade',
            slideClass: 'card-slider__slide',
            wrapperClass: 'card-slider__wrapper',
            simulateTouch: false,
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: '.card-slider__pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: this.navigation ? '.card-slider__arrow--next' : null,
                prevEl: this.navigation ? '.card-slider__arrow--prev' : null
            },
            on: this.mainpage ? this.handlerMap(vm) : {}
        }

        if (this.autoplay) {
            options.autoplay = {
                delay: this.autoplay
            }
        }

        this.$nextTick(function () {
            this.slider = new Swiper(this.$refs.sliderContainer, options)
        })
    },
    computed: {
        activeIndex () {
            if (this.slider) {
                return this.slider.activeIndex
            } else {
                return -1
            }
        },
        classObject () {
            let arrClass = []

            if (this.theme) {
                arrClass.push(`card-slider__pagination-${this.theme}`)
            }

            return arrClass
        }
    },
    watch: {
        activeIndex (newValue, oldValue) {
            if (newValue === this.slider.activeIndex) {
                this.$refs.map[0].onload = this.goAnimationMap()
            }
        }
    },
    methods: {
        isSvgMap (img, index) {
            const re = /(?:\.([^.]+))?$/
            let fileExtension = re.exec(img)[1]

            this.mapIndex = index

            return fileExtension === 'svg'
        },
        handlerMap (vm) {
            return {
                init: function () {
                    if (vm.$refs.map) {
                        vm.$refs.map[0].onload = function () {
                            vm.goAnimationMap()
                        }
                    }
                }
            }
        },
        goAnimationMap () {
            const svgDocument = this.$refs.map[0].contentDocument
            const countryRu = svgDocument.querySelector('.card-slider__map--country-ru')
            const countryEn = svgDocument.querySelector('.card-slider__map--country-en')
            const countryCn = svgDocument.querySelector('.card-slider__map--country-en')
            const $dots = svgDocument.querySelectorAll('.card-slider__map-dots')
            const $red = svgDocument.querySelectorAll('.card-slider__map-red-lines')
            const $yellow = svgDocument.querySelectorAll('.card-slider__map-yellow-lines')
            const $blue = svgDocument.querySelectorAll('.card-slider__map-blue-lines')

            if (this.lang === 'ru') {
                countryRu.style.display = 'block'
            } else if (this.lang === 'cn') {
                countryCn.style.display = 'block'
            } else {
                countryEn.style.display = 'block'
            }

            Velocity(this.$refs.map, {
                opacity: 1
            }, {
                delay: 500,
                duration: 2000
            })

            Velocity($dots, {
                opacity: 1
            }, {
                delay: 2500,
                duration: 1000
            })

            Velocity($red, {
                strokeDashoffset: 0
            }, {
                delay: 3500,
                duration: 1000
            })

            Velocity($yellow, {
                strokeDashoffset: 0
            }, {
                delay: 4500,
                duration: 1000
            })

            Velocity($blue, {
                strokeDashoffset: 0
            }, {
                delay: 5500,
                duration: 1000
            })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

'use strict'

var d3 = require('d3')

module.exports = function (width, height) {
    return d3.geo.mercator()
        .scale((width + 1) / 2 / Math.PI)
        .translate([width / 2, height * 1.40625 / 2])
        .clipExtent([[0, 0], [width, height]])
        .precision(0.1)
}

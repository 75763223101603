/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'letter': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.926 19.008H47.02c1.702-.04 3.366.454 4.711 1.394l-1.715 1.004-18.1 10.51-4.711-2.764a.962.962 0 00-.34-.187L13.92 21.397l-1.705-.995c1.325-.974 3.003-1.47 4.71-1.394zm-5.898 2.704l1.592.935 12.447 7.305-12.334 11.564-1.338 1.25c-.93-1.171-1.418-2.58-1.394-4.022l.01-13.512a6.428 6.428 0 011.017-3.52zm38.877 20.953L37.328 30.896l-4.806 2.806-.16.068h-.075a1.031 1.031 0 01-.236 0 1.031 1.031 0 01-.236 0h-.075l-.226-.068-4.768-2.789L14.1 42.699l-1.357 1.267a7.803 7.803 0 004.184 1.029h30.152a8.205 8.205 0 004.155-1.088l-1.328-1.242zm4.09-17.407v13.487c.063 1.438-.416 2.852-1.357 4.013l-1.348-1.267-12.324-11.573 12.437-7.27 1.602-.927a6.148 6.148 0 01.99 3.537z" _fill="#ED1C24"/>'
  }
})

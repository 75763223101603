<template src="./index.html"></template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
import { mixinDevice } from '@/utils/mixin'
import { debounce } from 'throttle-debounce'

export default {
    name: 'gallery',
    mixins: [mixinDevice],
    props: {
        slides: Array,
        pagination: Boolean,
        modal: Boolean,
        isHtml: Boolean,
        autoplay: Boolean,
        effect: {
            type: String,
            default: 'slide'
        },
        theme: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            slider: null,
            lang: this.$root.lang,
            scrollPos: 0
        }
    },
    created () {
        this.$nextTick(function () {
            if (this.businessTheme) {
                this.breakpointChecker()
            } else {
                this.enableSwiper()
            }
        })
    },
    computed: {
        businessTheme () {
            return this.theme === 'business-model'
        },
        slidesForModal () {
            return this.slides.map((el) => {
                el.type = 'photo'
                el.image = {
                    preview: el.preview,
                    full: el.image
                }

                return el
            })
        }
    },
    methods: {
        listenScroll () {
            const vm = this

            window.addEventListener('scroll', debounce(600, e => {
                if (vm.slider !== null && !vm.slider?.destroyed) {
                    const $slider = vm.slider.$el[0] // vm.slider.slides[vm.slider.activeIndex]

                    if ((document.body.getBoundingClientRect()).top > vm.scrollPos) {
                        if ($slider.getBoundingClientRect().top > 100) {
                            vm.slider.slidePrev()
                        }
                    } else {
                        const diffHeight = $slider.getBoundingClientRect().top + $slider.offsetHeight

                        if (document.documentElement.clientHeight >= diffHeight) {
                            console.log(document.documentElement.clientHeight + ' >= ' + diffHeight)
                            vm.slider.slideNext()
                        }
                    }
                }

                vm.scrollPos = (document.body.getBoundingClientRect()).top
            }))
        },
        enableSwiper () {
            this.slider = new Swiper(this.$refs.sliderContainer, {
                init: false,
                observer: true,
                observeParents: true,
                autoHeight: true,
                // height: this.businessTheme && !(this.isMobile() || this.isVMobile()) && 820,
                slidesPerView: this.businessTheme ? 'auto' : 1,
                effect: this.effect,
                slideClass: 'gallery__slide',
                wrapperClass: 'gallery__wrapper',
                autoplay: this.autoplay,
                speed: this.businessTheme ? 1000 : 300,
                // containerModifierClass: 'gallery',
                simulateTouch: false,
                fadeEffect: {
                    crossFade: true
                },
                pagination: (this.pagination) ? {
                    el: `.gallery__pagination-${this._uid}`,
                    type: 'bullets',
                    clickable: true
                } : false,
                navigation: {
                    nextEl: `.gallery__arrow--next-${this._uid}`,
                    prevEl: `.gallery__arrow--prev-${this._uid}`
                },
                initialSlide: this.businessTheme ? 1 : 0,
                direction: this.businessTheme ? 'vertical' : 'horizontal',
                on: {
                    init: () => {
                        console.log('swiper loaded')
                        this.$emit('loaded')
                    }
                }
            })

            this.slider.init()

            this.slider.on('transitionEnd', () => {
                console.log('transition end')
                this.slider.update()
            })
        },
        breakpointChecker () {
            // if (this.isMobile() || this.isVMobile()) {
            if (this.slider !== null && !this.slider?.destroyed) this.slider.destroy(true, true)
            this.$emit('loaded')
            let gallery = document.querySelector('.gallery__wrapper')
            gallery.classList.remove('swiper-wrapper')
        }
    },
    watch: {
        device (value) {
            if (value !== 'v-mobile' || value !== 'mobile') {
                console.log('slider init?')
                // Непонятно для чего вызывается здесь, ломает отображение на CompanyAbout
                // this.breakpointChecker()
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cargo-item': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M14.933 25.171V42.81h35.152V25.17H14.933zm3.524 15.753c0 .186-.12.332-.277.332h-1.174c-.155 0-.277-.146-.277-.332V27.079c0-.187.121-.332.277-.332h1.174c.156 0 .277.145.277.332v13.845zm3.748 0c0 .186-.12.332-.277.332h-1.192c-.138 0-.259-.146-.259-.332V27.079c0-.187.121-.332.26-.332h1.191c.156 0 .277.145.277.332v13.845zm3.749 0c0 .186-.121.332-.277.332h-1.192c-.155 0-.277-.146-.277-.332V27.079c0-.187.121-.332.277-.332h1.192c.155 0 .277.145.277.332v13.845zm3.748 0c0 .186-.138.332-.277.332h-1.192c-.155 0-.277-.146-.277-.332V27.079c0-.187.121-.332.277-.332h1.192c.139 0 .277.145.277.332v13.845zm3.73 0c0 .186-.12.332-.258.332h-1.192c-.155 0-.277-.146-.277-.332V27.079c0-.187.121-.332.277-.332h1.192c.139 0 .259.145.259.332v13.845zm3.75 0c0 .186-.121.332-.278.332h-1.173c-.156 0-.278-.146-.278-.332V27.079c0-.187.121-.332.278-.332h1.173c.156 0 .277.145.277.332v13.845zm3.748 0c0 .186-.121.332-.277.332H39.46c-.139 0-.26-.146-.26-.332V27.079c0-.187.121-.332.26-.332h1.192c.155 0 .277.145.277.332v13.845zm3.748 0c0 .186-.121.332-.276.332h-1.193c-.138 0-.277-.146-.277-.332V27.079c0-.187.14-.332.277-.332h1.192c.155 0 .277.145.277.332v13.845zm3.749 0c0 .186-.121.332-.277.332h-1.192c-.156 0-.277-.146-.277-.332V27.079c0-.187.12-.332.277-.332h1.192c.155 0 .277.145.277.332v13.845zM50.706 22H14.294c-.174 0-.294.145-.294.353v1.844c0 .207.12.352.294.352h36.412c.173 0 .294-.145.294-.352v-1.844c0-.208-.12-.353-.294-.353zM50.706 43.431H14.294c-.174 0-.294.165-.294.373v1.844c0 .187.12.352.294.352h36.412c.173 0 .294-.166.294-.352v-1.844c0-.208-.12-.373-.294-.373z" _fill="#ED1C24"/>'
  }
})

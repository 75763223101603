<template>
    <div class="subscribe">
        <div class="subscribe__title">
            <h1 v-html="text.title"></h1>
            <p><span v-html="text.subTitle.text"></span>
                <z-link
                    href="mailto:ir@globalports.com"
                    v-html="text.subTitle.link"
                ></z-link>
            </p>
        </div>
        <form
            class="subscribe__container"
            v-if="!isSend"
            v-on:submit.prevent="submit"
        >
            <div class="subscribe__content">
                <h3 v-html="text.subscribeFormTitle"></h3>
                <h5 v-html="text.subscribeFormDescription"></h5>
                <div class="row between-default">
                    <div
                        class="col-default-6 col-mobile-12"
                        v-for="item in subscribe"
                        :key="item.code"
                    >
                        <z-checkbox
                            :name="item.code"
                            paramsName="rubrics"
                            @change="onChange"
                        >{{ item.name }}
                        </z-checkbox>
                    </div>
                    <span v-if="isSubmitting && params.rubrics.length === 0" class="subscribe__error">{{ text.field.rubrics.error }}</span>
                </div>
            </div>
            <div class="subscribe__content">
                <div class="row between-default">
                    <div class="col-default-6 col-mobile-12">
                        <z-input
                            ref="mail"
                            name="mail"
                            type="email"
                            v-model="params.mail"
                            @change="onChange({
                                name: 'mail',
                                value: params.mail
                            })"
                            :placeholder="text.field.email"
                            required
                        ></z-input>
                        <z-input
                            v-if="code === 'news'"
                            ref="company"
                            name="company"
                            v-model="params.company"
                            @change="onChange({
                                name: 'company',
                                value: params.company
                            })"
                            :placeholder="text.field.company"
                        ></z-input>
                        <z-input
                            v-else
                            ref="company"
                            name="company"
                            v-model="params.company"
                            @change="onChange({
                                name: 'company',
                                value: params.company
                            })"
                            :placeholder="text.field.company"
                            required
                        ></z-input>
                    </div>
                    <div class="col-default-6 col-mobile-12">
                        <z-input
                            v-if="code === 'news'"
                            ref="name"
                            name="name"
                            v-model="params.name"
                            @change="onChange({
                                name: 'name',
                                value: params.name
                            })"
                            :placeholder="text.field.fullName"
                        ></z-input>
                        <z-input
                            v-else
                            ref="name"
                            name="name"
                            v-model="params.name"
                            @change="onChange({
                                name: 'name',
                                value: params.name
                            })"
                            :placeholder="text.field.fullName"
                            required
                        ></z-input>

                        <div class="subscribe__agreement">
                            <z-checkbox
                                name="subscribtionAggrement"
                                @change="onChange"
                                :class="'subscribe__required-field'"
                            >
                                {{ text.field.agreement.text }}
                                <z-link
                                    :href="`/${lang}/terms/privacy-notice/`"
                                >{{ text.field.agreement.link }}</z-link>
                            </z-checkbox>
                            <span v-if="isSubmitting && !params.subscribtionAggrement" class="subscribe__error">{{ text.field.agreement.error }}</span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="subscribe__content">
                <div class="row">
                    <div class="col-default-6 col-mobile-12">
                        <span class="subscribe__tip">
                            <span>*</span> {{ text.field.required }}
                        </span>
                        <z-button
                            type="submit"
                            rounded
                        >{{ text.field.subscribe }}</z-button>
                    </div>
                </div>
            </div>
        </form>
        <div class="subscribe__container" v-else>
            <p v-if="code === 'news'" v-html="text.successNews"></p>
            <div v-else-if="code === 'investors'">
                <p>Thank you for choosing to&nbsp;subscribe to&nbsp;Global Ports&rsquo; Investor Relations email alerts.</p>
                <p>Our team will verify your information and then add your email address to&nbsp;the distribution list. Once your email address has been added, going forward you will receive Global Ports&rsquo; Investor Relations email alerts.</p>
                <p>These email alerts are provided for institutional investors only and do&nbsp;not substitute the official disclosures made in&nbsp;accordance with regulatory requirements. Please continue to&nbsp;consult the Regulatory News Service section&nbsp;of <z-link href="https://www.londonstockexchange.com">www.londonstockexchange.com</z-link> for full and timely disclosures.</p>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'
import { postSubscribtion } from '@/api/subscribtion'

import { localize } from '@/utils/i18n'

export default {
    name: 'subscribe',
    props: {
        component: {
            type: String,
            required: true
        },
        code: {
            type: String,
            default: 'news'
        }
    },
    data () {
        return {
            isSubmitting: false,
            isSend: false,
            lang: this.$root.lang,
            subscribe: this.$root.app.components[this.component],
            params: {
                name: '',
                mail: '',
                company: '',
                subscribtionAggrement: false,
                rubrics: [],
                subscribtionCaptchaToken: ''
            },
            text: {
                title: localize({
                    'ru': 'Для подписки на&nbsp;новости заполните форму ниже',
                    'en': 'Please fill in&nbsp;your details below to&nbsp;receive our emails'
                }),
                subTitle: {
                    text: localize({
                        'ru': 'Если вы уже подписаны и хотите отписаться, то отправьте ваш запрос на ',
                        'en': 'If you are already receiving the newsletter and want to unsubscribe, please send your request to the following '
                    }),
                    link: localize({
                        'ru': 'электронный адрес',
                        'en': 'email address'
                    })
                },
                subscribeFormTitle: localize({
                    'ru': 'Подпишитесь на&nbsp;новости',
                    'en': 'Sign up&nbsp;for news'
                }),
                subscribeFormDescription: localize({
                    'ru': 'Подписка позволит вам не&nbsp;пропускать события и&nbsp;новости интересующих вас направлений',
                    'en': 'Subscription allows you not to&nbsp;miss events and news of&nbsp;directions you are interested&nbsp;in.'
                }),
                successNews: localize({
                    'ru': 'Благодарим Вас за&nbsp;подписку на&nbsp;новости компании Global Ports. Наша команда проверит указанную Вами информацию и&nbsp;включит ваш email адрес в&nbsp;список рассылки. Сразу после этого вы&nbsp;начнете получать от&nbsp;нас письма с&nbsp;последней информацией о&nbsp;новостях компании.',
                    'en': 'Thank you for choosing to&nbsp;subscribe to&nbsp;Global Ports&rsquo; Company news email alerts. Our team will verify your information and then add your email address to&nbsp;the distribution list. Once your email address has been added, going forward you will receive Global Ports&rsquo; Company news email alerts.'
                }),
                field: {
                    fullName: localize({
                        'ru': 'Введите ФИО',
                        'en': 'Name'
                    }),
                    email: localize({
                        'ru': 'Введите свой E-mail',
                        'en': 'Email'
                    }),
                    company: localize({
                        'ru': 'Название компании',
                        'en': 'Company name'
                    }),
                    required: localize({
                        'ru': 'Поля, обязательные для заполнения',
                        'en': 'Required fields'
                    }),
                    subscribe: localize({
                        'ru': 'Подписаться',
                        'en': 'Subscribe'
                    }),
                    agreement: {
                        text: localize({
                            'ru': 'Согласен с ',
                            'en': 'I agree with '
                        }),
                        link: localize({
                            'ru': 'политикой обработки персональных данных',
                            'en': 'the personal data processing policy'
                        }),
                        error: localize({
                            'ru': 'Поле обязательно для заполнения',
                            'en': 'Required field'
                        })
                    },
                    rubrics: {
                        error: localize({
                            'ru': 'Выберите новости интересующих вас направлений',
                            'en': 'Please select news directions you are interested in'
                        })
                    }
                },
                success: localize({
                    'ru': 'Отправка успешна',
                    'en': 'Sending successful'
                }),
                error: localize({
                    'ru': 'Ошибка отправки',
                    'en': 'Sending error'
                })
            },
            errors: {},
            gKey: '6LcWjt4UAAAAAEHUXE1KYNBAgxm5gDuWtCfi3ra5'
        }
    },
    created () {
        const script = document.createElement('script')

        script.async = true
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.gKey}`

        document.head.appendChild(script)
    },
    methods: {
        valid () {
            this.$refs.name.valid()
            this.$refs.mail.valid()
            this.$refs.company.valid()

            let valid = !this.$refs.name.error &&
                        !this.$refs.mail.error &&
                        !this.$refs.company.error &&
                        this.params.subscribtionAggrement &&
                        this.params.rubrics.length

            return valid
        },
        onChange (data) {
            if (data.hasOwnProperty('paramsName') && data.paramsName !== undefined) {
                if (data.paramsName === 'rubrics') {
                    if (data.value) {
                        this.params[data.paramsName].push(data.name)
                    } else {
                        // let index = this.params[data.paramsName].indexOf(data.name)
                        this.params[data.paramsName] = this.params[data.paramsName].filter(el => el !== data.name)
                    }
                } else {
                    this.params[data.paramsName] = data.value
                }
            } else {
                this.params[data.name] = data.value
            }
        },
        submit () {
            this.isSubmitting = true
            let isValid = this.valid()
            if (isValid) {
                this.send(this, this.params, this.gKey)
            }
        },
        statusForm (status) {
            if (status) {
                this.isSend = true
            } else {
                this.isSend = false
            }
        },
        buildFormData (params) {
            let data = new FormData()

            // eslint-disable-next-line
            for (let key in params) {
                if (key === 'rubrics') {
                    params[key].forEach(code => {
                        data.append('rubrics[]', code)
                    })
                } else {
                    data.append(key, params[key])
                }
            }

            return data
        },
        send: debounce(500, function (_this, params, key) {
            grecaptcha.ready(function () {
                grecaptcha.execute(key, {
                    action: 'subscribtionCaptcha'
                }).then(function (token) {
                    params.subscribtionCaptchaToken = token

                    let data = _this.buildFormData(params)

                    postSubscribtion(data).then(res => {
                        const notyState = res.status ? 'success' : 'error'

                        showNotyfications(_this.text[notyState], { type: notyState })

                        _this.statusForm(res.status)
                        _this.isSubmitting = false
                    })
                }, function (reason) {
                    showNotyfications(reason, { type: 'error' })
                })
            })
        })
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

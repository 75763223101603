/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'job-bus': {
    width: 99,
    height: 57,
    viewBox: '0 0 99 57',
    data: '<path pid="0" d="M26.055 54.771a8.123 8.123 0 100-16.245 8.123 8.123 0 000 16.245z" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M26.055 49.856a3.211 3.211 0 01-3.208-3.207 3.212 3.212 0 013.208-3.208 3.212 3.212 0 013.208 3.208 3.211 3.211 0 01-3.208 3.207zM82.568 54.772a8.123 8.123 0 100-16.246 8.123 8.123 0 000 16.246z" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M82.568 49.856a3.211 3.211 0 01-3.208-3.207 3.212 3.212 0 013.208-3.208c1.77 0 3.208 1.439 3.208 3.208a3.211 3.211 0 01-3.208 3.207zM14.627 51.097H7.641M20.39 55.813H1.005M63.53 49.856h-6.15M65.626 53.892h-19.56M63.53 35.536h5.24M71.215 46.649h-34.34m56.928 0a4.191 4.191 0 004.192-4.192V31.268c0-2.025-.536-4.014-1.551-5.767l-8.102-16.48a3.668 3.668 0 00-3.172-1.828H36.875M12.417 46.649H8.75V32.858M97.996 40.036h-5.473M38.382 13.495H90.356" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" d="M81.112 15.002v13.174h16.459M77.306 15.002v13.174M59.361 15.002v13.174m-3.515-13.174v13.174M81.112 28.176h-42.73M93.222 28.176l-2.866-3.29M88.244 25.423l3.545-2.533M19.8 28.133c6.534 0 11.831-5.298 11.831-11.832 0-6.535-5.297-11.833-11.832-11.833-6.535 0-11.832 5.298-11.832 11.833 0 6.534 5.297 11.832 11.832 11.832z" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="4" d="M13.898 20.193l2.26-7.785 3.641 4.646 3.642-4.646 2.26 7.785" _stroke="#ED1C24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="5" d="M19.8 31.597c8.447 0 15.295-6.849 15.295-15.297 0-8.447-6.848-15.295-15.296-15.295S4.503 7.853 4.503 16.3c0 8.447 6.848 15.296 15.296 15.296z" _stroke="#ED1C24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})

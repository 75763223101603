<template>
    <div class="services">
        <div class="row" equal-height=".card">
            <div
                v-for="(item, i) in list"
                :key="i"
                class="col-mobile-12"
                :class="buildClass(item)"
            >
                <card
                    :link="item.main ? '' : item.link"
                    overlay="rgba(0,0,0,0.35)"
                    theme="background"
                    :image="item.image"
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>
                        {{ item.name }}
                    </template>
                    <template v-slot:content>
                        <div style="min-height: 50px">{{ item.detail }}</div>
                    </template>
                    <template v-slot:bottom-left v-if="item.main">
                        <z-button tag="a" :href="item.link" kind="text" theme="white">
                            {{ more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="white"
                            size="m"
                        >
                           <div><img :src="item.icon" /></div>
                        </card-icon>
                    </template>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'services',
    props: {
        component: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            more: localize({
                'ru': 'Подробнее',
                'en': 'Learn more',
                'cn': '了解详情'
            }),
            list: this.$root.app.components[this.component]
        }
    },
    methods: {
        buildClass (item) {
            return item.main ? 'col-default-8' : 'col-default-4'
        }
    }
}
</script>

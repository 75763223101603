<template>
    <label
        :for="name"
        class="z-input"
        :class="{
            'is-error': error,
            'is-filled': val,
            'is-focused': focus,
            'is-disabled': disabled,
            'is-required': required
        }"
    >
        <input
            :id="name"
            :name="name"
            :type="type"
            :value="value"
            :disabled="disabled"
            @blur="focus = false"
            @focus="focus = true"
            @change="change($event.target.value)"
            @input="input($event.target.value)"
            autocomplete="off"
        />
        <span
            v-if="placeholder"
            v-show="!value && !focus"
            v-html="placeholder"
            class="z-input__placeholder"
        ></span>
        <span
            class="z-input__error"
            v-if="required && error"
        >
            <span v-if="!value">{{ text.errors.required }}</span>
            <span v-else-if="type === 'email'">{{ text.errors.invalid.email }}</span>
            <span v-else-if="type === 'phone'">{{ text.errors.invalid.phone }}</span>
        </span>
        <z-icon
            v-if="theme === 'search'"
            :name="value.length > 0 ? 'close' : 'search'"
            @click="$emit('input', '')"
            width="24"
            height="24"
        />
    </label>
</template>

<script>
export default {
    name: 'z-input',
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'text',
            validator: prop => ['text', 'email', 'phone'].includes(prop)
        },
        disabled: {
            type: Boolean,
            default: false
        },
        theme: {
            type: String,
            default: 'text',
            validator: prop => ['text', 'search'].includes(prop)
        },
        required: Boolean,
        placeholder: String,
        value: [Number, String]
    },
    data () {
        return {
            val: this.value,
            error: false,
            focus: false,
            text: {
                errors: {
                    required: this.$root.lang === 'ru' ? 'Поле обязательно для заполнения' : 'Required field',
                    invalid: {
                        email: this.$root.lang === 'ru' ? 'Формат для e-mail указан неверно' : 'Invalid email format',
                        phone: this.$root.lang === 'ru' ? 'Пожалуйста введите Ваш контактный телефон' : 'Please enter your contact phone number'
                    }
                }
            }
        }
    },
    methods: {
        change (value) {
            this.valid()
            this.$emit('change', value)
        },
        input (value) {
            if (this.required && !value) {
                this.error = true
            } else {
                this.error = false
            }
            this.$emit('input', value)
        },
        valid () {
            if (this.required && !this.value) {
                this.error = true
            } else {
                this.error = false

                if (this.type === 'email') {
                    this.checkEmail(this.value)
                }

                if (this.type === 'phone') {
                    this.checkPhone(this.value)
                }
            }
        },
        checkEmail (value) {
            if (!this.validateEmail(value)) this.error = true
        },
        checkPhone (value) {
            if (isNaN(value)) this.error = true
        },
        validateEmail (email) {
            // eslint-disable-next-line no-useless-escape
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return regex.test(email)
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>

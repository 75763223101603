import { axios } from '@/utils/request'

export function getBooklets (parameter) {
    return axios({
        url: '/booklets',
        method: 'get',
        params: parameter
    })
}

export function getIdentity (parameter) {
    return axios({
        url: '/identity',
        method: 'get',
        params: parameter
    })
}

export function getMedia (parameter) {
    return axios({
        url: '/photos-videos',
        method: 'get',
        params: parameter
    })
}

export function getMediaDetail (parameter, url) {
    return axios({
        url,
        method: 'get',
        params: parameter
    })
}

export function getMediaKit (parameter) {
    return axios({
        url: '/media-kit',
        method: 'get',
        params: parameter
    })
}

export function getMediaKitDetail (parameter) {
    return axios({
        url: '/media-kit/detail',
        method: 'get',
        params: parameter
    })
}

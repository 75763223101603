/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M54 47H10l14.024-13.153a1 1 0 011.322-.041l5.528 4.574a1 1 0 001.418-.145l7.38-9.202a1 1 0 011.595.046L54 47z" _fill="#ED1C24"/><ellipse pid="1" cx="29.25" cy="23.773" rx="2.75" ry="2.773" _fill="#ED1C24"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'management': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M48 21.5H16a6 6 0 00-6 6v1.125l5 3.188h14v-1.938a1 1 0 011-1h5.125a1 1 0 011 1v1.938h12.813L54 28.625V27.5a6 6 0 00-6-6z" _fill="#ED1C24"/><path pid="1" d="M16 47.5h32a6 6 0 006-6v-11l-5.063 3H36.126v1.25a2 2 0 01-2 2H31a2 2 0 01-2-2V33.5H15l-5-3v11a6 6 0 006 6z" _fill="#ED1C24"/><path pid="2" d="M34 30.375h-3V35h3v-4.625zM27 18.5a2 2 0 012-2h6a2 2 0 012 2v1a1 1 0 01-1 1h-1.063a1 1 0 01-1-1 1 1 0 00-1-1H31a1 1 0 00-1 1 1 1 0 01-1 1h-1a1 1 0 01-1-1v-1z" _fill="#ED1C24"/>'
  }
})

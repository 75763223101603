/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'terminals': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M31.21 19.237h1.578c.058 0 .101-.059.101-.103V13.53a.1.1 0 00-.101-.103h-1.577a.1.1 0 00-.102.104v5.603c0 .044.044.103.102.103zM33.324 13.84v4.968l7.222-2.484-7.222-2.483zM32.63 11.814c0-.34-.29-.635-.623-.635a.647.647 0 00-.637.635v1.168h1.26v-1.168zM32.63 19.68h-1.26v4.48h1.26v-4.48zM29.098 25.699h5.804a.55.55 0 00.536-.547.54.54 0 00-.536-.547h-5.804a.528.528 0 00-.521.547c0 .295.231.547.521.547zM38.606 30.89c-.767-2.234-1.953-3.845-3.3-4.85a.919.919 0 01-.404.102h-5.804a.966.966 0 01-.492-.148c-1.274.947-2.374 2.47-3.112 4.614l-2.36 6.757h17.73l-2.258-6.476zm-1.823 2.04c-.478.236-1.418-.754-2.07-2.203-.665-1.45-.81-2.81-.332-3.047.492-.222 1.418.755 2.084 2.204.665 1.463.81 2.824.318 3.046zM41.11 37.809H22.89c-.55 0-1 .459-1 1.02 0 .562.45 1.035 1 1.035h18.22c.55 0 1-.473 1-1.035 0-.562-.45-1.02-1-1.02zM25.494 47.065c2.75 7.897 10.391 7.54 13.112-.281l2.258-6.476H23.136l2.358 6.757z" _fill="#ED1C24"/>'
  }
})

<template>
    <div class="services-detail">
        <div v-html="article.detail" class="u-bottom-margin--xl-desktop html-content"></div>

        <card
            v-if="article['key-results']"
            class="u-bottom-margin--xl-desktop"
        >
            <template v-slot:title>{{ article['key-results'].name }}</template>
            <template v-slot:content>
                <div class="row">
                    <div
                        v-for="(item, i) in article['key-results'].items"
                        :key="i"
                        class="col-default-3 col-tablet-6 col-mobile-12"
                    >
                        <z-key-indicator
                            :value="item.number"
                            :unit="item.unit"
                            :name="item.name"
                        ></z-key-indicator>
                    </div>
                </div>
            </template>
        </card>

        <template v-if="article.items.length">
            <h2>{{ title }}</h2>
            <div class="row">
                <div
                    v-for="(item, i) in article.items"
                    :key="i"
                    class="col-default-4 col-mobile-12"
                >
                    <card
                        :link="item.link"
                        overlay="rgba(54,54,54,0.6)"
                        theme="background"
                        :image="item.image"
                        padding="300px"
                        :mark="false"
                    >
                        <template v-slot:title>{{ item.name }}</template>
                        <template v-slot:bottom-right>
                            <img :src="item.icon">
                        </template>
                    </card>
                </div>
            </div>
        </template>

        <div class="row">
            <div class="col-default-7 col-mobile-12">
                <card
                    style="min-height: 400px"
                    v-if="article.contacts"
                    fullWidth
                >
                    <template v-slot:title>{{ titleContacts }}</template>
                    <template v-slot:content>
                        <div class="card__contacts">
                            <div class="card__contacts-row" v-if="article.contacts.address">
                                <span class="card__contacts-name">{{ titleAddress }}</span>
                                <span class="card__contacts-value">{{ article.contacts.address }}</span>
                            </div>
                            <div class="card__contacts-row" v-if="article.contacts.phones.length">
                                <span class="card__contacts-name">{{ titlePhone }}</span>
                                <span class="card__contacts-value">
                                    <span
                                        v-for="phone in article.contacts.phones"
                                        :key="phone.number"
                                    >
                                        {{ phone.number }}
                                    </span>
                                </span>
                            </div>
                            <div class="card__contacts-row" v-if="article.contacts.fax">
                                <span class="card__contacts-name">{{ titleFax }}</span>
                                <span class="card__contacts-value">{{ article.contacts.fax }}</span>
                            </div>
                            <div class="card__contacts-row" v-if="article.contacts.mail">
                                <span class="card__contacts-name">{{ titleEmail }}</span>
                                <span class="card__contacts-value"><z-link :href="'mailto:' + article.contacts.mail">{{ article.contacts.mail }}</z-link></span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" :href="'/' + $root.lang + '/contacts/'" kind="text">
                            {{ allContacts }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="grey"
                            size="l"
                        >
                            <z-icon name="lighthouse" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            <!-- <div class="col-default-5 col-mobile-12">
                <card
                    padding="400px"
                >
                    <template v-slot:title>{{ titleFeedback }}</template>
                    <template v-slot:content>
                        <p v-html="textFeedback"></p>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="red"
                            size="l"
                        >
                            <z-icon name="letter" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            -->
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { localize } from '@/utils/i18n'

export default {
    name: 'services-detail',
    props: {
        component: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            allContacts: localize({
                'ru': 'Все контакты',
                'en': 'All contacts',
                'cn': '所有联系方式'
            }),
            titleContacts: localize({
                'ru': 'Контакты',
                'en': 'Contacts',
                'cn': '联系方式'
            }),
            titleAddress: localize({
                'ru': 'Адрес:',
                'en': 'Address:',
                'cn': '地址:'
            }),
            titlePhone: localize({
                'ru': 'Тел.:',
                'en': 'Phone:',
                'cn': '电话:'
            }),
            titleFax: localize({
                'ru': 'Факс:',
                'en': 'Fax:'
            }),
            titleEmail: localize({
                'ru': 'Е-mail:',
                'en': 'Е-mail:',
                'cn': '电子邮件:'
            }),
            titleFeedback: localize({
                'ru': 'Форма обратной связи',
                'en': 'Feedback'
            }),
            textFeedback: localize({
                'ru': 'Напишите нам по E-mail',
                'en': 'E-mail us'
            }),
            title: localize({
                'ru': 'Терминалы',
                'en': 'Terminals',
                'cn': '码头'
            }),
            article: this.$root.app.components[this.component]
        }
    },
    methods: {
        ...mapActions(['SetHeroTitle', 'SetHeroSrc'])
    },
    created () {
        this.SetHeroTitle(this.article.name)
        this.SetHeroSrc(this.article.image)
    }
}
</script>

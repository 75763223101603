<template>
    <div class="about">
        <z-tabs v-if="about.length">
            <template v-slot:label>
                <z-tabs-label
                    v-for="tab in about"
                    :key="tab.code"
                    :id="tab.code"
                >{{ tab.name }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="tab in about"
                    :key="tab.code"
                    :id="tab.code"
                >
                    <div v-if="tab.detail.length" v-html="tab.detail" class="html-content"></div>
                    <card v-if="tab.indicators && tab.indicators.length">
                        <template v-slot:title>
                            {{ keyHighlights }}
                        </template>
                        <template v-slot:content>
                            <div v-for="(item, index) in tab.indicators" :key="index" class="card__content--highlights">
                                <div class="highlights-key">
                                    <span class="highlights-key--indicator">{{ item.indicator }}</span>
                                    <sub class="highlights-key--unit">{{ item.unit }}</sub>
                                </div>
                                <div class="highlights-name">{{ item.name }}</div>
                            </div>
                        </template>
                    </card>
                    <div class="results" v-if="tab.advantage">
                        <h2 v-if="tab.advantage.length">{{ results }}</h2>
                        <div class="results__items">
                            <div class="results__item" v-for="(advantage, index) in tab.advantage" :key="index">
                                <z-key-indicator
                                    :value="advantage.value"
                                    :name="advantage.name"
                                    :unit="advantage.unit"
                                    :prefix="advantage.prefix"
                                >
                                    <template v-slot:icon>
                                        <img :src="advantage.icon">
                                    </template>
                                </z-key-indicator>
                            </div>
                        </div>
                    </div>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'about',
    props: {
        component: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            about: this.$root.app.components[this.component],
            keyHighlights: localize({
                'ru': 'Ключевые показатели',
                'en': 'Key highlights',
                'cn': '主要指标'
            }),
            results: localize({
                'ru': 'Конкурентные преимущества',
                'en': 'Competitive advantages',
                'cn': '竞争优势'
            })
        }
    },
    methods: {

    },
    created () {

    }
}
</script>

<style lang="scss" src="./index.scss"></style>

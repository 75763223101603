/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cargo': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M60 32.42h-3.078v-1.428h.547V28.01h-.547v-.963h-.89v.963h-.563v2.982h.562v1.429H50.11v-3.417h-1.14v3.417h-1.681l-3.014 2.324h-.047v-.963h-5.181v.963h-5.024v-3.157h-.276v-3.83h-.434v.19l-2.78 1.829-2.896-1.83v-.189h-.464v3.83h-.177v3.157h-4.67V32.47h-4.066v2.274h-.58v-3.432h-.58v-2.125h1.16v-4.426h1.036v-.707h-5.251V17h-1.105v7.055h-.937V21.01H7.995v3.046H6.444v.707h1.13v9.983H5.86v-3.157H4.761v3.157H4l1.473 6.366h51.226L60 32.42zm-26.688-4.04v3.208h-.144l-2.264-1.59 2.409-1.618zm-.777 3.208H28.47l2.062-1.377 2.004 1.377zm-4.899-3.208l2.505 1.618-2.344 1.59h-.16V28.38zm.69 4.203h4.233v2.162h-4.233v-2.162zM7.995 18.977H12v.994H7.995v-.994zm14.297 11.47v1.224h-4.018v-1.225h-.518v-.692h5.054v.692h-.518zm-.689.005h-2.597v.56h2.597v-.56zM5.788 42.018h50.026c.953 0 1.724.995 1.724 2.223 0 1.227-.771 2.222-1.723 2.222H30.682v1.582H20.58v-1.582H7.2l-1.412-4.445z" _fill="#ED1C24"/>'
  }
})

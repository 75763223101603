<template>
    <div class="media-photos-detail">
        <z-not-found
            v-if="list.length === 0"
            :title="notFound.title"
            :text="notFound.text"
        ></z-not-found>
        <h3 class="detail__name">{{ data.name }}</h3>
        <div class="row" v-if="list.length" equal-height=".card">
            <div
                class="col-default-4 col-tablet-12"
                v-for="(item, i) in list"
                :key="i"
            >
                <template v-if="item.type === 'photo'">
                    <card
                        style="min-height: 272px"
                        class="ar-card"
                        :theme="item.preview ? 'background' : 'red'"
                        overlay="rgba(0,0,0,0.45)"
                        :mark="false"
                        :image="item.preview"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title v-if="item.name">{{item.name}}</template>
                        <template v-slot:content>
                            <span v-if="item.size && item.extension" class="card__file-info">{{item.size}}, {{item.extension}}</span>
                            <span v-if="item.cargo && item.cargo.length"><p class="detail__cargo-list"><span v-for="(tag,index) in item.cargo" :key="index">{{tag}}</span></p></span>
                        </template>
                        <template v-slot:bottom-left v-if="item.image">
                            <z-link
                                class="ar-card__icon u-inline"
                                :content="image.download"
                                :href="item.image.full"
                                downloadFile
                                :downloadTitle="item.name"
                                v-tippy-tooltip="{
                                    theme : 'transparent',
                                    arrow: true,
                                    placement: 'top-start'
                                }"
                            >
                                <z-icon name="download" width="48" height="48"></z-icon>
                            </z-link>
                            <z-link
                                class="ar-card__icon u-inline"
                                :content="image.preview"
                                href="#"
                                @click="$root.$bus.$emit('open-modal', {id: 'photosModal', index: i})"
                                v-tippy-tooltip="{
                                    theme : 'transparent',
                                    arrow: true,
                                    placement: 'top-start'
                                }"
                            >
                                <z-icon name="zoom-in" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                    </card>
                </template>
                <template v-else-if="item.type === 'video'">
                    <card
                        @click="$root.$bus.$emit('open-modal', {id: 'photosModal', index: i})"
                        :mark="false"
                        :overlay="item.image ? 'rgba(0,0,0,0.35)' : ''"
                        :theme="item.image ? 'background' : 'red'"
                        :image="item.image.preview"
                        padding="272px"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>
                            {{ item.name }}
                        </template>
                        <template v-slot:content>
                            <span v-if="item.count" class="card__file-info">{{item.count}}</span>
                            <span v-if="item.size && item.duration" class="card__file-info">{{item.size}} | {{item.duration}}</span>
                        </template>
                        <template v-slot:bottom-left v-if="item.type === 'video'">
                            <z-link
                                class="ar-card__icon u-inline"
                                @click="$root.$bus.$emit('open-modal', {id: 'videosModal', index: i})"
                                href="#"
                            >
                                <z-icon name="video" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                    </card>
                </template>
            </div>
        </div>
        <z-preloader v-if="loading"></z-preloader>
        <z-modal
            autoWidth
            id="photosModal"
            class="gallery-modal"
            download
            theme="grey"
            :data="slidesForModal"
        ></z-modal>
    </div>
</template>

<script>
import { getMediaDetail } from '@/api/media'
import { throttle } from 'throttle-debounce'
import { localize } from '@/utils/i18n'

export default {
    name: 'media-photos-detail',
    props: {
        initial: {
            type: Object
        },
        component: {
            type: String,
            required: true
        },
        url: {
            type: String,
            default: '/terminal/media-library'
        }
    },
    data () {
        return {
            loading: false,
            params: {},
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не найдено',
                    'en': 'No results found'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            },
            image: {
                download: localize({
                    'ru': 'Скачать изображение',
                    'en': 'Download image'
                }),
                preview: localize({
                    'ru': 'Посмотреть изображение',
                    'en': 'Preview image'
                })
            },
            data: this.$root.app.components[this.component],
            list: this.$root.app.components[this.component].items,
            page: this.$root.app.components[this.component].nav,
            code: this.$root.app.system.params.get.TERMINAL
        }
    },
    created () {
        this.params = {
            page: 1,
            code: this.code
        }

        if (!this.list.length) {
            this.notFound.state = true
        }

        this.listenScroll()
    },
    computed: {
        slidesForModal () {
            return this.list.map((el) => {
                if (el.type === 'photo') {
                    if (!el.image.hasOwnProperty('full')) {
                        el.image = {
                            preview: el.preview,
                            full: el.image
                        }
                    }
                } else if (el.type === 'video') {
                    if (!el.image.hasOwnProperty('full')) {
                        el.image = {
                            preview: el.image,
                            full: el.link
                        }
                    }
                }
                return el
            })
        }
    },
    methods: {
        getMoreMedia () {
            this.loading = true

            if (this.params.page === 1) this.list = []

            getMediaDetail(this.params, this.url).then((res) => {
                if (res.hasOwnProperty('nav')) {
                    this.page.current = res.nav.current
                    this.page.total = res.nav.total
                    this.page.count = res.nav.count
                }

                if (res.hasOwnProperty('items')) {
                    if (res.items.length === 0) {
                        this.notFound.state = true
                    } else {
                        this.notFound.state = false
                    }

                    if (this.page.current <= this.page.total) {
                        this.list = this.list.concat(res.items)
                    } else {
                        this.list = res.items
                    }
                }

                // This garbage code for the sake of IE
                let customEvent = document.createEvent('HTMLEvents')
                customEvent.initEvent('newCardsAdded', true, true)
                document.dispatchEvent(customEvent)

                this.loading = false
            })
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(500, () => {
                const offsetTop = this.$el.offsetTop
                const offsetHeight = this.$el.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (scrolled >= (bottom - 100) && !this.loading && this.page.current < this.page.total) {
                    this.params.page++
                    this.getMoreMedia()
                };
            }))
        }
    }
}
</script>

<style lang="scss">
.media-photos-detail {
    .detail {
        &__name {
            color: $token-colors-lighter-grey;
            margin-bottom: 24px;
        }

        &__cargo-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            & > span {
                font-size: 14px;
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }
    }

    .ar-card__icon {

        &:hover {
            svg {
                background-color: #fff;
            }

            [pid="0"] {
                fill: #fff !important;
            }
        }
    }

    .card__bottom-left {
        flex-basis: 100%;
    }
}
</style>

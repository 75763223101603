/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burger-menu': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _stroke="#7A7A7E" stroke-width="1.5" stroke-linecap="round" d="M23.25 4.75H.75M23.25 12.75H.75M23.25 20.75H.75"/>'
  }
})

<template src="./index.html"></template>

<script>
export default {
    name: 'z-caption',
    props: {
        tag: {
            type: String,
            default: 'p',
            validator: prop => ['span', 'p', 'caption', 'figcaption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(prop)
        },
        size: {
            type: String,
            default: 'm',
            validator: prop => ['xs', 's', 'm', 'l', 'xl'].includes(prop)
        },
        weight: {
            type: String,
            default: '700',
            validator: prop => ['100', '200', '300', '400', '500', '600', '700', '800', '900'].includes(prop)
        },
        color: {
            type: String,
            default: 'black'
        },
        uppercase: {
            type: Boolean
        },
        italic: {
            type: Boolean
        }
    },
    computed: {
        classObject: function () {
            let arrClass = [
                {
                    'u-italic': this.italic,
                    'u-uppercase': this.uppercase
                }
            ]

            if (this.size) {
                arrClass.push(`z-caption--size-${this.size}`)
            }
            if (this.color) {
                arrClass.push(`z-caption--color-${this.color}`)
            }
            if (this.weight) {
                arrClass.push(`z-caption--weight-${this.weight}`)
            }

            return arrClass
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

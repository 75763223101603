<template>
    <div
        :class="[
            'special-equipment-card',
            { 'special-equipment-card--sold' : data.sold }
        ]"
        v-if="data"
    >
        <div class="special-equipment-card__helper" :style="`background-image: url(${data.image})`"></div>
        <div class="special-equipment-card__header">
            <h4 class="special-equipment-card__title" v-html="data.name"></h4>
            <span class="special-equipment-card__tag" v-if="data.sold">{{ text.sold }}</span>
        </div>
        <div class="special-equipment-card__content">
            <template v-if="!data.sold">
                <p class="special-equipment-card__prop" v-html="text.terminal"></p>
                <p class="special-equipment-card__value" v-html="data.terminal"></p>
                <p class="special-equipment-card__prop" v-html="text.request">Приём заявок до:</p>
                <p class="special-equipment-card__value">{{ data.date | momentFilter('DD/MM/YYYY') }}</p>
                <p class="special-equipment-card__prop" v-html="text.currentPrice"></p>
                <p class="special-equipment-card__value" v-html="data.price"></p>
            </template>
            <template v-else>
                <p class="special-equipment-card__prop" v-html="text.totalPrice"></p>
                <p class="special-equipment-card__value" v-html="data.price"></p>
            </template>
        </div>
        <div class="special-equipment-card__footer">
            <z-button tag="a" :href="data.link" kind="text" theme="white">
                {{ text.more }}
                <template v-slot:right>
                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                </template>
            </z-button>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'special-equipment-card',
    props: {
        data: Object
    },
    computed: {
        styleObject () {
            return {
                backgroundImage: `url(${this.data.image})`
            }
        }
    },
    data () {
        return {
            text: {
                more: localize({
                    ru: 'Подробнее',
                    en: 'Learn more'
                }),
                sold: localize({
                    ru: 'Продано',
                    en: 'Sold'
                }),
                terminal: localize({
                    ru: 'Терминал:',
                    en: 'Terminal:'
                }),
                request: localize({
                    ru: 'Приём заявок до:',
                    en: 'Acceptance of applications before:'
                }),
                currentPrice: localize({
                    ru: 'Текущая цена:',
                    en: 'Current price:'
                }),
                totalPrice: localize({
                    ru: 'Итоговая цена:',
                    en: 'Total price:'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.special-equipment-card {
    padding: $token-spacers-desktop-xl;
    color: $token-colors-white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        background: $token-colors-black;
        opacity: 0.6;
        top: 0;
        left: 0;
        position: absolute;
        pointer-events: none;
        z-index: 1;
    }

    &--sold {
        .special-equipment-card__helper {
            opacity: 0.6;
        }
    }

    &__helper {
        position: absolute;
        background-size: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
    }

    &__tag {
        font-size: 16px;
        background: $token-colors-gp-red;
        padding: 1px 6px;
        margin-bottom: $token-spacers-mobile-l;
        display: inline-block;
    }

    &__header,
    &__footer {
        flex-shrink: 0;
        flex-grow: 0;
        z-index: 2;
    }

    &__footer {
        padding-top: $token-spacers-desktop-xl;
    }

    &__content {
        flex-grow: 1;
        z-index: 2;
    }

    &__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: $token-spacers-mobile-l;
        color: inherit;
        position: relative;
        padding-top: 6px;
        margin-top: 0;

        &:before {
            content: '';
            height: 0.25rem;
            width: 2rem;
            position: absolute;
            top: 0;
            left: 0;
            background: $token-colors-white;
        }
    }

    &__prop {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 5px;
    }

    &__value {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 700;
        margin-bottom: 10px;
    }
}
</style>

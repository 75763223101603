<template>
    <div
        class="z-accordion-item"
        :class="{'z-accordion-item--opened': state}"
    >
        <a
            @click="toggleItem($event)"
            :href="`#${itemId}`"
            class="z-accordion-item__header"
        >
            <span class="z-accordion-item__header-button">
                <slot name="icon"><span>v</span></slot>
            </span>
            <div class="z-accordion-item__header-title">
                <slot name="header" />
            </div>
        </a>
        <transition
            @enter="animationEnter"
            @leave="animationLeave"
        >
            <div v-show="state" class="z-accordion-item__body">
                <slot name="body" />
            </div>
        </transition>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'
export default {
    name: 'z-accordion-item',
    props: {
        id: {
            type: String,
            default: '',
            required: true
        }
    },
    data () {
        return {
            state: false,
            duration: 300,
            name: 'z-accordion-item',
            itemId: `accordion-${this.id}`,
            itemChildrens: this.$children
        }
    },
    methods: {
        toggleItem (e) {
            this.state = !this.state

            if (!this.state) e.preventDefault()

            this.$parent.$emit('toggle-item', {
                itemId: this.itemId,
                itemChildrens: this.itemChildrens
            })
        },
        animationEnter (el, done) {
            Velocity(el, 'slideDown', { duration: this.duration })
        },
        animationLeave (el, done) {
            Velocity(el, 'slideUp', { duration: this.duration })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"z-list",class:[
        `z-list--size-${_vm.size}`,
        `z-list--theme-${_vm.theme}`,
        {
            'z-list--nested': _vm.nested,
            'z-list--no-bullets': !_vm.bullets
        }
    ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'appstore': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M32.25 13.5c.61 0 1.125.234 1.594.656.422.469.656.985.656 1.594v16.5c0 .656-.234 1.172-.656 1.594-.469.468-.985.656-1.594.656h-16.5c-.656 0-1.172-.188-1.594-.656-.469-.422-.656-.938-.656-1.594v-16.5c0-.61.188-1.125.656-1.594a2.155 2.155 0 011.594-.656h16.5zM19.453 30l.89-1.547c-.468-.562-1.077-.75-1.827-.515l-.703 1.171c-.141.235-.188.47-.094.703.093.282.234.47.468.563.188.14.422.188.704.094.234-.047.422-.188.562-.469zm6.516-2.484c.187-.375.187-.797-.047-1.22-.235-.421-.563-.655-1.031-.655h-2.907l4.032-6.985c.093-.187.14-.422.093-.703a1.095 1.095 0 00-.468-.562c-.235-.094-.47-.141-.703-.094-.282.094-.47.234-.563.422l-.422.703-.422-.703a1.279 1.279 0 00-.562-.422c-.282-.047-.516 0-.703.094-.235.14-.375.328-.422.562-.094.281-.047.516.093.703l.938 1.64-3.047 5.345h-2.39c-.282 0-.47.093-.657.28-.187.188-.281.376-.281.657s.094.469.281.656c.188.188.375.282.657.282h8.53zm4.593 0a.926.926 0 00.657-.282c.187-.187.281-.375.281-.656s-.094-.469-.281-.656a.926.926 0 00-.657-.281h-2.437l-2.766-4.782c-.375.282-.562.657-.656 1.22-.14.702-.047 1.358.328 1.921l3.469 6.047c.094.234.281.375.563.422.234.093.468.047.703-.094.187-.094.328-.281.422-.563.093-.234.046-.468-.047-.75l-.938-1.546h1.36z" _fill="#ED1C24"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file_filled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.827 1.5H20.5v21h-18V5.695L7.827 1.5zm.346 1L3.5 6.18V21.5h16v-19H8.173zM6 10.625a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5z" _fill="#7A7A7E"/>'
  }
})

<template>
    <tippy-tooltip
        class="z-tooltip"
        :class="{'z-tooltip--click': trigger === 'click'}"
        :trigger="trigger"
        :theme="theme"
        :arrow="arrow"
        :placement="placement"
    >
        <template v-slot:trigger>
            <slot />
            <z-icon
                name="tooltip"
                width="12"
                height="12"
                class="z-tooltip__icon"
                :class="{'z-tooltip__icon--inverted': inverted}"
                v-if="!iconHidden"
            />
        </template>
        <span v-html="content"></span>
    </tippy-tooltip>
</template>

<script>
export default {
    name: 'z-tooltip',
    props: {
        text: {
            type: String
        },
        theme: {
            type: String,
            default: 'grey'
        },
        arrow: {
            type: Boolean,
            default: true
        },
        placement: {
            type: String,
            default: 'top-start'
        },
        content: {
            type: String,
            required: true
        },
        inverted: {
            type: Boolean
        },
        trigger: {
            type: String,
            default: 'mouseenter'
        },
        iconHidden: {
            type: Boolean
        }
    }
}
</script>

<style lang="scss">
.z-tooltip {
    display: inline-block;

    > div {
        outline: 0;
    }

    &--click {
        cursor: pointer;
    }

    &__icon {
        vertical-align: super;

        [pid='0'] {
            fill: #c4c4c4;
        }

        &--inverted {
            [pid='0'] {
                fill: white;
            }
        }
    }

    &--block {
        display: inline-block;

        > span {
            display: inline-block;
        }
    }

    > span {
        outline: 0;
    }
}
</style>

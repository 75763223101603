<template>
    <div class="disclosure-documents">
        <div class="u-bottom-margin--double" v-if="iframeLinks[terminalName] && $root.lang === 'ru'">
            <z-link
                href="javascript:void(0)"
                @click="$root.$bus.$emit('open-modal', {id: 'iframeTerminalModal', index: 0})"
            >Реестр заявок на заключение договоров</z-link>

            <z-modal id="iframeTerminalModal">
                <iframe
                    id="first"
                    :src="iframeLinks[terminalName]"
                    style="background: none; border: medium; width: 100%; height: 500px;"
                ></iframe>
            </z-modal>
        </div>

        <div class="disclosure-documents__content" v-for="(item, index) in documents" :key="index">
            <z-lead v-html="item.detail"></z-lead>
            <z-accordion v-if="item.sections.length">
                <z-accordion-item
                    v-for="section in item.sections"
                    :key="section.code"
                    :id="section.code"
                >
                    <template v-slot:icon>
                        <z-icon name="arrow" dir="down" height="16"></z-icon>
                    </template>
                    <template v-slot:header>
                        <span v-html="section.name"></span>
                    </template>
                    <template v-slot:body>
                        <p v-if="section.detail" v-html="section.detail"></p>
                        <z-filelist
                            v-if="section.items.length"
                        >
                            <z-filelist-item
                                v-for="(file, index) in section.items"
                                :key="index"
                                icon="file_filled"
                                :date="file.date"
                                :info="`${file.size}, ${file.extension}`"
                            >
                                <z-link
                                    :href="file.link"
                                    :downloadTitle="file.name"
                                    downloadFile
                                >{{ file.name }}</z-link>
                            </z-filelist-item>
                        </z-filelist>
                    </template>
                </z-accordion-item>
            </z-accordion>
        </div>
    </div>
</template>

<script>
export default {
    name: 'disclosure-documents',
    props: {
        terminalName: String,
        component: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            documents: this.$root.app.components[this.component],
            iframeLinks: {
                'first-container-terminal': 'https://www.rlisystems.ru/conclusion-contract/reestr/PRIMSYB',
                'ust-luga-container-terminal': 'https://www.rlisystems.ru/conclusion-contract/reestr/ULCT',
                'petrolesport': 'https://www.rlisystems.ru/conclusion-contract/reestr/PLP',
                'moby-dik': 'https://www.rlisystems.ru/conclusion-contract/reestr/MD',
                'vostochnaya-stevedoring-company': 'https://www.rlisystems.ru/conclusion-contract/reestr/VSC'
            }
        }
    }
}
</script>

<style lang="scss">
    .disclosure-documents {
        &__content {
            padding-bottom: 2.5rem;
        }
    }
</style>

<template>
    <div class="shareholders-structure">
        <div class="container">
            <div class="row">
                <div class="page__aside col-default-3 col-tablet-hide">
                    <navigation-left></navigation-left>

                    <card
                        v-if="databook"
                        style="min-height: 250px"
                        class="u-bottom-margin--l-desktop"
                    >
                        <template v-slot:title>{{ databook.name }}</template>
                        <template v-slot:content>
                            <span class="card__file-info">{{ databook.size }}, {{ databook.extension }}</span>
                        </template>
                        <template v-slot:bottom-left>
                            <z-link :href="databook.link" target="_blank">
                                <z-icon name="download" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="grey"
                                size="m"
                            >
                                <z-icon name="databank" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>

                <main class="content page__main col-default-9 col-tablet-12">
                    <z-lead>Strong and experienced controlling shareholder&nbsp;— Delo Group</z-lead>
                        <p class="ownership__text">Delo Group is&nbsp;the major Russian transportation and logistics holding owning and operating port container terminals in&nbsp;the Azov and Black Sea, Baltic and Far-Eastern basins, with a&nbsp;wide network of&nbsp;rail-container terminals, a&nbsp;fleet of&nbsp;containers and flatcars.</p>

                    <h2 class="u-top-margin--xl-desktop u-bottom-margin--m-desktop">Ownership structure</h2>

                    <z-chart
                        @mounted="getStructureChart"
                        :options='structureOptions'
                        :legend='{
                            position: "right-middle"
                        }'
                    ></z-chart>

                    <!-- <div class="ownership__text">
                        <p class="u-normal">APM Terminals operate a&nbsp;network of&nbsp;74&nbsp;terminals globally. APM Terminals is&nbsp;a&nbsp;part of&nbsp;A.P. Moller-Maersk, the world&rsquo;s largest integrator of&nbsp;container and ports logistics.</p>
                        <p class="u-normal">Delo Group is&nbsp;one of&nbsp;the largest private transportation and logistics holding companies in&nbsp;Russia. The Group offers a&nbsp;full range of&nbsp;services in&nbsp;the port of&nbsp;Novorossiysk, including stevedoring, tug boats and vessels bunkering (DeloPorts). Delo Group also offers multimodal freight forwarding services using own inland terminals, warehouses, flatcars (RUSCON). Delo Group operates two marine terminals and five inland terminals and employs a&nbsp;workforce of&nbsp;over 2,000&nbsp;people.</p>
                        <p class="u-normal">Ilibrinio Establishment Limited and Polozio Enterprises Limited (former owners of&nbsp;NCC&nbsp;Group) each own&nbsp;9% of&nbsp;the share capital of&nbsp;Global Ports.</p>
                    </div> -->
                    <h2 class="u-top-margin--xl-desktop u-bottom-margin--m-desktop">Shareholder structure</h2>
                    <z-table
                        :columns="['', 'Number of Ordinary Voting Shares', 'Percentage']"
                        financial
                    >
                        <z-table-row>
                            <z-table-col index="0" style="width: 270px">LLC Management Company Delo<br>LLC Delo-Center<br>LLC TransTerminal-Holding</z-table-col>
                            <z-table-col index="1" class="gp-red-text">352,500,000</z-table-col>
                            <z-table-col index="2">61.50%</z-table-col>
                        </z-table-row>
                        <!-- <z-table-row>
                            <z-table-col index="0">APM Terminals BV</z-table-col>
                            <z-table-col index="1" class="gp-red-text">176,250,000</z-table-col>
                            <z-table-col index="2">30.75%</z-table-col>
                            <z-table-col index="3">126,814,024</z-table-col>
                            <z-table-col index="4">29.99%</z-table-col>
                            <z-table-col index="5">49,435,976</z-table-col>
                            <z-table-col index="6">32.86%</z-table-col>
                        </z-table-row> -->
                        <z-table-row>
                            <z-table-col index="0">Ilibrino Establishment Limited</z-table-col>
                            <z-table-col index="1" class="gp-red-text">51,585,365</z-table-col>
                            <z-table-col index="2">9.00%</z-table-col>
                        </z-table-row>
                        <z-table-row>
                            <z-table-col index="0">Polozio Enterprises Limited</z-table-col>
                            <z-table-col index="1" class="gp-red-text">51,585,365</z-table-col>
                            <z-table-col index="2">9.00%</z-table-col>
                        </z-table-row>
                        <z-table-row>
                            <z-table-col index="0">Free-float</z-table-col>
                            <z-table-col index="1" class="gp-red-text">117,500,001</z-table-col>
                            <z-table-col index="2">20.50%</z-table-col>
                        </z-table-row>
                        <z-table-row isTotal>
                            <z-table-col index="0"></z-table-col>
                            <z-table-col index="1" class="gp-red-text">573,170,731</z-table-col>
                            <z-table-col index="2">100%</z-table-col>
                        </z-table-row>
                    </z-table>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

const structureOptions = {
    clipPath: false,
    size: {
        height: 450
    },
    data: {
        columns: [
            ['Free-float-LSE-listing', 20.5],
            ['Delo Group', 61.5],
            ['Ilibrinio Establishment Ltd', 9],
            ['Polozio Enterprises Ltd', 9]
        ],
        type: 'donut'
    },
    padding: {
        top: 24,
        right: 24,
        bottom: 24,
        left: 24
    },
    donut: {
        padAngle: 0.025,
        label: {
            ratio: 1.25,
            format: function (value, ratio, id) {
                return value + '%'
            }
        },
        width: 20
    },
    tooltip: {
        format: {
            name: function (x) {
                var newX = x
                if (newX === 'Free-float-LSE-listing') newX = 'Free-float'
                return newX
            },
            value: function (value, ratio, id) {
                return value + '%'
            }
        }
    },
    legend: {
        contents: {
            template: function (id, color, data) {
                var newTitle = id
                if (newTitle === 'Free-float-LSE-listing') newTitle = 'Free-float'

                return '<span class="z-chart__legend-item">' +
                    '<span class="z-chart__legend-square" style="background-color:' + color + '"></span>' +
                    '<span class="z-chart__legend-title">' + newTitle + '</span>' +
                '</span>'
            }
        },
        item: {
            onclick: function (id, par) {
                this.api.focus(id)
            }
        }
    }
}

export default {
    name: 'shareholders-structure',
    mixins: [mixinDevice],
    data () {
        return {
            lang: 'en',
            databook: this.$root.app.components['shareholder-structure-databook'],
            structureOptions,
            structureChart: null
        }
    },
    methods: {
        getStructureChart (chart) {
            this.structureChart = chart
        },
        setRatio (value) {
            return this.structureChart.config('donut.label.ratio', value, true)
        }
    },
    watch: {
        device (value) {
            if (this.structureChart !== null) {
                if (value === 'v-mobile') {
                    this.setRatio(0.7)
                } else {
                    this.setRatio(1.25)
                }
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

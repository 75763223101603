/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dots': {
    width: 16,
    height: 4,
    viewBox: '0 0 16 4',
    data: '<circle pid="0" cx="2" cy="2" r="2" _fill="#ED1C24"/><circle pid="1" cx="8" cy="2" r="2" _fill="#ED1C24"/><circle pid="2" cx="14" cy="2" r="2" _fill="#ED1C24"/>'
  }
})

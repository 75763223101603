<template>
    <div class="terminals-detail">
        <div class="container">
            <div class="row">
                <div class="page__aside col-default-3 col-tablet-hide">
                    <navigation-left></navigation-left>

                    <card
                        theme="red"
                        style="min-height: 250px"
                        class="u-bottom-margin--l-desktop"
                    >
                        <template v-slot:title>
                            <p class="u-bottom-margin--xl-desktop">{{ text.portalTitle }}</p>
                        </template>
                        <template v-slot:bottom-left>
                            <z-link theme="white" href="https://globalports.rlisystems.ru/" external target="_blank">
                                <span v-html="text.signin"></span>
                            </z-link>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="white"
                                size="m"
                            >
                                <z-icon name="man" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <main class="content page__main col-default-9 col-tablet-12">
                    <navigation-left-tablet></navigation-left-tablet>

                    <article>
                        <div class="row terminals-detail__records" v-if="article.records">
                            <div class="col-default-4 col-mobile-12" v-if="article.records.about">
                                <span class="terminals-detail__records-name">{{ text.about }}</span>
                                <span class="terminals-detail__records-detail" v-html="article.records.about"></span>
                            </div>
                            <div class="col-default-4 col-mobile-12" v-if="article.records.location">
                                <span class="terminals-detail__records-name">{{ text.location }}</span>
                                <span class="terminals-detail__records-detail" v-html="article.records.location"></span>
                            </div>
                            <div class="col-default-4 col-mobile-12" v-if="article.records['start-work']">
                                <span class="terminals-detail__records-name">{{ text.startOperations }}</span>
                                <span class="terminals-detail__records-detail" v-html="article.records['start-work']"></span>
                            </div>
                        </div>

                        <div class="row u-bottom-margin--l-desktop">
                            <div class="col-default-8 col-tablet-12">
                                <z-lead v-html="article.detail" class="html-content"></z-lead>
                            </div>
                            <div class="col-default-4 col-tablet-12">
                                <div class="terminals-detail__key-indicators" v-if="article.indicators">
                                    <z-key-indicator
                                        class="u-bottom-margin--l-desktop"
                                        v-for="(item, i) in article.indicators.items"
                                        :key="i"
                                        :value="item.indicator"
                                        :unit="item.unit"
                                        :name="item.name"
                                    ></z-key-indicator>
                                    <z-button
                                        v-if="article.button"
                                        rounded
                                        tag="a"
                                        :href="article.indicators.link"
                                    >
                                        {{ text.more }}
                                        <z-icon style="margin-left: 1rem" name="arrow" dir="right" color="#fff" width="16" height="16"></z-icon>
                                    </z-button>
                                </div>
                                <card
                                    class="card--ecologic"
                                    v-if="article.ecologic"
                                    style="min-height: 250px"
                                >
                                    <template v-slot:title><p class="u-bottom-margin--xxl-desktop">{{ text.ecologic }}</p></template>
                                    <template v-slot:bottom-left v-if="lang !== 'cn'">
                                        <z-button tag="a" :href="article.ecologic" kind="text">
                                            {{ text.more }}
                                            <template v-slot:right>
                                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                            </template>
                                        </z-button>
                                    </template>
                                    <template v-slot:bottom-right>
                                        <card-icon
                                            theme="red"
                                            size="m"
                                        >
                                            <z-icon name="eco" width="64" height="64"></z-icon>
                                        </card-icon>
                                    </template>
                                </card>
                            </div>
                        </div>

                        <template v-if="article.services.length">
                            <h2>{{ text.services }}</h2>
                            <div class="row" equal-height=".card">
                                <div
                                    v-for="(item, i) in article.services"
                                    :key="i"
                                    class="col-mobile-12"
                                    :class="buildClass(item)"
                                >
                                    <card
                                        :link="item.main ? '' : item.link"
                                        overlay="rgba(0,0,0,0.35)"
                                        theme="background"
                                        :image="item.image"
                                        style="min-height: 250px"
                                        equal-height-max-breakpoint="mobile"
                                    >
                                        <template v-slot:title>
                                            {{ item.name }}
                                        </template>
                                        <template v-slot:content>
                                            <div style="min-height: 50px">{{ item.detail }}</div>
                                        </template>
                                        <template v-slot:bottom-left v-if="item.main">
                                            <z-button tag="a" :href="item.link" kind="text" theme="white">
                                                {{ text.more }}
                                                <template v-slot:right>
                                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                                </template>
                                            </z-button>
                                        </template>
                                        <template v-slot:bottom-right>
                                            <card-icon
                                                theme="white"
                                                size="m"
                                            >
                                                <div><img :src="item.icon" /></div>
                                            </card-icon>
                                        </template>
                                    </card>
                                </div>
                            </div>
                        </template>

                        <div class="row" v-if="article.contacts || article.news" equal-height=".card">
                            <div class="col-default-6 col-mobile-12" v-if="article.news.items.length">
                                <card
                                    style="min-height: 500px"
                                    equal-height-max-breakpoint="mobile"
                                >
                                    <template v-slot:title>{{ text.news }}</template>
                                    <template v-slot:content>
                                        <div class="card-news">
                                            <div
                                                v-for="(item, i) in article.news.items"
                                                :key="i"
                                                class="card-news__item"
                                            >
                                                <z-link
                                                    :href="item.link"
                                                    class="card-news__link"
                                                    :underline="false"
                                                    theme="dark-grey"
                                                    :size="i == 0 ? 'xl' : 'l'"
                                                >
                                                    <span v-html="item.name"></span>
                                                </z-link>
                                                <span class="card__date">
                                                    {{ item.date | momentFilter('DD MMMM YYYY') }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:bottom-left>
                                        <z-button tag="a" :href="article.news.link" kind="text">
                                            {{ text.newsAll }}
                                            <template v-slot:right>
                                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                            </template>
                                        </z-button>
                                    </template>
                                </card>
                            </div>
                            <div class="col-default-6 col-mobile-12" v-if="article.contacts">
                                <card
                                    style="min-height: 500px"
                                    equal-height-max-breakpoint="mobile"
                                    fullWidth
                                >
                                    <template v-slot:title>{{ text.contacts }}</template>
                                    <template v-slot:content>
                                        <div class="card__contacts">
                                            <div class="card__contacts-row" v-if="article.contacts.address">
                                                <span class="card__contacts-name">{{ text.address }}</span>
                                                <span class="card__contacts-value">{{ article.contacts.address }}</span>
                                            </div>
                                            <div class="card__contacts-row" v-if="article.contacts.phones.length">
                                                <span class="card__contacts-name">{{ text.phone }}</span>
                                                <span class="card__contacts-value">
                                                    <span
                                                        v-for="phone in article.contacts.phones"
                                                        :key="phone.number"
                                                    >
                                                        {{ phone.number }}
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="card__contacts-row" v-if="article.contacts.fax">
                                                <span class="card__contacts-name">{{ text.fax }}</span>
                                                <span class="card__contacts-value">{{ article.contacts.fax }}</span>
                                            </div>
                                            <div class="card__contacts-row" v-if="article.contacts.mail">
                                                <span class="card__contacts-name">{{ text.email }}</span>
                                                <span class="card__contacts-value"><z-link :href="'mailto:' + article.contacts.mail">{{ article.contacts.mail }}</z-link></span>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:bottom-left v-if="lang !== 'cn'">
                                        <z-button tag="a" :href="article.contacts.link" kind="text">
                                            {{ text.contactsAll }}
                                            <template v-slot:right>
                                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                            </template>
                                        </z-button>
                                    </template>
                                    <template v-slot:bottom-right>
                                        <card-icon
                                            theme="grey"
                                            size="l"
                                        >
                                            <z-icon name="lighthouse" width="64" height="64"></z-icon>
                                        </card-icon>
                                    </template>
                                </card>
                            </div>
                        </div>
                    </article>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { localize } from '@/utils/i18n'

export default {
    name: 'terminals-detail',
    data () {
        return {
            lang: this.$root.lang,
            text: {
                portalTitle: localize({
                    'ru': 'Единый клиентский портал',
                    'en': 'Client portal',
                    'cn': '统一客户平台'
                }),
                signin: localize({
                    'ru': 'Войти в&nbsp;личный кабинет',
                    'en': 'Sign in',
                    'cn': '登入个人帐户'
                }),
                portalText: localize({
                    'ru': 'Подать электронную заявку, отследить и&nbsp;подписать документы электронной подписью',
                    'en': 'Submit an&nbsp;electronic application, track and sign documents electronically'
                }),
                online: localize({
                    'ru': 'Online сервисы',
                    'en': 'Interactive tools'
                }),
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more',
                    'cn': '了解详情'
                }),
                about: localize({
                    'ru': 'О компании',
                    'en': 'About',
                    'cn': '关于公司'
                }),
                location: localize({
                    'ru': 'Расположение',
                    'en': 'Location',
                    'cn': '地理位置'
                }),
                startOperations: localize({
                    'ru': 'Начало работы',
                    'en': 'Start of operations',
                    'cn': '正式运营'
                }),
                ecologic: localize({
                    'ru': 'ЭКО-терминал',
                    'en': 'ECO-terminal',
                    'cn': '绿色环保港口'
                }),
                services: localize({
                    'ru': 'Услуги терминала',
                    'en': 'Services',
                    'cn': '港口服务'
                }),
                news: localize({
                    'ru': 'Новости терминала',
                    'en': 'News',
                    'cn': '港口新闻'
                }),
                newsAll: localize({
                    'ru': 'Все новости',
                    'en': 'All news',
                    'cn': '所有新闻'
                }),
                contacts: localize({
                    'ru': 'Контакты',
                    'en': 'Сontacts',
                    'cn': '联系方式'
                }),
                contactsAll: localize({
                    'ru': 'Все контакты',
                    'en': 'All contacts',
                    'cn': '所有联系方式'
                }),
                address: localize({
                    'ru': 'Адрес:',
                    'en': 'Address:',
                    'cn': '地址:'
                }),
                phone: localize({
                    'ru': 'Тел.:',
                    'en': 'Phone:',
                    'cn': '电话:'
                }),
                fax: localize({
                    'ru': 'Факс:',
                    'en': 'Fax:',
                    'cn': '传真:'
                }),
                email: localize({
                    'ru': 'Е-mail:',
                    'en': 'Е-mail:',
                    'cn': '邮箱:'
                })
            },
            article: this.$root.app.components['terminals-detail']
        }
    },
    methods: {
        ...mapActions(['SetHeroTitle', 'SetHeroSrc', 'SetTerminalLogo']),
        buildClass (item) {
            return item.main ? 'col-default-8' : 'col-default-4'
        }
    },
    created () {
        this.SetHeroTitle(this.article.name)
        this.SetHeroSrc(this.article.image)
        this.SetTerminalLogo(this.article.icon)
    }
}
</script>

<style lang="scss">
    .terminals-detail {
        &__records {
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: 2px solid #e4e4e4;

            &-name {
                font-weight: bold;
                font-size: 24px;
                line-height: 1.4;
                color: #363636;
                display: block;
            }

            &-detail {
                font-size: 18px;
                line-height: 1.5;
                display: block;
                color: #7A7A7E;
                margin-top: 1rem;
            }
        }

        &__key-indicators {
            margin-bottom: 2rem;
        }

        .card--ecologic .card__title:before {
            content: none;
        }

        .card-news {
            &__item {
                &:first-child {
                    margin-bottom: 2.5rem;
                    padding-bottom: 2.5rem;
                    border-bottom: 2px solid #E4E4E4;

                    .card__date {
                        font-size: 14px;
                    }
                }

                + .card-news__item {
                    margin-top: 1rem;
                }
            }

            .z-link {
                &--size-xl {
                    font-size: 22px;
                    font-weight: normal;
                }
            }

            .card__date {
                font-size: 12px;
                margin-top: 0.5rem;
            }
        }
    }
</style>

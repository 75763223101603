/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M25.657 14.44A2 2 0 0126.91 14h18.472a2 2 0 012 2v32a2 2 0 01-2 2h-26a2 2 0 01-2-2V22.046a2 2 0 01.748-1.559l7.527-6.046z" _fill="#ED1C24"/><path pid="1" d="M23.559 28.85h17.647m-17.647 5.4h17.647m-17.647 5.4h17.647" _stroke="#fff" stroke-width="2" stroke-linecap="round"/>'
  }
})

<template>
    <div class="news__filter">
        <span class="news__filter-title">
            <span v-if="!searchOpened">{{ text.filter }}</span>
            <span v-else>{{ text.search }}</span>
        </span>
        <div class="news__filter-container">
            <div class="news__filter-box" v-show="!searchOpened">
                <z-select-multi
                    name="cargo"
                    ref="vmCargo"
                    @change="filtersChange"
                    :data="data.cargo"
                    :placeholder="text.placeholders.cargo"
                >
                    <template v-slot:icon-arrow>
                        <z-icon name="arrow" dir="down" width="16" height="16"></z-icon>
                    </template>
                    <template v-slot:icon-clear>
                        <z-icon name="close" width="12" height="12"></z-icon>
                    </template>
                </z-select-multi>
                <z-select-multi
                    v-if="data.enterprises"
                    name="enterprises"
                    ref="vmEnterprises"
                    @change="filtersChange"
                    :data="data.enterprises"
                    :placeholder="text.placeholders.enterprises"
                >
                    <template v-slot:icon-arrow>
                        <z-icon name="arrow" dir="down" width="16" height="16"></z-icon>
                    </template>
                    <template v-slot:icon-clear>
                        <z-icon name="close" width="12" height="12"></z-icon>
                    </template>
                </z-select-multi>
                <z-select-multi
                    v-if="data.materials"
                    name="materials"
                    ref="vmMaterials"
                    @change="filtersChange"
                    :data="data.materials"
                    :placeholder="text.placeholders.materials"
                >
                    <template v-slot:icon-arrow>
                        <z-icon name="arrow" dir="down" width="16" height="16"></z-icon>
                    </template>
                    <template v-slot:icon-clear>
                        <z-icon name="close" width="12" height="12"></z-icon>
                    </template>
                </z-select-multi>
                <z-button
                    v-if="isFiltersActive"
                    kind="text"
                    theme="grey"
                    @click="filtersReset"
                    class="news__reset"
                >{{ text.reset }}</z-button>
            </div>
            <div class="news__search-box" v-show="searchOpened">
                <z-icon
                    class="news__search-submit"
                    @click="filtersChange"
                    name="search"
                    width="24px"
                    height="24px"
                ></z-icon>
                <z-input
                    name="name"
                    v-model="name"
                    :value="data.name"
                    :placeholder="text.placeholders.name"
                    @change="filtersChange({
                        name: 'name',
                        value: name
                    })"
                />
                <z-icon
                    class="news__search-close"
                    @click="searchOpened = false"
                    name="close"
                    width="24px"
                    height="24px"
                ></z-icon>
            </div>
            <div
                class="news__search-icon"
                v-show="!searchOpened"
                @click="searchOpened = true"
            >
                <z-icon
                    class="news__search-toggle"
                    name="search"
                    width="24px"
                    height="24px"
                ></z-icon>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'media-filters',
    props: {
        data: Object
    },
    data () {
        return {
            name: this.data.name,
            text: {
                filter: localize({
                    'ru': 'Фильтровать',
                    'en': 'Filter'
                }),
                search: localize({
                    'ru': 'Искать',
                    'en': 'Search'
                }),
                reset: localize({
                    'ru': 'Сбросить фильтры',
                    'en': 'Reset'
                }),
                placeholders: {
                    name: localize({
                        'ru': 'Введите название медиа',
                        'en': 'Enter the name of the media'
                    }),
                    cargo: localize({
                        'ru': 'По типу груза',
                        'en': 'By cargo'
                    }),
                    enterprises: localize({
                        'ru': 'По предприятиям',
                        'en': 'By enterprises'
                    }),
                    materials: localize({
                        'ru': 'По типу материала',
                        'en': 'Select materials'
                    })
                }
            },
            searchOpened: false
        }
    },
    computed: {
        isFiltersActive () {
            return !!((this.name.length || this.cargo.length || this.materials.length || this.isEnterprises()))
        },
        cargo () {
            if (this.data.cargo) {
                return this.data.cargo.filter(obj => obj.selected).map(obj => obj.id)
            }
        },
        enterprises () {
            if (this.data.enterprises) {
                return this.data.enterprises.filter(obj => obj.selected).map(obj => obj.id)
            }
        },
        materials () {
            if (this.data.materials) {
                return this.data.materials.filter(obj => obj.selected).map(obj => obj.id)
            }
        }
    },
    methods: {
        filtersChange (data) {
            this.$emit('change', data)
        },
        filtersReset () {
            this.name = ''

            this.$refs.vmCargo.clearSelected()
            if (this.data.enterprises) this.$refs.vmEnterprises.clearSelected()
            this.$refs.vmMaterials.clearSelected()

            setTimeout(() => {
                this.$emit('change', null)
            }, 1)
        },
        isEnterprises () {
            if (this.enterprises) {
                return this.enterprises.length
            }
        }
    },
    created () {
        // this.name = this.data.name
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

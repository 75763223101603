/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'presentation': {
    width: 21,
    height: 19,
    viewBox: '0 0 21 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.5 0H0v15h10v1.085a1.5 1.5 0 101 0V15h10V0H.5zm10 17a.5.5 0 100 1 .5.5 0 000-1zM1 14V3.5h19V14H1zm9.5-11.5H20V1H1v1.5h9.5zM4 11.625a.5.5 0 000 1h13a.5.5 0 000-1H4z" _fill="#7A7A7E"/>'
  }
})

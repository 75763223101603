<template>
    <component
        :is="tag"
        class="z-list"
        :class="[
            `z-list--size-${size}`,
            `z-list--theme-${theme}`,
            {
                'z-list--nested': nested,
                'z-list--no-bullets': !bullets
            }
        ]"
    >
        <slot/>
    </component>
</template>

<script>
export default {
    name: 'z-list',
    props: {
        nested: Boolean,
        bullets: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: 'm',
            validator: prop => ['l', 'm', 's'].includes(prop)
        },
        tag: {
            type: String,
            default: 'ul',
            validator: prop => ['ul', 'ol'].includes(prop)
        },
        theme: {
            type: String,
            default: 'default'
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

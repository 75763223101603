/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 46.5c12.426 0 22.5-10.074 22.5-22.5S36.426 1.5 24 1.5 1.5 11.574 1.5 24 11.574 46.5 24 46.5zm0 1.5c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z" _fill="#C4C4C4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M29.303 21.757L24.53 26.53a.75.75 0 01-1.06 0l-4.773-4.773a.75.75 0 011.06-1.06l3.493 3.492V13a.75.75 0 011.5 0v11.19l3.493-3.493a.75.75 0 011.06 1.06z" _fill="#C4C4C4"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M10.25 26h1.5v6.25h24V26h1.5v7.75h-27V26z" _fill="#C4C4C4"/>'
  }
})

import enquireJs from 'enquire.js'

export const DeviceType = {
    laptop: 'laptop',
    desktop: 'desktop',
    tablet: 'tablet',
    mobile: 'mobile',
    vmobile: 'v-mobile'
}

export const deviceEnquire = function (callback) {
    const matchDesktop = {
        match: () => {
            callback && callback(DeviceType.desktop)
        }
    }

    const matchLaptop = {
        match: () => {
            callback && callback(DeviceType.laptop)
        }
    }

    const matchTablet = {
        match: () => {
            callback && callback(DeviceType.tablet)
        }
    }

    const matchMobile = {
        match: () => {
            callback && callback(DeviceType.mobile)
        }
    }

    const matchVMobile = {
        match: () => {
            callback && callback(DeviceType.vmobile)
        }
    }

    enquireJs
        .register('screen and (max-width: 480px)', matchVMobile)
        .register('screen and (min-width: 480px) and (max-width: 768px)', matchMobile)
        .register('screen and (min-width: 769px) and (max-width: 1024px)', matchTablet)
        .register('screen and (min-width: 1024px) and (max-width: 1240px)', matchLaptop)
        .register('screen and (min-width: 1240px)', matchDesktop)
}

<template>
    <div class="news-and-media">
        <div class="row" equal-height=".card-group">
            <div class="col-default-6 col-mobile-12">
                <card
                    theme="white"
                    style="min-height: 565px"
                    class="card-group" equal-height-max-breakpoint="tablet"
                >
                <template v-slot:title>{{text.news}}</template>
                <template v-slot:content>
                    <div class="media__news">
                        <div v-if="news.length !== 0" class="media__news-main">
                            <span>
                                <z-link :href="news[0].link" :underline="false">
                                    <p class="media__news-paragraph">{{news[0].name}}</p>
                                </z-link>
                                <span class="media__news-card-date media__news-card-date--main">{{ news[0].date | momentFilter(datePattern) }}</span>
                            </span>
                        </div>
                        <div class="media__news-other">
                            <div class="row">
                                <div class="col-default-6 col-mobile-12" v-for="(item,index) in news.slice(1, 3)" :key="index">
                                    <span>
                                        <z-link :href="item.link" :underline="false">
                                            <p class="media__news-paragraph media__news-paragraph--small">{{item.name}}</p>
                                        </z-link>
                                        <span class="media__news-card-date">{{ item.date | momentFilter(datePattern) }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:bottom-left>
                    <z-button tag="a" theme="dark-grey" :href="`/${lang}/news-and-media/press-releases-and-news/`" kind="text">
                    <span v-html="text.moreNews"></span>
                    <template v-slot:right>
                        <z-icon name="arrow" theme="gp-red" dir="right" width="16" height="16"></z-icon>
                    </template>
                    </z-button>
                </template>
                </card>
            </div>
            <template v-if="banner">
                <div class="col-default-6 col-mobile-12">
                    <div class="card-group" equal-height-max-breakpoint="mobile">
                        <card theme="red" style="min-height: 440px" >
                            <template v-slot:title><div v-html="banner.name"></div></template>
                            <template v-slot:content>
                                <div class="html-content" v-html="banner.detail"></div>
                            </template>
                            <template v-slot:bottom-left>
                                <z-button tag="a" theme="white" :href="banner.link" kind="text">
                                <span v-html="text.more"></span>
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                                </z-button>
                            </template>
                            <template v-slot:bottom-right>
                                <card-icon theme="white" size="l">
                                <z-icon name="clock" width="64" height="64"></z-icon>
                                </card-icon>
                            </template>
                        </card>
                        <div class="media__banner-materials">
                            <card
                                link="media-library/ "
                                style="min-height: 155px"
                                class="ar-card u-bottom-margin--l-desktop"
                                theme="background"
                                overlay="rgba(0,0,0,0.45)"
                                image="/images/components/news-and-media/materials-small.jpg"
                            >
                                <template v-slot:title>{{text.media}}</template>
                            </card>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="col-default-6 col-mobile-12">
                    <div class="card-group" equal-height-max-breakpoint="tablet">
                        <card
                            theme="background"
                            overlay="rgba(0,0,0,0.45)"
                            image="/images/components/news-and-media/materials-large.jpg"
                            style="min-height: 273px"
                            class="u-bottom-margin--l-desktop"
                        >
                            <template v-slot:title>{{text.media}}</template>
                            <template v-slot:content>
                                <p v-html="text.mediaContent"></p>
                                <p v-html="text.mediaKit"></p>
                            </template>
                            <template v-slot:bottom-left>
                                <z-button tag="a" theme="white" :href="`/${lang}/news-and-media/media-library/`" kind="text">
                                <span v-html="text.more"></span>
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                                </z-button>
                            </template>
                            <template v-slot:bottom-right>
                                <card-icon theme="white" size="l">
                                    <z-icon name="photo" width="64" height="64"></z-icon>
                                </card-icon>
                            </template>
                        </card>

                        <div class="row" equal-height=".card">
                            <div class="col-default-6 col-tablet-12 col-mobile-12">
                                <card
                                    style="min-height: 259px"
                                    class="media__contacts-card"
                                    equal-height-max-breakpoint="tablet"
                                >
                                    <template v-slot:title>{{text.contacts}}</template>
                                    <template v-slot:content v-if="contacts">
                                        <div
                                            class="media__contacts"
                                        >
                                            <span
                                                class="media__contacts-info"
                                                v-for="(phone,i) in contacts.phones"
                                                :key="i"
                                            >
                                                <p v-html="`${text.phone}:`" class="media__contacts-info--type"></p>
                                                <z-link :href="`tel:${phone.number}`" :underline="false" v-html="phone.number"></z-link>
                                            </span>
                                            <span class="media__contacts-info">
                                                <p class="media__contacts-info--type">E-mail:</p>
                                                <z-link :href="`mailto:${contacts.mail}`" v-html="contacts.mail"></z-link>
                                            </span>
                                        </div>
                                    </template>
                                    <template v-slot:bottom-left>
                                        <z-button tag="a" theme="dark-grey" :href="lang !== 'ru' ? '/en/news-and-media/contacts/' : '/ru/news-and-media/contacts/'" kind="text">
                                        <span v-html="text.more"></span>
                                        <template v-slot:right>
                                            <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                        </template>
                                        </z-button>
                                    </template>
                                </card>
                            </div>
                            <div class="col-default-6 col-tablet-12 col-mobile-12">
                                <card style="min-height: 259px" equal-height-max-breakpoint="tablet">
                                    <template v-slot:title>{{text.subscription}}</template>
                                    <template v-slot:content>
                                        <p v-html="text.subscriptionContent"></p>
                                    </template>
                                    <template v-slot:bottom-left>
                                        <z-button tag="a" theme="dark-grey" :href="`/${lang}/news-and-media/contacts/subscription/`" kind="text">
                                            <span v-html="text.more"></span>
                                            <template v-slot:right>
                                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                            </template>
                                        </z-button>
                                    </template>
                                    <template v-slot:bottom-right>
                                        <card-icon theme="grey" size="m">
                                            <z-icon name="letter" width="64" height="64"></z-icon>
                                        </card-icon>
                                    </template>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'news-and-media',
    data () {
        return {
            lang: this.$root.lang,
            news: this.$root.app.components['news-and-media'].news,
            banner: this.$root.app.components['news-and-media'].banner,
            contacts: this.$root.app.components['news-and-media'].contacts_news,
            text: {
                news: localize({
                    'ru': 'Новости',
                    'en': 'News',
                    'cn': '新闻'
                }),
                moreNews: localize({
                    'ru': 'Подробнее',
                    'en': 'All news',
                    'cn': '了解详情'
                }),
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more',
                    'cn': '了解详情'
                }),
                media: localize({
                    'ru': 'Медиатека',
                    'en': 'Media library',
                    'cn': '媒体中心'
                }),
                mediaContent: localize({
                    'ru': 'Фото- и видеоматериалы',
                    'en': 'Photo and video content',
                    'cn': '照片和视频材料'
                }),
                mediaKit: localize({
                    'ru': 'Медиакит',
                    'en': 'Media kit',
                    'cn': '媒体资料包'
                }),
                contacts: localize({
                    'ru': 'Контакты для СМИ',
                    'en': 'Media contacts',
                    'cn': '媒体联系人'
                }),
                subscription: localize({
                    'ru': 'Подписка',
                    'en': 'Subscription',
                    'cn': '订阅'
                }),
                subscriptionContent: localize({
                    'ru': 'Оформите подписку и&nbsp;получайте новости компании первыми',
                    'en': 'Subscribe and be&nbsp;the first to&nbsp;receive company news',
                    'cn': '注册成为第一个收到公司新闻的人'
                }),
                phone: localize({
                    'ru': 'Тел.',
                    'en': 'Phone',
                    'cn': '电话'
                })
            }
        }
    },
    computed: {
        datePattern () {
            if (this.lang === 'cn') return 'YYYY MM DD'
            return 'DD MMMM YYYY'
        }
    }
}
</script>

<style lang="scss">
.news-and-media {
    margin-top: 2rem;
    margin-bottom: 4rem;

    .card--with-icon.card--icon-size-l .card__content {
        max-width: 80%;
        margin-bottom: 0;

        p:first-child {
            margin-bottom: 0.5rem;
        }
    }
}
.media {
    &__news {
        &-main {
            padding-bottom: 42px;
            border-bottom: 2px solid $token-colors-light-grey;
        }

        &-other {
            margin-top: 42px;
        }

        &-paragraph {
            color: $token-colors-dark-grey;
            font-size: 24px;
            margin-bottom: 8px;
            max-width: 700px;
            &--small {
                font-size: 18px;
            }
        }

        &-card {
            &-date {
                display: inline-block;
                width: 100%;
                font-size: 12px;
                line-height: 1.4;
                color: rgba(79, 79, 79, 0.5);
                &--main {
                    font-size: 14px;
                }
            }
        }
    }
    &__banner {
        &-materials {
            margin-top: 30px;
        }
    }
    &__contacts {
        margin-bottom: 1.25rem;

        &-card {
            .card__content {
                margin-bottom: 0;
            }
        }

        & .z-caption {
            margin-bottom: 12px;
        }

        &-info {
            display: flex;

            a {
                font-size: 0.938rem;
                word-break: break-all;
            }

            &--type {
                flex-basis: 80px;
                color: $token-colors-lighter-grey;
                margin-bottom: 12px;
                flex-shrink: 0;
            }
        }

        &-name {
            margin-top: 1rem;
        }

        &-position {
            font-size: 16px;
            font-weight: normal;
            color: $token-colors-darker-grey;
        }
    }
}
</style>

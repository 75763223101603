/* eslint-disable import/no-webpack-loader-syntax */
'use strict'
import mapSvg from '!!raw-loader!./map/world/world.svg'
import features from './features'
import clustersData from './clusters'
import data from './data.js'

// require('innersvg-polyfill')
// require('zebra-map/src/innersvg')

const ZebraMap = require('zebra-map')
const d3 = require('d3')
const topojson = require('topojson')

const mapConfig = require('./map/world/config.json')
const mapProjection = require('./map/world/projection.js')(mapConfig.size.width, mapConfig.size.height)

export class Map {
    constructor (idMap, idRussia) {
        this.idMap = idMap
    }

    createContainers (containerId) {
        var container = d3.select('#' + containerId)
        container.append('div')
            .attr('id', containerId + '__main')
            .attr('class', 'map__main')
        container.append('div')
            .attr('id', containerId + '__info')
            .attr('class', 'map__info')
    }

    zoomShowContent (scale) {
        // const scaleRound = Math.round(scale * 10) / 10

        d3.selectAll(`[data-zoom-factor]:not([data-zoom-factor="-1"]`)
            .style('opacity', function (d) {
                return (scale >= d.zoomShowFactor) ? 1 : 0
            })
            .style('display', function (d) {
                return (scale >= d.zoomShowFactor) ? 'inherit' : 'none'
            })

        d3.selectAll(`.map__point--hub`)
            .style('opacity', function (d) {
                return (scale >= 8) ? 0 : 1
            })
            .style('display', function (d) {
                return (scale >= 8) ? 'none' : 'inherit'
            })
    }

    loadSea () {
        d3.json('/ajax/sea.json', function (err, json) {
            if (err) {
                console.log(err.stack)
            }

            const sea = topojson.feature(json, json.objects.sea)
            sea.features.forEach(function (feature) {
                d3.select('#waters-group')
                    .append('path')
                    .datum(feature)
                    .attr('d', d3.geo.path().projection(mapProjection))
                    .attr('id', feature.properties.name_en)
                    .attr('class', 'water')
            })

            d3.selectAll(`.water`)
                .transition()
                .duration(2000)
                .style('stroke-dashoffset', function (d) {
                    return 0
                })
        })
    }

    loadRoad () {
        d3.json('/ajax/road.json', function (err, json) {
            if (err) {
                console.log(err.stack)
            }

            const road = topojson.feature(json, json.objects.road)
            road.features.forEach(function (feature) {
                d3.select('#roads-group')
                    .append('path')
                    .datum(feature)
                    .attr('d', d3.geo.path().projection(mapProjection))
                    .attr('id', feature.properties.name_en)
                    .attr('class', function (feature) {
                        return feature.properties.Name === 'arrow' ? 'road road--arrow' : 'road road--line'
                    })
            })

            d3.selectAll(`.road--line`)
                .transition()
                .duration(4000)
                .style('stroke-dashoffset', function (d) {
                    return 0
                })

            d3.selectAll(`.road--arrow`)
                .transition()
                .delay(2000)
                .duration(1500)
                .style('opacity', function (d) {
                    return 1
                })
        })
    }

    loadRail () {
        d3.json('/ajax/rail.json', function (err, json) {
            if (err) {
                console.log(err.stack)
            }

            const rail = topojson.feature(json, json.objects.rail)
            rail.features.forEach(function (feature) {
                d3.select('#railway-group')
                    .append('path')
                    .datum(feature)
                    .attr('d', d3.geo.path().projection(mapProjection))
                    .attr('id', feature.properties.name_en)
                    .attr('class', function (feature) {
                        return feature.properties.Name === 'arrow' ? 'railway railway--arrow' : 'railway railway--line'
                    })
            })

            d3.selectAll(`.railway--line`)
                .transition()
                .duration(4000)
                .style('stroke-dashoffset', function (d) {
                    return 0
                })

            d3.selectAll(`.railway--arrow`)
                .transition()
                .delay(2000)
                .duration(1500)
                .style('opacity', function (d) {
                    return 1
                })
        })
    }

    initMap () {
        const idMap = this.idMap
        this.createContainers(idMap)

        let markers = {}
        features.forEach(function (feature) {
            markers[feature.id] = feature.image
        })
        function getMarkerSvgContent (map) {
            return markers[this.type]
        }

        this.terminalsMap = new ZebraMap(idMap + '__main', data, mapConfig, mapSvg, getMarkerSvgContent, mapProjection, null, clustersData)

        this.loadSea()
        this.loadRoad()
        this.loadRail()

        d3.select('.map__svg svg').on('dblclick.zoom', null)
        d3.select('.map__svg svg').on('mousedown.drag', null) // нихуя не работает
    }
}

document.TerminalsMap = Map
window.TerminalsMap = Map

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'invest-case-4': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19 48.037a1 1 0 01.966-1.033c14.706-.498 26.861-7.846 35.384-15.145a85.829 85.829 0 009.847-9.959c.778-.929 1.43-1.75 1.95-2.425l-6.315 1.248a1 1 0 11-.388-1.962l8.83-1.745a1 1 0 011.174.787l1.745 8.83a1 1 0 01-1.962.387l-1.301-6.584a75.512 75.512 0 01-2.2 2.749 87.82 87.82 0 01-10.08 10.193c-8.699 7.45-21.277 15.105-36.616 15.624A1 1 0 0119 48.037zm8.007-7.914a1 1 0 01.873-1.113c6.546-.793 11.203-2.976 14.212-4.941a23.415 23.415 0 003.313-2.59c.357-.339.617-.614.785-.8.085-.093.146-.164.184-.21l.026-.032.014-.017.007-.008a1 1 0 011.572 1.237l-.733-.577c.733.577.733.578.732.578l-.003.004-.005.007-.018.022a10.044 10.044 0 01-.291.337c-.2.221-.497.533-.892.909-.791.75-1.98 1.758-3.598 2.815-3.238 2.114-8.184 4.417-15.065 5.252a1 1 0 01-1.113-.873zM8 79.003h80v-2H8v2zm12-19a3 3 0 013-3h4a3 3 0 013 3v11h-2v-11a1 1 0 00-1-1h-4a1 1 0 00-1 1v11h-2v-11zm19-10a3 3 0 00-3 3v18h2v-18a1 1 0 011-1h4a1 1 0 011 1v18h2v-18a3 3 0 00-3-3h-4zm13 4a3 3 0 013-3h4a3 3 0 013 3v17h-2v-17a1 1 0 00-1-1h-4a1 1 0 00-1 1v17h-2v-17zm19-19a3 3 0 00-3 3v33h2v-33a1 1 0 011-1h4a1 1 0 011 1v33h2v-33a3 3 0 00-3-3h-4z" _fill="#ED1C24"/>'
  }
})

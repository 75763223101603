/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'webcast': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.25 3.531c-.77 0-1.454.322-1.912.822a.5.5 0 01-.737-.677 3.588 3.588 0 012.649-1.145c1.052 0 2.002.44 2.649 1.145a.5.5 0 01-.736.677 2.589 2.589 0 00-1.913-.822zM6 7.47a2.5 2.5 0 00-2.5 2.5v9a2.5 2.5 0 002.5 2.5h12a2.5 2.5 0 002.5-2.5v-9a2.5 2.5 0 00-2.5-2.5H6zm-1.5 2.5a1.5 1.5 0 011.5-1.5h12a1.5 1.5 0 011.5 1.5v9a1.5 1.5 0 01-1.5 1.5H6a1.5 1.5 0 01-1.5-1.5v-9zm9.75 5l-3.75 2.165v-4.33l3.75 2.165zm.5-.866a1 1 0 010 1.732L11 18a1 1 0 01-1.5-.866v-4.33a1 1 0 011.5-.866l3.75 2.165zm-4.108-7.954c.158-.2.377-.31.608-.31.23 0 .45.11.608.31a.5.5 0 00.784-.62 1.775 1.775 0 00-1.392-.69c-.568 0-1.065.276-1.392.69a.5.5 0 00.784.62z" _fill="#7A7A7E"/>'
  }
})

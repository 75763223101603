var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-photos-detail"},[(_vm.list.length === 0)?_c('z-not-found',{attrs:{"title":_vm.notFound.title,"text":_vm.notFound.text}}):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"detail__name"},[_vm._v(_vm._s(_vm.data.name))]),_vm._v(" "),(_vm.list.length)?_c('div',{staticClass:"row",attrs:{"equal-height":".card"}},_vm._l((_vm.list),function(item,i){return _c('div',{key:i,staticClass:"col-default-4 col-tablet-12"},[(item.type === 'photo')?[_c('card',{staticClass:"ar-card",staticStyle:{"min-height":"272px"},attrs:{"theme":item.preview ? 'background' : 'red',"overlay":"rgba(0,0,0,0.45)","mark":false,"image":item.preview,"equal-height-max-breakpoint":"mobile"},scopedSlots:_vm._u([(item.name)?{key:"title",fn:function(){return [_vm._v(_vm._s(item.name))]},proxy:true}:null,{key:"content",fn:function(){return [(item.size && item.extension)?_c('span',{staticClass:"card__file-info"},[_vm._v(_vm._s(item.size)+", "+_vm._s(item.extension))]):_vm._e(),_vm._v(" "),(item.cargo && item.cargo.length)?_c('span',[_c('p',{staticClass:"detail__cargo-list"},_vm._l((item.cargo),function(tag,index){return _c('span',{key:index},[_vm._v(_vm._s(tag))])}),0)]):_vm._e()]},proxy:true},(item.image)?{key:"bottom-left",fn:function(){return [_c('z-link',{directives:[{name:"tippy-tooltip",rawName:"v-tippy-tooltip",value:({
                                theme : 'transparent',
                                arrow: true,
                                placement: 'top-start'
                            }),expression:"{\n                                theme : 'transparent',\n                                arrow: true,\n                                placement: 'top-start'\n                            }"}],staticClass:"ar-card__icon u-inline",attrs:{"content":_vm.image.download,"href":item.image.full,"downloadFile":"","downloadTitle":item.name}},[_c('z-icon',{attrs:{"name":"download","width":"48","height":"48"}})],1),_vm._v(" "),_c('z-link',{directives:[{name:"tippy-tooltip",rawName:"v-tippy-tooltip",value:({
                                theme : 'transparent',
                                arrow: true,
                                placement: 'top-start'
                            }),expression:"{\n                                theme : 'transparent',\n                                arrow: true,\n                                placement: 'top-start'\n                            }"}],staticClass:"ar-card__icon u-inline",attrs:{"content":_vm.image.preview,"href":"#"},on:{"click":function($event){return _vm.$root.$bus.$emit('open-modal', {id: 'photosModal', index: i})}}},[_c('z-icon',{attrs:{"name":"zoom-in","width":"48","height":"48"}})],1)]},proxy:true}:null],null,true)})]:(item.type === 'video')?[_c('card',{attrs:{"mark":false,"overlay":item.image ? 'rgba(0,0,0,0.35)' : '',"theme":item.image ? 'background' : 'red',"image":item.image.preview,"padding":"272px","equal-height-max-breakpoint":"mobile"},on:{"click":function($event){return _vm.$root.$bus.$emit('open-modal', {id: 'photosModal', index: i})}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n                        "+_vm._s(item.name)+"\n                    ")]},proxy:true},{key:"content",fn:function(){return [(item.count)?_c('span',{staticClass:"card__file-info"},[_vm._v(_vm._s(item.count))]):_vm._e(),_vm._v(" "),(item.size && item.duration)?_c('span',{staticClass:"card__file-info"},[_vm._v(_vm._s(item.size)+" | "+_vm._s(item.duration))]):_vm._e()]},proxy:true},(item.type === 'video')?{key:"bottom-left",fn:function(){return [_c('z-link',{staticClass:"ar-card__icon u-inline",attrs:{"href":"#"},on:{"click":function($event){return _vm.$root.$bus.$emit('open-modal', {id: 'videosModal', index: i})}}},[_c('z-icon',{attrs:{"name":"video","width":"48","height":"48"}})],1)]},proxy:true}:null],null,true)})]:_vm._e()],2)}),0):_vm._e(),_vm._v(" "),(_vm.loading)?_c('z-preloader'):_vm._e(),_vm._v(" "),_c('z-modal',{staticClass:"gallery-modal",attrs:{"autoWidth":"","id":"photosModal","download":"","theme":"grey","data":_vm.slidesForModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
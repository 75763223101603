<template>
    <div class="fast-search">
        <a class="fast-search__toggle" href="javascript: void(0)" @click="showSearchPopup">
            <z-icon name="search"></z-icon>
        </a>

        <div class="fast-search__overlay" v-show="show">
            <div class="fast-search__wrapper">
                <button class="fast-search__close" @click="showSearchPopup">
                    <z-icon
                        name="close_bold"
                        width="16px"
                        height="16px"
                    ></z-icon>
                </button>

                <div class="fast-search__container">
                    <div class="fast-search__input">
                        <z-input
                            name="q"
                            theme="search"
                            v-model="query"
                            @input="onInput({
                                name: 'q',
                                value: query
                            })"
                            :placeholder="text.placeholder"
                        ></z-input>
                        <span class="fast-search__input--prompt">{{ text.prompt }}</span>
                    </div>
                    <div class="fast-search__content" v-if="!data.items.length && notFound.state">
                        <z-not-found
                            :title="notFound.title"
                            :text="notFound.text"
                        ></z-not-found>
                    </div>
                    <div class="fast-search__content" v-if="data.items.length">
                        <div v-for="(item, index) of data.items" :key="index" class="fast-search__list">
                            <div class="fast-search__list--title">
                                <span>
                                    <a :href="item.link" v-html="item.name"></a>
                                </span>
                            </div>
                            <ul
                                :class="listClassBuilder(list.enough, index)"
                                v-for="(list, index) in getLists(item.items)"
                                :key="index"
                            >
                                <li v-for="(item, i) in list" :key="i">
                                    <a :href="item.link" v-html="item.name"></a>
                                </li>
                            </ul>
                        </div>
                        <span class="fast-search__show">
                            <z-button tag="a" :href="data['all-results']" kind="text">
                                {{ text.allResults }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="14" height="14"></z-icon>
                                </template>
                            </z-button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { getFastSearch } from '@/api/fast-search'
import { localize } from '@/utils/i18n'

export default {
    name: 'fast-search',
    data () {
        return {
            show: false,
            data: {
                items: [],
                allResult: ''
            },
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не найдено',
                    'en': 'No results found',
                    'cn': '没有找到结果'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no matches were found for your request. Try changing your search terms.',
                    'cn': '抱歉，没有找到与您的要求相匹配的结果。请尝试更改您的搜索条件。'
                })
            },
            query: '',
            text: {
                allResults: localize({
                    'ru': 'Все результаты',
                    'en': 'All results',
                    'cn': '所有结果'
                }),
                prompt: localize({
                    'ru': 'Введите Ваш запрос, например, «грузоперевозки»',
                    'en': 'Enter your request, for example, "freight"',
                    'cn': '输入您的要求，例如，“运费”'
                }),
                placeholder: localize({
                    'ru': 'Введите ключевое слово',
                    'en': 'Enter keyword',
                    'cn': '输入关键词'
                })
            },
            params: {}
        }
    },
    methods: {
        listClassBuilder (isListEnough, index) {
            return `fast-search__list--items
                    ${isListEnough ? 'fast-search__list--items-enough' : ''}
                    ${index > 0 && index % 2 === 0 ? 'col-default-offset-4 col-tablet-offset-0' : ''}`
        },
        getLists (items) {
            // @result [[{},{},{},{}], [{},{},{},{}]]
            let result = []
            let list = []

            items.forEach(item => {
                if (list.length === 4) {
                    list.enough = true
                    result.push(list)
                    list = []
                }
                list.push(item)
            })

            if (list.length) {
                if (result.length) list.enough = true
                result.push(list)
            }

            return result
        },
        showSearchPopup () {
            this.show = !this.show
            this.data = {
                items: [],
                allResult: ''
            }
            this.query = ''

            if (this.show) {
                this.$root.$el.style.cssText = 'overflow: hidden;'
            } else {
                this.$root.$el.style.cssText = ''
            }
        },
        onInput (data) {
            this.params[data.name] = data.value

            if (data.value.length >= 3) {
                this.send(this.clearEmptyParams(this.params))
            } else {
                this.data = {
                    items: [],
                    allResult: ''
                }
                this.notFound.state = false
            }
        },
        send: debounce(500, function (params) {
            getFastSearch(params).then(response => {
                this.data = response
                if (!this.data.items.length) {
                    this.notFound.state = true
                }
            })
        }),
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

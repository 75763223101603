/* eslint-disable */
// object-fit
!(function () {
	'use strict';if ('objectFit' in document.documentElement.style != !1) {
		return void (window.objectFitPolyfill = function () {
			return !1;
		});
	} var t = function (t) {
			var e = window.getComputedStyle(t, null), i = e.getPropertyValue('position'), o = e.getPropertyValue('overflow'), n = e.getPropertyValue('display');i && i !== 'static' || (t.style.position = 'relative'), o !== 'hidden' && (t.style.overflow = 'hidden'), n && n !== 'inline' || (t.style.display = 'block'), t.clientHeight === 0 && (t.style.height = '100%'), t.className.indexOf('object-fit-polyfill') === -1 && (t.className = t.className + ' object-fit-polyfill');
		}, e = function (t) {
			var e = window.getComputedStyle(t, null), i = {'max-width': 'none', 'max-height': 'none', 'min-width': '0px', 'min-height': '0px', top: 'auto', right: 'auto', bottom: 'auto', left: 'auto', 'margin-top': '0px', 'margin-right': '0px', 'margin-bottom': '0px', 'margin-left': '0px'};for (var o in i) {
				e.getPropertyValue(o) !== i[o] && (t.style[o] = i[o]);
			}
		}, i = function (t, e, i) {
			var o, n, l, a, d;if (i = i.split(' '), i.length < 2 && (i[1] = i[0]), t === 'x') {
				o = i[0], n = i[1], l = 'left', a = 'right', d = e.clientWidth;
			} else {
				if (t !== 'y') {
					return;
				}o = i[1], n = i[0], l = 'top', a = 'bottom', d = e.clientHeight;
			} return o === l || n === l ? void (e.style[l] = '0') : o === a || n === a ? void (e.style[a] = '0') : o === 'center' || o === '50%' ? (e.style[l] = '50%', void (e.style['margin-' + l] = d / -2 + 'px')) : o.indexOf('%') >= 0 ? (o = parseInt(o), void (o < 50 ? (e.style[l] = o + '%', e.style['margin-' + l] = d * (o / -100) + 'px') : (o = 100 - o, e.style[a] = o + '%', e.style['margin-' + a] = d * (o / -100) + 'px'))) : void (e.style[l] = o);
		}, o = function (o) {
			var n = o.dataset ? o.dataset.objectFit : o.getAttribute('data-object-fit'), l = o.dataset ? o.dataset.objectPosition : o.getAttribute('data-object-position');n = n || 'cover', l = l || '50% 50%';var a = o.parentNode;t(a), e(o), o.style.position = 'absolute', o.style.height = '100%', o.style.width = 'auto', n === 'scale-down' && (o.style.height = 'auto', o.clientWidth < a.clientWidth && o.clientHeight < a.clientHeight ? (i('x', o, l), i('y', o, l)) : (n = 'contain', o.style.height = '100%')), n === 'none' ? (o.style.width = 'auto', o.style.height = 'auto', i('x', o, l), i('y', o, l)) : n === 'cover' && o.clientWidth > a.clientWidth || n === 'contain' && o.clientWidth < a.clientWidth ? (o.style.top = '0', o.style.marginTop = '0', i('x', o, l)) : n !== 'scale-down' && (o.style.width = '100%', o.style.height = 'auto', o.style.left = '0', o.style.marginLeft = '0', i('y', o, l));
		}, n = function (t) {
			if (void 0 === t) {
				t = document.querySelectorAll('[data-object-fit]');
			} else if (t && t.nodeName) {
				t = [t];
			} else {
				if (typeof t != 'object' || !t.length || !t[0].nodeName) {
					return !1;
				}t = t;
			} for (var e = 0;e < t.length;e++) {
				if (t[e].nodeName) {
					var i = t[e].nodeName.toLowerCase();i === 'img' ? t[e].complete ? o(t[e]) : t[e].addEventListener('load', function () {
						o(this);
					}) : i === 'video' && (t[e].readyState > 0 ? o(t[e]) : t[e].addEventListener('loadedmetadata', function () {
						o(this);
					}));
				}
			} return !0;
		};document.addEventListener('DOMContentLoaded', function () {
			n();
		}), window.addEventListener('resize', function () {
			n();
		}), window.objectFitPolyfill = n;
}());

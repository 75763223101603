<template>
    <nav class="z-breadcrumbs">
        <ul
            class="z-breadcrumbs__list ul--clear"
            itemscope=""
            itemtype="http://schema.org/BreadcrumbList"
        >
            <li
                v-for="(item, index) in list"
                :key="index"
                class="z-breadcrumbs__item"
                itemprop="itemListElement"
                itemscope=""
                itemtype="http://schema.org/ListItem"
            >
                <z-icon v-if="index !== 0" name="bullet" width="12" height="12"></z-icon>
                <a
                    v-if="!isCurrentPathname(item.url)"
                    class="z-breadcrumbs__link"
                    itemscope=""
                    itemtype="http://schema.org/Thing"
                    itemprop="item"
                    :href="item.url"
                    :title="item.name"
                >
                    <span itemprop="name" v-html="item.name"></span>
                </a>
                <span
                    v-else
                    class="z-breadcrumbs__link is-current"
                    itemscope=""
                    itemtype="http://schema.org/Thing"
                    itemprop="item"
                >
                    <span itemprop="name" v-html="item.name"></span>
                </span>
            </li>
        </ul>
    </nav>
</template>

<script>
// import { localize } from '@/utils/i18n'
export default {
    name: 'z-breadcrumbs',
    data: function () {
        return {
            list: this.$root.app.components['breadcrumbs']
        }
    },
    created () {
        // this.list.unshift({
        //     name: localize({
        //         'ru': 'Главная',
        //         'en': 'Mainpage'
        //     }),
        //     url: '/'
        // })
    },
    methods: {
        isCurrentPathname (url) {
            let isCurrentPath = true

            if (url !== location.pathname) {
                isCurrentPath = false
            }

            return isCurrentPath
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template src="./index.html"></template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'navigation-main',
    data () {
        return {
            navIndexOpened: -1,
            list: this.$root.app.components.navigation.main,
            closeText: localize({
                'ru': 'Закрыть',
                'en': 'Close',
                'cn': 'Close (cn)'
            })
        }
    },
    methods: {
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : '_self'
        },
        showChilds (event, i, item) {
            if (!item.hasOwnProperty('items')) {
                this.hideChilds()
            } else if (item.hasOwnProperty('items') && item.items.length && this.navIndexOpened !== i) {
                this.navIndexOpened = i
                this.$root.$bus.$emit('navigation-open')
            }
        },
        hideChilds (i) {
            this.navIndexOpened = -1
            this.$root.$bus.$emit('navigation-close')
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <div class="online-services">
        <template v-if="onlineServices.length">
            <div class="row">
                <div
                    v-for="(item, i) in onlineServices"
                    :key="i"
                    class="col-default-4 col-mobile-12"
                >
                    <card
                        :link="item.link"
                        :overlay="'rgba(54,54,54,0.6)'"
                        :theme="'background'"
                        :image="item.image"
                        :shadow="false"
                        :mark="false"
                        padding="300px"
                    >
                        <template v-slot:title>
                            {{ item.name }}
                        </template>
                        <template v-slot:bottom-right>
                            <img :src="item.icon" height="32px">
                        </template>
                    </card>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'online-services',
    data () {
        return {
            onlineServices: this.$root.app.components['online-services-list']
        }
    },
    methods: {

    },
    created () {
    }
}
</script>

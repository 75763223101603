<template>
    <div class="careers">
        <div class="container">
            <div class="row" equal-height=".card">
                <div class="col-default-4 col-tablet-12">
                    <card
                        theme="background"
                        overlay="rgba(0,0,0,0.45)"
                        image="/images/components/careers/shape.jpg"
                        style="min-height: 350px"
                        class="card-equal"
                        equal-height-max-breakpoint="tablet"
                    >
                        <template v-slot:title>{{ text.teamTitle }}</template>
                        <template v-slot:content>
                            <div v-html="text.teamText"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" :href="`/${lang}/careers/team/`" kind="text" theme="white">
                                {{ text.more }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="white"
                                size="m"
                            >
                                <z-icon name="ship2" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <div class="col-default-8 col-tablet-12">
                    <card
                        theme="background"
                        overlay="rgba(0,0,0,0.45)"
                        image="/images/components/careers/shape2.jpg"
                        style="min-height: 350px"
                        class="card-equal"
                        equal-height-max-breakpoint="tablet"
                    >
                        <template v-slot:title>{{ text.studyTitle }}</template>
                        <template v-slot:content>
                            <div v-html="text.studyText"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" :href="`/${lang}/careers/development/`" kind="primary" rounded>
                                {{ text.more }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                    </card>
                </div>
            </div>
            <div class="row" equal-height=".card">
                <div class="col-default-4 col-tablet-6 col-mobile-12">
                    <card
                        :link="`/${lang}/careers/specialists/`"
                        style="min-height: 200px"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>{{ text.specialistsTitle }}</template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="red"
                                size="m"
                            >
                                <z-icon name="management" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <div class="col-default-4 col-tablet-6 col-mobile-12">
                    <card
                        :link="`/${lang}/careers/workers/`"
                        style="min-height: 200px"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>{{ text.workersTitle }}</template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="red"
                                size="m"
                            >
                                <z-icon name="worker" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <div class="col-default-4 col-tablet-12">
                    <card
                        :link="`/${lang}/careers/beginning/`"
                        style="min-height: 200px"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>{{ text.studentsTitle }}</template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="red"
                                size="m"
                            >
                                <z-icon name="manager" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
            </div>
            <div class="row" equal-height=".card">
                <!-- <div class="col-default-8 col-tablet-12">
                    <card
                        style="min-height: 410px"
                        equal-height-max-breakpoint="tablet"
                        class="card-equal careers-history"
                    >
                        <template v-slot:title>{{ text.employeeTitle }}</template>
                        <template v-slot:content>
                            <card-slider
                                navigation
                                theme="blockquote"
                                :slides="slides"
                            ></card-slider>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button class="careers-history__btn" tag="a" :href="`/${lang}/careers/employee/`" kind="text" theme="black">
                                {{ text.moreStories }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                    </card>
                </div> -->
                <div class="col-default-4 col-tablet-12">
                    <card
                        equal-height-max-breakpoint="tablet"
                        style="min-height: 410px"
                        class="card-equal careers__contact-card"
                    >
                        <template v-slot:title>{{ text.contactsTitle }}</template>
                        <template v-slot:content>
                            <div>
                                <span class="careers__contacts-contact">
                                    <p class="careers__contacts-title">{{ text.contactsText }}</p>
                                </span>
                                <span class="careers__contacts-contact">
                                    <p class="careers__contacts-tool">E-mail<p>
                                    <p class="careers__contacts-info">job@globalports.com</p>
                                </span>
                                <span class="careers__contacts-contact">
                                    <p class="careers__contacts-tool">Tel<p>
                                    <p class="careers__contacts-info">8-800-101-65-25</p>
                                </span>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'careers',
    data () {
        return {
            lang: this.$root.lang,
            slides: [
                {
                    name: localize({
                        'ru': 'Василий Аксеновский',
                        'en': 'Vasily Aksenovsky'
                    }),
                    image: '/images/components/careers/aksenovsky.jpg',
                    detail: localize({
                        'ru': 'Мы&nbsp;еще только в&nbsp;начале пути, планов множество.',
                        'en': 'We&nbsp;are still at&nbsp;the beginning of&nbsp;this journey. We&nbsp;have many plans.'
                    }),
                    post: localize({
                        'ru': 'Главный категорийный менеджер',
                        'en': 'Lead Category Manager'
                    })
                },
                {
                    name: localize({
                        'ru': 'Иван Хейстонен',
                        'en': 'Ivan Kheystonen'
                    }),
                    image: '/images/components/careers/kheystonen.jpg',
                    detail: localize({
                        'ru': 'В&nbsp;своей работе я&nbsp;люблю постоянный драйв, движение.',
                        'en': 'I&nbsp;love constant movement and drive about my&nbsp;job.'
                    }),
                    post: localize({
                        'ru': 'Заместитель директора Контейнерного терминала',
                        'en': 'Deputy Director of&nbsp;the Container Terminal'
                    })
                },
                {
                    name: localize({
                        'ru': 'Мария Суворова',
                        'en': 'Maria Suvorova'
                    }),
                    image: '/images/components/careers/suvorova.jpg',
                    detail: localize({
                        'ru': 'Компания дала мне колоссальный опыт, стабильность и&nbsp;уверенность в&nbsp;завтрашнем дне.',
                        'en': 'The company gave me&nbsp;tremendous experience, stability and confidence in&nbsp;the future.'
                    }),
                    post: localize({
                        'ru': 'Начальник отдела управления денежными средствами и&nbsp;организации расчетов',
                        'en': 'Head of&nbsp;Cash Management and Payment Department'
                    })
                }
            ],
            text: {
                teamTitle: localize({
                    'ru': 'Команда Глобал Портс',
                    'en': 'Global Ports team'
                }),
                teamText: localize({
                    'ru': 'Нас&nbsp;&mdash; более 3&nbsp;200&nbsp;человек',
                    'en': 'We&nbsp;are more than 3,200 people'
                }),
                studyTitle: localize({
                    'ru': 'Обучение и развитие',
                    'en': 'Education and development'
                }),
                studyText: localize({
                    'ru': 'Мы&nbsp;принимаем активное участие в&nbsp;построение карьеры и&nbsp;развитии профессиональных, личностных компетенций наших сотрудников.',
                    'en': 'We&nbsp;take an&nbsp;active part in&nbsp;building a&nbsp;career and developing professional, personal competencies of&nbsp;our colleagues.'
                }),
                specialistsTitle: localize({
                    'ru': 'Специалистам и руководителям',
                    'en': 'For specialists and managers'
                }),
                workersTitle: localize({
                    'ru': 'Рабочим',
                    'en': 'For workers'
                }),
                studentsTitle: localize({
                    'ru': 'Студентам и начинающим специалистам',
                    'en': 'For students and beginners'
                }),
                contactsTitle: localize({
                    'ru': 'Контакты для соискателей',
                    'en': 'Contacts'
                }),
                contactsText: localize({
                    'ru': 'Департамент по управлению персоналом',
                    'en': 'HR department'
                }),
                employeeTitle: localize({
                    'ru': 'Истории сотрудников',
                    'en': 'Employee Stories'
                }),
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more'
                }),
                moreStories: localize({
                    'ru': 'К историям',
                    'en': 'Go to stories'
                })
            }
        }
    }
}
</script>

<style lang="scss">
    .careers {
        margin-top: 2rem;
        margin-bottom: 4rem;

        &__contacts {
            &-contact {
                display: flex;
            }

            &-title {
                flex: 0 100%;
                font-weight: 700;
                font-size: 20px;
                line-height: 1.4;
                color: $token-colors-black;
                margin-bottom: 1rem;
            }

            &-tool {
                flex-basis: 80px;
                color: $token-colors-lighter-grey;
                flex-shrink: 0;
            }

            &-info {
                color: $token-colors-darker-grey;
                flex-basis: 280px;
                word-break: break-all;
            }
        }

        .card--theme-white:not(.careers__contact-card) .card__content {
            flex: 1 210px;

            @include breakpoint(desktop) {
                flex-basis: 186px;
            }

            @include breakpoint(tablet) {
                flex-basis: 210px;
            }

            @include breakpoint(mobile) {
                flex-basis: 186px;
            }

            @include breakpoint(v-mobile) {
                flex-basis: 380px;
            }
        }

        .card-slider__slide {
            padding: 0;
        }

        .card--with-icon .card__body {
            padding-bottom: 2.5rem;
            padding-right: 7rem;
        }
    }

    .careers-history {

        @include breakpoint(mobile) {
            &__btn {
                white-space: nowrap;
            }

            .card {
                .card__content {
                    flex: auto;
                }
            }
            .card-slider__container {
                position: static;
            }

            .card-slider {
                position: static;
                padding-bottom: 2rem;

                &__pagination-blockquote {
                    bottom: 0rem;
                }
            }
        }

        @include breakpoint(v-mobile) {
            .z-blockquote {
                &__body {
                    padding-left: 0;
                }

                &__body-slider {
                    .z-blockquote__quotes {
                        left: 0;
                    }
                }
            }
        }

    }
</style>

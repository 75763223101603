/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" d="M19 8.556c0 .044 0 .094-.002.152-.002.162-.006.344-.01.538a29.922 29.922 0 01-.088 1.639c-.06.722-.15 1.324-.27 1.776a2.79 2.79 0 01-1.974 1.97c-.569.153-1.682.247-3.249.305a94.9 94.9 0 01-2.34.056c-.28.004-.539.006-.772.008h-.59c-.233-.002-.492-.004-.772-.008a94.907 94.907 0 01-2.34-.056c-1.567-.06-2.682-.154-3.249-.304a2.802 2.802 0 01-1.974-1.97c-.123-.453-.21-1.055-.27-1.777a29.99 29.99 0 01-.088-1.639c-.006-.194-.008-.376-.01-.538L1 8.556v-.112c0-.044 0-.094.002-.152.002-.162.006-.344.01-.538.016-.558.044-1.115.088-1.639.06-.722.15-1.324.27-1.776a2.794 2.794 0 011.974-1.97c.567-.153 1.682-.247 3.249-.305a94.905 94.905 0 012.34-.056c.28-.004.539-.006.772-.008h.59c.233.002.492.004.772.008.797.01 1.595.028 2.34.056 1.567.06 2.682.154 3.249.304a2.79 2.79 0 011.974 1.97c.123.453.21 1.055.27 1.777.044.524.072 1.08.088 1.639.006.194.008.376.01.538l.002.152v.112zm-1.446-.104c0-.042 0-.088-.002-.142-.002-.156-.006-.328-.01-.514a26.872 26.872 0 00-.085-1.559c-.054-.644-.13-1.172-.225-1.526a1.349 1.349 0 00-.952-.95c-.422-.112-1.485-.203-2.93-.257a91.684 91.684 0 00-3.063-.062h-.574l-.76.006c-.785.01-1.57.028-2.304.056-1.444.056-2.509.145-2.929.257a1.352 1.352 0 00-.952.95c-.095.354-.17.882-.225 1.526a28.855 28.855 0 00-.085 1.559c-.006.186-.008.36-.01.514l-.002.142v.096c0 .042 0 .088.002.142.002.156.006.328.01.514.015.532.043 1.064.085 1.559.054.644.13 1.172.225 1.526.124.462.49.828.952.95.422.112 1.485.202 2.93.256a91.661 91.661 0 003.063.063h.574l.76-.006c.785-.01 1.57-.028 2.304-.056 1.444-.056 2.509-.145 2.929-.257.462-.124.828-.488.952-.95.095-.354.17-.882.225-1.526.042-.495.068-1.027.084-1.559.006-.186.009-.36.01-.514 0-.054.003-.102.003-.142v-.096zM8.212 5.819l4.66 2.701-4.66 2.66V5.82z" _fill="#363636"/>'
  }
})

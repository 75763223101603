/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'man': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M34.826 31.848v1.986c0 .361-.158.672-.453.892l-3.305 2.458-3.304-2.458a1.08 1.08 0 01-.454-.892v-2.032l-.26-.264a2.604 2.604 0 01-.78-1.718l-.077-1.139-.016-.005c-.235-.068-.43-.147-.577-.264-.147-.116-.245-.269-.357-.572a9.96 9.96 0 01-.327-1.164 7.873 7.873 0 01-.168-1.079c-.025-.312-.02-.579.057-.787a.743.743 0 01.422-.417c.142-.043.218-.016.28-.032.063-.015-.031-.05-.034-.08l-.003-.043a1.62 1.62 0 01-.04-.05c-.533-.676-.934-2.003-1.045-3.189-.111-1.186.069-2.23.248-3.276.332.201.664.402 1.272.087.608-.315 1.493-1.145 2.502-1.722 1.01-.576 2.143-.897 3.311-.844 1.168.054 2.37.483 3.055 1.049.684.566.85 1.27 1.015 1.973.533-.013 1.065-.026 1.449.577.384.603.618 1.822.528 2.89-.09 1.069-.505 1.986-.923 2.465a1.718 1.718 0 01-.112.118l-.005.034c.103.006.204.027.301.063.203.074.388.206.475.453.088.247.077.608.044.968a6.74 6.74 0 01-.503 2.065c-.111.26-.21.412-.357.529-.147.116-.343.195-.577.264a2.682 2.682 0 01-.135.036l-.074 1.1a2.603 2.603 0 01-.77 1.708l-.304.312h.001zm-8.522 2.415c.275 1.317.55 2.634.718 3.767.168 1.132.229 2.079.29 3.026.275-.562.55-1.125.855-1.547.305-.422.641-.703.977-.984.428.651.855 1.302.973 1.695.118.392-.072.525-.301 1.983-.23 1.457-.496 4.24-.763 7.022h-14.2c.336-2.841.672-5.683 1.214-7.614.542-1.931 1.29-2.953 2.465-3.737 1.176-.784 4.154-1.332 5.291-1.894 1.137-.563 1.81-1.14 2.481-1.717zm10.094 0c-.275 1.317-.55 2.634-.718 3.767-.168 1.132-.228 2.079-.29 3.026-.274-.562-.55-1.125-.855-1.547-.305-.422-.64-.703-.977-.984-.427.651-.855 1.302-.973 1.695-.118.392.072.525.301 1.983.23 1.457.497 4.24.764 7.022h14.2c-.336-2.841-.672-5.683-1.214-7.614-.542-1.931-1.29-2.953-2.467-3.737-1.175-.784-4.152-1.332-5.29-1.894-1.137-.563-1.81-1.14-2.48-1.717z" _fill="#ED1C24"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'print': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 46.5c12.426 0 22.5-10.074 22.5-22.5S36.426 1.5 24 1.5 1.5 11.574 1.5 24 11.574 46.5 24 46.5zm0 1.5c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z" _fill="#C4C4C4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M16.25 12.25h14.5v4h6v16.5h-6v4h-14.5v-4h-6v-16.5h6v-4zm1.5 4h11.5v-2.5h-11.5v2.5zm-1.5 15v-6.5h14.5v6.5h4.5v-13.5h-23.5v13.5h4.5zM32 21.75h-2v-1.5h2v1.5zm-14.25 4.5v9h11.5v-9h-11.5z" _fill="#C4C4C4"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'telegram': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21zm4.15-4.71a.804.804 0 01-.699-.085c-1.28-.842-3.538-2.37-3.538-2.37L8.457 15.54a.58.58 0 01-.513.12.537.537 0 01-.38-.335c-.479-1.301-1.396-3.859-1.396-3.859s-1.964-.574-3.46-.987a.492.492 0 01-.374-.446.484.484 0 01.342-.466c1.776-.607 4.92-1.692 7.873-2.71 2.59-.895 5.034-1.738 6.272-2.161a.54.54 0 01.504.082c.139.109.202.278.164.442-.245 1.063-.691 3.058-1.146 5.095-.473 2.113-.955 4.271-1.235 5.484a.696.696 0 01-.458.49zm-6.596-1.917l-1.06-3.23 7.516-4.431a.19.19 0 01.237.037.153.153 0 01-.02.22L8.29 12.328l-.236 2.045z" _fill="#363636"/>'
  }
})

<template>
    <div class="special-equipment-price">
        <div class="subscribe">
            <form
                class="subscribe__container"
                v-on:submit.prevent="submit"
            >
                <div class="subscribe__content">
                    <h3>{{ text.field.priceOffer }}</h3>
                </div>
                <div class="subscribe__content">
                    <div class="row between-default">
                        <div class="col-default-6 col-mobile-12">
                            <z-input
                                ref="name"
                                name="name"
                                v-model="params.name"
                                :placeholder="text.field.fullName"
                                required
                            />
                            <z-input
                                ref="phone"
                                name="phone"
                                type="phone"
                                v-model="params.phone"
                                :placeholder="text.field.phone"
                                required
                            />
                            <file-input
                                name="document"
                                accept="application/pdf, image/png, application/msword"
                                subtitle="pdf, png, doc"
                                @change="onChangeFile"
                            />
                        </div>
                        <div class="col-default-6 col-mobile-12">
                            <z-input
                                ref="mail"
                                name="mail"
                                type="email"
                                v-model="params.mail"
                                :placeholder="text.field.email"
                                required
                            />
                            <z-input
                                ref="message"
                                name="message"
                                v-model="params.message"
                                :placeholder="text.field.comment"
                            />
                        </div>
                    </div>
                </div>
                <div class="u-top-margin--double">
                    <z-button type="submit" rounded>{{ text.field.deal }}</z-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'
import { postPrice } from '@/api/price'
import { localize } from '@/utils/i18n'

export default {
    name: 'special-equipment-price',
    props: {
        id: String
    },
    data () {
        return {
            lang: this.$root.lang,
            params: {
                id: parseInt(this.id),
                name: '',
                phone: '',
                mail: '',
                message: '',
                lang: this.$root.lang,
                captcha: '',
                document: ''
            },
            text: {
                subscribeFormTitle: localize({
                    'ru': 'Предложить цену',
                    'en': 'Sign up&nbsp;for news'
                }),
                field: {
                    fullName: localize({
                        'ru': 'Введите ФИО',
                        'en': 'Name'
                    }),
                    email: localize({
                        'ru': 'Введите свой E-mail',
                        'en': 'Email'
                    }),
                    phone: localize({
                        'ru': 'Введите номер телефона',
                        'en': 'Введите номер телефона'
                    }),
                    comment: localize({
                        'ru': 'Комментарий',
                        'en': 'Комментарий'
                    }),
                    required: localize({
                        'ru': 'Поля, обязательные для заполнения',
                        'en': 'Required fields'
                    }),
                    agreement: {
                        text: localize({
                            'ru': 'Согласен с ',
                            'en': 'I agree with '
                        }),
                        link: localize({
                            'ru': 'политикой обработки персональных данных',
                            'en': 'the personal data processing policy'
                        }),
                        error: localize({
                            'ru': 'Поле обязательно для заполнения',
                            'en': 'Required field'
                        })
                    },
                    deal: localize({
                        'ru': 'Сделать предложение',
                        'en': 'IСделать предложение'
                    }),
                    priceOffer: localize({
                        'ru': 'Предложить цену',
                        'en': 'Предложить цену'
                    })
                },
                success: localize({
                    'ru': 'Отправка успешна',
                    'en': 'Sending successful'
                }),
                error: localize({
                    'ru': 'Ошибка отправки',
                    'en': 'Sending error'
                })
            },
            errors: {},
            gKey: '6LcWjt4UAAAAAEHUXE1KYNBAgxm5gDuWtCfi3ra5'
        }
    },
    created () {
        const script = document.createElement('script')

        script.async = true
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.gKey}`

        document.head.appendChild(script)
    },
    methods: {
        valid () {
            this.$refs.name.valid()
            this.$refs.mail.valid()
            this.$refs.phone.valid()

            let valid = !this.$refs.name.error &&
                !this.$refs.mail.error &&
                !this.$refs.phone.error

            return valid
        },
        onChangeFile (data) {
            this.params.document = data
        },
        submit () {
            let isValid = this.valid()
            if (isValid) {
                this.send(this, this.params, this.gKey)
            }
        },
        buildFormData (params) {
            let data = new FormData()

            // eslint-disable-next-line
            for (let key in params) {
                data.set(key, params[key])
            }
            return data
        },
        send: debounce(500, function (_this, params, key) {
            grecaptcha.ready(function () {
                grecaptcha.execute(key, {
                    action: 'subscribtionCaptcha'
                }).then(function (token) {
                    _this.$root.$bus.$emit('close-modal', { id: 'priceModal', index: 0 })
                    params.captcha = token

                    let data = _this.buildFormData(params)

                    postPrice(data).then(res => {
                        showNotyfications(_this.text[res.status], { type: res.status })
                    })
                }, function (reason) {
                    showNotyfications(reason, { type: 'error' })
                })
            })
        })
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cars': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.33 39.13c-.312-.96-.336-2.86-.329-3.998.003-.492.107-.814.61-.915.305-.062.615-.124.623-.25a76.9 76.9 0 01.373-4.18c.103-.962 1.854-1.81 2.907-2.725.935-.811 2.87-2.193 3.688-2.837.688-.543-.34-1.153.301-1.411 1.024-.45 2.945-.632 5.012-.694 5.071-.152 11.947-.21 16.9.139 1.763.124 3.52.354 4.868.765 3.732 1.141 6.928 3.956 10.468 5.563 4.284.437 8.598.816 12.938 1.85 1.404.336 1.709.863 2.14 1.666.47.875.285 1.696.5 2.193.344.027.356-.057.465.136.115.204.051.473.076.806.114 1.522.469 3.79-.85 4.737-.066.047-.135.065-.207.105h-1.99c-.994 0-.928-.182-1.036-1.188-.315-2.941-2.9-5.236-6.041-5.236-3.04.011-5.559 2.243-6.004 4.98-.202 1.246.11 1.47-1.216 1.47H19.522c-.928 0-.868-.087-.927-.983-.2-3.051-2.837-5.466-6.06-5.466-3.192 0-5.763 2.372-6.055 5.377-.104 1.067.017 1.072-1.025 1.072H2.932c-.788 0-1.34-.17-1.602-.977zm11.204-4.116c-2.58 0-4.671 2.011-4.671 4.493 0 2.481 2.092 4.493 4.671 4.493 2.58 0 4.672-2.012 4.672-4.493 0-2.482-2.092-4.493-4.672-4.493zm40.212 0c-2.58 0-4.672 2.011-4.672 4.493 0 2.481 2.092 4.493 4.672 4.493 2.58 0 4.671-2.012 4.671-4.493 0-2.482-2.091-4.493-4.671-4.493zm-12.544-5.457c-.03-.116-.191-.554-.22-1.015-.048-.78 1.433-.837 2.352-.78-1.782-1.274-4.221-2.826-6.588-3.615-.833-.268-3.297-.417-4.861-.449l-2.013-.04c-.363-.005-.412.157-.305.432.359.924.502 2.922.718 4.263.104.647.263.8.95.806 2.717.022 10 .524 9.967.399zm-21.74-.792c.035-1.444.33-2.846.775-4.224 2.545-1.07 4.451-.853 7.13-.884.204-.002.452.11.498.226.288.716.355 1.692.73 4.638.102.806.047.588-.883.577-2.29-.027-5.653-.196-8.25-.333zm-.427-3.953c-.43 1.27-.688 2.581-.846 3.917-.915-.047-.641-.087-1.462-.113-1.19-.038-1.154-.497-.4-1.487.257-.34 1.359-1.715 2.708-2.317zm-5.947-.54l-1.556.1c-1.534 1.21-3.067 2.282-4.6 3.45-.815.622-.025.56.623.446 2.402-.425 4.056-2.221 5.533-3.995z" _fill="#ED1C24"/>'
  }
})

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sustainability-people"},[_c('h3',{staticClass:"u-top-margin--xl-desktop u-bottom-margin--xl-desktop",domProps:{"innerHTML":_vm._s(_vm.text.firstChartTitle)}}),_vm._v(" "),_c('z-chart',{attrs:{"options":{
            clipPath: false,
            size: {
                height: 400
            },
            data: {
                columns: [
                    [_vm.text.female, 29],
                    [_vm.text.male, 71]
                ],
                type: "donut"
            },
            padding: {
                top: 24,
                right: 24,
                bottom: 24,
                left: 24
            },
            donut: {
                padAngle: 0.025,
                label: {
                    ratio: 1.35
                },
                width: 20
            }
        },"legend":{
            position: "right-middle",
        }},on:{"mounted":_vm.getStructureChart1}}),_vm._v(" "),_c('h3',{staticClass:"u-top-margin--xxl-desktop u-bottom-margin--xl-desktop",domProps:{"innerHTML":_vm._s(_vm.text.secondChartTitle)}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-default-6 col-mobile-12"},[_c('z-figure',{staticClass:"u-top-margin--l-desktop",scopedSlots:_vm._u([{key:"caption",fn:function(){return [_c('z-caption',{domProps:{"innerHTML":_vm._s(_vm.text.production)}})]},proxy:true},{key:"content",fn:function(){return [_c('z-chart',{attrs:{"options":{
                            clipPath: false,
                            size: {
                                height: 250
                            },
                            data: {
                                columns: [
                                    [_vm.text.female, 25],
                                    [_vm.text.male, 75]
                                ],
                                type: "donut"
                            },
                            padding: {
                                top: 24,
                                right: 24,
                                bottom: 24,
                                left: 24
                            },
                            donut: {
                                padAngle: 0.025,
                                label: {
                                    ratio: 1.65
                                },
                                width: 20
                            }
                        }}})]},proxy:true}])})],1),_vm._v(" "),_c('div',{staticClass:"col-default-6 col-mobile-12"},[_c('z-figure',{staticClass:"u-top-margin--l-desktop",scopedSlots:_vm._u([{key:"caption",fn:function(){return [_c('z-caption',{domProps:{"innerHTML":_vm._s(_vm.text.administration)}})]},proxy:true},{key:"content",fn:function(){return [_c('z-chart',{attrs:{"options":{
                            clipPath: false,
                            size: {
                                height: 250
                            },
                            data: {
                                columns: [
                                    [_vm.text.female, 62],
                                    [_vm.text.male, 38]
                                ],
                                type: "donut"
                            },
                            padding: {
                                top: 24,
                                right: 24,
                                bottom: 24,
                                left: 24
                            },
                            donut: {
                                padAngle: 0.025,
                                label: {
                                    ratio: 1.65
                                },
                                width: 20
                            }
                        }}})]},proxy:true}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <transition
        @before-enter="appearAnimationBefore"
        @enter="appearAnimationEnter"
        @leave="appearAnimationLeave"
    >
        <div
            class="cookie-popup"
            v-if="visible"
        >
            <div class="container cookie-popup__body">
                <div class="cookie-popup__text">
                    <h5>{{ text.title }}</h5>
                    <p><vue-raw :raw="text.description" /></p>
                </div>
                <transition>
                    <div v-if="visibleSettings" class="cookie-popup__settings">
                        <div class="row">
                            <div class="col-default-3 col-tablet-6 col-mobile-12">
                                <div class="settings__title">
                                    <h6 @click.stop="device === 'mobile' ? showText.policy = !showText.policy : ''">{{text.cookies.cookies_policy.title}} </h6>
                                    <input class="cookie-switch cookie-switch--locked" disabled type="checkbox" checked>
                                </div>
                                <p :class="showText.policy ? `settings__text--show` : `settings__text`" v-html="text.cookies.cookies_policy.description"></p>
                            </div>
                            <div class="col-default-3 col-tablet-6 col-mobile-12">
                                <div class="settings__title">
                                    <h6 @click.stop="device === 'mobile' ? showText.performance = !showText.performance : ''">{{text.cookies.performance.title}}</h6>
                                    <input @click.stop class="cookie-switch" type="checkbox" value="performance" v-model="checkedCookies">
                                </div>
                                <p :class="showText.performance ? `settings__text--show` : `settings__text`" v-html="text.cookies.performance.description"></p>
                            </div>
                            <div class="col-default-3 col-tablet-6 col-mobile-12">
                                <div class="settings__title">
                                    <h6 @click.stop="device === 'mobile' ? showText.functional = !showText.functional : ''">{{text.cookies.functional.title}} </h6>
                                    <input @click.stop class="cookie-switch" type="checkbox" value="functional" v-model="checkedCookies">
                                </div>
                                <p :class="showText.functional ? `settings__text--show` : `settings__text`" v-html="text.cookies.functional.description"></p>
                            </div>
                            <div class="col-default-3 col-tablet-6 col-mobile-12">
                                <div class="settings__title">
                                    <h6 @click.stop="device === 'mobile' ? showText.marketing = !showText.marketing : ''">{{text.cookies.marketing.title}}</h6>
                                    <input @click.stop class="cookie-switch" type="checkbox" value="marketing" v-model="checkedCookies">
                                </div>
                                <p :class="showText.marketing ? `settings__text--show` : `settings__text`" v-html="text.cookies.marketing.description"></p>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="cookie-popup__buttons">
                    <template v-if="!visibleSettings">
                        <div class="cookie-popup__button">
                            <z-button
                                @click="submit"
                                kind="primary"
                                :rounded="true"
                                tag="button"
                            >{{text.button.confirm }}</z-button>
                        </div>
                        <div class="cookie-popup__button">
                            <z-button
                                @click="showSettings"
                                tag="button"
                                kind="secondary"
                                :rounded="true"
                            >{{ text.button.settings }}</z-button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="cookie-popup__button">
                            <z-button
                                @click="submit"
                                kind="primary"
                                :rounded="true"
                                tag="button"
                            >{{ text.button.confirmAll}}</z-button>
                        </div>
                        <div class="cookie-popup__button">
                            <z-button
                                @click="submitSettings"
                                tag="button"
                                kind="secondary"
                                :rounded="true"
                            >{{ text.button.confirmSettings}}</z-button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Velocity from 'velocity-animate'
import { localize } from '@/utils/i18n'
import {deviceEnquire} from '@/utils/device'
const cookie = require('cookie_js')

export default {
    name: 'cookie-popup',
    data () {
        return {
            duration: 250,
            visible: false,
            visibleSettings: false,
            checkedCookies: ['cookie_policy'],
            device: 'desktop',
            showText: {
                policy: false,
                performance: false,
                functional: false,
                marketing: false
            },
            text: {
                title: localize({
                    'ru': 'Использование файлов cookie',
                    'en': 'Cookie policy',
                    'cn': 'Cookie政策'
                }),
                description: localize({
                    'ru': 'Этот веб-сайт использует файлы cookie. Некоторые обеспечивают работу сайта, а&nbsp;другие предназначены для повышения удобства пользователей. Нажмите &laquo;Принять&raquo;, чтобы принять наши файлы cookie. Вы&nbsp;можете изменить настройки файлов cookie, нажав &laquo;Изменить настройки&raquo;, или на&nbsp;странице <z-link href="/ru/terms/cookies/">&laquo;Политика использования файлов cookie&raquo;</z-link>.',
                    'en': 'This website uses cookies. Some are necessary for the operation, and some are optional and designed to&nbsp;improve your experience. Click &rsquo;Accept&rsquo; to&nbsp;accept the cookies. You can change the cookie settings by&nbsp;clicking &rsquo;Change settings&rsquo; or&nbsp;on&nbsp;the <z-link href="/en/terms/cookies/">Cookie Policy page</z-link>.',
                    'cn': '本网站使用cookie。有一些Cookie是必需的，也有一些Cookie是可选的，使用Cookie旨在改善您的体验。单击“接受”，即表示您接受cookie。您可以通过点击“更改设置”或在 <z-link href="/en/terms/cookies/">Cookie 政策页面 </z-link>上更改 Cookie 设置。'
                }),
                cookies: {
                    cookies_policy: {
                        title: localize({
                            'ru': 'Обязательные cookies',
                            'en': 'Strictly necessary cookies',
                            'cn': '绝对必要的Cookie'
                        }),
                        description: localize({
                            'ru': 'Эти файлы необходимы для правильной работы сайта и&nbsp;функционала.',
                            'en': 'These cookies are essential for the website and its features to work properly.',
                            'cn': '这些Cookie对于网站及其功能的正常运行至关重要。'
                        })
                    },
                    performance: {
                        title: localize({
                            'ru': 'Эксплуатационные cookies',
                            'en': 'Performance cookies',
                            'cn': '性能Cookie'
                        }),
                        description: localize({
                            'ru': 'Эти файлы собирают информацию о&nbsp;том, как вы&nbsp;используете сайт, и&nbsp;помогают нам улучшать его в&nbsp;соответствии с&nbsp;вашими потребностями.',
                            'en': 'These cookies collect information about how you use the website and help&nbsp;us to&nbsp;adapt the content according to&nbsp;your needs, improving its usability.',
                            'cn': '这些Cookie收集关于您如何使用网站的信息，帮助我们根据您的需求调整内容，提高网站的可用性。'
                        })
                    },
                    functional: {
                        title: localize({
                            'ru': 'Функциональные cookies',
                            'en': 'Functionality cookies',
                            'cn': '功能性Cookie'
                        }),
                        description: localize({
                            'ru': 'Эти файлы позволяют повысить удобство использования сайта, сохраняя информацию о&nbsp;настройках пользователя.',
                            'en': 'These cookies enable&nbsp;us to&nbsp;improve the comfort and usability of&nbsp;the website and to&nbsp;provide various features.',
                            'cn': '这些Cookie使我们能够改善网站的舒适性和可用性，并提供各种功能。'
                        })
                    },
                    marketing: {
                        title: localize({
                            'ru': 'Маркетинговые cookies',
                            'en': 'Marketing cookies',
                            'cn': '营销类Cookie'
                        }),
                        description: localize({
                            'ru': 'Эти файлы используются для показа рекламной информации, которая может быть вам интересна, в&nbsp;частности, на&nbsp;основе посещенных вами сайтов.',
                            'en': 'These cookies are used to&nbsp;send advertising and promotional information that is&nbsp;relevant to&nbsp;you, e.g. based on&nbsp;the web pages you visited.',
                            'cn': '这些Cookie用于发送与您相关的广告和促销信息，例如，根据您访问过的网页给您发送广告和促销信息'
                        })
                    }
                },
                button: {
                    confirm: localize({
                        'ru': 'Принять',
                        'en': 'Accept',
                        'cn': '接受'
                    }),
                    confirmAll: localize({
                        'ru': 'Разрешить все и продолжить',
                        'en': 'Allow all and continue',
                        'cn': '准许所有的就继续'
                    }),
                    settings: localize({
                        'ru': 'Изменить настройки',
                        'en': 'Change settings',
                        'cn': '更改设置'
                    }),
                    confirmSettings: localize({
                        'ru': 'Подтвердить мой выбор',
                        'en': 'Confirm my choices',
                        'cn': '确认选择'
                    })
                }
            }
        }
    },
    mounted () {
        this.visible = !cookie.get('cookies_policy')
        deviceEnquire((device) => {
            this.device = device
        })

        this.$root.$bus.$on('open-cookies', this.checkCookies)
    },
    beforeDestroy () {
        this.$root.$bus.$off('open-cookies', this.checkCookies)
    },
    methods: {
        checkCookies () {
            this.checkedCookies = ['cookie_policy']
            let cookies = cookie.get(['performance', 'functional', 'marketing'])
            Object.keys(cookies).forEach(key => {
                if (cookies[key] === 'true' && !this.checkedCookies.includes(key)) {
                    this.checkedCookies.push(key)
                }
            })
            this.visible = true
            this.visibleSettings = true
        },
        submit () {
            cookie.set({
                cookies_policy: true,
                performance: true,
                functional: true,
                marketing: true
            }, {
                domain: '.' + location.host,
                path: '/',
                expires: 365
            })

            // eslint-disable-next-line no-return-assign
            this.$nextTick(this.setVisibleFalse)
        },
        setVisibleFalse () {
            this.visible = false
        },
        showSettings () {
            this.visibleSettings = true
        },
        submitSettings () {
            cookie.set({
                cookies_policy: true,
                performance: this.checkedCookies.includes('performance'),
                functional: this.checkedCookies.includes('functional'),
                marketing: this.checkedCookies.includes('marketing')
            }, {
                domain: '.' + location.host,
                path: '/',
                expires: 365
            })

            // eslint-disable-next-line no-return-assign
            this.$nextTick(this.setVisibleFalse)
        },
        appearAnimationBefore (el) {
            el.style.opacity = 0
        },
        appearAnimationEnter (el, done) {
            Velocity(el, { opacity: 1 }, { duration: this.duration, delay: this.duration }, { complete: done })
        },
        appearAnimationLeave (el, done) {
            Velocity(el, { opacity: 0 }, { duration: this.duration })
            Velocity(el, { display: 'none' }, { complete: done })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

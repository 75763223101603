<template>
    <div class="z-preloader">
        <img :src="buildSrc()">
    </div>
</template>

<script>
export default {
    name: 'z-preloader',
    methods: {
        buildSrc: function () {
            return require('./images/preloader.gif')
        }
    }
}
</script>

<style lang="scss">
    .z-preloader {
        text-align: center;
        padding: 2rem 0;

        > img {
            height: 1rem;
        }
    }
</style>

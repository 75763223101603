/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Q&A': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M42.197 37.03H36.68V16.494h5.517v20.538zm-6.24 7.49c0-.868.331-1.63.994-2.288.694-.687 1.568-1.031 2.623-1.031.904 0 1.673.344 2.306 1.031.663.658.995 1.42.995 2.287 0 .987-.332 1.839-.995 2.556-.633.688-1.402 1.032-2.306 1.032-1.055 0-1.93-.344-2.623-1.032a3.638 3.638 0 01-.994-2.556z" _fill="#ED1C24"/><path pid="1" d="M20.515 24.525l.601.676.553-.716c1.348-1.747 2.99-2.578 4.968-2.578 1.036 0 1.86.315 2.522.923.664.61.978 1.323.978 2.183 0 1.602-.814 3.04-2.66 4.316l-.011.007-.01.008a44.783 44.783 0 00-1.887 1.46c-.416.344-.933.895-1.541 1.619l-.002.002a6.027 6.027 0 00-1.289 2.659l-.002.01-.002.01c-.17.943-.147 2.004.043 3.169l.104.635.644-.006 4.748-.045.743-.007v-.743c0-.372-.03-.718-.1-1.024a1.254 1.254 0 01.059-.433c.116-.29.208-.533.273-.726.023-.07.09-.204.245-.409.205-.26.346-.423.431-.508l.03-.029.026-.032c.093-.115.246-.282.471-.506.26-.23.454-.394.587-.499l.006-.004.005-.005c.186-.154.414-.35.682-.586.294-.233.533-.426.716-.577 1.46-1.171 2.619-2.392 3.455-3.668h0l.004-.007c.884-1.382 1.294-3.135 1.294-5.202 0-2.46-.953-4.53-2.827-6.164-1.835-1.683-4.34-2.478-7.419-2.478-2.042 0-3.926.444-5.638 1.341-1.703.892-3.031 2.087-3.954 3.59l-.292.475.37.417 3.076 3.452zm8.527 23.058l.006-.006.005-.006c.758-.859 1.137-1.889 1.137-3.052 0-1.078-.42-2.023-1.205-2.807a3.815 3.815 0 00-2.89-1.261c-1.213 0-2.265.414-3.107 1.249-.793.786-1.216 1.736-1.216 2.82 0 1.172.401 2.207 1.194 3.064l.01.012.012.012c.842.834 1.894 1.249 3.106 1.249 1.167 0 2.168-.426 2.948-1.274z" _fill="#ED1C24" _stroke="#fff" stroke-width="1.5"/>'
  }
})

document.addEventListener('DOMContentLoaded', () => {
    let linksWithDownload = document.querySelectorAll('a[download]')

    Array.from(linksWithDownload).forEach(link => {
        let file = link.getAttribute('href')

        const re = /(?:\.([^.]+))?$/
        let fileExtension = re.exec(file)[1]

        let fileName = link.getAttribute('download')

        let fileNameArr = fileName.split('.')

        let hasExtension = fileNameArr[fileNameArr.length - 1] === fileExtension

        if (fileExtension && fileName.length && !hasExtension) {
            link.setAttribute('download', fileName + '.' + fileExtension)
        }
    })
})

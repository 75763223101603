/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sustainability': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M12.319 32.806a1.088 1.088 0 01-.319-.773c0-.27.106-.555.319-.758l1.511-1.558c.12-.853.305-1.707.544-2.519l-3.023 3.088a2.502 2.502 0 000 3.509l3.023 3.088a17.884 17.884 0 01-.544-2.533l-1.511-1.544zM29.462 13.747c.212-.204.477-.311.756-.311.265 0 .53.108.742.31l1.512 1.559c.848.122 1.683.298 2.479.542l-3.023-3.089a2.438 2.438 0 00-1.71-.718c-.623 0-1.247.244-1.724.718l-3.023 3.089c.796-.244 1.631-.42 2.48-.542l1.51-1.558zM49.084 30.286l-3.036-3.088c.252.812.424 1.665.544 2.518l1.511 1.558c.212.204.318.488.318.759 0 .284-.106.555-.318.772l-1.511 1.544c-.12.868-.292 1.707-.544 2.534l3.036-3.089a2.554 2.554 0 00.703-1.76c0-.623-.24-1.26-.703-1.748z" _fill="#ED1C24"/><path pid="1" d="M30.218 15.549c-8.924 0-16.136 7.382-16.136 16.484 0 9.116 7.213 16.485 16.136 16.485 8.91 0 16.135-7.369 16.135-16.485 0-9.102-7.226-16.484-16.135-16.484zm-3.235.744a16.182 16.182 0 013.235-.338c1.073 0 2.16.121 3.221.338v6.53a9.336 9.336 0 00-3.221-.57c-1.114 0-2.201.19-3.235.57v-6.53zM14.798 35.34a17.244 17.244 0 010-6.597h6.39a9.91 9.91 0 00-.556 3.291c0 1.138.185 2.249.556 3.306h-6.39zm18.641 12.435a15.26 15.26 0 01-3.221.338c-1.087 0-2.175-.109-3.235-.338v-6.516a9.355 9.355 0 003.235.569c1.1 0 2.187-.19 3.221-.57v6.517zm-3.221-6.354c-5.078 0-9.188-4.199-9.188-9.387 0-5.174 4.11-9.373 9.188-9.373 5.064 0 9.174 4.2 9.174 9.373 0 5.188-4.11 9.387-9.174 9.387zm9.015-6.081a9.936 9.936 0 00.557-3.306 9.91 9.91 0 00-.557-3.291h6.39a17.22 17.22 0 01.332 3.291c0 1.11-.12 2.222-.332 3.306h-6.39z" _fill="#ED1C24"/><path pid="2" d="M30.96 50.32a1.02 1.02 0 01-.742.312c-.279 0-.544-.096-.756-.312l-1.512-1.544a16.505 16.505 0 01-2.48-.556l3.024 3.089c.477.488 1.1.73 1.724.73.61 0 1.233-.242 1.71-.73l3.023-3.089c-.795.244-1.63.434-2.48.556L30.96 50.32z" _fill="#ED1C24"/>'
  }
})

<template>
    <li class="z-list-item">
        <slot/>
    </li>
</template>

<script>
export default {
    name: 'z-list-item'
}
</script>

<template>
    <div id="terminals-map" class="terminals-map">
        <div class="terminals-map__legend" v-if="!info.show">
            <div class="terminals-map__legend-item terminals-map__legend-item--blue">{{ legend.sea }}</div>
            <div class="terminals-map__legend-item terminals-map__legend-item--red">{{ legend.rail }}</div>
            <div class="terminals-map__legend-item terminals-map__legend-item--yellow">{{ legend.road }}</div>
        </div>
        <div class="terminals-info" v-else>
            <div class="terminals-info__container">
                <span class="terminals-info__name">{{ info.name }}</span>
                <!-- <div class="terminals-info__detail" v-html="info.detail"></div>
                <z-key-indicator
                    v-for="(indicator, i) in info.indicators"
                    :key="i"
                    :value="indicator.value"
                    :unit="indicator.unit"
                    :name="indicator.name"
                    :prefix="indicator.prefix"
                ></z-key-indicator> -->
            </div>
        </div>
        <button v-if="info.show" class="terminals-map__reset" @click="resetMap"></button>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { Map } from './map'

export default {
    name: 'terminals-map',
    components: {
    },
    data () {
        return {
            map: null,
            lang: this.$root.lang,
            info: {
                show: false,
                title: '',
                detail: '',
                indicators: []
            },
            legend: {
                sea: localize({
                    ru: 'Морские пути',
                    en: 'By Sea',
                    cn: '海上航线'
                }),
                rail: localize({
                    ru: 'Железнодорожные пути',
                    en: 'By rail',
                    cn: '铁路'
                }),
                road: localize({
                    ru: 'Автомобильные пути',
                    en: 'By road',
                    cn: '公路'
                })
            }
        }
    },
    methods: {
        resetMap () {
            this.info.show = false
            this.map.terminalsMap.zoom.zoomReset()
            setTimeout(() => {
                this.map.terminalsMap.zoom.zoomBy([810, 220], 3.2)
            }, 500)
        }
    },
    created () {
        const self = this

        document.addEventListener('DOMContentLoaded', () => {
            const $mapNode = document.querySelector('#terminals-map')

            if (typeof ($mapNode) !== 'undefined' && $mapNode !== null) {
                this.map = new Map('terminals-map')
                this.map.initMap()

                this.map.terminalsMap
                    .on('select', function (point) {
                        self.map.terminalsMap.refreshSize()
                        if (point.link && !point.showInfo) {
                            window.location.href = `/${self.lang}/${point.link}`
                        } else if (point.showInfo) {
                            const centerPoint = [self.map.terminalsMap.width / 2, self.map.terminalsMap.height / 2]
                            const coords = self.map.terminalsMap.getObjectPixelCoords(this)
                            self.info = {
                                show: true,
                                name: point.name,
                                detail: point.detail,
                                indicators: point.indicators
                            }

                            /* Hack */
                            if (coords[0] > 700) {
                                coords[1] = 350
                                coords[0] = 860
                            }
                            self.map.terminalsMap.zoom.zoomAndCenter(coords, 12, centerPoint, true, 600)
                        }
                    })
                    .on('pointSelect', function (elem) {
                        console.log('pointSelect')
                    })
                    .on('removeSelection', function () {
                        console.log('removeSelection')
                    })
                    .on('clearSelection', function () {
                        console.log('clearSelection')
                    })
                    .on('zoom', function (scale) {
                        self.map.zoomShowContent(scale)
                    })
                    .on('zoomReset', function (scale) {
                        console.log('zoomReset')
                        self.map.terminalsMap.clearHighlightPointsInCluster()
                    })

                this.map.terminalsMap.zoom.zoomBy([810, 220], 3.2)
                this.map.zoomShowContent(1)
            }
        })
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <component
        :is="tag"
        v-on="$listeners"
        class="z-button"
        :class="classObject"
        @click="$emit('click')"
    >
        <div v-if="hasLeftSlot" class="z-button__icon z-button__icon--align-left">
            <slot name="left"></slot>
        </div>
        <slot></slot>
        <div v-if="hasRightSlot" class="z-button__icon z-button__icon--align-right">
            <slot name="right"></slot>
        </div>
    </component>
</template>

<script>
export default {
    name: 'z-button',
    props: {
        tag: {
            type: String,
            default: 'button'
        },
        kind: {
            type: String,
            validator: prop => ['primary', 'secondary', 'text', 'tag'].includes(prop),
            default: 'primary'
        },
        rounded: {
            type: Boolean
        },
        inverted: {
            type: Boolean
        },
        size: {
            type: String,
            validator: prop => ['xs', 's', 'm', 'l', 'xl'].includes(prop)
        },
        theme: {
            type: String
        }
    },
    computed: {
        hasLeftSlot () {
            return !!this.$slots['left']
        },
        hasRightSlot () {
            return !!this.$slots['right']
        },
        classObject () {
            let arrClass = [
                {
                    'z-button--rounded': this.rounded,
                    'z-button--inverted': this.inverted
                }
            ]

            if (this.hasRightSlot || this.hasLeftSlot) {
                arrClass.push(`z-button--with-slot`)
            }
            if (this.kind) {
                arrClass.push(`z-button--kind-${this.kind}`)
            }
            if (this.size) {
                arrClass.push(`z-button--size-${this.size}`)
            }
            if (this.theme) {
                arrClass.push(`z-button--theme-${this.theme}`)
            }

            return arrClass
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

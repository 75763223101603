<template>
    <div class="media-kit">
        <div class="news__list">
            <z-not-found
                v-if="(notFound.state && !loading)"
                :title="notFound.title"
                :text="notFound.text"
            ></z-not-found>
            <div class="row" v-else-if="list && list.length" equal-height=".card">
                <div
                    v-for="(item, i) in list"
                    :key="i"
                    class="col-default-4 col-mobile-12"
                >
                    <card
                        :link="item.type === 'album' ? item.link : ''"
                        @click="item.type === 'video' ? $root.$bus.$emit('open-modal', {id: 'videosModal', index: item.index}) : ''"
                        :mark="false"
                        :overlay="item.image ? 'rgba(0,0,0,0.45)' : ''"
                        :theme="item.image ? 'background' : 'red'"
                        :image="item.type === 'album' || item.type === 'file' ? item.image : item.image.preview"
                        style="min-height: 320px"
                        data-stable-height="true"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>
                            {{ item.name }}
                        </template>
                        <template v-slot:content>
                            <span v-if="item.count" class="card__file-info">{{item.count}}</span>
                            <span v-if="item.size && item.duration" class="card__file-info">{{item.size}} | {{item.duration}}</span>
                            <!-- if filter photos -->
                            <span v-if="item.size && item.extension" class="card__file-info">{{item.size}}, {{item.extension}}</span>
                            <!-- end if -->
                        </template>
                        <template v-slot:bottom-right v-if="item.type === 'album'">
                            <card-icon theme="white" size="m">
                                <z-icon name="photo" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                        <template v-slot:bottom-left v-if="item.type === 'video' || item.type === 'picture' || item.type === 'file'">
                            <template v-if="item.type === 'video'">
                                <z-link
                                    class="ar-card__icon u-inline"
                                    @click="$root.$bus.$emit('open-modal', {id: 'videosModal', index: item.index})"
                                    href="#"
                                >
                                    <z-icon name="video" width="48" height="48"></z-icon>
                                </z-link>
                            </template>
                            <!-- if pictures -->
                            <template v-if="item.image && item.type === 'picture'">
                                <z-link
                                    class="ar-card__icon u-inline"
                                    :content="image.download"
                                    :href="item.image.full"
                                    downloadFile
                                    :downloadTitle="item.name"
                                    v-tippy-tooltip="{
                                        theme : 'transparent',
                                        arrow: true,
                                        placement: 'top-start'
                                    }"
                                >
                                    <z-icon name="download" width="48" height="48"></z-icon>
                                </z-link>
                                <z-link
                                    class="ar-card__icon u-inline"
                                    :content="image.preview"
                                    href="#"
                                    @click="$root.$bus.$emit('open-modal', {id: 'videosModal', index: item.index})"
                                    v-tippy-tooltip="{
                                        theme : 'transparent',
                                        arrow: true,
                                        placement: 'top-start'
                                    }"
                                >
                                    <z-icon name="zoom-in" width="48" height="48"></z-icon>
                                </z-link>
                            </template>
                            <!-- end if -->
                            <template v-if="item.type === 'file'">
                                <z-link
                                    class="ar-card__icon u-inline"
                                    :href="item.link"
                                    :downloadTitle="item.name"
                                    downloadFile
                                    :content="setVersion(item.extension)"

                                    v-tippy-tooltip="{
                                        theme : 'transparent',
                                        arrow: true,
                                        placement: 'top-start'
                                    }"
                                >
                                    <z-icon name="download" width="48" height="48"></z-icon>
                                </z-link>
                                <z-link
                                    class="ar-card__icon u-inline"
                                    :href="item.link"
                                    target="_blank"
                                    :content="content.online"

                                    v-tippy-tooltip="{
                                        theme : 'transparent',
                                        arrow: true,
                                        placement: 'top-start'
                                    }"
                                >
                                    <z-icon name="desktop" width="48" height="48"></z-icon>
                                </z-link>
                            </template>
                        </template>
                    </card>
                </div>
            </div>
            <z-preloader v-if="loading"></z-preloader>
        </div>
        <z-modal
            video
            autoWidth
            id="videosModal"
            class="gallery-modal"
            :data="slidesForModal"
        ></z-modal>
    </div>
</template>

<script>
import { getMediaKit } from '@/api/media'
import { throttle, debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'
import { localize } from '@/utils/i18n'

export default {
    name: 'media-kit',
    props: {
        initial: {
            type: Object
        }
    },
    data () {
        return {
            loading: false,
            params: {},
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не найдено',
                    'en': 'No results found'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            },
            image: {
                download: localize({
                    'ru': 'Скачать изображение',
                    'en': 'Download image'
                }),
                preview: localize({
                    'ru': 'Посмотреть изображение',
                    'en': 'Preview image'
                })
            },
            content: {
                version: localize({
                    'ru': 'версия',
                    'en': 'version'
                }),
                online: localize({
                    'ru': 'Онлайн версия',
                    'en': 'Online version'
                })
            },
            data: this.$root.app.components['media-kit-list'],
            list: this.$root.app.components['media-kit-list'].items,
            page: this.$root.app.components['media-kit-list'].nav,
            code: this.$root.app.system.params.get.CODE
        }
    },
    computed: {
        slidesForModal () {
            if (this.list) {
                return this.list.filter(elem => elem.type === 'video' || elem.type === 'picture').map((el, i) => {
                    if (el.type === 'video') {
                        el.image = {
                            preview: el.image,
                            full: el.link
                        }
                        el.index = i
                    } else if (el.type === 'picture') {
                        if (!el.image.hasOwnProperty('full')) {
                            el.image = {
                                preview: el.preview,
                                full: el.image
                            }
                        }
                        el.index = i
                    }
                    return el
                })
            }
        },
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })
            delete params['code']

            return params
        }
    },
    methods: {
        getMoreMedia () {
            this.loading = true
            if (this.params.page === 1) this.list = []

            queryString(this.clearEmptyParams)

            getMediaKit(this.params).then((res) => {
                if (res.hasOwnProperty('filters')) {
                    if (Object.keys(res.filters).length) this.filters = res.filters
                }

                if (res.hasOwnProperty('nav')) {
                    this.page.current = res.nav.current
                    this.page.total = res.nav.total
                    this.page.count = res.nav.count
                }

                if (res.hasOwnProperty('items') && res.items) {
                    if (res.items.length === 0) {
                        this.notFound.state = true
                    } else {
                        this.notFound.state = false
                    }

                    if (this.page.current < 2) {
                        this.list = res.items
                    } else {
                        this.list = this.list.concat(res.items)
                    }
                }

                // This garbage code for the sake of IE
                let customEvent = document.createEvent('HTMLEvents')
                customEvent.initEvent('newCardsAdded', true, true)
                document.dispatchEvent(customEvent)

                this.loading = false
            })
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(500, e => {
                const offsetTop = this.$el.offsetTop
                const offsetHeight = this.$el.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (scrolled >= (bottom - 100) && !this.loading && this.page.current < this.page.total) {
                    this.params.page++
                    this.getMoreMedia()
                };
            }))
        },
        setVersion (item) {
            if (item) {
                return `${item.toUpperCase()} ${this.content.version}`
            }
        }
    },
    created () {
        this.params = {
            ...this.initial,
            page: 1,
            code: this.code
        }

        this.debouncedChange = debounce(500, (data) => {
            if (data == null) {
                const paramsObj = {
                    name: '',
                    code: this.code
                }

                this.params = paramsObj
            } else if (data.hasOwnProperty('name')) {
                this.params[data.name] = data.value
            }

            this.params.page = 1
            this.getMoreMedia()
        })

        if (this.list && !this.list.length) {
            this.notFound.state = true
        }
        this.listenScroll()
    }
}
</script>

<style lang="scss">
.media-kit {
    .detail {
        &__name {
            color: $token-colors-lighter-grey;
            margin-bottom: 24px;
        }

        &__cargo-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            & > span {
                font-size: 14px;
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }
    }

    .ar-card__icon {
        &:hover {
            svg {
                background-color: transparent;
            }

            [pid="0"] {
                fill: $token-colors-gp-red !important;
            }
        }
    }

    .ar-card {
        .z-dropdown__trigger {
            text-decoration: none;
            color: #fff !important;
        }

        .z-dropdown__content {
            border-radius: 4px;
            overflow: hidden;
        }

        &__version {
            background: rgba(255, 255, 255, 0.4);
            font-size: 12px;
            line-height: 1.5;
            color: #FFFFFF;
            padding: 0.5rem;
            cursor: pointer;
        }

        &__active {
            font-size: 14px;
            line-height: 1.5;
        }

        &__icon {

            &:not(:last-child) {
                margin-right: 1rem;
            }

            // + .ar-card__icon {
            //     margin-left: 1rem;
            // }

            svg {
                background-color: transparent;
                border-radius: 50%;
                transition: background-color 0.25s;
            }

            path {
                fill: #fff;
            }

            &:hover {
                svg {
                    background-color: #fff;
                }

                [pid="0"] {
                    fill: #fff !important;
                }
            }
        }
    }

    .card__bottom-left {
        flex-basis: 100%;
    }
}
</style>

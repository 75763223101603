<template>
    <div id="eetpWidget"> </div>
</template>

<script>

export default {
    name: 'widget-roseltorg',
    props: {
        settings: Object,
        widgetName: String,
        filter: Object
    },

    data () {
        return {
            show: false,
        }
    },
    mounted () {
        this.initWidget()
    },
    methods: {
        initWidget () {
            (function (divId, eetpObj, scriptUrl, options) {
                window[eetpObj] = window[eetpObj] || {};
                window[eetpObj][divId] = options;
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.charset = "UTF-8";
                s.async = true;
                s.src = scriptUrl;
                var ss = document.getElementsByTagName("script")[0];
                ss.parentNode.insertBefore(s, ss);
            })(
                "eetpWidget",
                "eetpWidgetObj",
                "https://www.roseltorg.ru/modules/etp_widget/js/widget.min.js",
                {
                    root: "https://www.roseltorg.ru",
                    settings: this.settings, //{filter: [], widgetStatus: ["5","0","1"], limit: []},
                    widgetName: this.widgetName, //"Активные закупочные процедуры на ЭТП",
                    filter: this.filter, //{inn:['7705867885'], kpp:['781001001'], source:["all"], }
                }
            );
        }

    }

}
</script>

<style lang="scss" src="./index.scss"></style>

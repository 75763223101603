<template>
    <div class="contacts contacts-page">
        <z-tabs v-if="contacts.length">
            <template v-slot:label>
                <z-tabs-label
                    v-for="tab in contacts"
                    :key="tab.code"
                    :id="tab.code"
                >{{ tab.name }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="tab in contacts"
                    :key="tab.code"
                    :id="tab.code"
                >
                    <contacts-item :data="tab"></contacts-item>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
export default {
    name: 'contacts',
    props: {
    },
    data () {
        return {
            contacts: this.$root.app.components['contacts']
        }
    }
}
</script>

<style lang="scss">
    .contacts {
        &-page {
            padding: 2rem 0;
        }
    }
</style>

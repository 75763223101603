/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lighthouse': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M36.54 11.78l.087-.092v-.752l-.086-.091h-9.083l-.085.091v.752l.085.093h9.083zM32.273 5.871V4.294c0-.165-.12-.294-.273-.294-.154 0-.273.128-.273.294V5.87c-1.997.129-3.91 1.597-3.91 4.423h8.366c0-2.826-1.913-4.293-3.91-4.423zM37.702 19.982l.086-.092v-.899l-.086-.091H26.297l-.085.091v.9l.085.09h11.405zM38.914 58.917H25.086L25 59.01v.899l.086.092h13.828l.086-.092v-.9l-.086-.09zM36.234 20.533h-8.468c-.683 12.605-1.35 25.229-2.032 37.834h12.532c-.684-12.606-1.35-25.23-2.032-37.834zm-8.263 5.779h8.058l.563 10.312h-9.184l.563-10.312zm5.002 15.798c0 .57-.427 1.046-.974 1.046-.547 0-.973-.477-.973-1.046 0-.587.427-1.064.973-1.064s.974.477.974 1.064zm1.144 15.706h-4.234v-5.578c0-1.248.956-2.275 2.116-2.275 1.16 0 2.117 1.027 2.117 2.275v5.578z" _fill="#ED1C24"/><path pid="1" d="M32 50.789c-.751 0-1.365.66-1.365 1.45v5.027h2.731v-5.028c0-.788-.615-1.45-1.366-1.45zM36.234 18.35V12.33h-8.468v6.018H31.3V15.56c0-.403.325-.752.7-.752.375 0 .7.349.7.752v2.79h3.534zm-6.198-.551h-1.382V15.01c0-.403.307-.752.682-.752.393 0 .7.349.7.752v2.789zm3.927-2.79c0-.403.307-.752.7-.752.375 0 .683.349.683.752v2.789h-1.383v-2.789z" _fill="#ED1C24"/>'
  }
})


const BrowserMixin = {
    data () {
        return {
            browser: ''
        }
    },
    methods: {
        getBrowserType () {
            const test = regexp => {
                return regexp.test(navigator.userAgent)
            }

            if (test(/opr\//i) || !!window.opr) {
                this.browser = 'Opera'
            } else if (test(/chromium\sgost/i)) {
                this.browser = 'ChromiumGost'
            } else if (test(/yabrowser/i)) {
                this.browser = 'YandexBrowser'
            } else if (test(/edg/i)) {
                this.browser = 'MicrosoftEdge'
            } else if (test(/chrome|chromium|crios/i)) {
                this.browser = 'GoogleChrome'
            } else if (test(/firefox|fxios/i)) {
                this.browser = 'MozillaFirefox'
            } else if (test(/safari/i)) {
                this.browser = 'AppleSafari'
            } else if (test(/trident/i)) {
                this.browser = 'MicrosoftIE'
            } else if (test(/ucbrowser/i)) {
                this.browser = 'UC Browser'
            } else if (test(/samsungbrowser/i)) {
                this.browser = 'SamsungBrowser'
            } else {
                this.browser = ''
            }
        }
    },
    mounted () {
        this.getBrowserType()
    }
}

export default BrowserMixin

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mediagallery': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<rect pid="0" x="13.5" y="20.519" width="37" height="26" rx="2" _fill="#ED1C24"/><path pid="1" d="M46.688 17.48H17.311a2 2 0 00-2 2v.102h33.376v-.101a2 2 0 00-2-2z" _fill="#ED1C24"/><path pid="2" d="M40 39.27H24l4.694-4.366a1 1 0 011.315-.04l1.165.956a1 1 0 001.342-.066l2.558-2.559a1 1 0 011.576.211L40 39.27z" _fill="#fff"/><circle pid="3" cx="31" cy="30.894" r="1" _fill="#fff"/>'
  }
})

<template src="./index.html"></template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'

export default {
    name: 'z-logo',
    mixins: [mixinDevice],
    props: {
        isIndex: {
            type: Boolean
        },
        siteDir: {
            type: String,
            default: '/',
            required: true
        },
        theme: {
            type: String,
            validator: prop => ['new-year'].includes(prop)
        }
    },
    data: function () {
        return {
            lang: this.$root.lang,
            title: localize({
                'ru': 'Перейти на главную страницу',
                'en': 'Go to mainpage'
            })
        }
    },
    methods: {
        buildSrc: function () {
            if (this.lang === 'ru') {
                return require('./images/logo-ru.svg')
            } else {
                return require('./images/logo-eng.svg')
            }
        }
    },
    computed: {
        classObject () {
            return [
                'z-logo',
                { 'z-logo--theme-new-year': this.theme }
            ]
        }

    }
}
</script>

<style lang="scss" src="./index.scss"></style>

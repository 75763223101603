<template src="./index.html"></template>

<script>
import { bb } from 'billboard.js/dist/billboard.pkgd'
// import { bb } from 'billboard.js'
// window.bb = bb

export default {
    name: 'z-chart',
    props: {
        options: {
            type: Object,
            required: true
        },
        legend: {
            type: Object,
            default: () => {
                return {
                    position: 'left-bottom'
                }
            }
        }
    },
    data () {
        return {
            $chart: null,
            theme: {
                grid: {
                    y: {
                        show: true
                    }
                },
                axis: {
                    x: {
                        tick: {
                            show: false
                        },
                        label: {
                            color: '#6F7070'
                        }
                    },
                    y: {
                        tick: {
                            show: false
                        }
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 30
                },
                color: {
                    pattern: [
                        '#ed1c24',
                        '#808083',
                        '#c4c4c4',
                        '#e4e4e4',
                        '#ffa1a5',
                        '#ff5258',
                        '#be1d2c',
                        '#a61e3a'
                    ]
                },
                legend: {
                    contents: {
                        template: `<span class="z-chart__legend-item">
                                        <span class="z-chart__legend-square" style='background-color:{=COLOR}'"></span>
                                        <span class="z-chart__legend-title">{=TITLE}</span>
                                    </span>`
                    }
                },
                bar: {
                    width: {
                        ratio: 0.7,
                        max: 50
                    }
                }
            }
        }
    },
    mounted () {
        this.$chart = this.generateChart()
        this.$emit('mounted', this.$chart)
    },
    unmounted () {
        this.destroyChart()
    },
    watch: {
        'options.data': {
            deep: true,
            handler (newData) {
                this.loadDataChart(newData)
            }
        }
    },
    methods: {
        generateChart () {
            /**
             * @function generateChart
             *
             * @description
             * generates the charts bases on the options this
             *
             * @returns {Object} the generated chart this
             */
            const options = Object.assign({
                ...this.theme,
                bindto: this.$refs.chart
            }, this.options)

            if (!options.legend.hasOwnProperty('show') || options.legend.show) {
                options.legend.contents.bindto = this.$refs.legend
            }

            return bb.generate(options)
        },
        destroyChart () {
            /**
             * @function destroyChart
             *
             * @description
             * destroys the chart and sets ref to null
             */
            return () => {
                try {
                    this.$chart.destroy()
                    this.$chart = null
                } catch (error) {
                    console.error('Internal Billboard.js error', error)
                }
            }
        },
        loadDataChart (data) {
            /**
             * @function loadDataChart
             *
             * @description
             * Updates the chart with the new data
             *
             * @param {object} options from this to update the chart with
             */
            return (data) => {
                if (!this.$chart) {
                    this.$chart = this.generateChart(this.options)
                }
                this.$chart.load(data)
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

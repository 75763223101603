<template>
    <p :class="`z-lead ${bold ? 'z-lead--bold' : ''}`">
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: 'z-lead',
    props: {
        bold: Boolean
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <div class="z-dropdown" v-click-outside="close" v-on:keyup.esc="close" :class="{ 'is-opened': isOpen }">
        <a href="#" v-on:click.prevent="toggle" class="z-dropdown__trigger">
            <slot name="trigger"></slot>
        </a>
        <div v-show="isOpen" class="z-dropdown__content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-dropdown',
    data: function () {
        return {
            isOpen: false
        }
    },
    methods: {
        toggle () {
            this.isOpen = !this.isOpen
        },
        close () {
            this.isOpen = false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

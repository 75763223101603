<template>
    <div class="investors-news">
        <z-tabs>
            <template v-slot:label>
                <z-tabs-label
                    id="news"
                    @click.native="updateNews"
                >IR news</z-tabs-label>
                <!-- <z-tabs-label id="frame">Regulatory News</z-tabs-label> -->
            </template>
            <template v-slot:content>
                <z-tabs-content id="news">
                    <news
                        :initial="initial"
                        :component="component"
                        url="/investors-news"
                    />
                </z-tabs-content>
                <!-- <z-tabs-content id="frame">
                    <iframe
                        :src="src"
                        width="100%"
                        height="1000px"
                        frameborder="0"
                    ></iframe>
                </z-tabs-content> -->
            </template>
        </z-tabs>
    </div>
</template>

<script>
export default {
    name: 'investors-news',
    props: {
        initial: {
            type: Object
        },
        component: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            src: 'https://irpages2.eqs.com/websites/globalports/English/1000/news-tool---rns---eqs-group.html?iframe=true'
        }
    },
    methods: {
        updateNews () {
            let customEvent = document.createEvent('HTMLEvents')
            customEvent.initEvent('newCardsAdded', true, true)
            document.dispatchEvent(customEvent)
        }
    }
}
</script>

<style lang="scss"></style>

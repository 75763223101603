<template>
    <div class="board-of-directors">
        <div class="u-top-margin--l-desktop u-bottom-margin--l-desktop">
            <z-caption
                weight="700"
                v-html="text.p"
            ></z-caption>
        </div>
        <bods
            :data="boardOfDirectors"
        ></bods>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'board-of-directors',
    data () {
        return {
            boardOfDirectors: this.$root.app.components['board-of-directors'],
            text: {
                p: localize({
                    'ru': 'Совет директоров Global Ports состоит из&nbsp;11&nbsp;членов, включая трех независимых директоров.',
                    'en': 'The Board of&nbsp;Directors of&nbsp;Global Ports is&nbsp;comprised of&nbsp;eleven members including 3&nbsp;independent directors.',
                    'cn': 'Global Ports 的董事会由11名成员组成，其中有3名独立董事 。'
                })
            }
        }
    }
}
</script>

<template src="./index.html"></template>

<script>
// import { localize } from '@/utils/i18n'

export default {
    name: 'navigation-bottom',
    data () {
        return {
            list: this.$root.app.components.navigation.bottom
        }
    },
    methods: {
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : '_self'
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

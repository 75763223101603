<template>
    <div :class="heroClass">
        <div class="z-hero__bg" :style="heroStyle"></div>

        <div v-if="type == 'video'" class="page-top__video">
            <video class="js-video" muted autoplay loop>
                <source :src="heroSrc" :type="'video/' + srcExtension">
            </video>
        </div>

        <div class="container">
            <z-breadcrumbs v-if="breadcrumbs"></z-breadcrumbs>

            <template v-if="terminal">
                <div v-if="titleShow" class="page__title">
                    <div class="row middle-default">
                        <div class="col-default-9 col-tablet-12">
                            <h1 v-html="heroTitle"></h1>
                        </div>
                        <div class="col-default-3 col-tablet-12">
                            <img v-if="terminal" :src="terminalLogo" />
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-if="titleShow" class="page__title">
                    <h1 v-html="heroTitle"></h1>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'z-hero',
    props: {
        size: {
            type: String,
            default: 'm'
        },
        type: {
            type: String,
            default: 'image'
        },
        position: {
            type: String,
            default: '50% 50%'
        },
        src: {
            type: String,
            default: ''
        },
        srcExtension: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        titleShow: {
            type: Boolean,
            default: true
        },
        breadcrumbs: {
            type: Boolean,
            default: true
        },
        terminal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            pageHero: this.$root.app.page.hero
        }
    },
    created () {
        this.SetHeroTitle(this.title)
        this.SetHeroSrc(this.src)

        if (this.pageHero) {
            if (this.pageHero.image !== '') {
                this.SetHeroSrc(this.pageHero.image)
            }
            if (this.pageHero.icon !== '') {
                this.SetTerminalLogo(this.pageHero.icon)
            }
        }
    },
    methods: {
        ...mapActions(['SetHeroTitle', 'SetHeroSrc', 'SetTerminalLogo'])
    },
    computed: {
        heroTitle () {
            return this.$store.getters.heroTitle
        },
        heroSrc () {
            return this.$store.getters.heroSrc
        },
        terminalLogo () {
            return this.$store.getters.terminalLogo
        },
        heroClass () {
            let heroClass = 'z-hero'

            if (this.type === 'video') {
                heroClass += ' z-hero--video'
            };

            heroClass += ` z-hero--size-${this.size}`

            return heroClass
        },
        heroStyle () {
            let heroStyle = 'background-color: rgba(0, 0, 0, 0.8);'

            if (this.src) {
                heroStyle = `background-image: url('${this.heroSrc}'); background-position: '${this.position}';`
            };

            return heroStyle
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

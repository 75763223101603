<template>
    <div class="media-photos">
        <media-filters
            :data="filtersData"
            @change="debouncedChange"
        ></media-filters>
        <div class="news__list">
            <z-not-found
                v-if="(notFound.state && !loading)"
                :title="notFound.title"
                :text="notFound.text"
            ></z-not-found>
            <div class="row" v-else-if="list.length" equal-height=".card">
                <div
                    v-for="(item, i) in list"
                    :key="i"
                    class="col-default-4 col-mobile-12"
                >
                    <card
                        :link="item.type === 'album' ? item.link : ''"
                        :mark="false"
                        :overlay="item.image ? 'rgba(0,0,0,0.35)' : ''"
                        :theme="item.image ? 'background' : 'red'"
                        equal-height-max-breakpoint="mobile"
                        :image="item.type === 'album' ? item.image : item.image.preview"
                        style="min-height: 272px"
                    >
                        <template v-slot:title>
                            {{ item.name }}
                        </template>
                        <template v-slot:content>
                            <span v-if="item.count" class="card__file-info">{{item.count}} {{text.photos}}</span>
                            <span v-if="item.size && item.duration" class="card__file-info">{{item.size}} | {{item.duration}}</span>
                            <!-- if filter photos -->
                            <span v-if="item.size && item.extension" class="card__file-info">{{item.size}}, {{item.extension}}</span>
                            <span v-if="item.cargo && item.cargo.length"><p class="detail__cargo-list"><span v-for="(tag,index) in item.cargo" :key="index">{{tag}}</span></p></span>
                            <!-- end if -->
                        </template>
                        <template v-slot:bottom-right v-if="item.type === 'album'">
                            <card-icon theme="white" size="m">
                                <z-icon name="photo" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                        <template v-slot:bottom-left v-if="item.type === 'video' || item.type === 'photo'">
                            <template v-if="item.type === 'video'">
                                <z-link
                                    class="ar-card__icon u-inline"
                                    @click="$root.$bus.$emit('open-modal', {id: 'videosModal', index: item.index})"
                                    href="#"
                                >
                                    <z-icon name="video" width="48" height="48"></z-icon>
                                </z-link>
                            </template>
                            <!-- if filter photos -->
                            <template v-if="item.image && item.type === 'photo'">
                                <z-link
                                    class="ar-card__icon u-inline"
                                    :content="image.download"
                                    :href="item.image.full"
                                    downloadFile
                                    :downloadTitle="item.name"
                                    v-tippy-tooltip="{
                                        theme : 'transparent',
                                        arrow: true,
                                        placement: 'top-start'
                                    }"
                                >
                                    <z-icon name="download" width="48" height="48"></z-icon>
                                </z-link>
                                <z-link
                                    class="ar-card__icon u-inline"
                                    :content="image.preview"
                                    href="#"
                                    @click="$root.$bus.$emit('open-modal', {id: 'videosModal', index: item.index})"
                                    v-tippy-tooltip="{
                                        theme : 'transparent',
                                        arrow: true,
                                        placement: 'top-start'
                                    }"
                                >
                                    <z-icon name="zoom-in" width="48" height="48"></z-icon>
                                </z-link>
                            </template>
                            <!-- end if -->
                        </template>
                    </card>
                </div>
            </div>
            <z-preloader v-if="loading"></z-preloader>
        </div>
        <z-modal
            video
            id="videosModal"
            :video-volume="1"
            class="gallery-modal"
            :data="slidesForModal"
        ></z-modal>
    </div>
</template>

<script>
import { getMedia } from '@/api/media'
import { throttle, debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'
import { localize } from '@/utils/i18n'

export default {
    name: 'media-photos',
    props: {
        initial: {
            type: Object
        }
    },
    data () {
        return {
            loading: false,
            params: {},
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не найдено',
                    'en': 'No results found'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            },
            image: {
                download: localize({
                    'ru': 'Скачать изображение',
                    'en': 'Download image'
                }),
                preview: localize({
                    'ru': 'Посмотреть изображение',
                    'en': 'Preview image'
                })
            },
            text: {
                photos: localize({
                    'ru': 'фото',
                    'en': 'photos'
                })
            },
            data: this.$root.app.components['photo-video-list'],
            filters: this.$root.app.components['photo-video-list'].filters,
            list: this.$root.app.components['photo-video-list'].items,
            page: this.$root.app.components['photo-video-list'].nav,
            code: this.$root.app.system.params.get.CODE
        }
    },
    computed: {
        slidesForModal () {
            return this.list.filter(elem => elem.type === 'video' || elem.type === 'photo').map((el, i) => {
                if (el.type === 'video') {
                    el.image = {
                        preview: el.image,
                        full: el.link
                    }
                    el.index = i
                } else if (el.type === 'photo') {
                    if (!el.image.hasOwnProperty('full')) {
                        el.image = {
                            preview: el.preview,
                            full: el.image
                        }
                    }
                    el.index = i
                }
                return el
            })
        },
        filtersData () {
            const filtersObj = {
                name: this.params.name,
                cargo: this.filters.cargo,
                enterprises: this.filters.enterprises,
                materials: this.filters.materials,
                code: this.code
            }

            // if (!this.filters.enterprises) delete filtersObj['enterprises']

            return filtersObj
        },
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })
            delete params['code']

            return params
        }
    },
    methods: {
        getMoreMedia () {
            this.loading = true
            if (this.params.page === 1) this.list = []

            queryString(this.clearEmptyParams)

            getMedia(this.params).then((res) => {
                if (res.hasOwnProperty('filters')) {
                    if (Object.keys(res.filters).length) this.filters = res.filters
                }

                if (res.hasOwnProperty('nav')) {
                    this.page.current = res.nav.current
                    this.page.total = res.nav.total
                    this.page.count = res.nav.count
                }

                if (res.hasOwnProperty('items')) {
                    if (res.items.length === 0) {
                        this.notFound.state = true
                    } else {
                        this.notFound.state = false
                    }

                    if (this.page.current < 2) {
                        this.list = res.items
                    } else {
                        this.list = this.list.concat(res.items)
                    }
                }

                // This garbage code for the sake of IE
                let customEvent = document.createEvent('HTMLEvents')
                customEvent.initEvent('newCardsAdded', true, true)
                document.dispatchEvent(customEvent)

                this.loading = false
            })
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(500, e => {
                const offsetTop = this.$el.offsetTop
                const offsetHeight = this.$el.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (scrolled >= (bottom - 100) && !this.loading && this.page.current < this.page.total) {
                    this.params.page++
                    this.getMoreMedia()
                };
            }))
        }
    },
    created () {
        if (this.filters.cargo) {
            this.filters.cargo = this.filters.cargo.map((obj) => {
                if (this.initial.cargo.indexOf(`${obj.id}`) >= 0) {
                    obj.selected = true
                }
                return obj
            })
        }

        if (this.filters.enterprises) {
            this.filters.enterprises = this.filters.enterprises.map((obj) => {
                if (this.initial.enterprises.indexOf(`${obj.id}`) >= 0) {
                    obj.selected = true
                }
                return obj
            })
        }

        if (this.filters.materials) {
            this.filters.materials = this.filters.materials.map((obj) => {
                if (this.initial.materials.indexOf(`${obj.id}`) >= 0) {
                    obj.selected = true
                }
                return obj
            })
        }

        this.params = {
            ...this.initial,
            page: 1,
            code: this.code
        }

        this.debouncedChange = debounce(500, (data) => {
            if (data == null) {
                const paramsObj = {
                    name: '',
                    cargo: [],
                    enterprises: [],
                    materials: [],
                    code: this.code
                }

                // if (!this.filters.enterprises) delete paramsObj['enterprises']

                this.params = paramsObj
            } else if (data.hasOwnProperty('name')) {
                this.params[data.name] = data.value
            }

            this.params.page = 1
            console.log(this.params)
            this.getMoreMedia()
        })

        if (!this.list.length) {
            this.notFound.state = true
        }
        this.listenScroll()
    }
}
</script>

<style lang="scss">
.media-photos {
    .detail {
        &__name {
            color: $token-colors-lighter-grey;
            margin-bottom: 24px;
        }

        &__cargo-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            & > span {
                font-size: 14px;
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }
    }

    .ar-card__icon {

        &:hover {
            svg {
                background-color: transparent;
            }

            [pid="0"] {
                fill: $token-colors-gp-red !important;
            }
        }
    }

    .card__bottom-left {
        flex-basis: 100%;
    }
}
</style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sport-items': {
    width: 78,
    height: 61,
    viewBox: '0 0 78 61',
    data: '<rect pid="0" x="64.406" y="33.608" width="6.052" height="22.155" rx="3" _stroke="#ED1C24" stroke-width="2"/><rect pid="1" x="14.097" y="55.26" width="6.052" height="22.155" rx="3" transform="rotate(-180 14.097 55.26)" _stroke="#ED1C24" stroke-width="2"/><rect pid="2" x="53.336" y="29.582" width="8.065" height="30.206" rx="4" _stroke="#ED1C24" stroke-width="2"/><rect pid="3" x="25.168" y="59.789" width="8.065" height="30.206" rx="4" transform="rotate(-180 25.168 59.789)" _stroke="#ED1C24" stroke-width="2"/><path pid="4" d="M3.02 47.705h4.528v-2.013H3.02v2.013zm21.135 0h30.068v-2.013H24.155v2.013zm45.793 0h5.033v-2.013h-5.033v2.013zM3.02 43.679h4.53v-2.013h-4.53v2.013zm21.136 0h30.068v-2.013H24.155v2.013zm45.793 0h5.033v-2.013h-5.033v2.013zm5.033 0c.556 0 1.006.45 1.006 1.007H78a3.02 3.02 0 00-3.02-3.02v2.013zM3.019 41.666A3.02 3.02 0 000 44.686h2.013c0-.556.45-1.007 1.006-1.007v-2.013zm71.962 6.039A3.02 3.02 0 0078 44.685h-2.013c0 .556-.45 1.007-1.006 1.007v2.013zM3.019 45.692c-.555 0-1.006-.45-1.006-1.006H0a3.02 3.02 0 003.02 3.019v-2.013z" _fill="#ED1C24"/><path pid="5" d="M10.065 29.193a1.006 1.006 0 102.012 0h-2.012zm33.227 12.774a1.006 1.006 0 101.436 1.411l-1.436-1.41zM12.077 29.193c0-10.063 8.158-18.22 18.221-18.22V8.958c-11.174 0-20.233 9.06-20.233 20.234h2.012zm18.221-18.22c10.064 0 18.222 8.157 18.222 18.22h2.013c0-11.175-9.06-20.234-20.235-20.234v2.013zm18.222 18.22a18.16 18.16 0 01-5.228 12.774l1.436 1.411a20.172 20.172 0 005.805-14.185H48.52z" _fill="#ED1C24"/><path pid="6" d="M17.366 15.198V5.032a4.026 4.026 0 014.026-4.026h17.814a4.026 4.026 0 014.025 4.026v10.166" _stroke="#ED1C24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="7" d="M21.143 12.435v-5.39c0-1.112.9-2.013 2.012-2.013h14.287c1.111 0 2.013.9 2.013 2.012v5.391" _stroke="#ED1C24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})

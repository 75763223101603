<template>
    <div class="management">
        <!-- <z-lead>
            <div v-html="text.lead"></div>
        </z-lead> -->
        <bods
            :data="management"
        ></bods>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'management',
    data () {
        return {
            management: this.$root.app.components['management'],
            text: {
                lead: localize({
                    'ru': 'Совет директоров Global Ports делегирует Генеральному директору ООО &laquo;Управляющая компания Глобал Портс&raquo; ответственность за&nbsp;эффективное и&nbsp;действенное внедрение и&nbsp;поддержание системы управления рисками. Повседневные обязанности по&nbsp;управлению компанией лежат на&nbsp;управленческой команде.',
                    'en': 'The Board delegates to&nbsp;the Chief Executive Officer of&nbsp;Global Ports Management responsibility LLC for effective and efficient implementation and maintenance of&nbsp;the risk management system. Day-to-day responsibility for the management of&nbsp;Company lies with the management team.'
                })
            }
        }
    }
}
</script>

<template>
    <div class="media-kit-detail">
        <z-not-found
            v-if="list.length === 0"
            :title="notFound.title"
            :text="notFound.text"
        ></z-not-found>
        <h3 class="detail__name" v-if="data.name">{{ data.name }}</h3>
        <div class="row" v-if="list.length" equal-height=".card">
            <div
                class="col-default-4 col-tablet-12"
                v-for="(item, i) in list"
                :key="i"
            >
                <card
                    class="ar-card"
                    @click="item.type === 'video' ? $root.$bus.$emit('open-modal', {id: 'photosModal', index: item.index}) : ''"
                    :mark="false"
                    :overlay="item.image ? 'rgba(0,0,0,0.45)' : ''"
                    :theme="item.image ? 'background' : 'red'"
                    :image="item.type === 'file' ? item.image : item.image.preview"
                    style="min-height: 320px"
                    data-stable-height="true"
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>
                        {{ item.name }}
                    </template>
                    <template v-slot:content>
                        <span v-if="item.count" class="card__file-info">{{item.count}}</span>
                        <span v-if="item.size && item.duration" class="card__file-info">{{item.size}} | {{item.duration}}</span>
                        <!-- if filter photos and files -->
                        <span v-if="item.size && item.extension" class="card__file-info">{{item.size}}, {{item.extension}}</span>
                        <!-- end if -->
                    </template>
                    <template v-slot:bottom-left v-if="item.type === 'video' || item.type === 'picture' || item.type === 'file'">
                        <template v-if="item.type === 'video'">
                            <z-link
                                class="ar-card__icon u-inline"
                                @click="$root.$bus.$emit('open-modal', {id: 'videosModal', index: item.index})"
                                href="#"
                            >
                                <z-icon name="video" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                        <template v-if="item.image && item.type === 'picture'">
                            <z-link
                                class="ar-card__icon u-inline"
                                :content="image.download"
                                :href="item.image.full"
                                downloadFile
                                :downloadTitle="item.name"
                                v-tippy-tooltip="{
                                    theme : 'transparent',
                                    arrow: true,
                                    placement: 'top-start'
                                }"
                            >
                                <z-icon name="download" width="48" height="48"></z-icon>
                            </z-link>
                            <z-link
                                class="ar-card__icon u-inline"
                                :content="image.preview"
                                href="#"
                                @click="$root.$bus.$emit('open-modal', {id: 'photosModal', index: item.index})"
                                v-tippy-tooltip="{
                                    theme : 'transparent',
                                    arrow: true,
                                    placement: 'top-start'
                                }"
                            >
                                <z-icon name="zoom-in" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                        <template v-if="item.type === 'file'">
                            <z-link
                                class="ar-card__icon u-inline"
                                :href="item.link"
                                :downloadTitle="item.name"
                                downloadFile
                                :content="setVersion(item.extension)"

                                v-tippy-tooltip="{
                                    theme : 'transparent',
                                    arrow: true,
                                    placement: 'top-start'
                                }"
                            >
                                <z-icon name="download" width="48" height="48"></z-icon>
                            </z-link>
                            <z-link
                                class="ar-card__icon u-inline"
                                :href="item.link"
                                target="_blank"
                                :content="content.online"

                                v-tippy-tooltip="{
                                    theme : 'transparent',
                                    arrow: true,
                                    placement: 'top-start'
                                }"
                            >
                                <z-icon name="desktop" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                    </template>
                </card>
            </div>
        </div>
        <z-preloader v-if="loading"></z-preloader>
        <z-modal
            autoWidth
            id="photosModal"
            class="gallery-modal"
            download
            theme="grey"
            :data="slidesForModal"
        ></z-modal>
    </div>
</template>

<script>
import { getMediaKitDetail } from '@/api/media'
import { throttle } from 'throttle-debounce'
import { localize } from '@/utils/i18n'

export default {
    name: 'media-kit-detail',
    props: {
        initial: {
            type: Object
        },
        component: {
            type: String,
            default: 'media-kit-detail'
        }
    },
    data () {
        return {
            loading: false,
            params: {},
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не найдено',
                    'en': 'No results found'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            },
            image: {
                download: localize({
                    'ru': 'Скачать изображение',
                    'en': 'Download image'
                }),
                preview: localize({
                    'ru': 'Посмотреть изображение',
                    'en': 'Preview image'
                })
            },
            content: {
                version: localize({
                    'ru': 'версия',
                    'en': 'version'
                }),
                online: localize({
                    'ru': 'Онлайн версия',
                    'en': 'Online version'
                })
            },
            data: this.$root.app.components[this.component],
            list: this.$root.app.components[this.component].items,
            page: this.$root.app.components[this.component].nav,
            code: this.$root.app.system.params.get.CODE
        }
    },
    created () {
        this.params = {
            page: 1,
            code: this.code
        }

        if (!this.list.length) {
            this.notFound.state = true
        }

        this.listenScroll()
    },
    computed: {
        slidesForModal () {
            return this.list.map((el, i) => {
                if (el.type === 'picture') {
                    if (!el.image.hasOwnProperty('full')) {
                        el.image = {
                            preview: el.preview,
                            full: el.image
                        }
                        el.index = i
                    }
                } else if (el.type === 'video') {
                    if (!el.image.hasOwnProperty('full')) {
                        el.image = {
                            preview: el.image,
                            full: el.link
                        }
                        el.index = i
                    }
                }
                return el
            })
        }
    },
    methods: {
        getMoreMedia () {
            this.loading = true

            if (this.params.page === 1) this.list = []

            getMediaKitDetail(this.params).then((res) => {
                if (res.hasOwnProperty('nav')) {
                    this.page.current = res.nav.current
                    this.page.total = res.nav.total
                    this.page.count = res.nav.count
                }

                if (res.hasOwnProperty('items')) {
                    if (res.items.length === 0) {
                        this.notFound.state = true
                    } else {
                        this.notFound.state = false
                    }

                    if (this.page.current <= this.page.total) {
                        this.list = this.list.concat(res.items)
                    } else {
                        this.list = res.items
                    }
                }

                // This garbage code for the sake of IE
                let customEvent = document.createEvent('HTMLEvents')
                customEvent.initEvent('newCardsAdded', true, true)
                document.dispatchEvent(customEvent)

                this.loading = false
            })
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(500, () => {
                const offsetTop = this.$el.offsetTop
                const offsetHeight = this.$el.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (scrolled >= (bottom - 100) && !this.loading && this.page.current < this.page.total) {
                    this.params.page++
                    this.getMoreMedia()
                };
            }))
        },
        setVersion (item) {
            if (item) {
                return `${item.toUpperCase()} ${this.content.version}`
            }
        }
    }
}
</script>

<style lang="scss">
.media-kit-detail {
    .detail {
        &__name {
            color: $token-colors-lighter-grey;
            margin-bottom: 24px;
        }

        &__cargo-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            & > span {
                font-size: 14px;
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }
    }

    .ar-card {
        .z-dropdown__trigger {
            text-decoration: none;
            color: #fff !important;
        }

        .z-dropdown__content {
            border-radius: 4px;
            overflow: hidden;
        }

        &__version {
            background: rgba(255, 255, 255, 0.4);
            font-size: 12px;
            line-height: 1.5;
            color: #FFFFFF;
            padding: 0.5rem;
            cursor: pointer;
        }

        &__active {
            font-size: 14px;
            line-height: 1.5;
        }

        &__icon {

            &:not(:last-child) {
                margin-right: 1rem;
            }

            // + .ar-card__icon {
            //     margin-left: 1rem;
            // }

            svg {
                background-color: transparent;
                border-radius: 50%;
                transition: background-color 0.25s;
            }

            path {
                fill: #fff;
            }

            &:hover {
                svg {
                    background-color: #fff;
                }

                [pid="0"] {
                    fill: #fff !important;
                }
            }
        }
    }

    .card__bottom-left {
        flex-basis: 100%;
    }
}
</style>

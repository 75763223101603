/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_up': {
    width: 15,
    height: 22,
    viewBox: '0 0 15 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.293 6.657L6.657.293a1 1 0 011.414 0l6.364 6.364a1 1 0 11-1.414 1.414L8.364 3.414V21a1 1 0 11-2 0V3.414L1.707 8.071A1 1 0 01.293 6.657z" _fill="#ED1C24"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.659 3.684a2.586 2.586 0 000 3.657.517.517 0 00.731-.731 1.552 1.552 0 010-2.195l2.926-2.926a1.552 1.552 0 012.195 2.195L9.048 5.147a.517.517 0 00.731.731l1.463-1.463A2.586 2.586 0 007.585.758L4.659 3.684zM7.34 8.316a2.586 2.586 0 000-3.657.517.517 0 10-.731.731 1.552 1.552 0 010 2.195L3.684 10.51a1.552 1.552 0 11-2.195-2.195l1.463-1.463a.517.517 0 00-.731-.731L.758 7.585a2.586 2.586 0 003.657 3.657l2.926-2.926z" _fill="#7A7A7E"/>'
  }
})

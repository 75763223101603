<template>
    <div class="logistic-map">
        <div class="logistic-map__wrapper">
            <object ref="map" class="logistic-map__svg" type="image/svg+xml" :data="svgSrc">Your browser does not support SVGs</object>
        </div>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'
import verge from 'verge'
import { throttle } from 'throttle-debounce'

export default {
    name: 'logistic-map',
    components: {
    },
    data () {
        return {
            lang: this.$root.lang,
            animated: false,
            svgLoaded: false
        }
    },
    computed: {
        svgSrc () {
            return require(`./map/map_${this.lang}.svg`)
        }
    },
    methods: {
        animate () {
            this.animated = true

            const svgMap = this.$refs.map.contentDocument
            const rails = svgMap.querySelectorAll('.rails')
            const waters = svgMap.querySelectorAll('.water')
            const markers = svgMap.querySelectorAll('.markers-group')
            const texts = svgMap.querySelectorAll('.text')

            Velocity(texts, {
                opacity: 1
            }, {
                duration: 1000
            })

            Velocity(markers, {
                opacity: 1
            }, {
                delay: 500,
                duration: 1000
            })
            Velocity(rails, {
                strokeDashoffset: 0
            }, {
                delay: 1000,
                duration: 1000
            })
            Velocity(waters, {
                strokeDashoffset: 0
            }, {
                delay: 1000,
                duration: 2000
            })
        },
        addEvents () {
            const svgMap = this.$refs.map.contentDocument
            const links = Array.from(svgMap.querySelectorAll('.link'))

            // костыль, так как ссылки внутри object открываются
            links.forEach((item, i) => {
                item.addEventListener('click', function (e) {
                    e.preventDefault()
                    const href = this.getAttribute('href')
                    window.location.href = href
                })
            })
        }
    },
    mounted () {
        const svgMap = this.$refs.map

        svgMap.addEventListener('load', () => {
            this.svgLoaded = true
            this.addEvents()
            if (verge.inViewport(this.$el, -200) && !this.animated) {
                this.animate()
            }
        })

        window.addEventListener('scroll', throttle(500, e => {
            if (verge.inViewport(this.$el, -200) && !this.animated && this.svgLoaded) {
                this.animate()
            }
        }))
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <div class="committees">
        <z-lead>
            <div v-html="text.lead"></div>
        </z-lead>
        <div class="committees__titles">
            <div
                v-for="(committee,index) in data"
                :key="index"
                class="committees__title"
                >
                <p>{{committee.name}}</p>
                <button class="committees__title-button" v-on:click="scrollTo(committee.code)">
                    <span class="committees__title-icon">
                        <z-icon name="arrow"></z-icon>
                    </span>
                </button>
            </div>
        </div>
        <div
            class="committee__items"
            v-for="(committee, index) in data"
            :key="index"
            :ref="committee.code"
            >
            <h2
                v-if="committee.name"
                class="committee__title"
                v-html="committee.name"
                ></h2>
            <div class="html-content" v-html="committee.detail"></div>
            <div class="committee__members" v-if="committee.items.length !== 0">
                <h3 class="committee__members-title">{{text.members}}</h3>
                <bods :data="committee.items"></bods>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'committees',
    props: {
        committee: {
            type: String,
            default: 'strategy-committee'
        }
    },
    data () {
        return {
            data: this.$root.app.components['committees-list'],
            text: {
                lead: localize({
                    'ru': 'Компанией учреждены три комитета при совете директоров:',
                    'en': 'The company has established three committees of the board of directors:'
                }),
                members: localize({
                    'ru': 'Участники',
                    'en': 'Members'
                })
            }
        }
    },
    mounted: function () {
        this.$nextTick(function () {
            if (this.committee === '') {
                this.scrollTo('strategy-committee')
            } else {
                this.scrollTo(this.committee)
            }
        })
    },
    methods: {
        scrollTo: function (view) {
            this.$refs[view][0].scrollIntoView(
                {behavior: 'smooth'}
            )
        }
    }
}
</script>

<style lang="scss">
    .committees__titles {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0;
        padding-bottom: 2rem;
        border-bottom: 2px solid $token-colors-light-grey;
    }
    .committees__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 35px;
        color: $token-colors-black;
        display: flex;
        & button {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            background-color: transparent;
            border: none;
            padding: 0;
            outline: none;
        }
        & p {
            max-width: 180px;
        }
        &-icon {
            margin-left: 18.75px;
            & > svg {
                transform: rotate(-180deg);
                    width: 24px;
                    height: 24px;
                    border: 2px solid gray;
                    border-radius: 50%;
            }
        }
    }
    .committee {
        &__title {
            padding-top: 40px;
        }
        &__list {
            padding: 32px 0;
        }
        &__members-title {
            margin-bottom: 32px;
        }
    }
</style>

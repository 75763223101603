<template>
    <div
        class="z-select-multi"
        :class="{'is-opened': opened}"
    >
        <div class="z-select-multi__container" @click.self="opened = !opened">
            <span
                class="z-select__placeholder"
                v-if="placeholder && !selected.text.length"
                v-html="placeholder"
            ></span>
            <span
                class="z-select__selected"
                v-if="selected.text.length"
                v-html="selected.text"
            ></span>
            <span
                class="z-select-multi__clear"
                v-if="selected.text.length"
                @click.prevent="removeSelected"
            >
                <slot @click.self="removeSelected" v-if="!!this.$slots['icon-clear']" name="icon-clear"></slot>
            </span>
            <span
                class="z-select-multi__arrow"
                @click.prevent="opened = !opened"
            >
                <slot @click.self="opened = !opened" v-if="!!this.$slots['icon-arrow']" name="icon-arrow"></slot>
            </span>
        </div>
        <div class="z-select-multi__dropdown" v-if="opened">
            <ul class="z-select__options">
                <li
                    class="z-select__option"
                    v-for="option in options"
                    :class="buildClass(option)"
                    @click="changeSelected(option)"
                    v-html="option.text"
                    :key="option.id"
                ></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-select',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        name: String,
        placeholder: String
    },
    data () {
        return {
            selected: {
                id: '',
                text: ''
            },
            opened: false,
            duration: 300,
            options: this.data
        }
    },
    mounted () {
        this.onLoad()

        this.$root.$bus.$on('clear', name => {
            if (this.name === name) this.removeSelected()
        })

        document.addEventListener('click', e => this.hideDropdown(e))
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.removeSelected()
        })

        document.removeEventListener('click', e => this.hideDropdown(e))
    },
    watch: {
        data (array) {
            this.options = array
        }
    },
    methods: {
        onLoad () {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].selected === true) this.initSelected(this.options[i])
            }
        },
        buildClass (option) {
            return {
                'is-selected': option.selected,
                'is-disabled': option.disabled
            }
        },
        hideDropdown (e) {
            let isOutside = this.$el !== e.target && !this.$el.contains(e.target)
            // eslint-disable-next-line no-unused-expressions
            isOutside ? this.opened = false : false
        },
        changeSelected (option) {
            option.selected ? this.removeSelected() : this.addSelected(option)
            this.opened = false
        },
        initSelected (option) {
            this.selected.id = option.id
            this.selected.text = option.text

            for (let i = 0; i < this.options.length; i++) {
                this.options[i].selected = this.options[i].id === option.id
            }
        },
        addSelected (option) {
            this.selected.id = option.id
            this.selected.text = option.text

            for (let i = 0; i < this.options.length; i++) {
                this.options[i].selected = this.options[i].id === option.id
            }

            this.send(this.selected.id)
        },
        removeSelected () {
            this.opened = false
            this.selected.id = ''
            this.selected.text = ''

            for (let i = 0; i < this.options.length; i++) this.options[i].selected = false

            this.send('')
        },
        send (value) {
            this.$nextTick(() => this.$emit('change', { value: value, name: this.name }))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'manager': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.527 31.25h29.461c.5 0 .906.406.906.906v.4c0 .121-.042.232-.112.319h-29.38a1.5 1.5 0 00-1.5 1.5v1.75a1.5 1.5 0 001.5 1.5H45.89a.512.512 0 01.004.068v.526c0 .57-.461 1.031-1.03 1.031H15.526a2 2 0 01-2-2v-4a2 2 0 012-2zm29.356 5.375v-2.75H16.402a.5.5 0 00-.5.5v1.75a.5.5 0 00.5.5h28.48zM18.218 40.25h29.461c.5 0 .906.406.906.906v.4c0 .121-.042.232-.112.319H18.968a1.5 1.5 0 00-1.5 1.5v1.75a1.5 1.5 0 001.5 1.5H48.58a.512.512 0 01.004.068v.526c0 .57-.461 1.031-1.03 1.031H18.217a2 2 0 01-2-2v-4a2 2 0 012-2zm29.356 5.375H18.968a.5.5 0 01-.5-.5v-1.75a.5.5 0 01.5-.5h28.606v2.75zM48.464 17.806c-3.053-.413-8.198-.681-16.404-.681-35.9 0-13.49 5.125-13.49 5.125h26.692s1.552-.35 3.202-.868v-3.576zm2 2.859c1.934-.815 3.01-1.785 0-2.503v2.503zm-31.25 2.585h26.25v6h-26.25v-6z" _fill="#ED1C24"/><path pid="1" d="M46.464 16.915c.302-.22.69-.428 1.09-.605 1.01-.446 1.91.335 1.91 1.44v10" _stroke="#ED1C24" stroke-linecap="round"/><path pid="2" d="M48.222 30.22a1.28 1.28 0 012.485 0l.757 3.03h-4l.758-3.03z" _fill="#ED1C24"/>'
  }
})

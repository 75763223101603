/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'zoom-in': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 46.5c12.426 0 22.5-10.074 22.5-22.5S36.426 1.5 24 1.5 1.5 11.574 1.5 24 11.574 46.5 24 46.5zm0 1.5c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z" _fill="#C4C4C4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13.038 20.716V14a.75.75 0 01.754-.746h6.784a.75.75 0 01.753.746.75.75 0 01-.753.746H15.61l7.31 7.237a.74.74 0 010 1.055.76.76 0 01-1.066 0l-7.31-7.236v4.914a.75.75 0 01-.753.746.75.75 0 01-.754-.746zm21.754-7.462h-6.784a.75.75 0 00-.754.746.75.75 0 00.754.746h4.964l-7.31 7.237a.741.741 0 000 1.055.76.76 0 001.066 0l7.31-7.236v4.914a.75.75 0 00.754.746.75.75 0 00.753-.746V14a.75.75 0 00-.753-.746zm.753 21.535v-6.716a.75.75 0 00-.753-.746.75.75 0 00-.754.746v4.914l-7.31-7.236a.76.76 0 00-1.066 0 .741.741 0 000 1.055l7.31 7.237h-4.964a.75.75 0 00-.754.746.75.75 0 00.754.746h6.784a.75.75 0 00.753-.746zm-21.753.957h6.784a.75.75 0 00.753-.746.75.75 0 00-.753-.746H15.61l7.31-7.236a.74.74 0 000-1.056.76.76 0 00-1.066 0l-7.31 7.237v-4.915a.75.75 0 00-.753-.746.75.75 0 00-.754.746V35a.75.75 0 00.754.746z" _fill="#C4C4C4"/>'
  }
})

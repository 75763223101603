<template>
    <div class="gallery__slide swiper-slide">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'gallery-slide',
    ready () {
        this.update()
    },
    mounted () {
        this.update()
    },
    updated () {
        this.update()
    },
    attached () {
        this.update()
    },
    methods: {
        update () {
            if (this.$parent && this.$parent.slider) {
                this.$parent.slider.update()
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <a
        :href="href"
        class="z-link"
        :download="setDownloadFile() ? downloadTitle : false"
        :class="classObject"
        @click="$emit('click')"
    >
        <slot></slot><z-icon v-if="external" name="external" width="7px" height="7px"></z-icon>
    </a>
</template>

<script>
export default {
    name: 'z-link',
    props: {
        href: {
            type: String,
            required: true
        },
        disabled: Boolean,
        size: {
            type: String,
            default: 'm',
            validator: prop => ['m', 'l', 'xl', 's', 'xs'].includes(prop)
        },
        external: Boolean,
        underline: {
            type: Boolean,
            default: true
        },
        theme: {
            type: String,
            default: 'black'
        },
        downloadFile: Boolean,
        downloadTitle: {
            type: String,
            default: ''
        }
    },
    methods: {
        setDownloadFile () {
            return Modernizr.adownload && this.downloadFile
        }
    },
    computed: {
        classObject: function () {
            return [
                {
                    'z-link--external': this.external,
                    'z-link--disabled': this.disabled,
                    'z-link--underline': this.underline
                },
                `z-link--size-${this.size}`,
                `z-link--theme-${this.theme}`
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

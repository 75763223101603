/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'job-food': {
    width: 81,
    height: 75,
    viewBox: '0 0 81 75',
    data: '<path pid="0" d="M38.438 53.493c0 11.324 9.18 20.505 20.505 20.505s20.505-9.18 20.505-20.505-9.18-20.505-20.505-20.505c-1.1 0-2.181.086-3.235.253" _stroke="#ED1C24" stroke-width="2" stroke-linecap="round"/><path pid="1" d="M62.36 43.924h-6.835v6.151h-6.151v6.835h6.151v6.152h6.836V56.91h6.151v-6.835h-6.151v-6.151z" _stroke="#ED1C24" stroke-width="2"/><circle pid="2" cx="27.038" cy="27.038" r="26.038" _stroke="#ED1C24" stroke-width="2"/><path pid="3" d="M30.873 10.415c0-.218.177-.395.395-.395v0a6.317 6.317 0 016.317 6.317v9.743h-3.356v15.972a2.004 2.004 0 01-2.004 2.005h-1.352V10.414zM27.038 21.56v1.785c0 1.65-1.01 3.13-2.546 3.732l-.81.317v14.985c0 .926-.75 1.678-1.677 1.678v0a1.678 1.678 0 01-1.678-1.678V27.394l-.833-.323a4.008 4.008 0 01-2.561-3.738V21.56m10.105 0l-1.324-11.499m1.324 11.499H21.99m-5.056 0l1.14-11.499m-1.14 11.499h5.056m0 0V10.061" _stroke="#ED1C24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bullet': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<circle pid="0" cx="6" cy="6" r="6" _fill="#C4C4C4" fill-opacity=".2"/><path pid="1" d="M8.015 6.265a.375.375 0 000-.53L5.63 3.348a.375.375 0 10-.53.53L7.218 6 5.1 8.121a.375.375 0 10.53.53l2.386-2.386zM7 6.375h.75v-.75H7v.75z" _fill="#C4C4C4"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'databank': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.382 16a2 2 0 00-2 2v5h30v-5a2 2 0 00-2-2h-26zm28 9h-30v6h30v-6zm0 8h-30v6h30v-6zm0 8h-30v5a2 2 0 002 2h26a2 2 0 002-2v-5z" _fill="#ED1C24"/>'
  }
})

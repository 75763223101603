<template>
    <div class="mainpage">
        <div class="container">
            <div class="row" equal-height=".card-group">
                <div class="col-default-4 col-desktop-6 col-mobile-12">
                    <card
                        theme="slider"
                        padding="125%"
                        class="card-group"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:content>
                            <card-slider
                                autoplay="6000"
                                :mainpage="true"
                                :slides="data.slider"
                            ></card-slider>
                        </template>
                    </card>
                </div>
                <div class="col-default-8 col-desktop-6 col-mobile-12" equal-height=".card">
                    <div class="card-group mainpage__card-titles--invisible">
                        <div class="row">
                            <div
                                v-for="(item, i) in data.terminals"
                                :key="i"
                                class="col-default-4 col-desktop-6 col-mobile-12"
                            >
                                <card
                                    :link="item.link"
                                    :overlay="'rgba(54,54,54,0.6)'"
                                    :theme="'background'"
                                    :image="item.image"
                                    :mark="false"
                                    padding="100%"
                                    equal-height-max-breakpoint="mobile"
                                >
                                    <template v-slot:title>
                                        {{ item.name }}
                                    </template>
                                    <template v-slot:bottom-right>
                                        <img :src="item.icon" height="32px">
                                    </template>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" equal-height=".card">
                <div class="col-default-4 col-tablet-6 col-mobile-12">
                    <card
                        theme="red"
                        style="min-height: 350px"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>{{ text.portalTitle }}</template>
                        <template v-slot:content>
                            <div v-html="text.portalText"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-link theme="white" href="//globalports.rlisystems.ru/" external target="_blank">
                                <span v-html="text.signin"></span>
                            </z-link>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="white"
                                size="l"
                            >
                                <z-icon name="man" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <div class="col-default-4 col-tablet-6 col-mobile-12">
                    <card
                        style="min-height: 350px"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>{{ text.onlineServicesTitle }}</template>
                        <template v-slot:content>
                            <div v-html="text.onlineServicesText"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" :href="lang !== 'ru' ? '/en/client/tools/' : '/ru/client/tools/'" kind="text">
                                {{ text.more }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="red"
                                size="l"
                            >
                                <z-icon name="ship" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <div class="col-default-4 col-tablet-12">
                    <card
                        style="min-height: 350px"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title>{{ text.contactsTitle }}</template>
                        <template v-slot:content>
                            <div v-html="text.contactsText"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" :href="`/${lang}/contacts/`" kind="text">
                                {{ text.more }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="red"
                                size="l"
                            >
                                <z-icon name="letter" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
            </div>

            <div v-if="data.services.length" class="u-bottom-margin--l-desktop" equal-height=".card-group">
                <h2 class="u-top-margin--xl-desktop">{{ text.services }}</h2>
                <div class="row">
                    <template v-if="services.main.length">
                        <div class="col-default-4 col-desktop-12 col-mobile-12">
                            <card
                                v-for="(item, i) in services.main"
                                :key="i"
                                :link="item.main ? '' : item.link"
                                overlay="rgba(0,0,0,0.35)"
                                theme="background"
                                :image="item.image"
                                padding="382px"
                                class="card-group"
                                equal-height-max-breakpoint="laptop"
                            >
                                <template v-slot:title>
                                    {{ item.name }}
                                </template>
                                <template v-slot:content v-if="item.detail">
                                    {{ item.detail }}
                                </template>
                                <template v-slot:bottom-left v-if="item.main">
                                    <z-button tag="a" :href="item.link" kind="text" theme="white">
                                        {{ text.more }}
                                        <template v-slot:right>
                                            <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                        </template>
                                    </z-button>
                                </template>
                                <template v-slot:bottom-right>
                                    <card-icon
                                        theme="white"
                                        size="l"
                                    >
                                        <div><img :src="item.icon" /></div>
                                    </card-icon>
                                </template>
                            </card>
                        </div>
                        <div class="col-default-8 col-desktop-12 col-mobile-12" equal-height=".card">
                            <div class="card-group" equal-height-max-breakpoint="laptop">
                                <div class="row">
                                    <div
                                        v-for="(item, i) in services.others"
                                        :key="i"
                                        class="col-default-6 col-mobile-12"
                                    >
                                        <card
                                            :link="item.main ? '' : item.link"
                                            overlay="rgba(0,0,0,0.35)"
                                            theme="background"
                                            :image="item.image"
                                            padding="175px"
                                            equal-height-max-breakpoint="mobile"
                                        >
                                            <template v-slot:title>
                                                {{ item.name }}
                                            </template>
                                            <template v-slot:content v-if="item.detail">
                                                {{ item.detail }}
                                            </template>
                                            <template v-slot:bottom-left v-if="item.main">
                                                <z-button tag="a" :href="item.link" kind="text" theme="white">
                                                    {{ text.more }}
                                                    <template v-slot:right>
                                                        <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                                    </template>
                                                </z-button>
                                            </template>
                                            <template v-slot:bottom-right>
                                                <card-icon
                                                    theme="white"
                                                    size="m"
                                                >
                                                    <div><img :src="item.icon" /></div>
                                                </card-icon>
                                            </template>
                                        </card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div class="row" equal-height=".card-group">
                <div class="col-default-5 col-desktop-8 col-mobile-12">
                    <card class="card-group" equal-height-max-breakpoint="mobile">
                        <template v-slot:title>{{ text.news }}</template>
                        <template v-slot:content>
                            <div class="media__news">
                                <div v-if="data.news.length !== 0" class="media__news-main">
                                    <span>
                                        <z-link :href="data.news[0].link" :underline="false">
                                            <p class="media__news-paragraph">{{data.news[0].name}}</p>
                                        </z-link>
                                        <span class="media__news-card-date media__news-card-date--main">{{ data.news[0].date | momentFilter('DD MMMM YYYY') }}</span>
                                    </span>
                                </div>
                                <div class="media__news-other">
                                    <div class="row">
                                        <div class="col-default-12" v-for="(item, index) in data.news.slice(1, data.news.length)" :key="index">
                                            <span>
                                                <z-link :href="item.link" :underline="false">
                                                    <p class="media__news-paragraph media__news-paragraph--small">{{item.name}}</p>
                                                </z-link>
                                                <span class="media__news-card-date">{{ item.date | momentFilter('DD MMMM YYYY') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" :href="`/${lang}/news-and-media/press-releases-and-news/`" kind="text">
                                {{ text.newsAll }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                    </card>
                </div>
                <div class="col-default-3 col-desktop-4 col-mobile-12">
                    <div class="row">
                        <!-- <div class="col-default-12">
                            <card style="min-height: 438px">
                                <template v-slot:title>{{ text.sharePriceTitle }}</template>
                                <template v-slot:content>
                                    <div style="position: relative; height: 200px">
                                        <iframe style="position: absolute; left: 0; top: 0; width: 100%; height: 100%;" :src="sharePriceSrc"></iframe>
                                    </div>
                                </template>
                                <template v-slot:bottom-left>
                                    <z-button tag="a" href="/en/investors/securities/tools/" kind="text">
                                        {{ text.moreShareprice }}
                                        <template v-slot:right>
                                            <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                        </template>
                                    </z-button>
                                </template>
                            </card>
                        </div> -->
                        <div
                            v-if="data['latter-results']"
                            class="col-default-12"
                        >
                            <card
                                class="card-group"
                                equal-height-max-breakpoint="mobile"
                                :link="data['latter-results'].link"
                                style="min-height: 702px"
                            >
                                <template v-slot:title>{{ text.financialResultsTitle }}</template>
                                <template v-slot:content>
                                    <span style="font-size: 18px; font-weight: 700;">
                                        {{ data['latter-results'].name }}
                                    </span>
                                </template>
                                <template v-slot:bottom-right>
                                    <card-icon
                                        theme="grey"
                                        size="m"
                                    >
                                        <z-icon name="play" width="24" height="24"></z-icon>
                                    </card-icon>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
                <div class="col-default-4 col-desktop-12 col-mobile-12" v-if="data['annual-report']">
                    <div class="card-group ar-card" equal-height-max-breakpoint="laptop">
                        <div class="row">
                            <div class="col-default-12 col-desktop-6 col-mobile-12">
                                <card
                                    padding="334px"
                                    overlay="rgba(0,0,0,0.35)"
                                    theme="background"
                                    :image="data['annual-report'][reportLang].image"
                                >
                                    <template v-slot:title>
                                        {{ data['annual-report'][reportLang].name }}
                                    </template>
                                    <template v-slot:content>
                                        <template v-if="data['annual-report']['en']">
                                            <z-dropdown ref="report-dropdown">
                                                <template v-slot:trigger>
                                                    <span
                                                        class="ar-card__active"
                                                        v-html="langVersion[reportLang]"
                                                    ></span>
                                                    <z-icon dir="down" color="#fff" name="arrow" width="12" height="12"></z-icon>
                                                </template>
                                                <template v-slot:content>
                                                    <div
                                                        class="ar-card__version"
                                                        @click.prevent="changeReportLang('ru')"
                                                    >
                                                        {{ langVersion['ru'] }}
                                                    </div>
                                                    <div
                                                        class="ar-card__version"
                                                        @click.prevent="changeReportLang('en')"
                                                    >
                                                        {{ langVersion['en'] }}
                                                    </div>
                                                </template>
                                            </z-dropdown>
                                        </template>
                                    </template>
                                    <template v-slot:bottom-left>
                                        <z-link
                                            v-if="data['annual-report'][reportLang].online"
                                            class="ar-card__icon u-inline"
                                            :href="data['annual-report'][reportLang].online"
                                            target="_blank"
                                            :content="text.onlineReport[reportLang]"

                                            v-tippy-tooltip="{
                                                theme : 'transparent',
                                                arrow: true,
                                                placement: 'top-start'
                                            }"
                                        >
                                            <z-icon name="desktop" width="48" height="48"></z-icon>
                                        </z-link>
                                        <z-link
                                            v-if="data['annual-report'][reportLang].file"
                                            class="ar-card__icon u-inline"
                                            :href="data['annual-report'][reportLang].file"
                                            :downloadTitle="data['annual-report'].name"
                                            downloadFile
                                            :content="text.pdfReport[reportLang]"

                                            v-tippy-tooltip="{
                                                theme : 'transparent',
                                                arrow: true,
                                                placement: 'top-start'
                                            }"
                                        >
                                            <z-icon name="download" width="48" height="48"></z-icon>
                                        </z-link>
                                    </template>
                                </card>
                            </div>
                            <div class="col-default-12 col-desktop-6 col-mobile-12">
                                <card style="min-height: 334px">
                                    <template v-slot:title>IR Quick Links</template>
                                    <template v-slot:content>
                                        <z-list
                                            theme="links"
                                            :bullets="false"
                                        >
                                            <z-list-item>
                                                <z-link
                                                    href="/en/investors/reports-and-results/"
                                                    :underline="false"
                                                    theme="dark-grey"
                                                >
                                                    <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                                    <span>Reports and results</span>
                                                </z-link>
                                            </z-list-item>
                                            <z-list-item>
                                                <z-link
                                                    href="/en/investors/contacts/"
                                                    :underline="false"
                                                    theme="dark-grey"
                                                >
                                                    <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                                    <span>Contact IR Team</span>
                                                </z-link>
                                            </z-list-item>
                                            <z-list-item>
                                                <z-link
                                                    href="/en/investors/events/"
                                                    :underline="false"
                                                    theme="dark-grey"
                                                >
                                                    <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                                    <span>IR calendar</span>
                                                </z-link>
                                            </z-list-item>
                                            <z-list-item>
                                                <z-link
                                                    href="/en/investors/subscription/"
                                                    :underline="false"
                                                    theme="dark-grey"
                                                >
                                                    <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                                    <span>Subscribe IR news</span>
                                                </z-link>
                                            </z-list-item>
                                        </z-list>
                                    </template>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'mainpage',
    data () {
        return {
            lang: this.$root.lang,
            reportLang: this.$root.lang,
            langVersion: {
                'ru': 'Russian version',
                'en': 'English version',
                'cn': 'English version'
            },
            data: this.$root.app.components['main-page'],
            text: {
                news: localize({
                    'ru': 'Новости',
                    'en': 'News',
                    'cn': '新闻'
                }),
                newsAll: localize({
                    'ru': 'Все новости',
                    'en': 'All news',
                    'cn': '所有新闻'
                }),
                services: localize({
                    'ru': 'Услуги терминалов',
                    'en': 'Services',
                    'cn': '服务'
                }),
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more',
                    'cn': '了解详情'
                }),
                moreShareprice: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more',
                    'cn': 'Learn more'
                }),
                portalTitle: localize({
                    'ru': 'Единый клиентский портал',
                    'en': 'Client portal',
                    'cn': '同一客户平台'
                }),
                signin: localize({
                    'ru': 'Войти в&nbsp;личный кабинет',
                    'en': 'Sign in',
                    'cn': '登入个人账户'
                }),
                portalText: localize({
                    'ru': 'Подать электронную заявку, отследить и&nbsp;подписать документы электронной подписью',
                    'en': 'Submit an&nbsp;electronic application, track and sign documents electronically',
                    'cn': '提交电子申请,以电子方式跟踪和签署文件'
                }),
                onlineServicesTitle: localize({
                    'ru': 'Online сервисы',
                    'en': 'Interactive tools',
                    'cn': '在线服务'
                }),
                onlineServicesText: localize({
                    'ru': 'Посмотреть информацию о судозаходах и поездах, отслеживание грузов',
                    'en': 'View information on calls and trains, cargo tracking',
                    'cn': '查询船舶和火车进港信息货物跟踪'
                }),
                contactsTitle: localize({
                    'ru': 'Контакты Глобал Портс',
                    'en': 'Global Ports Contacts',
                    'cn': 'Global Ports 联系方式'
                }),
                contactsText: localize({
                    'ru': 'Свяжитесь с нами любым удобным способом',
                    'en': 'You are welcome to contact us in any convenient way for you',
                    'cn': '您可以通过以下方式联系我们'
                }),
                sharePriceTitle: localize({
                    'ru': 'Котировки',
                    'en': 'Share price',
                    'cn': 'Share price'
                }),
                financialResultsTitle: localize({
                    'ru': 'Financial results',
                    'en': 'Financial results',
                    'cn': 'Financial results'
                }),
                onlineReport: {
                    'ru': 'Онлайн версия',
                    'en': 'Online version',
                    'cn': 'Online version'
                },
                pdfReport: {
                    'ru': 'PDF версия',
                    'en': 'PDF version',
                    'cn': 'PDF version'
                }
            },
            sharePriceSrc: localize({
                'ru': 'https://charts3.equitystory.com/teaser/globalports-v31/Russian/',
                'en': 'https://charts3.equitystory.com/teaser/globalports-v31/English/',
                'cn': 'https://charts3.equitystory.com/teaser/globalports-v31/English/'
            })
        }
    },
    computed: {
        services () {
            let services = {
                main: [],
                others: []
            }

            this.data.services.forEach((item, i) => {
                if (item.main) {
                    services.main.push(item)
                } else {
                    services.others.push(item)
                }
            })

            return services
        }
    },
    methods: {
        changeReportLang (lang) {
            if (this.$refs['report-dropdown']) this.$refs['report-dropdown'].toggle()
            this.reportLang = lang
        }
    }
}
</script>

<style lang="scss">
    .mainpage {
        margin-top: 2rem;
        margin-bottom: 4rem;

        &__card-titles--invisible {
            @media (max-width: 940px) and (min-width: $breakpoint-mobile) {
                .card__title {
                    display: none;
                }
            }
        }

        .ar-card {
            &__version {
                background-color: rgba(255, 255, 255, 0.9);
                color: black;
            }
        }
    }
</style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external': {
    width: 7,
    height: 7,
    viewBox: '0 0 7 7',
    data: '<path pid="0" d="M6.472 1a.5.5 0 00-.5-.5h-4.5a.5.5 0 000 1h4v4a.5.5 0 001 0V1zM1.326 6.354l5-5-.707-.708-5 5 .707.708z" _fill="#363636"/>'
  }
})

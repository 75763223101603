<template>
    <div class="client">
        <div class="container">
            <div class="row" equal-height=".card">
                <div class="col-default-4 col-tablet-6 col-mobile-12">
                    <card
                        theme="red"
                        style="min-height: 350px"
                        equal-height-max-breakpoint="mobile"
                        fullWidth
                    >
                        <template v-slot:title>{{ text.portalTitle }}</template>
                        <template v-slot:content>
                            <div v-html="text.portalText"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-link theme="white" href="https://globalports.rlisystems.ru/" external target="_blank">
                                <span v-html="text.signin"></span>
                            </z-link>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="white"
                                size="l"
                            >
                                <z-icon name="man" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <div class="col-default-4 col-tablet-6 col-mobile-12">
                    <card
                        style="min-height: 350px"
                        equal-height-max-breakpoint="mobile"
                        fullWidth
                    >
                        <template v-slot:title>{{ text.onlineServicesTitle }}</template>
                        <template v-slot:content>
                            <div v-html="text.onlineServicesText"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" :href="lang !== 'ru' ? '/en/client/tools/' : '/ru/client/tools/'" kind="text">
                                {{ text.more }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="red"
                                size="l"
                            >
                                <z-icon name="cargo" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <div class="col-default-4 col-tablet-12" v-if="data['banner']">
                    <card
                        style="min-height: 350px"
                        theme="background"
                        overlay="rgba(0,0,0,0.45)"
                        image="/images/components/client/banner.jpg"
                        equal-height-max-breakpoint="mobile"
                    >
                        <template v-slot:title><span v-html="data['banner'].name"></span></template>
                        <template v-slot:content>
                            <div class="html-content" v-html="data['banner'].detail"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" :href="data['banner'].link" theme="white" kind="text">
                                {{ text.more }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                    </card>
                </div>
            </div>
            <div v-if="data.services" class="u-bottom-margin--l-desktop" equal-height=".card-group">
                <h2 class="u-top-margin--xl-desktop">{{ text.services }}</h2>
                <div class="row">
                    <template v-if="services.main.length">
                        <div class="col-default-4 col-desktop-12 col-mobile-12">
                            <card
                                v-for="(item, i) in services.main"
                                :key="i"
                                :link="item.main ? '' : item.link"
                                overlay="rgba(0,0,0,0.35)"
                                theme="background"
                                :image="item.image"
                                padding="382px"
                                equal-height-max-breakpoint="laptop"
                                fullWidth
                            >
                                <template v-slot:title>
                                    {{ item.name }}
                                </template>
                                <template v-slot:content v-if="item.detail">
                                    {{ item.detail }}
                                </template>
                                <template v-slot:bottom-left v-if="item.main">
                                    <z-button tag="a" :href="item.link" kind="text" theme="white">
                                        {{ text.more }}
                                        <template v-slot:right>
                                            <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                        </template>
                                    </z-button>
                                </template>
                                <template v-slot:bottom-right>
                                    <card-icon
                                        theme="white"
                                        size="l"
                                    >
                                        <div><img :src="item.icon" /></div>
                                    </card-icon>
                                </template>
                            </card>
                        </div>
                        <div class="col-default-8 col-desktop-12 col-mobile-12" equal-height=".card">
                            <div class="card-group" equal-height-max-breakpoint="laptop">
                                <div class="row">
                                    <div
                                        v-for="(item, i) in services.others"
                                        :key="i"
                                        class="col-default-6 col-mobile-12"
                                    >
                                        <card
                                            :link="item.main ? '' : item.link"
                                            overlay="rgba(0,0,0,0.35)"
                                            theme="background"
                                            :image="item.image"
                                            padding="175px"
                                            equal-height-max-breakpoint="mobile"
                                        >
                                            <template v-slot:title>
                                                {{ item.name }}
                                            </template>
                                            <template v-slot:content v-if="item.detail">
                                                {{ item.detail }}
                                            </template>
                                            <template v-slot:bottom-left v-if="item.main">
                                                <z-button tag="a" :href="item.link" kind="text" theme="white">
                                                    {{ text.more }}
                                                    <template v-slot:right>
                                                        <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                                    </template>
                                                </z-button>
                                            </template>
                                            <template v-slot:bottom-right>
                                                <card-icon
                                                    theme="white"
                                                    size="m"
                                                >
                                                    <div><img :src="item.icon" /></div>
                                                </card-icon>
                                            </template>
                                        </card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="row" equal-height=".card-group">
                <div class="col-default-4 col-mobile-12">
                    <card
                        style="min-height: 558px"
                        class="card-group"
                        equal-height-max-breakpoint="mobile"
                        fullWidth
                    >
                        <template v-slot:title>{{ text.security }}</template>
                        <template v-slot:content>
                            <div v-html="text.securityContent"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" :href="lang !== 'ru' ? '/en/client/security/' : '/ru/client/security/'" kind="text">
                                {{ text.more }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="grey"
                                size="l"
                            >
                                <z-icon name="safety" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <div class="col-default-4 col-mobile-12">
                    <card
                        style="min-height: 558px"
                        class="card-group"
                        equal-height-max-breakpoint="mobile"
                        fullWidth
                    >
                        <template v-slot:title>{{ text.howToBeAClient }}</template>
                        <template v-slot:content>
                            <div v-html="text.howToBeAClientContent"></div>
                        </template>
                        <template v-slot:bottom-left>
                            <z-button tag="a" :href="lang !== 'ru' ? '/en/client/userguide/' : '/ru/client/userguide/'" kind="text">
                                {{ text.more }}
                                <template v-slot:right>
                                    <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                                </template>
                            </z-button>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="grey"
                                size="l"
                            >
                                <z-icon name="dogovor" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <div class="col-default-4 col-mobile-12">
                    <div class="card-group" equal-height-max-breakpoint="mobile">
                        <card
                            style="min-height: 240px"
                        >
                            <template v-slot:title>{{ text.contactsTitle }}</template>
                            <template v-slot:content>
                                <div>
                                    <span v-if="data['contacts'].address" class="client__contacts-contact">
                                        <p class="client__contacts-tool">{{text.address}}<p>
                                        <p class="client__contacts-info">{{data['contacts'].address}}</p>
                                    </span>
                                    <span v-if="data['contacts'].fax" class="client__contacts-contact">
                                        <p class="client__contacts-tool">{{text.fax}}<p>
                                        <p class="client__contacts-info">{{data['contacts'].fax}}</p>
                                    </span>
                                    <span v-if="data['contacts'].mail" class="client__contacts-contact">
                                        <p class="client__contacts-tool">{{text.mail}}<p>
                                        <p class="client__contacts-info">{{data['contacts'].mail}}</p>
                                    </span>
                                    <template v-if="data['contacts'].phones">
                                        <span
                                            v-for="(phone,index) in data['contacts'].phones"
                                            :key="index"
                                            class="client__contacts-contact"
                                        >
                                            <p class="client__contacts-tool">{{phone.name}}:</p>
                                            <p class="client__contacts-info">{{phone.number}}</p>
                                        </span>
                                    </template>
                                </div>
                            </template>
                        </card>
                        <card
                            style="min-height: 237px"
                            class="contacts__app-card"
                        >
                            <template v-slot:title>{{ text.mobileApp }}</template>
                            <template v-slot:content>
                                <div class="mobile__buttons">
                                    <z-button kind="secondary" theme="black" tag="a" href="https://play.google.com/store/apps/details?id=ru.rlisystems.mobile.rms&hl=ru" class="mobile__button">
                                        <template v-slot:left><z-icon width="48" height="44" name="googleplay"></z-icon></template>
                                        <div class="mobile__button-text">
                                            <p class="mobile__description">{{text.mobileDescription.google}}</p>
                                            <p class="mobile__title">Google play</p>
                                        </div>
                                    </z-button>
                                    <z-button kind="secondary" theme="black" tag="a" href="https://apps.apple.com/ru/app/%D1%80%D0%BE%D0%BB%D0%B8%D1%81/id1368418025" class="mobile__button">
                                        <template v-slot:left><z-icon width="45" height="44" name="appstore"></z-icon></template>
                                        <div class="mobile__button-text">
                                            <p class="mobile__description">{{text.mobileDescription.apple}}</p>
                                            <p class="mobile__title">App store</p>
                                        </div>
                                    </z-button>
                                </div>
                                <div>
                                    <z-link
                                        :href="lang !== 'ru' ? '/en/app/' : '/ru/app/'"
                                        :underline="false"
                                        theme="dark-grey"
                                    >
                                        <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                        <span>{{text.mobileLink}}</span>
                                    </z-link>
                                </div>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'client',
    data () {
        return {
            data: this.$root.app.components['client'],
            lang: this.$root.lang,
            text: {
                services: localize({
                    'ru': 'Услуги терминалов',
                    'en': 'Services',
                    'cn': '码头服务'
                }),
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more',
                    'cn': '了解详情'
                }),
                portalTitle: localize({
                    'ru': 'Единый клиентский портал',
                    'en': 'Client portal',
                    'cn': '统一客户平台'
                }),
                signin: localize({
                    'ru': 'Войти в&nbsp;личный кабинет',
                    'en': 'Sign in',
                    'cn': '登录您的个人帐户'
                }),
                portalText: localize({
                    'ru': 'Подать электронную заявку, отследить и&nbsp;подписать документы электронной подписью',
                    'en': 'Submit an&nbsp;electronic application, track and sign documents electronically',
                    'cn': '提交电子申请，<br>使用电子签名跟踪和签署文件'
                }),
                onlineServicesTitle: localize({
                    'ru': 'Online сервисы',
                    'en': 'Interactive tools',
                    'cn': '在线服务'
                }),
                onlineServicesText: localize({
                    'ru': 'Посмотреть информацию о судозаходах и поездах, отслеживание грузов',
                    'en': 'View information on calls and trains, cargo tracking',
                    'cn': '查询船舶和⽕车进港信息，货物跟踪'
                }),
                contactsTitle: localize({
                    'ru': 'Контакты',
                    'en': 'Contacts',
                    'cn': '联系⽅式'
                }),
                security: localize({
                    'ru': 'Безопасность',
                    'en': 'Security',
                    'cn': '安全性'
                }),
                securityContent: localize({
                    'ru': 'Обеспечение промышленной безопасности является нашей первоочередной задачей. Global Ports&nbsp;&mdash; ответственная компания, чьи продуманные и&nbsp;взвешенные действия укрепляют ее&nbsp;значимость в&nbsp;глазах акционеров и&nbsp;представителей бизнеса.',
                    'en': 'Safety remains the highest priority for the business. We&nbsp;are a&nbsp;responsible organization and by&nbsp;acting appropriately we&nbsp;create value for the business and our stakeholders.',
                    'cn': '确保⼯艺安全是我们的⾸要任务。Global Ports 是⼀家负责任的公司，其深思熟虑和谨慎的⾏动加强了其在股东和企业代表眼中的价值。'
                }),
                howToBeAClient: localize({
                    'ru': 'Как стать клиентом Группы Global Ports',
                    'en': 'How to become a Global Ports Group customer',
                    'cn': '如何成为Global Ports 的客户'
                }),
                howToBeAClientContent: localize({
                    'ru': 'Личный Кабинет Клиента Global Ports позволяет использовать удобства и&nbsp;преимущества полного цикла электронного документооборота&nbsp;&mdash; договорного, грузового и&nbsp;финансового.',
                    'en': 'Global Ports Customer Portal is&nbsp;aimed to&nbsp;ensure ease of&nbsp;doing business by&nbsp;providing access to&nbsp;full scale of&nbsp;E-commerce: contractual, operational and financial electronic documentation flow.',
                    'cn': 'Global Ports的个⼈帐户可以让您享受到⽂件电⼦化带来的便利和优势。'
                }),
                address: localize({
                    'ru': 'Адрес:',
                    'en': 'Address:'
                }),
                fax: localize({
                    'ru': 'Факс:',
                    'en': 'Fax:'
                }),
                mail: localize({
                    'ru': 'E-mail:',
                    'en': 'E-mail:',
                    'cn': '邮箱:'
                }),
                mobileApp: localize({
                    'ru': 'Мобильный клиентский портал',
                    'en': 'Mobile client portal',
                    'cn': '移动客户平台'
                }),
                mobileLink: localize({
                    'ru': 'Информация о приложении',
                    'en': 'More info',
                    'cn': '应用信息'
                }),
                mobileDescription: {
                    google: localize({
                        'ru': 'Доступно на',
                        'en': 'Got it on',
                        'cn': '可玩'
                    }),
                    apple: localize({
                        'ru': 'Загрузите в',
                        'en': 'Download on the',
                        'cn': '下载'
                    })
                }
            }
        }
    },
    computed: {
        services () {
            let services = {
                main: [],
                others: []
            }

            this.data.services.forEach((item, i) => {
                if (item.main) {
                    services.main.push(item)
                } else {
                    services.others.push(item)
                }
            })

            return services
        }
    }
}
</script>

<style lang="scss">
    .client {
        margin-top: 2rem;
        margin-bottom: 4rem;
        &__contacts {
            &-contact {
                display: flex;
            }
            &-tool {
                flex-basis: 80px;
                color: $token-colors-lighter-grey;
                flex-shrink: 0;
            }
            &-info {
                color: $token-colors-darker-grey;
                flex-basis: 280px;
                word-break: break-all;
            }
        }
        .contacts__app {
            &-button {
                cursor: pointer;
                border-radius: 8px;
                border: 0px solid $token-colors-lighter-grey;
            }
            &-card {
                margin-top: 2rem;
            }
        }
        .mobile {
            &__buttons {
                margin-bottom: 24px;
                margin-top: -12px;
                flex-wrap: wrap;
            }
            &__button {
                border-radius: 8px;
                border: 1px solid $token-colors-lighter-grey;
                width: 160px;
                height: 48px;
                background-color: transparent;
                outline: none;
                color: black;
                margin-right: 12px;
                margin-top: 12px;
                &-text {
                    text-align: left;
                }
            }
            &__button:nth-child(2) {
                margin-right: 0;
            }
            &__description {
                font-size: 8px;
                line-height: 150%;
                color: $token-colors-dark-grey;
                margin-bottom: 0;
                margin-top: 4px;
                text-transform: uppercase;
            }
            &__title {
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                color: $token-colors-dark-grey;
                margin-bottom: 0;
            }
        }
    }
</style>

<template src="./index.html"></template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'navigation-mobile',
    data () {
        return {
            navOpened: false,
            navIndexOpened: -1,
            heightStyles: {},
            list: this.$root.app.components.navigation.main,
            closeText: localize({
                'ru': 'Меню сайта',
                'en': 'Site menu',
                'cn': 'Site menu'
            }),
            reviewText: localize({
                'ru': 'Обзор',
                'en': 'Overview',
                'cn': 'Overview'
            }),
            subscribeText: localize({
                'ru': 'Подписаться на&nbsp;новости',
                'en': 'Subscribe',
                'cn': '订阅'
            }),
            sitemapText: localize({
                'ru': 'Карта сайта',
                'en': 'Sitemap',
                'cn': '网站地图'
            }),
            privacyText: localize({
                'ru': 'Политика конфиденциальности',
                'en': 'Privacy Regulations',
                'cn': '隐私政策'
            }),
            hotlineText: localize({
                'ru': 'Горячая линия СБ',
                'en': 'Security Hotline',
                'cn': '热线'
            }),
            hotlineMore: localize({
                'ru': 'Подробная информация',
                'en': 'Detailed information',
                'cn': '阅读更多'
            }),
            hotlineUrl: localize({
                'ru': '/ru/hotline/',
                'en': '/en/hotline/',
                'cn': '/en/hotline/'
            }),
            socText: localize({
                'ru': 'Мы в соцсетях',
                'en': 'Social media',
                'cn': '社交平台'
            }),
            portalText: localize({
                'ru': 'Единый клиентский портал',
                'en': 'Client portal',
                'cn': '统一客户平台'
            }),
            signInText: localize({
                'ru': 'Войти в&nbsp;личный кабинет',
                'en': 'Sign&nbsp;in',
                'cn': '登入个人帐户'
            }),
            callCenterText: localize({
                'ru': 'Сервисный колл-центр',
                'en': 'Service call center',
                'cn': '服务热线'
            })
        }
    },
    updated () {
        this.matchHeight()
    },
    methods: {
        matchHeight () {
            if (this.navIndexOpened !== -1) {
                let heightString = this.$refs.menuHeight[this.navIndexOpened].clientHeight + 'px'
                this.$refs.menuHeightStyle.setAttribute('style', `min-height: ${heightString}`)
                // fix landscape mode 1st level showing
                this.$refs.menuHeight[this.navIndexOpened].setAttribute('style', `background-color: #F4F4F4`)
            } else {
                this.$refs.menuHeightStyle.setAttribute('style', ``)
            }
        },
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : '_self'
        },
        checkOverviewActive (link) {
            return location.pathname === link
        },
        showChilds (event, i, item) {
            if (!item.hasOwnProperty('items')) {
                this.hideChilds()
            } else if (item.hasOwnProperty('items') && item.items.length && this.navIndexOpened !== i) {
                event.preventDefault()
                this.navIndexOpened = i
            }
        },
        hideChilds () {
            this.navIndexOpened = -1
        },
        toggleNav () {
            if (!this.navOpened) {
                this.navOpened = true
                this.disableScroll()
                this.$root.$bus.$emit('navigation-open')
            } else {
                this.navOpened = false
                this.enableScroll()
                this.$root.$bus.$emit('navigation-close')
            }
        },
        disableScroll () {
            if (document.body.style.position !== 'fixed') {
                document.body.style.position = 'fixed'
                document.body.style.top = `0`
                document.body.style.width = '100%'
            }
        },
        enableScroll () {
            document.body.style.position = ''
            document.body.style.top = ''
            document.body.style.width = ''
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

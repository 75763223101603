/* eslint-disable no-undef */
<template>
    <div class="careers-vacancies">
        <div class="careers-vacancies__features">
            <div class="row">
                <div class="col-default-8 col-tablet-12">
                    <div class="row">
                        <div
                            class="careers-vacancies__icon-item col-default-6 col-tablet-4 col-mobile-6"
                            v-for="(indicator, i) in indicators[section]"
                            :key="i"
                        >
                            <z-key-indicator
                                :name="indicator.name"
                                :class="(i === 0 || i === 1) && `u-collapse--all`"
                            >
                                <template v-slot:icon>
                                    <img
                                        :src="`/images/careers/vacancies/icons/${indicator.icon}.svg`"
                                    >
                                </template>
                            </z-key-indicator>
                        </div>
                    </div>
                </div>
                <div class="col-default-4 col-tablet-12" ref="photo-wrapper">
                    <img
                        v-for="(photo, i) in photos[section]"
                        :key="i"
                        :ref="`photo-${i}`"
                        :src="photo"
                        class="careers-vacancies__features-photo"
                    />
                </div>
            </div>
        </div>
        <div class="careers-vacancies__jobs-section" v-if="lang === 'ru'">
            <h2>{{text.vacancies}}</h2>
            <z-select
                v-if="cities"
                name="cities"
                ref="vmCities"
                @change="citiesChanged"
                :data="cities"
                :placeholder="text.placeholders.cities"
                class="careers-vacancies__jobs-filter"
            >
                <template v-slot:icon-arrow>
                    <z-icon name="arrow" dir="down" width="16" height="16"></z-icon>
                </template>
                <template v-slot:icon-clear>
                    <z-icon name="close" width="12" height="12"></z-icon>
                </template>
            </z-select>
            <z-button
                v-if="params.city && params.city.length"
                kind="text"
                theme="grey"
                @click="filterReset"
                class="news__reset"
            >{{ text.reset }}</z-button>
            <z-accordion v-if="vacancies.items.length || Object.keys(vacancies.items).length" class="careers-vacancies__jobs-list">
                <z-accordion-item
                    v-for="(item, index) in vacancies.items"
                    :key="index"
                    :id="String(index)"
                >
                    <template v-slot:icon>
                        <z-icon name="arrow" dir="down" height="16"></z-icon>
                    </template>
                    <template v-slot:header>
                        <span v-html="item.name"></span>
                        <span class="lighter-grey-text" v-html="item.city"></span>
                        <span class="lighter-grey-text" v-html="item.salary"></span>
                    </template>
                    <template v-slot:body>
                        <div class="careers-vacancies__jobs-info">
                            <div v-html="item.experience"></div>
                            <div v-html="item.employment"></div>
                            <div class="html-content" v-html="item.detail"></div>
                            <z-button
                                @click="openForm"
                                kind="primary"
                                rounded
                                tag="button"
                            >{{text.button}}</z-button>
                        </div>
                    </template>
                </z-accordion-item>
            </z-accordion>
            <template v-else>
                <h2>Вакансии в этом городе не найдены :(</h2>
            </template>
        </div>
        <z-modal id="modalVacanciesForm" class="careers-vacancies__modal" @close="modalClosed">
            <div class="careers-vacancies__modal-body">
                <h2>Откликнуться на вакансию</h2>
                <z-input
                    ref="name"
                    name="name"
                    v-model="form.fio"
                    placeholder="ФИО"
                    required
                ></z-input>
                <div class="row between-default">
                    <div class="col-default-6 col-mobile-12">
                        <z-input
                            ref="phone"
                            name="phone"
                            v-model="form.phone"
                            placeholder="Телефон для связи"
                            type="phone"

                            required
                        ></z-input>
                    </div>
                    <div class="col-default-6 col-mobile-12">
                        <z-input
                            ref="email"
                            name="email"
                            v-model="form.mail"
                            type="email"
                            placeholder="Ваш E-mail"
                            required
                        ></z-input>
                    </div>
                </div>
                <z-input
                    ref="message"
                    name="message"
                    v-model="form.message"
                    placeholder="Ваше сообщение"
                ></z-input>
                <div class="careers-vacancies__modal-fileinput" @click="selectResume">
                    Прикрепить резюме
                    <input @change="handleResume" type="file" ref="resume" name="file" style="display: none;">
                    <img src="/images/careers/vacancies/icons/form-scrappy.svg" />
                    <template v-if="form.resume">
                        <span>
                            {{form.resume.name}}
                        </span>
                    </template>
                </div>
                <div class="careers-vacancies__modal-description">
                    Ваши персональные данные обрабатываются сотрудниками департамента по&nbsp;управлению персоналом Группы Global Ports, которые рассмотрят и&nbsp;обработают ваше заявление на&nbsp;объявленную вакансию и&nbsp;свяжутся с&nbsp;вами, в&nbsp;случае если ваша кандидатура соответствует заявленным требованиям вакантной позиции. Если вам необходима дополнительная информация о&nbsp;том, как обрабатываются и&nbsp;используются ваши персональные данные рекрутинговыми командами Группы Global Ports, ознакомьтесь с&nbsp;нашей <z-link href="/ru/terms/privacy-notice/" target="_blank">Политикой конфиденциальности</z-link>.
                </div>
                <div class="careers-vacancies__modal-checkboxes">
                    <div class="careers-vacancies__modal-checkbox">
                        <z-checkbox
                            name="privacy1"
                            @change="handleCheckbox"
                            class="careers-vacancies__modal-checkbox--required"
                            ref="agree1"
                        >
                            Выражаю свое согласие на&nbsp;обработку моих персональных данных для целей, описанных выше, а&nbsp;также для внесения в&nbsp;кадровый резерв на&nbsp;срок, не&nbsp;превышающий 3&nbsp;х лет со&nbsp;дня подачи настоящего заявления<sup>1</sup>.
                        </z-checkbox>
                        <span v-if="form.isSubmitting && !form.checkboxes.includes('privacy1')" class="careers-vacancies__modal-checkbox--error">Поле обязательно для заполнения</span>
                    </div>
                    <div class="careers-vacancies__modal-checkbox">
                        <z-checkbox
                            name="privacy2"
                            @change="handleCheckbox"
                            class="careers-vacancies__modal-checkbox--required"
                            ref="agree2"
                        >
                            <p>Я&nbsp;понимаю и&nbsp;согласен, что:</p>
                            <z-list>
                                <z-list-item>в&nbsp;любое время в&nbsp;процессе отбора и&nbsp;в&nbsp;случае трудоустройства, в&nbsp;любое время в&nbsp;течение периода работы в&nbsp;одной из&nbsp;компаний Группы Global Ports, последняя может осуществлять проверки данных, в&nbsp;том числе связываться с&nbsp;предыдущим работодателями и&nbsp;учебными учреждениями, чтобы оценить правдивость, полноту и&nbsp;точность любой информации, представлений и&nbsp;заявлений, содержащихся в&nbsp;моем заявлении;</z-list-item>
                                <z-list-item>любая недостоверная информация в&nbsp;заявлении или другом документе, запрошенном Группой Global Ports, может привести к&nbsp;увольнению.</z-list-item>
                            </z-list>
                        </z-checkbox>
                        <span v-if="form.isSubmitting && !form.checkboxes.includes('privacy2')" class="careers-vacancies__modal-checkbox--error">Поле обязательно для заполнения</span>
                    </div>
                </div>
                <span class="careers-vacancies__modal-tip">
                    <span>*</span>поля, обязательные для заполнения
                </span>
                <span class="careers-vacancies__modal-tip">
                    <sup>1</sup> <small>Такое согласие на&nbsp;обработку персональных данных может быть отозвано вами до&nbsp;истечения 3х&nbsp;летнего срока путем направления обращения по&nbsp;адресу: <z-link href="mailto:pressa@globalports.com" size="s">job@globalports.com</z-link>. Отправляя настоящее заявление, я&nbsp;подтверждаю, что информация и&nbsp;утверждения, сделанные мною, являются правдивыми, насколько мне известно, исчерпывающими и&nbsp;верными.</small>
                </span>
                <div class="careers-vacancies__modal-buttons">
                    <div class="careers-vacancies__modal-button">
                        <z-button
                            ref="submitbtn"
                            type="submit"
                            @click="submit"
                            rounded
                        >Отправить</z-button>
                    </div>
                    <div class="careers-vacancies__modal-button">
                        <z-button
                            type="button"
                            @click="resetAll"
                            kind="secondary"
                            rounded
                        >Очистить поля ввода</z-button>
                    </div>
                </div>
            </div>
        </z-modal>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { queryString } from '@/utils/queryString'
import { getFilteredVacancies, sendFormData } from '@/api/vacancies'
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'careers-vacancies',
    props: {
        page: {
            type: String,
            default: 'specialists'
        }
    },
    mixins: [mixinDevice],
    data () {
        return {
            lang: this.$root.lang,
            vacancies: this.$root.app.components['vacancies-list'],
            section: (this.$root.app.components['vacancies-list'] && this.$root.app.components['vacancies-list'].section) || this.page,
            params: {},
            photos: {
                specialists: [
                    '/images/careers/vacancies/specialists1.png',
                    '/images/careers/vacancies/specialists2.png',
                    '/images/careers/vacancies/specialists3.png'
                ],
                workers: [
                    '/images/careers/vacancies/workers1.png',
                    '/images/careers/vacancies/workers2.png',
                    '/images/careers/vacancies/workers3.png'
                ],
                beginning: [
                    '/images/careers/vacancies/beginning1.png',
                    '/images/careers/vacancies/beginning2.png',
                    '/images/careers/vacancies/beginning3.png'
                ]
            },
            indicators: {
                specialists: localize({
                    'ru': [
                        {
                            name: 'Работа в&nbsp;международной компании-лидере отрасли',
                            icon: 'about-1'
                        },
                        {
                            name: 'Современные технологии ведения бизнеса',
                            icon: 'globe'
                        },
                        {
                            name: 'Работа в&nbsp;команде профессионалов',
                            icon: 'worker-band'
                        },
                        {
                            name: 'Достойное вознаграждение и&nbsp;годовой бонус',
                            icon: 'job-money'
                        },
                        {
                            name: 'Возможность развития и&nbsp;построения карьеры',
                            icon: 'hands-shake'
                        },
                        {
                            name: 'Тренинги и&nbsp;тимбилдинги',
                            icon: 'teambuilding'
                        },
                        {
                            name: 'Корпоративный фитнес',
                            icon: 'sport-items'
                        },
                        {
                            name: 'Реальные бизнес-проекты',
                            icon: 'business-reports'
                        },
                        {
                            name: 'Социальная ответственность и&nbsp;гарантии',
                            icon: 'teambuilding2'
                        },
                        {
                            name: 'Корпоративная программа медицинского страхования',
                            icon: 'hands-safe'
                        }
                    ],
                    'en': [
                        {
                            name: 'Work in&nbsp;an&nbsp;international company&nbsp;&mdash; an&nbsp;industry leader',
                            icon: 'about-1'
                        },
                        {
                            name: 'Modern business technologies',
                            icon: 'globe'
                        },
                        {
                            name: 'Teamwork of professionals',
                            icon: 'worker-band'
                        },
                        {
                            name: 'Decent salary and annual bonus',
                            icon: 'job-money'
                        },
                        {
                            name: 'Career development and opportunity',
                            icon: 'hands-shake'
                        },
                        {
                            name: 'Trainings and teambuilding',
                            icon: 'teambuilding'
                        },
                        {
                            name: 'Corporate fitness',
                            icon: 'sport-items'
                        },
                        {
                            name: 'Real business projects',
                            icon: 'business-reports'
                        },
                        {
                            name: 'Social responsibility and guarantees',
                            icon: 'teambuilding2'
                        },
                        {
                            name: 'Corporate health insurance program',
                            icon: 'hands-safe'
                        }
                    ]
                }),
                workers: localize({
                    'ru': [
                        {
                            name: 'Стабильная международная компания более 20&nbsp;лет на&nbsp;рынке',
                            icon: 'about-1'
                        },
                        {
                            name: 'Применение лучших мировых практик',
                            icon: 'globe'
                        },
                        {
                            name: 'Востребованные профессии в&nbsp;разных сферах бизнеса',
                            icon: 'job-contract'
                        },
                        {
                            name: '100% официальный доход и&nbsp;годовой бонус',
                            icon: 'job-money'
                        },
                        {
                            name: 'Возможность развития и&nbsp;построения карьеры',
                            icon: 'hands-shake'
                        },
                        {
                            name: 'Бесплатные медосмотры, корпоративная спецодежда',
                            icon: 'hands-safe'
                        },
                        {
                            name: 'Служебная развозка от метро',
                            icon: 'job-bus'
                        },
                        {
                            name: 'Работа в команде профессионалов',
                            icon: 'worker-band'
                        },
                        {
                            name: 'Социальная ответственность и&nbsp;гарантии',
                            icon: 'teambuilding2'
                        },
                        {
                            name: 'Столовая на&nbsp;территории, круглосуточный медпункт',
                            icon: 'job-food'
                        }
                    ],
                    'en': [
                        {
                            name: 'International stable company more than 20&nbsp;years in&nbsp;the market',
                            icon: 'about-1'
                        },
                        {
                            name: 'Application of&nbsp;world best practices',
                            icon: 'globe'
                        },
                        {
                            name: 'Popular professions in&nbsp;various business fields',
                            icon: 'job-contract'
                        },
                        {
                            name: 'Decent salary and annual bonus',
                            icon: 'job-money'
                        },
                        {
                            name: 'Career development and opportunity',
                            icon: 'hands-shake'
                        },
                        {
                            name: 'Free medical examinations and corporate uniforms',
                            icon: 'hands-safe'
                        },
                        {
                            name: 'Service delivery from the metro',
                            icon: 'job-bus'
                        },
                        {
                            name: 'Teamwork of&nbsp;professionals',
                            icon: 'worker-band'
                        },
                        {
                            name: 'Social responsibility and guarantees',
                            icon: 'teambuilding2'
                        },
                        {
                            name: '24-hour medical center and dining room on-site',
                            icon: 'job-food'
                        }
                    ]
                }),
                beginning: localize({
                    'ru': [
                        {
                            name: 'Стабильная международная компания более 20&nbsp;лет на&nbsp;рынке',
                            icon: 'about-1'
                        },
                        {
                            name: 'Применение лучших мировых практик',
                            icon: 'globe'
                        },
                        {
                            name: 'Востребованные профессии в&nbsp;разных сферах бизнеса',
                            icon: 'job-contract'
                        },
                        {
                            name: '100% официальный доход и&nbsp;годовой бонус',
                            icon: 'job-money'
                        },
                        {
                            name: 'Реальные возможности карьерного и&nbsp;профессионального роста',
                            icon: 'hands-shake'
                        },
                        {
                            name: 'Интересные разноплановые задачи',
                            icon: 'business-reports'
                        },
                        {
                            name: 'Работа в команде профессионалов',
                            icon: 'worker-band'
                        },
                        {
                            name: 'Социальная ответственность и&nbsp;гарантии',
                            icon: 'teambuilding2'
                        }
                    ],
                    'en': [
                        {
                            name: 'International stable company more than 20&nbsp;years in&nbsp;the market',
                            icon: 'about-1'
                        },
                        {
                            name: 'Application of&nbsp;world best practices',
                            icon: 'globe'
                        },
                        {
                            name: 'Popular professions in&nbsp;various business fields',
                            icon: 'job-contract'
                        },
                        {
                            name: 'Decent salary and annual bonus',
                            icon: 'job-money'
                        },
                        {
                            name: 'Real career opportunities and professional growth',
                            icon: 'hands-shake'
                        },
                        {
                            name: 'Interesting and diverse tasks',
                            icon: 'business-reports'
                        },
                        {
                            name: 'Teamwork of&nbsp;professionals',
                            icon: 'worker-band'
                        },
                        {
                            name: 'Social responsibility',
                            icon: 'teambuilding2'
                        }
                    ]
                })
            },
            text: {
                reset: localize({
                    'ru': 'Сбросить фильтр',
                    'en': 'Reset'
                }),
                vacancies: localize({
                    'ru': 'Вакансии',
                    'en': 'Vacancies??'
                }),
                placeholders: {
                    cities: localize({
                        'ru': 'По городам',
                        'en': 'By cities'
                    })
                },
                button: localize({
                    'ru': 'Откликнуться на вакансию',
                    'en': 'Otkliknyca na vakansiyu'
                }),
                success: localize({
                    'ru': 'Отправка успешна',
                    'en': 'Sending successful'
                }),
                error: localize({
                    'ru': 'Ошибка отправки',
                    'en': 'Sending error'
                })
            },
            form: {
                fio: '',
                phone: '',
                mail: '',
                message: '',
                resume: '',
                checkboxes: [],
                isSubmitting: false,
                isSent: false
            },
            gKey: '6LcWjt4UAAAAAEHUXE1KYNBAgxm5gDuWtCfi3ra5'
        }
    },
    mounted () {
        window.addEventListener('resize', () => {
            this.getAutoMargin()
        })
    },
    computed: {
        cities () {
            let resultCities = []
            this.vacancies.cities.map(city => {
                let tmp = {}
                if (city === this.params.city) {
                    tmp = {id: city, selected: true, text: city}
                } else {
                    tmp = {id: city, selected: false, text: city}
                }
                resultCities.push(tmp)
            })
            return resultCities
        },
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        }
    },
    created () {
        const script = document.createElement('script')

        script.async = true
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.gKey}`

        document.head.appendChild(script)

        this.params = this.$root.app.system.params.get

        if (this.params.city !== undefined) this.citiesChanged({value: this.params.city})

        window.addEventListener('load', () => {
            this.getAutoMargin()
        })
    },
    methods: {
        filterReset () {
            this.$refs.vmCities.removeSelected()
            this.params.city = ''

            this.citiesChanged({value: this.params.city})
        },
        getAutoMargin () {
            let photoWrapperHeight = this.$refs['photo-wrapper'].offsetHeight
            let photoHeight = this.$refs['photo-0'][0].offsetHeight
            if (this.isTablet() || this.isMobile() || this.isVMobile()) {
                this.$refs['photo-1'][0].style = ''
                this.$refs['photo-2'][0].style = ''
            } else {
                this.$refs['photo-1'][0].style.marginTop = (photoWrapperHeight - photoHeight * 3) / 2 - 19 + 'px'
                this.$refs['photo-2'][0].style.marginTop = (photoWrapperHeight - photoHeight * 3) / 2 - 19 + 'px'
            }
        },
        valid () {
            this.$refs.name.valid()
            this.$refs.email.valid()
            this.$refs.phone.valid()

            let valid = !this.$refs.name.error &&
                        !this.$refs.email.error &&
                        !this.$refs.phone.error &&
                        this.form.checkboxes.length === 2

            return valid
        },
        modalClosed () {
            this.form.checkboxes = []
        },
        citiesChanged (cities) {
            const {value} = cities

            this.params = {
                city: value,
                section: this.section
            }

            queryString(this.clearEmptyParams)

            getFilteredVacancies(this.params).then(res => {
                this.vacancies.items = res.items
            })
        },
        openForm () {
            this.$root.$bus.$emit('open-modal', {
                id: `modalVacanciesForm`,
                index: 0
            })
        },
        selectResume () {
            this.$refs.resume.click()
        },
        handleResume () {
            this.form.resume = this.$refs.resume.files[0]
        },
        handleCheckbox (data) {
            const { name, value } = data
            if (!this.form.checkboxes.includes(name) && value) {
                this.form.checkboxes.push(name)
            } else {
                let index = this.form.checkboxes.indexOf(name)
                this.form.checkboxes.splice(index, 1)
            }
        },
        submit () {
            this.form.isSubmitting = true
            let isValid = this.valid()
            if (isValid) this.send(this.form, this.gKey, this)
        },
        send: debounce(500, (params, key, that) => {
            const submitBtn = that.$refs.submitbtn.$el

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(key, {
                    action: 'vacancyFormCaptcha'
                }).then((token) => {
                    let data = new FormData()

                    params.vacancyFormCaptcha = token

                    for (let key in params) {
                        data.append(key, params[key])
                    }

                    submitBtn.disabled = true

                    sendFormData(data).then(res => {
                        showNotyfications(res.status === 'success' ? 'Отправка успешна' : 'Ошибка отправки', { type: res.status })
                        params.isSent = res.status
                        params.isSubmitting = false

                        if (params.isSent === 'success') {
                            let closeBtn = document.querySelector('.z-modal__close')
                            closeBtn.click()
                            that.resetAll()
                            submitBtn.disabled = false
                        }
                    })
                }, (reason) => {
                    showNotyfications(reason, { type: 'error' })
                })
            })
        }),
        resetAll () {
            this.form = {
                ...this.form,
                fio: '',
                phone: '',
                mail: '',
                message: '',
                resume: '',
                isSubmitting: false,
                isSent: false
            }
            this.$refs.agree1.change(false)
            this.$refs.agree2.change(false)
        }
    }
}
</script>

<style lang="scss">
.careers-vacancies {
    margin-bottom: 2rem;

    .row {
        margin-top: 0;
    }

    &__features {
        .z-key-indicator {
            margin-top: 1.5rem;

            @include breakpoint(tablet) {
                margin-top: 0;
                margin-bottom: 1.5rem !important;
            }
        }

        .z-key-indicator__icon {
            min-width: 6rem;
        }

        .z-key-indicator__name {
            max-width: 9.375rem;
            margin-top: 0;

            @include breakpoint(mobile) {
                margin-top: 2rem;
            }
        }

        &-photo {
            &:nth-child(n+2) {
                margin-top: 2rem;

                @media screen and (max-width: 1360px) {
                    margin-top: 3rem;
                }
            }

            @include breakpoint(tablet) {
                width: calc(100%/3 - 1.5rem);

                &:nth-child(n+2) {
                    margin-top: 0;
                    margin-left: 1.5rem;
                }
            }

            @include breakpoint(mobile) {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 1.5rem;
                    margin-left: 0;
                }

                &:first-child {
                    margin-top: 1.5rem;
                }
            }
        }

        @include breakpoint(mobile) {
            .z-key-indicator {
                flex-direction: column;
            }
        }
    }

    &__jobs {
        &-section {
            margin-top: 5rem;

            .news__reset {
                margin-top: 1rem;
            }

            .z-select-multi {
                flex: 0 1 15rem;
                box-shadow: none;
                border-bottom: 1px solid #C4C4C4;
                border-radius: 0;
                max-width: 15rem;

                &__container {
                    min-height: 2rem;
                    padding: 0.25rem 0;
                }
            }

            @include breakpoint(mobile) {
                margin-top: 2.5rem;
            }
        }

        &-filter {
            margin-top: 4rem;

            @include breakpoint(mobile) {
                margin-top: 1.5rem;
            }
        }

        &-list {
            margin-top: 4rem;

            .z-accordion-item__header {
                padding: 2rem 1rem;

                &-button {
                    top: 50%;
                    margin-top: -1.125rem;
                }

                @include breakpoint(mobile) {
                    padding: 1rem;
                }
            }

            .z-accordion-item__header-title {
                & > span {
                    &:first-child {
                        flex-grow: 4;

                        @include breakpoint(v-mobile) {
                            max-width: 280px;
                        }
                    }

                    &:nth-child(n + 2) {
                        flex-grow: 2;

                        @include breakpoint(mobile) {
                            padding: 0.5rem 0;
                        }
                    }
                }

                @include breakpoint(mobile) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            @include breakpoint(mobile) {
                margin-top: 1.5rem;

                .z-accordion-item__body {
                    margin: 1rem;
                }
            }
        }

        &-info {
            & > div {
                &:nth-child(-n + 2) {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 140%;
                    color: $token-colors-lighter-grey;

                    @include breakpoint(mobile) {
                        font-size: 17px;
                    }
                }

                &:last-of-type {
                    margin-top: 2rem;
                }
            }

            .z-button {
                margin-top: 3rem;

                @include breakpoint(mobile) {
                    margin-top: 2rem;
                    width: 100%;
                }
            }
        }
    }

    &__modal {
        &-body {
            .z-input {
                height: 2.125rem;
                padding-bottom: 1rem;
                margin-bottom: 2rem;
                margin-top: 0.5rem;
                border-bottom: 1px solid #C4C4C4;

                input {
                    color: #4F4F4F;
                    line-height: 17px;
                    min-height: 17px;
                }

                &__placeholder {
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 1rem;
                    line-height: 1.5;
                    color: #4F4F4F;
                }

                @include breakpoint(laptop) {
                    margin-bottom: 1rem;
                }
            }

            @include breakpoint(laptop) {
                h2 {
                    font-size: 1.5rem;
                }
            }

        }

        &-fileinput {
            color: #4F4F4F;
            font-weight: bold;
            display: flex;
            margin-top: 2.5rem;
            width: max-content;

            img {
                margin-left: 1rem;
                margin-right: 1rem;
            }

            span {
                font-weight: normal;
            }

            @include breakpoint(laptop) {
                margin-top: 1.5rem;
            }
        }

        &-description {
            margin-top: 3rem;

            @include breakpoint(laptop) {
                margin-top: 1.5rem;
            }
        }

        &-checkboxes {
            margin-top: 2rem;

            @include breakpoint(laptop) {
                margin-top: 1.5rem;
            }
        }

        &-checkbox {
            position: relative;
            max-width: 51.25rem;

            &:nth-child(n + 2) {
                margin-top: 1.5rem;
            }

            &--required {
                position: relative;
                padding-right: 1.25rem;

                &:after {
                    content: '*';
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-family: Open Sans;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: #ED1C24;
                }
            }

            &--error {
                padding: .2rem 0 0;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
                color: #ED1C24;
                font-size: .65rem;
            }
        }

        &-tip {
            display: block;
            margin-bottom: 2rem;
            margin-top: 1.688rem;
            font-size: 1rem;
            line-height: 1.5rem;

            @include breakpoint(laptop) {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            @include breakpoint(mobile) {
                margin-bottom: 1rem;
                margin-top: 1rem;
            }

            @include breakpoint(v-mobile) {
                font-size: .75rem;
            }

            span {
                color: #ED1C24;
                font-family: Open Sans;
                margin-right: 2rem;
            }
        }

        &-buttons {
            margin-top: 3rem;
            display: flex;

            @include breakpoint(laptop) {
                margin-top: 1rem;
            }

            @include breakpoint(mobile) {
                flex-direction: column;
            }
        }

        &-button {
            &:nth-child(n + 2) {
                margin-left: 2rem;

                @include breakpoint(mobile) {
                    margin-left: 0;
                    margin-top: 2rem;
                }
            }

            @include breakpoint(laptop) {
                padding: 4px 16px;
            }

            @include breakpoint(mobile) {
                .z-button {
                    width: 100%;
                }
            }
        }

        .z-modal__html {
            padding: 4rem;

            @include breakpoint(laptop) {
                padding: 2.5rem 3rem;
                margin: 0 auto;
                width: 100%;
            }

            @include breakpoint(mobile) {
                padding: 2rem;
            }
        }

        .z-modal__window {
            @include breakpoint(laptop) {
                max-width: 1010px;
            }
        }

        .z-modal__content {
            max-height: calc(100vh - 6rem);
        }

        @include breakpoint(mobile) {
            .z-modal__window {
                width: 100%;
                overflow: auto;
                height: 100%;
            }

            .z-modal__html {
                width: 100%;
            }

            .z-modal__close {
                top: 1rem;
                right: 1rem;

                path {
                    fill: $token-colors-lighter-grey;
                }
            }
        }
    }

    &__icon-item {
        margin-bottom: 1.5rem;

        @include breakpoint(mobile) {
            display: flex;
            justify-content: center;
        }
    }
}
</style>

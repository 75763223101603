<template>
    <z-list
        :bullets="false"
        tag="ul"
        class="z-filelist"
        :class="[
            `z-filelist--size-${size}`,
            `z-filelist--theme-${theme}`,
            {
                'z-filelist--horizontal': horizontal,
                'z-filelist--vertical': !horizontal
            }
        ]"
    >
        <slot/>
    </z-list>

</template>

<script>
export default {
    name: 'z-filelist',
    props: {
        size: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: 'default'
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
    <blockquote class="z-blockquote">
        <div class="z-blockquote__aside" v-if="slider">
            <div class="z-blockquote__image z-blockquote__image-slider" v-if="image">
                <img :src="image" />
            </div>
        </div>
        <div class="z-blockquote__body" :class="classObject">
            <z-icon v-if="icon" name="quotes" width="17px" height="15px" class="z-blockquote__quotes"></z-icon>
            <span class="z-blockquote__date" v-if="date">
                {{ date | momentFilter('DD MMMM YYYY') }}
            </span>
            <div v-if="!!this.$slots['detail']" class="z-blockquote__detail">
                <slot name="detail"></slot>
            </div>
            <div class="z-blockquote__detail" v-else-if="detail" v-html="detail"></div>
            <span class="z-blockquote__author" v-html="author" v-if="slider && author"></span>
            <span class="z-blockquote__post" v-html="post" v-if="slider && post"></span>
        </div>
        <div class="z-blockquote__aside" v-if="!slider">
            <div class="z-blockquote__image" v-if="image">
                <img :src="image" />
            </div>
            <span class="z-blockquote__author" v-html="author" v-if="author"></span>
            <span class="z-blockquote__post" v-html="post" v-if="post"></span>
        </div>
    </blockquote>
</template>

<script>
export default {
    name: 'z-blockquote',
    props: {
        date: {
            type: [String, Number],
            default: ''
        },
        detail: {
            type: String,
            default: ''
        },
        post: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        author: {
            type: String,
            default: ''
        },
        slider: Boolean,
        icon: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {}
    },
    computed: {
        classObject () {
            let arrClass = [
                {
                    'z-blockquote__body-slider': this.slider,
                    'z-blockquote__body--without-icon': !this.icon
                }
            ]

            return arrClass
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

<template>
  <div class="logistic-scheme">
      <div class="logistic-scheme__list">
          <template v-for="(item, index) in list" >
              <div class="logistic-scheme__item logistic-scheme-item" :key="`item_${index}`">
                  <div class="logistic-scheme-item__icon">
                      <z-icon name="sheep" width="72" height="52"></z-icon>
                  </div>
                  <p class="logistic-scheme-item__title" v-html="item.name"></p>
              </div>

              <div class="logistic-scheme__subitem" v-if="item.subitem" :key="`subitem_${index}`">
                  <div class="logistic-scheme__subitem-arrow"></div>
                  <z-icon class="logistic-scheme__subitem-icon" :name="item.subitem" width="62" height="46"></z-icon>
              </div>
          </template>
      </div>
  </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    data () {
        return {
            list: [
                {
                    name: localize({
                        'ru': 'Азия',
                        'en': 'Asia'
                    }),
                    subitem: 'about-1' // icon name
                },
                {
                    name: localize({
                        'ru': 'ВСК',
                        'en': 'VSC'
                    }),
                    subitem: 'rzd'
                },
                {
                    name: localize({
                        'ru': 'УЛКТ&nbsp;/ ПКТ&nbsp;/&nbsp;ПЛП',
                        'en': 'ULCT&nbsp;/ FCT&nbsp;/&nbsp;PLP'
                    }),
                    subitem: 'about-1'
                },

                {
                    name: localize({
                        'ru': 'Европа',
                        'en': 'Europe'
                    }),
                    subitem: null
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

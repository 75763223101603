<template>
    <div class="widget-fabrikant">
        <div class="widget-fabrikant__wrapper">
            <div id="fabrikant_widget" class="widget-fabrikant__root"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'widget-fabrikant',
    props: {
        widgetKey: {
            type: String,
            default: 'd3a84bb0718b8805b7846b0626e10dcd'
        },
        filter: {
            type: String,
            default: 'actual',
            validator: prop => ['actual', 'archive'].includes(prop)
        }
    },
    mounted () {
        this.initWidget()
    },
    methods: {
        initWidget () {
            const s = document.createElement('script')
            s.type = 'text/javascript'
            s.charset = 'UTF-8'
            s.async = true
            s.src = `https://www.fabrikant.ru/trade-widget/index.php?key=${this.widgetKey}`
            window.location.hash = `!fabrikant/search=&status=${this.filter}`
            const ss = document.getElementsByTagName('script')[0]
            ss.parentNode.insertBefore(s, ss)
        }
    }
}
</script>

<style lang="scss">
.widget-fabrikant {
    overflow-x: auto;

    &__wrapper {
        min-width: 600px;

        @include breakpoint(mobile) {
            padding-bottom: 6px;
        }
    }
}
</style>

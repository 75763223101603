/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M16 .893a.894.894 0 00-.286-.643c-.178-.143-.393-.25-.607-.25H.893C.607 0 .393.107.25.25A.86.86 0 000 .893v14.214c0 .25.071.464.25.643.143.179.357.25.643.25h7.643V9.786H6.464V7.393h2.072V5.607c0-1 .285-1.786.857-2.357.571-.536 1.321-.821 2.25-.821.714 0 1.357.035 1.857.071v2.179h-1.286c-.464 0-.785.107-.964.321-.143.179-.214.464-.214.857v1.536h2.393l-.322 2.393h-2.071V16h4.071a.86.86 0 00.643-.25c.143-.179.25-.393.25-.643V.893z" _fill="#363636"/>'
  }
})

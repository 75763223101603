/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.53 4.47a.75.75 0 00-1.06 0L2.697 9.243a.75.75 0 001.06 1.06L8 6.061l4.243 4.242a.75.75 0 001.06-1.06L8.53 4.47zM8.75 6V5h-1.5v1h1.5z" _fill="#4F4F4F"/>'
  }
})

<template>
<div class="special-equipment-detail">
    <z-button tag="a" href="/ru/sale/" kind="text" class="special-equipment-detail__back">
        <template v-slot:left>
            <z-icon name="arrow" dir="left" width="14" height="14"></z-icon>
        </template>
        {{ text.backTo }}
    </z-button>
    <div class="row">
        <div class="col-default-6 col-mobile-12">
            <slider
                :modal="true"
                :pagination="false"
                :slides='imageList'
            />
            <div class="special-equipment-detail__text html-content" v-html="info.detail"></div>
        </div>
        <div class="col-default-6 col-mobile-12">
            <div class="special-equipment-detail__info">
                <div class="special-equipment-detail__line" v-if="info.mileage.length">
                    <span class="special-equipment-detail__line-title">{{ text.info.mileage }}</span>
                    <span class="special-equipment-detail__line-sub-title">{{ info.mileage }}</span>
                </div>
                <div class="special-equipment-detail__line" v-if="info.garage_room.length">
                    <span class="special-equipment-detail__line-title">{{ text.info.garageRoom }}</span>
                    <span class="special-equipment-detail__line-sub-title">{{ info.garage_room }}</span>
                </div>
                <div class="special-equipment-detail__line" v-if="info.inventory_number.length">
                    <span class="special-equipment-detail__line-title">{{ text.info.inventoryNumber }}</span>
                    <span class="special-equipment-detail__line-sub-title">{{ info.inventory_number }}</span>
                </div>
                <div class="special-equipment-detail__line" v-if="info.mileage_operating.length">
                    <span class="special-equipment-detail__line-title">{{ text.info.mileageOperating }}</span>
                    <span class="special-equipment-detail__line-sub-title">{{ info.mileage_operating }}</span>
                </div>
                <div class="special-equipment-detail__line">
                    <span class="special-equipment-detail__line-title">{{ text.info.psm }}</span>
                    <span class="special-equipment-detail__line-sub-title">{{ psm }}</span>
                </div>
                <div class="special-equipment-detail__line">
                    <span class="special-equipment-detail__line-title">{{ text.info.startPrice }}</span>
                    <span class="special-equipment-detail__line-sub-title">{{ info.price }}</span>
                </div>
            </div>
            <div class="special-equipment-detail__offer">
                <div class="special-equipment-detail__offer-info">
                    <div>
                        <z-button
                            tag="a"
                            href="#"
                            kind="primary"
                            rounded
                            :size="smallBtn ? 'xs' : null"
                            :disabled="info.sold"
                            @click="$root.$bus.$emit('open-modal', {id: 'priceModal', index: 0})">
                            {{ text.priceOrder }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"/>
                            </template>
                        </z-button>
                    </div>
                </div>
            </div>
            <z-filelist>
                <z-filelist-item icon="file_filled">
                    <z-link href="/files/ru/sale_rule.pdf" target="_blank">{{ text.saleRule.title }}</z-link>
                </z-filelist-item>
                <z-filelist-item icon="file_filled" v-if="info.document.path.length">
                    <z-link :href="info.document.path" target="_blank">{{ text.contract.title }}</z-link>
                </z-filelist-item>
            </z-filelist>
        </div>
    </div>
    <z-modal id="priceModal">
        <special-equipment-price
            :id="info.id"
        />
    </z-modal>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'

export default {
    name: 'special-equipment-detail',
    mixins: [mixinDevice],
    data () {
        return {
            text: {
                backTo: localize({
                    'ru': 'Вернуться в каталог',
                    'en': 'Вернуться в каталог'
                }),
                info: {
                    mileage: localize({
                        'ru': 'Пробег',
                        'en': 'Пробег'
                    }),
                    garageRoom: localize({
                        'ru': 'Гаражный номер',
                        'en': 'Гаражный номер'
                    }),
                    inventoryNumber: localize({
                        'ru': 'Инвентарный номер',
                        'en': 'Инвентарный номер'
                    }),
                    mileageOperating: localize({
                        'ru': 'Пробег (наработка)',
                        'en': 'Пробег (наработка)'
                    }),
                    psm: localize({
                        'ru': 'ПСМ',
                        'en': 'ПСМ'
                    }),
                    startPrice: localize({
                        'ru': 'Начальная цена',
                        'en': 'Начальная цена'
                    })
                },
                priceOrder: localize({
                    'ru': 'Предложить цену ',
                    'en': 'Предложить цену '
                }),
                saleRule: {
                    title: localize({
                        'ru': 'Правила продажи техники',
                        'en': 'Правила продажи'
                    })
                },
                contract: {
                    title: localize({
                        'ru': 'Шаблон договора продажи',
                        'en': 'Шаблон договора продажи'
                    })
                }
            },
            info: {}
        }
    },
    created () {
        if (this.$root.app.components['special-equipment-detail']) {
            this.info = this.$root.app.components['special-equipment-detail']
        }
    },
    methods: {
        ...mapActions(['SetHeroSrc'])
    },
    computed: {
        psm () {
            if (this.info.psm) {
                return 'есть'
            } else {
                return 'отсутствует'
            }
        },
        imageList () {
            let imgList = []
            for (let i = 0; i < this.info.images.length; i++) {
                let q = {}
                q.preview = this.info.images[i]
                imgList.push(q)
            }
            return imgList
        },
        fileInfo () {
            return `${this.info.document.size}, ${this.info.document.extension}`
        },
        smallBtn () {
            return this.isTablet()
        },
        buildFileName () {
            const p = /[^a-zа-я0-9]+/g
            let str = this.info.document.name.split('.')[0].replace(p, ' ').replace(/^\w/, (c) => c.toUpperCase())
            return str
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>

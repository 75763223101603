/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-3': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.627 9.708a1 1 0 00-1 1v14.277a1 1 0 001 1h14.128a1 1 0 100-2H6.627V11.708h82.746v12.277h-6.618a1 1 0 100 2h7.618a1 1 0 001-1V10.708a1 1 0 00-1-1H5.627zm48.246 9.139a4.181 4.181 0 014.181 4.181v14.623a3.533 3.533 0 007.066 0V20.668a1.821 1.821 0 013.642 0v16.868a3.648 3.648 0 007.297 0v-15.43c0-1.8 1.46-3.26 3.26-3.26v-2a5.26 5.26 0 00-5.26 5.26v15.43a1.648 1.648 0 11-3.296 0V20.668a3.821 3.821 0 00-7.643 0V37.65a1.533 1.533 0 01-3.066 0V23.028a6.181 6.181 0 00-6.181-6.181v2zm-11.57-.152h-8.41a6 6 0 00-6 6v2.896h20.41v-2.896a6 6 0 00-6-6zm-8.41-2a8 8 0 00-8 8v4.896H30.188v12.12a8.37 8.37 0 007.292 8.301v4.029h1a2.626 2.626 0 11-2.514 3.388 1 1 0 00-1.914.579 4.638 4.638 0 002.072 2.64l-14.066 8.15v17.494h32V68.798L40.76 60.692a4.628 4.628 0 00-1.28-8.543V50.03a8.37 8.37 0 007.447-8.319v-12.12h3.375v-4.896a8 8 0 00-8-8h-8.41zm11.035 12.896H32.189v6.691a8.35 8.35 0 016.37-2.94 8.35 8.35 0 016.369 2.94v-6.69zm-6.37 18.49a6.37 6.37 0 100-12.739 6.37 6.37 0 000 12.739zm0-6.527a.158.158 0 100 .315.158.158 0 000-.315zm0 2a1.842 1.842 0 100-3.685 1.842 1.842 0 000 3.685zm13.5 26.367l-13.62-8.302-14.38 8.332v14.341h28v-14.37zM30.2 72.708v6h-1.072v-6h1.072zm-3.072-2h5.072v10h-5.072v-10zm11.537 8v-6H37.59v6h1.073zm-1.073-8h-2v10h5.073v-10H37.59zm9.536 2v6h-1.072v-6h1.072zm-3.072-2h5.072v10h-5.072v-10z" _fill="#ED1C24"/>'
  }
})

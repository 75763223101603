<template>
    <div class="company">
        <div class="row" equal-height=".card-equal">
            <div class="col-default-4 col-tablet-6 col-mobile-12">
                <card
                    style="min-height: 350px"
                    class="card-equal"
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>{{ text.aboutTitle }}</template>
                    <template v-slot:content>
                        <div v-html="text.aboutText"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" :href="`/${lang}/company/about/`" kind="text">
                            {{ text.more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="grey"
                            size="l"
                        >
                            <z-icon name="captain" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            <div class="col-default-4 col-tablet-6 col-mobile-12">
                <card
                    style="min-height: 350px"
                    class="card-equal"
                    equal-height-max-breakpoint="mobile"
                >
                    <template v-slot:title>{{ text.structureTitle }}</template>
                    <template v-slot:content>
                        <div v-html="text.structureText"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" :href="`/${lang}/company/structure/`" kind="text">
                            {{ text.more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="grey"
                            size="l"
                        >
                            <z-icon name="captain" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            <div class="col-default-4 col-tablet-12">
                <card
                    theme="background"
                    overlay="rgba(0,0,0,0.45)"
                    image="/images/components/investors/governance.jpg"
                    style="min-height: 350px"
                    class="card-equal"
                    equal-height-max-breakpoint="tablet"
                >
                    <template v-slot:title>{{ text.governanceTitle }}</template>
                    <template v-slot:content>
                        <z-list
                            theme="links"
                            :bullets="false"
                        >
                            <!-- <z-list-item>
                                <z-link
                                    :href="`/${lang}/company/governance/management/`"
                                    :underline="false"
                                    theme="white"
                                >
                                    <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                    <span>{{ text.managementLink }}</span>
                                </z-link>
                            </z-list-item> -->
                            <!-- <z-list-item>
                                <z-link
                                    :href="`/${lang}/company/governance/board-of-directors/`"
                                    :underline="false"
                                    theme="white"
                                >
                                    <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                    <span>{{ text.directorsLink }}</span>
                                </z-link>
                            </z-list-item> -->
                            <z-list-item>
                                <z-link
                                    :href="lang !== 'ru' ? '/en/company/governance/documents/' : '/ru/company/governance/documents/'"
                                    :underline="false"
                                    theme="white"
                                >
                                    <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                    <span>{{ text.documentsLink }}</span>
                                </z-link>
                            </z-list-item>
                        </z-list>
                    </template>
                    <!-- <template v-slot:bottom-left>
                        <z-button tag="a" :href="`/${lang}/company/governance/`" kind="text" theme="white">
                            {{ text.more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template> -->
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="white"
                            size="l"
                        >
                            <z-icon name="governance" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            <div class="col-default-12">
                <card
                    theme="background"
                    overlay="rgba(0,0,0,0.45)"
                    image="/images/hero/terminals/terminals.jpg"
                    style="min-height: 285px"
                >
                    <template v-slot:content class="u-bottom-margin--m-desktop">
                        <div class="row">
                            <div class="col-default-5 col-tablet-12" v-html="text.performanceText"></div>
                            <!-- <div class="col-default-3 col-mobile-0"></div>
                            <div class="col-default-4 col-mobile-12">
                                <z-list
                                    class="u-top-margin--xxl-desktop"
                                    theme="links"
                                    :bullets="false"
                                >
                                    <z-list-item>
                                        <z-link
                                            :href="`/${lang}/company/strategy/`"
                                            :underline="false"
                                            theme="white"
                                        >
                                            <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                            <span>{{ text.strategyLink }}</span>
                                        </z-link>
                                    </z-list-item>
                                    <z-list-item>
                                        <z-link
                                            :href="`/${lang}/company/business-model/`"
                                            :underline="false"
                                            theme="white"
                                        >
                                            <z-icon name="link" color="#c4c4c4" width="14" height="14"></z-icon>
                                            <span>{{ text.bussinesLink }}</span>
                                        </z-link>
                                    </z-list-item>
                                </z-list>
                            </div> -->
                        </div>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="white"
                            size="l"
                        >
                            <z-icon name="worker" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
            <div class="col-default-4 col-tablet-12">
                <card
                    style="min-height: 350px"
                >
                    <template v-slot:title>{{ text.terminalsTitle }}</template>
                    <template v-slot:content>
                        <div v-html="text.terminalsText"></div>
                    </template>
                    <template v-slot:bottom-left>
                        <z-button tag="a" :href="`/${lang}/terminals/`" kind="text">
                            {{ text.more }}
                            <template v-slot:right>
                                <z-icon name="arrow" dir="right" width="16" height="16"></z-icon>
                            </template>
                        </z-button>
                    </template>
                    <template v-slot:bottom-right>
                        <card-icon
                            theme="grey"
                            size="l"
                        >
                            <z-icon name="terminals" width="64" height="64"></z-icon>
                        </card-icon>
                    </template>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'mainpage',
    data () {
        return {
            lang: this.$root.lang,
            text: {
                aboutTitle: localize({
                    'ru': 'О нас',
                    'en': 'At a glance',
                    'cn': '关于我们'
                }),
                aboutText: localize({
                    'ru': '№&nbsp;1&nbsp;Оператор контейнерных терминалов, неоспоримый лидер рынка',
                    'en': 'No.&nbsp;1&nbsp;container terminal operator and undisputed market leader',
                    'cn': '排名第一的集装箱码头运营商，无可争议的市场领导者'
                }),
                structureTitle: localize({
                    'ru': 'Структура компании',
                    'en': 'Company Structure',
                    'cn': '集团结构'
                }),
                structureText: localize({
                    'ru': 'Единственный игрок на&nbsp;рынке с&nbsp;сетью терминалов в&nbsp;двух ключевых морских бассейнах России',
                    'en': 'Only player with network of&nbsp;terminals in&nbsp;key container gateways',
                    'cn': '市场上唯一在俄罗斯两个主要海水区中拥有码头网络的参与者'
                }),
                governanceTitle: localize({
                    'ru': 'Корпоративное управление',
                    'en': 'Corporate Governance',
                    'cn': '公司管理'
                }),
                performanceText: localize({
                    'ru': '<p>Приоритетом Глобал Портс является стремление использовать возможности развивающегося рынка за&nbsp;счет создания максимально привлекательных услуг для клиентов и&nbsp;сохранения конкурентоспособного уровня цен.</p>',
                    'en': '<p>The Company strategically focuses on&nbsp;building a&nbsp;strong value proposition for its clients, while maintaining the competitive pricing levels, in&nbsp;order to&nbsp;take advantage of&nbsp;the opportunities offered by&nbsp;the developing market.</p>',
                    'cn': '<p>本集团的首要任务是通过为客户创造最具吸引力的服务和保持有竞争力的价格水平，在不断变化的市场中抓住机遇。</p>'
                }),
                terminalsTitle: localize({
                    'ru': 'Терминалы',
                    'en': 'Terminals',
                    'cn': '码头'
                }),
                terminalsText: localize({
                    'ru': '7&nbsp;контейнерных и&nbsp;многофункциональных морских терминалов в&nbsp;России и&nbsp;Финляндии',
                    'en': '7&nbsp;marine container and multipurpose terminals in&nbsp;Russia and Finland',
                    'cn': '俄罗斯和芬兰的 7 个集装箱和多功能海运码头'
                }),
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more',
                    'cn': '阅读更多'
                }),
                managementLink: localize({
                    'ru': 'Менеджмент',
                    'en': 'Management'
                }),
                directorsLink: localize({
                    'ru': 'Совет директоров',
                    'en': 'Board of directors',
                    'cn': '董事会'
                }),
                documentsLink: localize({
                    'ru': 'Корпоративные документы',
                    'en': 'Documents',
                    'cn': '公司文件'
                }),
                strategyLink: localize({
                    'ru': 'Стратегия',
                    'en': 'Strategy'
                }),
                bussinesLink: localize({
                    'ru': 'Бизнес-модель',
                    'en': 'Business model'
                })

            }
        }
    }
}
</script>

<style lang="scss">
    .company {
        margin-top: 2rem;
        margin-bottom: 4rem;

        @include breakpoint(mobile) {
            .card--with-icon.card--icon-size-l .card__content {
                max-width: 100%;
            }
        }
    }
</style>

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 46.5c12.426 0 22.5-10.074 22.5-22.5S36.426 1.5 24 1.5 1.5 11.574 1.5 24 11.574 46.5 24 46.5zm0 1.5c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z" _fill="#C4C4C4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19.75 31.861l12-6.928a.5.5 0 000-.866l-12-6.928a.5.5 0 00-.75.433v13.856a.5.5 0 00.75.433zm12.75-5.629c1.333-.77 1.333-2.694 0-3.464l-12-6.928c-1.333-.77-3 .192-3 1.732v13.856c0 1.54 1.667 2.502 3 1.732l12-6.928z" _fill="#C4C4C4"/>'
  }
})

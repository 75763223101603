/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ship': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M38.926 31.966v2.8h11.302v-7.75h2.037v-1.988h-2.037v-3.926h-4.255V17h-2.112v4.102h-4.935v3.926h-2.037v1.988h2.037v4.95zm2.522-7.74v-1.339a.45.45 0 01.452-.446h1.358c.25 0 .453.2.453.446v1.34a.45.45 0 01-.453.446H41.9a.45.45 0 01-.452-.446zm4.525 0v-1.339a.45.45 0 01.453-.446h1.433a.46.46 0 01.378.446v1.34c0 .204-.19.409-.378.446h-1.433a.45.45 0 01-.453-.446zM27.17 25.964H20.42v3.914h6.751v-3.914zm-6.751 4.892h6.751v3.914H20.42v-3.914zM16.658 44.26c-1.833 0-3.62.287-5.34.702l-3.375-7.346c-.152-.975.535-1.765 1.536-1.765H55.65c1 0 1.61.775 1.362 1.73l-3.03 7.245c-2.266-.66-4.66-1.195-7.198-1.426-3.136-.288-6.283.546-9.296 1.348-1.27.338-2.471.657-3.663.895-1.155.23-2.326.342-3.58.342-2.506 0-4.922-.447-7.48-.92a96.2 96.2 0 00-3.734-.637 16.584 16.584 0 00-2.373-.168zm18.229-13.405h-6.752v3.914h6.752v-3.914zm-22.184 0h6.751v3.914h-6.752v-3.914zm0-4.892h6.751v3.914h-6.752v-3.914zM7.649 50.86a2.358 2.358 0 01-.77.141c-1.067 0-1.702-.944-1.855-1.877-.127-.766.258-.884 1.136-1.154.211-.065.451-.139.719-.232 1.672-.584 3.144-1.07 4.999-1.519 1.563-.38 3.167-.64 4.78-.64.718 0 1.429.052 2.111.152 1.239.184 2.472.416 3.662.643h.002c2.526.48 5.14.975 7.812.975a19.39 19.39 0 003.94-.388c1.243-.254 2.52-.603 3.757-.94l.019-.006.03-.008c2.855-.78 5.793-1.582 8.627-1.32 2.4.224 4.682.75 6.853 1.398 1.365.408 2.686.862 3.962 1.325.28.1.514.175.71.237.783.247.948.3.816 1.069-.158.916-.78 1.84-1.816 1.84-.245 0-.509-.05-.784-.15l-1.035-.379c-2.913-1.073-6.216-2.289-9.487-2.289-.993 0-1.95.114-2.845.337-.67.167-1.341.343-2.013.518l-.196.051-.02.006c-2.954.771-6.008 1.569-9.05 1.776-4.02.272-7.74-.501-11.367-1.255h-.001a16.43 16.43 0 00-3.375-.35c-3.217 0-6.124.924-9.32 2.039z" _fill="#ED1C24"/>'
  }
})
